.c-situation-relationship-card {
    .c-situation-related-situation-card {
        @include margin-bottom(16px);
        width: 100%;
    }

    &__hotspot {
        @include font-size(12px);
        @include line-height(20px);
        position: absolute;
        right: rem(8px);
        top: rem(8px);
        z-index: 1;
        width: rem(24px);
        height: rem(24px);
        border: 2px solid get-color-neutral("white");
        color: get-color-neutral("white");
        border-radius: 50%;
        display: block;
        font-weight: bold;
        text-align: center;

        &.-situation {
            background: get-color-neutral("70");
        }

        &.-solution {
            background: get-color-status("success-dark");
            position: static;
            flex: 0 0 1.5rem;
        }
    }

    &__paragraph.-solution {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        white-space: normal;
    }
}
