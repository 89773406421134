.c-join-group-on-demand-page {
    background-color: get-color-neutral("05");
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    color: get-color-neutral("90");
    padding-top: rem(80px);

    @include respond-to("tablet") {
        padding-top: 0;
    }

    &__content {
        max-width: rem(1000px);
        width: 100%;

        &__form-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            gap: 1rem 5rem;
            padding: 1rem;

            @include respond-to("tablet") {
                justify-content: center;
                flex-direction: column;
            }

            &__superscript {
                line-height: 0;
            }

            &__header {
                height: 100%;
                width: 100%;
            }

            &__form {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem 0;

                .-text-gap {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem 0;
                }

                h2 {
                    @include font-style($size: "large", $weight: "bold");
                    line-height: 27px;
                    margin-bottom: 0.75rem;
                }

                p {
                    font-size: 16px;
                    margin-bottom: 0.75rem;
                    line-height: 24px;

                    a {
                        font-size: 16px;
                        @include font-style($weight: "bold");
                        color: get-color-status("info-dark");
                    }

                    button {
                        font-size: 16px;
                        @include font-style($weight: "bold");
                        color: get-color-status("info-dark");
                        text-transform: lowercase;
                    }

                    button:hover {
                        text-decoration: underline;
                    }

                    span {
                        line-height: normal;
                    }
                }

                a {
                    font-size: 16px;
                    @include font-style($weight: "bold");
                    color: get-color-status("info-dark");
                    margin-bottom: 0.75rem;
                }

                .-block {
                    display: block;
                }

                button {
                    @include margin-top(8px);
                }
            }
        }
    }
}
