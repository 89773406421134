.c-image-viewer {
    $b: &;

    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    grid-area: content;

    &__left-sidebar {
        $sidebar-width: 20rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: stretch;
        background-color: get-color-background("neutral");
        width: $sidebar-width;
        transition: transform 0.5s ease;
        position: relative;
        height: 100%;
        z-index: 1;

        &.-closed {
            // transform: translateX(-100%);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            transform: translateX(-100%);

            #{$b}__sidebar-toggle {
                right: -1rem;
                transform: translateX(100%);
            }
        }

        @include respond-to("tablet") {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    &__nav {
        @include padding(16);
        @include padding-bottom(48px);
        overflow-y: auto;
        min-width: rem(320);

        &__list {
            display: flex;
            flex-direction: column;
            gap: rem(24);

            &__item {
                @include font-style(
                    $size: get-font-size("xsmall"),
                    $color: get-color-neutral("90"),
                    $line-height: get-line-height("small")
                );
                background-color: inherit;
                text-align: left;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .c-collapse-panel > .c-button {
            top: 16px;
        }
        .c-collapse-panel.-article > .c-button {
            top: 0;
            left: 0;
        }
    }

    &__sidebar-toggle {
        position: absolute;
        top: 1rem;
        right: -1rem;
        transform: translateX(100%);
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond-to("tablet") {
            right: 1rem;
            transform: translateX(0);
        }
    }

    &__content {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: stretch;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    &__image-wrapper {
        flex: 1 1 auto;
        overflow: auto;
        text-align: center;
        width: 100%;
        height: 100%;
        max-height: 100%;
        position: relative;
    }

    &__image {
        height: 100%;
        width: auto;
        display: block;
        margin: 0 auto;

        &.hide-menu {
            pointer-events: none;
        }
        &.-background {
            background-image: var(--nfpa-free-access-uri);
            background-size: cover;
        }
        &.-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    &__image.hide-menu {
        pointer-events: none;
    }

    &__footer {
        align-self: stretch;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // position: fixed;
        // height: 41px;
        @include padding(8px, 4px);
        border-top: 1px solid get-color-neutral("30");
        background-color: get-color-background("primary");
        // bottom: 0;
        // width: 100%;

        // @include respond-to("phone") {
        //     width: 100%;
        // }
    }

    &__wrapper {
        width: 100%;
    }

    &.-free-access {
        #{$b}__left-sidebar {
            padding-top: 1rem;
        }

        #{$b}__sidebar-toggle {
            top: 2rem;
        }
    }

    &.-legacy {
        height: calc(100% - #{$breadcrumb-menu-height});
    }
}
