.c-subscription-choice-card {
    @include padding(48px);
    border: rem(1px) solid get-color-neutral("30");
    border-radius: $border-radius-small;
    max-width: rem(480);

    @include respond-to("phone") {
        padding: 0;
        border: none;
    }

    > p {
        &.-large {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                get-line-height("large")
            );
            @include margin-bottom(8px);
        }
        &.-small {
            color: get-color-neutral("70");
        }
    }

    &__plan {
        @include padding(24px);
        @include margin-top(24px);
        background-color: get-color-accents("blue-light");

        @include respond-to("phone") {
            @include padding(16px);
        }

        > .c-icon {
            vertical-align: text-bottom;
        }
        > h5 {
            @include font-style(
                $font-primary,
                "small",
                get-color-accents("blue-dark"),
                800,
                get-line-height("large")
            );
            @include margin(0, 0, 16px, 8px);
            display: inline-block;
        }

        &__price {
            @include font-style(
                $font-primary,
                "xlarge",
                get-color-neutral("90"),
                300,
                get-line-height("large")
            );
            display: inline;
        }
        &__cycle {
            @include font-style(
                $font-primary,
                "base",
                get-color-neutral("70"),
                300,
                get-line-height("large")
            );
        }

        > p.-xsmall {
            color: get-color-neutral("70");
        }

        .c-subscription-card-feature {
            &:first-of-type {
                @include margin-top(16px);
            }
        }
    }
    &__action {
        display: flex;
        > a {
            @include font-style(
                $font-primary,
                "base",
                get-color-accents("blue-dark"),
                400,
                get-line-height("base")
            );
            @include margin-right(8px);
            @include margin-top(16px);
            @include padding(11, 0);
        }
        > .c-button {
            margin: 0;
            flex-grow: 1;
        }
        &__separator {
            @include font-style(
                $font-primary,
                "base",
                get-color-neutral("70"),
                400,
                get-line-height("base")
            );
            @include margin-right(16px);
            @include margin-top(16px);
            @include padding(11, 0);
        }
    }
}
