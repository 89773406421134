.c-informational-note-section {
    @include margin(24px, 24px, 0, 24px);
    @include font-style($font-primary, "xsmall");
    line-height: 22px;
    color: get-color-neutral("70");

    + .c-exception-section {
        @include margin-top(24px);
    }

    p {
        @include paragraph-styles("xsmall");
        display: inline-block;
        color: inherit;

        .c-publication-anchor {
            &.-not-found {
                font-size: inherit;
                color: inherit;
                font-weight: inherit;
                line-height: inherit;
            }
        }

        &:first-of-type {
            display: inline;

            &.-deletion {
                display: none;
            }
        }

        &.c-figure-section__caption {
            display: inherit;
        }
    }

    a {
        @include font-style(
            $font-primary,
            "xsmall",
            get-color-neutral("70"),
            700,
            22px
        );
    }
}
