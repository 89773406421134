.c-support-admin-dashboard-page {
    $spacer-x: 48px;
    $spacer-y: 16px;
    height: 100%;
    max-height: calc(
        100vh - #{$breadcrumb-menu-height} - #{$support-users-heading-height}
    );
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__content {
        @include padding(48px);
        @include padding-top(0px);
        box-shadow: 0px 9px 10px -4px rgba(0, 26, 57, 0.1);
        &__controls {
            display: flex;
            justify-content: space-between;

            @include if-internet-explorer {
                display: block;
            }

            .c-search-form {
                flex-grow: 0.25;

                @include if-internet-explorer {
                    display: inline-block;
                    width: 25%;
                    max-width: 25%;
                }
            }
        }
    }

    &__heading {
        padding-left: 48px;
    }

    &__table {
        @include respond-to("phone") {
            @include padding(0, 16px);
        }

        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 1rem 0;
        align-items: stretch;
        justify-content: flex-start;
        @include padding(15px, 48px);
    }

    &__footer {
        z-index: 1;
        box-shadow: 0px -9px 10px -4px rgba(0, 26, 57, 0.1);
    }
}
