.c-link-card {
    @include margin-bottom(16px);
    @include padding(16px);
    border: 1px solid get-color-neutral("30");
    background: get-color-neutral("white");
    border-radius: 9px;
    text-decoration: none;
    word-break: break-word;
    width: 100%;
    text-align: left;

    &:focus {
        @include box-shadow(0px, 0px, 5px, get-color-accents("blue-dark"), 1px);
        outline: none;
    }
    &:hover {
        @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));
        background: get-color-neutral("white");
    }

    &.-with-icon {
        @include padding-left(36px);
        position: relative;
        display: block;

        .c-icon {
            @include margin-top(4px);
            min-width: 16px;
            position: absolute;
            left: rem(16px);
            top: rem(14px);

            path {
                fill: get-color-status("success");
            }
        }
    }

    &__content {
        @include padding-left(12px);
        @include padding-right(12px);
        display: flex;
        flex-direction: column;

        p {
            @include ellipsis(block);
        }
    }

    &__label {
        p {
            @include ellipsis(block);
            @include margin-top(8px);
            color: get-color-neutral("70");
            display: block;
            letter-spacing: 0.02em;
        }
    }
}
