.c-code-change {
    $baseClass: &;
    &.-new {
        .c-code-change.-addition {
            background-color: none;
        }
        .c-icon.-change-indicator {
            display: none;
        }
    }
    &.-deletion {
        display: none;
    }

    &.-addition {
        background-color: get-color-accents("orange-light");
    }
}
