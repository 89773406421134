.c-alert-banner {
    @include padding(8px, 24px, 8px, 32px);
    @include font-style(
        $font-primary,
        "xsmall",
        false,
        400,
        get-line-height("xsmall")
    );
    border-radius: $border-radius-small;
    position: relative;

    &__error {
        color: get-color-status("error-dark");
        background-color: get-color-status("error-light");

        a {
            @include font-style(
                $line-height: get-line-height("xsmall"),
                $size: "xsmall",
                $weight: "bold"
            );
            color: get-color-status("error-dark");
        }

        svg {
            path {
                fill: get-color-primary("error");
            }
        }
    }

    &__info {
        color: get-color-accents("blue-dark");
        background-color: get-color-status("info-light");

        a {
            @include font-style(
                $line-height: get-line-height("xsmall"),
                $size: "xsmall",
                $weight: "bold"
            );
            color: get-color-accents("blue-dark");
        }

        svg {
            path {
                fill: get-color-accents("blue-base");
            }
        }
    }

    &__success {
        color: get-color-status("success-dark");
        background-color: get-color-status("success-light");

        a {
            @include font-style(
                $line-height: get-line-height("xsmall"),
                $size: "xsmall",
                $weight: "bold"
            );
            color: get-color-status("success-dark");
        }

        svg {
            path {
                fill: get-color-status("success");
            }
        }
    }

    &__warning {
        color: get-color-status("warning-dark");
        background-color: get-color-status("warning-light");

        a,
        &__button {
            @include font-style(
                $line-height: get-line-height("xsmall"),
                $size: "xsmall",
                $weight: "bold"
            );
            color: get-color-status("warning-dark");
        }

        svg {
            path {
                fill: get-color-status("warning");
            }
        }
    }

    svg {
        position: absolute;
        left: rem(8px);
        top: rem(10px);
    }
}
