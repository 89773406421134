.c-solution-page {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    word-break: break-word;

    &__bottom {
        @include padding(24px);

        @include respond-to("phone") {
            padding-top: 0;
        }
    }

    &__top {
        @include padding(24px, 24px, 0);
        @include icon-fill(get-color-status("success"));
        background: get-color-neutral("white");
        display: flex;
        justify-content: space-between;

        &__solution-icon {
            @include margin-right(8px);
            vertical-align: middle;
        }

        .-solution-label {
            color: get-color-status("success-dark");
        }
    }

    &__content {
        @include padding(16px, 24px);
        @include padding-top(32px);
        max-width: 730px;
        margin: 0 auto;
        > h2 {
            @include margin-bottom(4px);
            @include font-style(
                $size: "small",
                $line-height: get-line-height("small"),
                $color: get-color-neutral("70")
            );
            letter-spacing: 0.02em;
        }
        > h4 {
            @include margin-bottom(16px);
            color: get-color-neutral("90");
        }
    }
    &__body {
        @include paragraph-styles("small");
    }

    .c-tabs {
        @include padding(16px, 24px);
        @include margin-top(48px);
        position: relative;
        left: -24px; // Offset position of tabs to account for padding
        // Make full width of the page including padding
        width: calc(100% + 48px);

        @include respond-to("phone") {
            padding-top: 0;
        }

        h6 {
            @include margin-bottom(8px);
            @include font-style(
                $font-primary,
                "xxsmall",
                get-color-neutral("70"),
                800,
                14px
            );
            text-transform: uppercase;
            letter-spacing: rem(2px);
        }
    }
}
