.c-heading-icon {
    .c-icon {
        @include margin-right(16px);
    }

    .c-icon,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        display: inline-block;
    }
}
