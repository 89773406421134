.c-admin-dashboard {
    padding-top: rem(48px);
    padding-left: rem(48px);
    padding-bottom: rem(48px);

    h3 {
        margin-bottom: rem(32px);
        color: get-color-neutral("90");
    }

    &__section {
        @include margin-bottom(32px);

        p {
            color: get-color-neutral("90");
            margin-bottom: rem(8px);
        }

        &__links {
            .c-button {
                margin-right: rem(16px);
            }
        }
    }
}

.c-support-users-page {
    h3 {
        margin-bottom: rem(32px);
        color: get-color-neutral("90");
    }
}
