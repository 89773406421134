.c-publication-batch-badge {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    height: rem(400px);
    overflow: auto;

    &__badge_button {
        display: flex;
        align-items: center;
        background-color: #f1f1f4;
        border: 1px solid #d4d6de;
        @include border-radius("base");
        @include margin(10px, 5px, 0px, 5px);
    }

    &__badge_text {
        font-size: 0.625rem;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 5px;
    }

    &__badge_icon {
        padding-top: 3px;
        padding-left: 3px;
        padding-right: 3px;
    }

    &__badge_icon:hover {
        cursor: pointer;
        background-color: #ffd9e3;
        color: #d50032;
    }
}
