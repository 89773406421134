.c-alert-notice {
    @include padding(120px, 100px);
    @include icon-fill(get-color-neutral("90"));
    display: flex;
    background-color: get-color-status("info-light");
    color: get-color-neutral("90");
    text-align: center;
    border-radius: $border-radius-medium;
    max-width: rem(820px);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include respond-to("phone") {
        @include padding(44px, 16px);
        max-width: calc(100% - 16px);
    }

    &.-error {
        @include icon-fill(get-color-status("error-dark"));
        background-color: get-color-status("error-light");
        color: get-color-status("error-dark");
    }

    &.-warning {
        @include icon-fill(get-color-status("warning"));
        background-color: get-color-status("warning-light");
        color: get-color-status("warning-dark");
    }

    .c-icon {
        @include margin-bottom(24px);
        height: rem(72px);
        width: rem(72px);
        @include respond-to("phone") {
            height: rem(64px);
            width: rem(64px);
        }
    }

    h1 {
        @include padding(20px);
        @include respond-to("phone") {
            @include font-style($font-primary, "xlarge", false, 100, 34px);
        }
    }

    p {
        @include padding-bottom(40px);
        color: inherit;
    }
}
