.c-bookview-user-preferences {
    height: 100%;
    background-color: get-color-neutral("white");

    @include if-internet-explorer() {
        background-color: transparent;
        margin-top: rem(-8px);
    }

    @include respond-to("lg-tablet") {
        border-left: 1px solid get-color-neutral("30");
    }

    // undo height for when the class is on the mobile modal
    &.c-modal {
        height: unset;

        &.-ec-hidden {
            background-color: get-color-neutral("white");

            &:before {
                display: none;
            }
        }
        .c-modal__content {
            @include margin-bottom(24px);
        }

        .c-menu-button__item {
            margin-bottom: 0;
        }
    }

    .c-menu-button.-ec-hidden {
        background: get-color-accents("blue-light");

        .c-menu-button__icon svg path {
            fill: get-color-accents("blue-dark");
        }

        &:before {
            content: "";
            display: inline-block;
            position: absolute;
            top: rem(4px);
            left: rem(4px);
            width: rem(6px);
            height: rem(6px);
            border-radius: 50%;
            background-color: get-color-status("warning");
            z-index: 1;
        }
    }

    &__expand-option {
        @include padding(8px, 16px);
        @include margin-top(8px);
        background-color: get-color-neutral("05");
        border-radius: $border-radius-medium;
    }

    .c-menu-button {
        &__icon {
            display: flex;
            position: relative;
            height: rem(40px);
            // background-color: transparent;
            border: none;
            border-radius: 0;
            align-items: center;
        }

        &__item {
            @include padding(8px);
            border-radius: $border-radius-small;
            background-color: transparent;

            .c-checkbox {
                @include font-style($size: "xsmall", $line-height: "xsmall");
            }

            &:focus-within:not(:hover) {
                background-color: transparent;
            }
        }

        &__menu {
            @include padding(16px);
            top: rem(48px);
            right: rem(16px);
            min-width: rem(256px);

            &__title {
                @include padding-bottom(16px);
                @include font-style(
                    $color: get-color-neutral("70"),
                    $size: "xxsmall",
                    $line-height: "xxsmall",
                    $weight: "bold"
                );
                letter-spacing: 0.11em;
                text-transform: uppercase;
            }

            &__footer {
                @include margin-top(8px);
            }
        }
    }
}
