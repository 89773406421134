.c-all-publications-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    &__header {
        @include padding(64px, 0, 48px, 0);
        max-width: rem(720px);

        @include respond-to("phone") {
            @include padding(40px, 0, 32px, 0);
        }

        h2 {
            @include font-style(
                $line-height: get-line-height("xxxlarge"),
                $size: "xxxlarge",
                $weight: "lightest"
            );
            color: get-color-neutral("90");
            text-align: center;

            @include respond-to("phone") {
                @include font-style(
                    $line-height: get-line-height("xlarge"),
                    $size: "xlarge",
                    $weight: "lightest"
                );
            }
        }
    }

    &__content-wrapper {
        background-color: get-color-neutral("white");
        width: 100%;
        flex-grow: inherit;
    }

    &__content {
        @include padding-top(16px);
        max-width: rem(800px);
        margin: 0 auto;

        @include respond-to("tablet") {
            @include padding(0, 24px, 24px);
        }

        .c-call-to-action {
            @include margin(40px, 0, 32px, 0);
            width: 100%;

            @include respond-to("phone") {
                flex-direction: column;
            }
        }

        .c-page-navigation-menu {
            padding-left: 0;
            padding-right: 0;
        }

        &__controls {
            @include padding-bottom(32px);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .c-search-form {
                flex-basis: 50%;

                @include respond-to("tablet") {
                    @include margin-right(48px);
                    flex-grow: 1;
                }

                &.-small {
                    @include margin-right(12px);
                    flex-grow: 1;
                }
            }

            &__topics-filter {
                @include rem-fallback(min-width, 212px);
                @include rem-fallback(height, 49px);

                flex-basis: 30%;

                .c-form-field {
                    margin-bottom: 0;
                }
            }
        }
    }
}
