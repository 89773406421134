.c-checkbox {
    @include font-style(
        $font-primary,
        "small",
        get-color-neutral("90"),
        400,
        20px
    );
    @include padding-left(36px);
    @include margin(2px, 0px);
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;

    &.-disabled {
        cursor: not-allowed;

        .c-checkbox__checkmark {
            background: get-color-neutral("05");
            border-color: get-color-neutral("50");

            &:after {
                color: get-color-neutral("70");
            }
        }
    }

    &:hover,
    &:focus {
        &.-disabled {
            .c-checkbox__checkmark {
                box-shadow: none;
            }
        }
        .c-checkbox__checkmark {
            background-color: transparent;
            box-shadow: 0 0 0 3px rgba(0, 176, 202, 0.4);
        }
    }

    &.-checked {
        color: get-color-accents("blue-dark");
        .c-checkbox__checkmark {
            border-color: get-color-accents("blue-dark");
            &:after {
                display: block;
                color: get-color-accents("blue-dark");
            }
        }
    }

    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 1px solid #bdc4c9;
        box-sizing: border-box;
        border-radius: 5px;
        outline: none;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        &:after {
            left: 7px;
            top: 4px;
            width: 5px;
            height: 10px;
            border: solid get-color("neutral", "dark-text");
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:focus {
            border-color: get-color-accents("blue-dark");
            box-shadow: 0 0 0 3px rgba(0, 176, 202, 0.4);
        }
    }
}
