.c-search-form {
    position: relative;
    > input[type="text"] {
        @include focus-outline();
        @include padding-bottom(10px);
        @include padding-right(75px);
        @include padding-top(10px);
        margin-top: 0;
        width: 100%;
        -webkit-appearance: none;

        @include if-internet-explorer {
            @include padding-right(48px);
        }

        @include respond-to("phone") {
            @include font-style($line-height: "xsmall");
        }

        &:focus {
            ~ .c-search-form__clear {
                // compensate for input focus
                border-top: 1px solid get-color-accents("blue-dark");
                border-bottom: 1px solid get-color-accents("blue-dark");
            }
            ~ .c-search-form__submit {
                // compensate for input focus
                border-top: 1px solid get-color-accents("blue-dark");
                border-right: 1px solid get-color-accents("blue-dark");
                border-bottom: 1px solid get-color-accents("blue-dark");
            }
        }
    }

    &__clear.c-button {
        @extend %c-button, .-icon;
        right: rem(45px);
        background-color: transparent;
        border-radius: 0;
        &:after {
            content: "";
            position: absolute;
            right: 0;
            height: rem(24px);
            border-right: 1px solid get-color-neutral("50");
        }
        &.-noDivider {
            &::after {
                content: none;
            }
        }
    }
    &__submit.c-button {
        right: 0;
        padding: 0;
        border-right: 1px solid get-color-neutral("30");
        border-left: none;
        border-radius: 0 5px 5px 0;
    }
    > .c-button {
        position: absolute;
        top: 0;
        width: rem(45px);
        height: 100%;
        // border-bottom: 1px solid get-color-neutral("30");
        border-top: 1px solid get-color-neutral("30");
    }
    &.-small {
        height: rem(32px);
        > input[type="text"] {
            @include font-style(
                $size: "xsmall",
                $line-height: get-line-height("xxsmall")
            );
            @include padding(7px);
            @include padding-right(75px);

            @include if-internet-explorer {
                @include padding-right(48px);
            }
        }
        .c-search-form__clear.c-button,
        .c-search-form__submit.c-button {
            @include if-internet-explorer {
                height: rem(30px);
            }
        }
        .c-search-form__clear.c-button {
            &:after {
                content: "";
                position: absolute;
                top: rem(6px);
                right: 0;
                height: rem(18px);
                border-right: 1px solid get-color-neutral("50");
            }
        }
    }

    &.-secondary {
        > input {
            background-color: get-color-buttons("secondary");
        }
    }
    &.-tertiary {
        > input {
            background-color: get-color-buttons("tertiary");
        }
    }
    &.-tertiary-alt {
        > input {
            background-color: get-color-buttons("tertiary-alt");
        }
    }
}
