.c-toast-message {
    &.-free-access {
        &.-title {
            font-size: get-font-size("xlarge");
            font-weight: get-font-weight("light");
        }
        &.-body {
            font-size: get-font-size("small");
            font-weight: get-font-weight("base");
        }
        &.-button {
            @include margin-top(12px);
        }
        a {
            text-decoration: none;
            font-weight: get-font-weight("bold");
        }
    }
}
