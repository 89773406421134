.c-figure-section {
    @include margin(32px, 0px);
    &.-changed {
        .c-figure-section__caption {
            @include padding-left(32px);
            position: relative;
        }
    }

    &__expandable {
        display: inline-block;
        position: relative;
    }

    &__expander {
        position: absolute;
        top: rem(-16px);
        right: rem(20px);

        @include respond-to("phone") {
            right: rem(-16px);
        }

        button.c-button {
            @include padding(6px);
        }
    }

    &__caption {
        @include margin-top(16px);
        @include font-style(
            $font-primary,
            "small",
            get-color-neutral("70"),
            400,
            22px
        );

        > .c-icon.-change-indicator {
            position: absolute;
            top: 0;
            left: rem(-8px);
        }
    }

    &-modal.-fullscreen {
        @include respond-to("phone") {
            @include padding-top(48px);
        }

        .c-modal {
            &__content {
                text-align: center;
                max-height: vh(78);

                @include respond-to("phone") {
                    height: 100%;
                    max-height: vh(100);
                }
            }
        }
    }

    img {
        @include border-radius("base");
        @include padding(16px);
        border: 1px solid get-color-neutral("30");
        max-width: 100%;
        margin: 0 auto;
    }
}
