.c-my-bookmarks-tab {
    $spacer-x: 48px;
    $spacer-y: 16px;
    height: 100%;
    @include padding-top($spacer-x);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__heading {
        @include padding-left($spacer-x);
        @include padding-right($spacer-x);
        @include padding-bottom($spacer-y);
    }

    &__filter-wrapper {
        z-index: 1;
        box-shadow: 0px 9px 10px -4px rgba(0, 26, 57, 0.1);
    }

    &__filters {
        @include respond-to("phone") {
            @include padding-bottom(12px);
        }
        @include padding-bottom(22px);
        display: flex;
        justify-content: space-between;
        @include padding-left($spacer-x);
        @include padding-right($spacer-x);

        > .c-search-form {
            align-self: flex-start;
            width: rem(280px);
            @include respond-to("sm-tablet") {
                width: rem(180px);
            }
        }

        > .c-button {
            @include respond-to("phone") {
                @include padding(7px);
                @include margin-left(16px);
                white-space: nowrap;
            }
        }

        &__dropdowns {
            align-self: flex-end;
            height: rem(30px);
            display: flex;

            .c-bookmark-color-filter-select {
                @include margin-right(16px);
                @include margin-left(16px);
                width: rem(220px);

                .c-multi-select {
                    &__input {
                        &__selector {
                            &__control {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &__body {
        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 1rem 0;

        > .c-filter-label {
            @include margin-bottom(16px);
        }
        .c-bookmark-card {
            @include margin-bottom(16px);
        }
        .c-no-results-banner {
            @include margin-top(124px);
            min-width: rem(542px);
            align-self: center;
            justify-self: center;
        }
    }

    &__bookmarks {
        @include respond-to("phone") {
            @include padding(0, 16px);
        }
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        @include padding(0, 48px);
    }

    &__footer {
        z-index: 1;
        box-shadow: 0px -9px 10px -4px rgba(0, 26, 57, 0.1);
    }
}
