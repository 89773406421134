.c-label {
    @include font-style(
        $font-primary,
        "xsmall",
        get-color-neutral("70"),
        700,
        18px
    );
    letter-spacing: 0.15em;
    text-transform: uppercase;

    &.-small {
        @include font-style(
            $font-primary,
            "xxsmall",
            get-color-neutral("70"),
            700,
            16px
        );
        letter-spacing: 0.11em;
    }
}
