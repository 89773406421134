.c-enhanced-content-modal {
    padding-bottom: 0;
    margin-top: 4vh;
    width: rem(669px);
    min-width: rem(669px);
    overflow: hidden;

    @include respond-to("sm-tablet") {
        @include fill-viewport-height();
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: initial;
    }

    &__body {
        overflow-y: auto;
        max-height: calc(#{vh()} - 300px);

        @include respond-to("phone") {
            max-height: calc(#{vh()} - #{$mobile-modal-header-height});
            overflow-y: auto;
        }
    }

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $size: "large",
                $color: get-color-neutral("90"),
                $line-height: "large"
            );
            display: inline-block;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }
        background-color: get-color-background("neutral");
        width: 100%;

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__content {
        @include padding(32px);

        &__title {
            @include margin-bottom(24px);
        }

        .c-rich-text-editor {
            @include margin-bottom(16px);

            > div[class^="RichTextEditor"] {
                .public-DraftEditorPlaceholder-inner {
                    @include font-style(
                        $size: "xsmall",
                        $line-height: get-line-height("xsmall")
                    );
                }

                .public-DraftEditor-content {
                    @include font-style(
                        $size: "xsmall",
                        $line-height: get-line-height("xsmall")
                    );
                    min-height: rem(200px);
                    max-height: rem(200px);
                    overflow-y: auto;

                    @include respond-to("phone") {
                        font-size: get-font-size("base");
                    }
                }
            }

            label {
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall"),
                    $color: get-color-neutral("90")
                );

                @include respond-to("phone") {
                    font-size: get-font-size("small");
                }

                .-help-text {
                    color: get-color-neutral("70");
                }
            }
        }
    }

    &__footer {
        @include padding(16px);
        border-top: 1px solid get-color-neutral("30");
        display: flex;
        justify-content: flex-end;

        .c-button:not(:first-of-type) {
            @include margin-left(8px);
        }
    }
}
