.c-categories-page {
    height: 100%;
    padding-top: rem(48px);
    display: flex;
    flex-direction: column;

    &.c-dashboard__panel {
        @include respond-to("tablet") {
            flex-wrap: nowrap;
        }
    }

    $categories-page-header-height: 147px;
    $categories-page-header-height-mobile: 130px;

    h5 {
        padding-bottom: rem(16px);
        padding-left: rem(48px);
    }

    &__content {
        height: calc(100% - 60px); // h5 height

        .c-route-tabs {
            &__panels {
                padding: 0;
                height: calc(100% - 39px); // route tabs height
                overflow-y: auto;

                @include respond-to("tablet") {
                    $categories-mobile-max-height: #{vh()} - #{$categories-page-header-height-mobile};
                    max-height: calc(#{$categories-mobile-max-height});
                    height: calc(#{$categories-mobile-max-height});
                }

                .c-admin-editor-page-layout {
                    padding-top: 0;
                    height: 100%;

                    &__content {
                        border-top: none;
                        height: 100%;

                        &__left {
                            &__list {
                                &__item {
                                    @include margin-bottom(2px);

                                    &:last-of-type {
                                        @include margin-bottom(24px);
                                    }
                                }
                            }
                        }

                        &__right {
                            &__none-selected {
                                @include padding-left(40px);
                                @include padding-right(40px);
                            }
                        }
                    }
                }
            }
        }
    }
}
