/*------------------------------------*\
  LISTS
\*------------------------------------*/

ul {
    position: relative;
    list-style: none;
    margin-left: 0;

    > li {
        @include margin-bottom(10px);
    }

    &.-has-icon {
        li {
            .c-icon {
                @include margin-bottom(-4px);
                @include margin-right(8px);
            }
        }

        &.-check > li:before {
            position: absolute;
            left: 0;
            font-size: 20px;
        }
    }
}

ol {
    > li {
        @include margin-bottom(10px);
    }
}
