.c-publication-batch-bulk-actions {
    @include margin-top(5px);
    display: flex;
    flex-direction: column;
    align-items: center;

    &__bulk-function-button {
        margin-top: 10px;
        min-width: rem(400px);
    }

    &__button-group {
        @include margin-top(25px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__reprocess-status {
        @include padding(32px);
    }
}
