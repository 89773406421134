.c-legacy-publication-page {
    height: 100%;
    width: 100%;
    overflow-x: hidden;

    .c-reference-panel-button {
        border-left: 1px solid get-color-neutral("30");
        @include padding-left(8px);
    }

    &.-reference-panel-open {
        display: flex;

        .c-image-viewer {
            width: 100%;
            &__footer {
                width: 100%;
            }
        }

        @include respond-to("tablet") {
            width: 50%;
        }

        @include respond-to("phone") {
            width: 100%;
        }
    }

    .c-loader {
        top: 50%;
        left: 50%;
        position: absolute;
    }
}
