.c-section-spacer {
    @include padding-top(48px);
    @include padding-bottom(48px);
    display: block;
    > hr {
        border-top: 1px solid rgba(get-color-neutral("70"), 0.5);
    }
    &.-medium {
        > hr {
            display: none;
        }
    }
    &.-small {
        @include padding-top(24px);
        padding-bottom: 0;
        > hr {
            display: none;
        }
    }
}
