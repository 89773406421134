/**
 * Border Radii
 */

$border-radius-xsmall: 3px;
$border-radius-small: 5px;
$border-radius-medium: 9px;
$border-radius-large: 32px;
$border-radius-huge: 100px;

/**
 * Spacing
 */
$space-xxxs: 2px;
$space-xxs: 4px;
$space-xs: 8px;
$space-s: 16px;
$space-m: 24px;
$space-l: 32px;
$space-xl: 48px;
$space-xxl: 72px;
$space-xxxl: 104px;

/**
* Loaders
*/

$loader-dual-ball-colored: "../../../images/Dual-Ball-1s-204px.gif";
$loader-dual-ball-neutral: "../../../images/Dual-Ball-1s-204px-neutral.gif";

$loader-link-full: "../../../images/link-loader-full-small.gif";
$loader-link-glyph: "../../../images/link-loader-glyph-small.gif";
$loader-link-glyph-blue: "../../../images/link-loader-glyph-bluebg-small.gif";
$loader-link-glyph-gray: "../../../images/link-loader-glyph-graybg-small.gif";

$loader-ripple: "../../../images/Ripple-1.5s-277px.gif";

/**
* Placeholder images
*/
$link-glyph-gray: "../../../images/nfpa-link-glyph-grayscale.png";

/**
* Book View
*/
$section-max-width: 730px;
$section-max-width-expanded: calc(100% - $space-xxl);
$section-padding: 8px;
$section-border: 1px;

/**
 * Change Indicator Icons
 */
$change-indicator-icon-yes: "Delta.svg";
$change-indicator-icon-addition: "New-Material.svg";
$change-indicator-icon-spacing: 24px + $section-padding + $section-border;

/**
* Solution Resource Video Icon
*/
$file-video-icon: "../../../icons/24px/File-Video.svg";

/**
* Measurements
*/
$admin-footer-height: 73px;
$breadcrumb-menu-height: 41px;
$call-to-action-banner-height-desktop: 82px;
$call-to-action-banner-height-mobile: 216px;
$call-to-action-banner-button-container-width-desktop: 218px;
$emulation-banner-height: 56px;
$mobile-modal-header-height: 80px;
$mobile-modal-footer-height: 60px;
$my-link-header-height: 160px;
$metanav-width: 320px;
$reference-panel-width: 420px;
$sidebar-menu-width: 56px;
$sidebar-plus-metanav-width: 376px;
$sitnav-category-selection-menu: 440px;
$support-users-heading-height: 104px;
$unauthenticated-footer-height: 110px;

/**
* z-index values
*/
$breadcrumb-menu-z-index: 11;
$dropdown-button-z-index: 11;
$hidden-content-banner-z-index: 10;
$modal-overlay-z-index: 11;
$publication-metanav-z-index: 12;
$sticky-notification-z-index: 10;
$topics-selects-z-index: 3;
$publication-metanav-toggle-z-index: 11;
