/*------------------------------------*\
  HEADINGS
\*------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}

h1 {
    @include font-style($font-primary, "xxxxxlarge", false, 100, 78px);
    @include respond-to("tablet") {
        @include font-style($font-primary, "xxxxlarge", false, 100, 62.4px);
    }
}

h2 {
    @include font-style($font-primary, "xxxxlarge", false, 100, 62px);
    @include respond-to("tablet") {
        @include font-style($font-primary, "xxlarge", false, 100, 42px);
    }
}

h3,
%h3 {
    @include font-style($font-primary, "xxxlarge", false, 100, 62px);
    @include respond-to("tablet") {
        @include font-style($font-primary, "xlarge", false, 100, 36px);
    }
}

h4 {
    @include font-style($font-primary, "xxlarge", false, 100, 56px);
    @include respond-to("tablet") {
        @include font-style($font-primary, "xlarge", false, 100, 36px);
    }
}

h5,
%h5 {
    @include font-style($font-primary, "xlarge", false, 100, 44px);
    @include respond-to("tablet") {
        @include font-style($font-primary, "large", false, 100, 27px);
    }
}

h6 {
    @include font-style($font-primary, "xxlarge", false, 100, 32px);
    @include respond-to("tablet") {
        @include font-style($font-primary, "xlarge", false, 100, 36px);
    }
}
