.c-section-content-preview {
    &__body {
        @include padding(20px, 20px);
        overflow-y: auto;

        &__section-title {
            @include font-style($size: "large", $line-height: "large");
            @include margin-bottom(20px);
            position: relative;
            > .c-icon.-change-indicator {
                position: absolute;
                left: -32px;
                top: 0;
            }
        }

        &__sub-section-title {
            @include font-style(
                $size: "base",
                $weight: "bold",
                $line-height: "base"
            );
            @include margin-bottom(20px);
            position: relative;

            > .c-icon.-change-indicator {
                position: absolute;
                left: -32px;
                top: 0;
            }
        }

        h6 {
            @include margin-bottom(20px);
            @include font-style(
                $font-primary,
                "xxsmall",
                get-color-neutral("70"),
                800,
                14px
            );
            text-transform: uppercase;
            letter-spacing: rem(2px);
        }

        div + h5 {
            @include margin-top(40px);
        }
    }
}
