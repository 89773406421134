.c-select {
    position: relative;
    display: inline-block;
    @include icon-fill(get-color-neutral("70"));

    select {
        @include focus-outline();
        @include font-style($color: get-color-neutral("90"));
        @include padding(8px);
        @include padding-right(32px);
        border: 1px solid get-color-neutral("70");
        border-radius: $border-radius-small;
        height: rem(40px);
        width: 100%;

        // hides default arrow so we can place our chevron down icon instead
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

        &::-ms-expand {
            // hide default arrow in IE 11
            display: none;
        }

        option {
            @include font-style($color: get-color-neutral("90"));
        }
    }

    .c-icon {
        position: absolute;
        right: rem(8px);
        top: rem(12px);
        // forward pointer events to the <select> underneath the icon
        pointer-events: none;
    }

    &.-small {
        select {
            @include padding(4px);
            @include padding-right(32px);
            @include padding-left(8px);
            height: rem(30px);
        }

        .c-icon {
            top: rem(8px);
        }
    }

    &.-button {
        display: flex;
        align-items: center;
        select {
            @include padding-right(24px);
            @include font-style($size: "xxsmall", $weight: "bold");
            height: 28px;
            border: none;
            background-color: get-color-neutral("05");
            width: fit-content;
        }

        .c-icon {
            @include icon-fill(get-color-neutral("90"));
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__modal {
        &.c-modal.-bottom {
            max-height: calc(#{vh()} - 32px);
            padding: 0;
        }

        &__top {
            @include padding(24px);
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid get-color-neutral("30");

            p {
                @include font-style($color: get-color-neutral("90"));
            }
        }

        &__bottom {
            @include padding(16px);
            max-height: calc(#{vh()} - 77px);
            overflow-y: scroll;

            .c-button {
                @include margin-bottom(8px);
                @include padding(12px, 24px);
                @include font-style(
                    $size: "small",
                    $line-height: get-line-height("small"),
                    $color: get-color-neutral("90")
                );
                text-align: left;
                width: 100%;
                text-decoration: none;

                &.-selected,
                &:hover {
                    @include font-style(
                        $size: "small",
                        $line-height: get-line-height("small"),
                        $color: get-color-neutral("90")
                    );
                    background-color: get-color-accents("blue-light");
                }
            }
        }
    }
}
