.c-filter-label {
    display: flex;
    > label {
        @include font-style(
            $font-primary,
            "xsmall",
            get-color-neutral("70"),
            400,
            "xsmall"
        );
        @include margin-right(8px);
        letter-spacing: 0.02em;
    }
    > .c-button {
        @include font-style(
            $font-primary,
            "xsmall",
            get-color-accents("blue-dark"),
            400,
            "xsmall"
        );

        @include respond-to("phone") {
            width: 6rem;
            align-self: flex-start;
            text-align: right;
        }
    }
}
