.c-publish-status-badge-with-icon {
    @include font-style(
        $size: "xxsmall",
        $line-height: get-line-height("xxsmall"),
        $weight: get-font-weight("bold")
    );
    @include padding(5px, 8px);
    border: 1px solid get-color-neutral("white");
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: $border-radius-huge;
    width: fit-content;

    &.-unpublished {
        background-color: get-color-status("error-light");
        color: get-color-status("error-dark");

        > svg path {
            fill: get-color-status("error-dark");
        }
    }

    &.-published {
        background-color: get-color-status("success-light");
        color: get-color-status("success-dark");

        > svg path {
            fill: get-color-status("success-dark");
        }

        &.-has-unpublished-changed {
            background-color: get-color-status("warning-light");
            color: get-color-status("warning-dark");
        }
    }

    > svg {
        @include margin-right(6px);
    }
}
