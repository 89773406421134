.c-section-wrapper {
    &__fragment {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        // Prevents all from loading at once,
        // since all sections are technically "on screen" since they have no height.
        &.-hidden {
            height: 100vh;
        }
    }
    position: relative;
    width: 100%;
    --tia-button-position: 1rem;

    .-highlight-section {
        border-color: get-color-status("warning");
        border-style: solid;
        border-radius: 10px;
        border-width: thin;
        scroll-margin-top: 76px;
    }

    &:focus {
        outline: none;
    }

    &.-virtualized {
        max-width: $section-max-width;
        margin: 0 auto;

        @include respond-to("desktop") {
            @include padding(0, 24px);
        }
    }

    &.-level-two {
        @include padding-left(32px);

        > .c-section-spacer {
            position: relative;
            width: calc(100% + 24px);
            left: rem(-24px);
        }

        @include respond-to("desktop") {
            @include padding-left(42px);
        }
    }

    &.-deleted {
        + .c-section-wrapper.-deleted {
            display: none;
        }
    }

    .c-section-detail-actions > .c-menu-button {
        position: absolute;
        top: -0.5rem;
        right: -0.3rem;
    }

    .-deleted-section-msg {
        align-self: center;
        color: get-color-status("warning-dark");
        @include margin-right(16px);
    }
}
