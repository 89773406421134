.c-dashboard-featured-publications {
    @include margin(48px, 43px, 52px, 0);

    @include respond-to("phone") {
        @include margin(30px, 24px, 52px, 0);
    }

    &__header {
        @include margin-bottom(8px);

        > h2 {
            @include font-style(
                $size: "xxsmall",
                $line-height: "xxsmall",
                $weight: "bold",
                $color: get-color-neutral("70")
            );
            letter-spacing: 0.11rem;
            text-transform: uppercase;
            display: inline;
        }

        .c-button {
            @include margin-left(16px);

            &.-xsmall {
                @include font-style(
                    $size: "xsmall",
                    $line-height: "xsmall",
                    $weight: "base",
                    $color: get-color-accents("blue-dark")
                );
            }
        }
    }

    &__list {
        > .c-publication-selection-list-group-item {
            &:first-of-type {
                border-top: none;
            }

            &:hover,
            &.-focused {
                background-color: get-color-background();
                border-radius: $border-radius-small;
            }
        }
    }
}
