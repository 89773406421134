.c-resource-modal {
    width: 592px;
    min-width: 592px;
    overflow: hidden;

    .c-modal {
        &__content {
            @include respond-to("sm-tablet") {
                display: flex;
                flex-direction: column;
            }
        }
    }

    @include respond-to("sm-tablet") {
        @include fill-viewport-height();
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: initial;
    }

    &__header {
        background: get-color-neutral("05");
        @include padding(16px, 24px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                24px
            );
            display: inline-block;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;

                path {
                    fill: get-color-neutral("70");
                }
            }
        }
    }
    &__content {
        @include scrollbar();
        @include font-style($font-primary);
        @include padding(32px);
        max-height: rem(416px);
        overflow-y: auto;

        @include respond-to("sm-tablet") {
            max-height: unset;
            flex-grow: 1;
        }
    }
    &__title {
        @include font-style(
            $font-primary,
            "small",
            get-color-neutral("90"),
            700,
            18px
        );
        @include padding(20, 16, 14);
        display: block;
        box-shadow: 0px 1px 0px get-color-neutral("30");
    }
    &__footer {
        @include padding(24px);
        border-top: 1px solid get-color-neutral("30");
        display: flex;
        width: 100%;
        justify-content: flex-end;

        > .c-button:first-of-type {
            @include margin-right(8px);
        }
    }
}
