.c-free-trial-terms {
    @include padding(24px);
    border: 1px solid get-color-neutral("30");
    border-radius: $border-radius-medium;
    width: 100%;

    label:not(.e-checkbox) {
        @include margin-bottom(8px);
        @include font-style(
            $size: "xxsmall",
            $line-height: "xxsmall",
            $weight: "bold",
            $color: get-color-neutral("70"),
            $letter-spacing: 0.11em
        );
        text-transform: uppercase;
        display: block;
    }

    p {
        @include margin-bottom(16px);
        @include font-style($size: "xsmall", $line-height: "xsmall");
        color: get-color-neutral("70");
    }

    &__error {
        @include margin-top(8px);

        span {
            @include font-style(
                $size: "xsmall",
                $line-height: "xsmall",
                $color: get-color-status("error")
            );
        }
    }
}
