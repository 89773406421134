.c-language-preferences-modal {
    &__header,
    &__body,
    &__footer {
        @include padding(20px, 20px);
    }

    padding-bottom: 0;
    width: rem(600px);
    min-width: rem(600px);
    overflow: hidden;

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $size: "large",
                $line-height: "large",
                $color: get-color-neutral("90")
            );
            display: inline-block;
            justify-self: left;
        }
        background-color: get-color-background("neutral");
        width: 100%;

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__content {
        @include padding(0, 24px);
        overflow-y: auto;
    }

    &__footer {
        @include margin-top(12px);
        border-top: 1px solid get-color-neutral("30");
        text-align: right;

        .c-button {
            @include margin-left(20px);
        }
    }

    &__container {
        @include padding(24px);
    }

    @include respond-to("sm-tablet") {
        margin: 0 auto;
        width: rem(500px);
        min-width: rem(500px);
    }

    @include respond-to("phone") {
        @include fill-viewport-height();
        min-width: initial;
        width: 100%;

        &.c-modal {
            max-width: 100%;
            border-radius: 0;
        }

        .c-modal__content {
            display: flex;
            flex-direction: column;
        }

        &__body {
            height: calc(#{vh(100)} - #{$mobile-modal-header-height});
        }
    }
}
