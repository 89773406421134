.c-file-upload-field {
    &.-error {
        label {
            color: get-color-status("error");
        }

        .c-file-upload-field__file-container {
            border-color: get-color-status("error");

            p {
                color: get-color-status("error");
            }
        }
    }

    label {
        &.-help-text {
            display: block;
            color: get-color-neutral("70");
            font-style: italic;
        }
    }

    .c-list-box {
        @include margin-top(8px);
        &__item {
            @include padding(14px, 16px);
            position: relative;
        }
    }

    &__browse-link {
        font-weight: 700;
        text-decoration: underline;
    }

    &__file-container {
        @include margin-top(8px);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: get-color-neutral("01");
        border: 1px dashed get-color-neutral("90");
        border-radius: 3px;
        min-height: 80px;

        &__help-text {
            @include if-internet-explorer {
                transform: translateY(100%);
            }
        }

        &__progress {
            &.-success {
                background-color: get-color-status("success-light");
            }

            &.-error {
                background-color: get-color-status("error-light");
            }

            &__progress-bar {
                flex-grow: 1;
            }

            &__filename {
                @include margin-right(16px);
                min-width: 50%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &__files {
            display: flex;
            justify-content: space-between;

            &__filename {
                @include margin-right(16px);
                @include font-style(
                    $size: "xsmall",
                    $weight: 700,
                    $line-height: rem(30px)
                );
                min-width: 50%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &__loading-message {
                &__label {
                    @include margin-right(18px);
                    @include font-style(
                        $color: get-color-neutral("70"),
                        $line-height: "xsmall",
                        $size: "xsmall",
                        $style: italic,
                        $weight: 400
                    );
                    display: inline-block;
                    vertical-align: bottom;
                }

                > .c-loader {
                    display: inline-block;
                    margin: 0;
                    vertical-align: bottom;
                }
            }

            &__remove-btn {
                // !important has to be used here because of issues with selector specificity
                @include icon-fill(get-color-neutral("70"));
                padding: rem(6px) !important;
            }
        }
    }
}
