$container-max-width: 35rem;
$team-container-max-width: 30rem;

.c-organization-login-page {
    max-width: $container-max-width;
    align-self: center;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include padding(16px);

    @include respond-to("phone") {
        @include padding(32px);
    }

    &__errorBanner {
        @include margin(16px, 0px, 32px);
    }

    &__heading,
    &__subheading,
    &__helpText {
        text-align: center;
        @include margin-bottom(16px);
        @include font-style($color: get-color-neutral("90"));

        @include respond-to("phone") {
            text-align: left;
        }
    }

    &__subheading,
    &__helpText {
        @include respond-to("phone") {
            display: none;
        }
    }

    &__heading {
        @include font-style(
            $size: get-font-size("xxlarge"),
            $weight: get-font-weight("base"),
            $line-height: get-line-height("xxlarge")
        );

        @include respond-to("phone") {
            @include font-style(
                $size: get-font-size("xlarge"),
                $line-height: get-line-height("xlarge")
            );
        }
    }

    &__subheading {
        @include font-style(
            $weight: get-font-weight("bold"),
            $size: get-font-size("large")
        );
    }

    &__helpText {
        @include font-style($size: get-font-size("base"));
    }

    &__organizationBlock {
        @include padding(16px, 24px);
        @include margin-bottom(32px);
        background-color: get-color-neutral("white");
        border-radius: $border-radius-small;
        border: 1px solid get-color-neutral("30");
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $team-container-max-width;
    }
    &__organizationLabel {
        @include margin-bottom(8px);
        @include font-style(
            $font-primary,
            "xxsmall",
            get-color-neutral("70"),
            700,
            get-line-height("xxsmall")
        );
        text-transform: uppercase;
        display: block;
    }
    &__organizationName {
        @include font-style(
            $font-primary,
            "base",
            get-color-neutral("90"),
            400,
            get-line-height("base")
        );
    }
    &__supportLink {
        @include font-style(
            $weight: get-font-weight("bold"),
            $size: get-font-size("base"),
            $color: get-color-accents("blue-dark")
        );
    }

    &__loginButton {
        @include respond-to("phone") {
            width: 100%;
        }
    }
}
