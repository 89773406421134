.c-enhanced-content-admin-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include respond-to("laptop") {
        align-items: flex-start;
        flex-direction: column;
    }

    &__left {
        width: 100%;

        .c-search-form {
            width: 100%;
            max-width: rem(400px);
        }

        @include respond-to("laptop") {
            @include margin-bottom(16px);
        }
    }

    &__right {
        margin-left: auto;
        display: flex;
        justify-content: space-between;

        @include respond-to("laptop") {
            margin-left: 0;
            flex-direction: column;
        }
    }

    .c-publish-status-filters-bar {
        @include rem-fallback(margin-left, 25px);

        @include respond-to("laptop") {
            @include margin-top(16px);
            margin-left: 0;
        }

        &__button-group {
            @include respond-to("laptop") {
                justify-content: flex-start;
            }
        }

        &__button-group__item.-all.c-button.-xsmall {
            height: rem(30px);
        }
    }
}
