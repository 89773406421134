.c-publish-status-menu {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    &__left {
        p {
            @include font-style(
                $size: "xsmall",
                $line-height: get-line-height("xsmall")
            );
            text-align: left;

            &.-bold {
                font-weight: get-font-weight("bold");
            }
        }
    }

    &__right {
        &__comment {
            @include margin-right(16px);
            display: inline-block;
            position: relative;
            bottom: 8px;
        }
        .c-menu-button {
            display: inline-block;

            &__menu {
                top: unset;
                right: 0;
                bottom: rem(50px);
                min-width: rem(150px);

                @include if-internet-explorer {
                    top: rem(-122px);
                }

                .c-button {
                    color: get-color-neutral("90");

                    &.-bold {
                        font-weight: get-font-weight("bold");
                    }
                }
            }

            @include if-internet-explorer {
                &.-items-1 {
                    .c-menu-button__menu {
                        height: rem(40px);
                        top: rem(-50px);
                    }
                }

                &.-items-2 {
                    .c-menu-button__menu {
                        height: rem(80px);
                        top: rem(-90px);
                    }
                }

                &.-items-3 {
                    .c-menu-button__menu {
                        height: rem(120px);
                        top: rem(-130px);
                    }
                }

                &.-items-4 {
                    .c-menu-button__menu {
                        height: rem(160px);
                        top: rem(-170px);
                    }
                }
            }
        }
    }
}
