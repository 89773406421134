.c-feature-flag-page {
    @include fill-viewport-height();
    @include padding-left(24px);
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    &__list {
        @include padding-top(24px);
        @include padding-bottom(48px);
        overflow-y: auto;
        h2 {
            @include margin-bottom(24px);
        }
        .c-cta-toggle {
            @include margin-bottom(16px);
            width: 400px;
            display: block;
        }
    }
}
