.c-dropdown-button {
    svg {
        vertical-align: middle;
    }

    &__list {
        @include box-shadow(0, 7px, 20px, rgba(0, 26, 57, 0.3));
        @include margin-top(8px);
        @include margin-left(64px);
        @include font-style($size: "small");
        background-color: get-color-neutral("white");
        border-radius: $border-radius-small;
        z-index: $dropdown-button-z-index;

        @include respond-to("phone") {
            @include margin-bottom(16px);
            border-radius: 0;
            box-shadow: none;
            margin-left: 0;
        }

        &__item {
            @include padding(12px);
            min-width: rem(162px);
            cursor: pointer;

            &:hover,
            &:focus,
            &:focus-within,
            &[data-selected] {
                // [data-selected] comes from the @reach-ui/menu-button component
                background-color: get-color-neutral("05");

                @include respond-to("phone") {
                    background-color: get-color-neutral("white");
                }
            }

            @include respond-to("phone") {
                @include padding(4px, 0px);
                @include margin-bottom(16px);
                @include font-style(
                    $size: "small",
                    $line-height: "small",
                    $color: get-color-neutral("70")
                );
                min-width: 0;
                display: block;
                background-color: get-color-neutral("white");
            }
        }
    }
}
