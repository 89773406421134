.c-admin-situation-hotspot-modal {
    padding-bottom: 0;
    margin-top: 4vh;
    width: rem(600px);
    min-width: rem(600px);
    overflow: hidden;

    &.-fullscreen {
        @include padding(0);
    }

    .c-button.-modal-close {
        top: rem(18px);
        right: rem(18px);
    }

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                get-line-height("large")
            );
            display: inline-block;
            justify-self: left;
        }
        background-color: get-color-background("neutral");
        width: 100%;

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }
    &__content {
        @include padding(16px, 32px);
        max-height: rem(640px);
        overflow-y: auto;

        .c-button {
            @include margin-right(20px);
            justify-self: right;
        }

        .c-select {
            @include margin(20px, 0);
            display: block;
            width: 100%;

            select {
                width: 100%;
            }
        }
    }

    &__footer {
        @include padding(24px);
        border-top: 1px solid get-color-neutral("30");
        display: flex;
        width: 100%;

        &__left {
            justify-content: flex-start;
        }

        &__right {
            margin-left: auto;
        }

        .c-button:first-of-type {
            @include margin-right(8px);
        }
    }

    @include respond-to("sm-tablet") {
        @include fill-viewport-height();
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: initial;
    }

    @include respond-to("phone") {
        &__content {
            max-height: calc(100vh - 80px);
        }
    }
}
