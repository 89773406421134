.c-shared-section-view {
    @include padding-left(32px);
    @include padding-right(32px);
    @include padding-top(16px);

    @include respond-to-min-width("tablet") {
        max-width: rem(780px);
        margin: 0 auto;
    }

    &__publication-details {
        @include border-radius("base");
        @include margin-bottom(24px);
        @include padding(16px);
        background: get-color-neutral("05");
        display: flex;

        &__cover-image-container {
            @include margin-right(24px);
            @include border-radius();
            flex-basis: rem(40px);
            overflow: hidden;
            min-width: rem(48px);

            img {
                @include object-fit-cover();
            }
        }

        &__text {
            &__edition {
                @include font-style(
                    $size: "xxsmall",
                    $color: get-color-neutral("70"),
                    $weight: get-font-weight("bold"),
                    $line-height: 14px
                );
                @include margin-bottom(6px);
                text-transform: uppercase;
            }

            &__code-title {
                @include font-style(
                    $color: get-color-neutral("90"),
                    $line-height: get-line-height("xxsmall")
                );
            }
        }
    }
}
