.c-breadcrumbs-menu {
    display: flex;
    height: $breadcrumb-menu-height;
    background-color: get-color-neutral("white");
    border-bottom: 1px solid get-color-neutral("30");
    align-items: center;
    white-space: nowrap;
    position: relative;

    $metanav-toggle-width: 40px;
    $controls-width: 150px;

    + #reach-skip-nav {
        height: calc(100% - #{$breadcrumb-menu-height});
    }

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        width: calc(100% - 101px);
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 85%,
            get-color-neutral("white") 100%
        );
        content: "";
        pointer-events: none;

        @include respond-to("phone") {
            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0) 0%,
                get-color-neutral("white") 100%
            );
        }
    }

    > .c-button {
        display: none;
        position: absolute;
        right: 0;
        padding: 0;
        height: 40px;
        width: 40px;
        border: none;
        border-left: 1px solid get-color-neutral("30");
        border-radius: 0;

        @include respond-to("phone") {
            display: block;
        }
    }

    &__metapanel-trigger {
        @include padding(8);
        border-right: 1px solid get-color-neutral("30");
        display: none;
        height: 40px;
        max-width: $metanav-toggle-width;

        &:hover {
            cursor: pointer;
        }

        @include respond-to("tablet") {
            display: block;
        }

        > button {
            border: none;
            background-color: get-color-background("primary");

            :hover {
                cursor: pointer;
            }
        }
    }
    &__mobile-menu {
        display: none;
        position: absolute;
        left: 40px;
        width: calc(100% - #{$metanav-toggle-width} - #{$controls-width});
        height: 40px;
        background: transparent;
        z-index: $breadcrumb-menu-z-index;

        @include respond-to("phone") {
            display: block;
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-left: auto;
        background-color: inherit;
        max-width: $controls-width;
        z-index: $breadcrumb-menu-z-index;
    }
}
