.c-change-edition-modal {
    width: 600px;
    min-width: 600px;
    overflow: hidden;

    @include respond-to("sm-tablet") {
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: initial;
    }

    &__header {
        padding: 24px 16px 16px 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                24px
            );
            display: inline-block;
            justify-self: left;
        }
        > .c-button {
            justify-self: right;
        }
        background-color: get-color-background("neutral");
        width: 100%;

        button.-modal-close {
            padding: 8px;

            svg {
                display: block;
            }
        }
    }
    &__content {
        @include padding(16px, 32px);
        max-height: 394px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        @include respond-to("sm-tablet") {
            max-height: 100%;
            height: 100%;
        }
    }
    &__title {
        @include font-style(
            $font-primary,
            "small",
            get-color-neutral("90"),
            700,
            18px
        );
        @include padding(20, 16, 14);
        display: block;
        box-shadow: 0px 1px 0px get-color-neutral("30");
    }
    &__footer {
        @include padding(24px);
        display: flex;
        width: 100%;
        border-top: 1px solid get-color-neutral("30");
        justify-content: flex-end;
        @include respond-to("phone") {
            height: 100px;
        }

        > .c-button:first-of-type {
            @include margin-right(8px);
        }
    }
}
