.c-application-layout {
    display: flex;
    word-break: break-word;
    height: 100vh;
    overflow-y: hidden;

    @include respond-to("phone") {
        flex-direction: column;
    }

    &.-emulation-mode {
        height: calc(100vh - #{$emulation-banner-height});
    }

    &__panel {
        width: calc(100% - #{$sidebar-menu-width});
        height: 100%;

        @include respond-to("phone") {
            height: calc(100% - #{$sidebar-menu-width});
            width: 100%;
            z-index: 1;
        }

        .c-error-fallback {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }
}
