.c-origins-list {
    @include margin(24px, 0px);

    &__desig {
        min-width: rem(40px);
    }

    &__item {
        display: flex;

        &__content {
            flex-grow: 1;
        }
    }
}
