.c-publish-status-badge {
    @include font-style(
        $size: "xsmall",
        $line-height: get-line-height("xsmall"),
        $weight: get-font-weight("bold")
    );
    @include padding(8px, 12px);
    @include margin-right(8px);
    @include margin-top(3px);
    display: inline-block;
    border-radius: $border-radius-huge;
    vertical-align: top;
    width: rem(92px);
    text-align: center;
    position: relative;
    white-space: nowrap;

    &.-small {
        @include font-style(
            $size: "xxsmall",
            $line-height: get-line-height("xxsmall"),
            $weight: get-font-weight("bold")
        );
        @include padding(5px, 8px);
        width: rem(24px);
        border: 1px solid get-color-neutral("white");
    }

    &.-unpublished {
        background-color: get-color-status("error-light");
        color: get-color-status("error-dark");
    }

    &.-published {
        background-color: get-color-status("success-light");
        color: get-color-status("success-dark");

        &.-has-unpublished-changed {
            background-color: get-color-status("warning-light");
            color: get-color-status("warning-dark");
        }
    }

    &.-has-unpublished-changed {
        background-color: get-color-status("warning-light");
        color: get-color-status("warning-dark");
    }
}
