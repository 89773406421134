.c-unhandled-xml {
    @include padding(16px);
    @include margin-bottom(8px);
    border-radius: $border-radius-small;
    background-color: get-color-status("error-light");

    &__tag-name {
        @include margin-bottom(8px);
        border-bottom: 1px solid get-color-neutral("50");
        color: get-color-status("error-dark");

        label {
            @include margin-right(4px);
            font-weight: get-font-weight("bold");
        }
    }

    &__xml-tag-preview {
        $xml-tag-preview-class: &;

        &__prop {
            @include margin-left(24px);
        }

        > div {
            display: inline-block;
            background-color: get-color-status("warning-light");
        }

        .-no-indent-props {
            #{$xml-tag-preview-class}__prop {
                @include margin-left(6px);
            }
        }
    }

    &__xml-children {
        @include padding-left(16px);
    }
}
