.c-extend-free-trial-input {
    display: flex;
    align-self: center;
    @include padding(0px, 48px);

    > .c-button {
        display: flex;
        align-items: center;
        gap: rem(4px);
        @include padding(8px, 16px);
        // width: fit-content;
    }

    &__user-details {
        display: flex;
        flex-direction: column;
        padding: 30px;
        gap: rem(30px);

        &__data {
            display: flex;
            gap: rem(60px);

            &__item {
                display: flex;
                flex-direction: column;
                align-self: center;

                &__heading {
                    text-transform: uppercase;
                    @include font-style(
                        $size: "xsmall",
                        $color: get-color-neutral("70"),
                        $weight: "bold",
                        $letter-spacing: 0.11em,
                        $line-height: "xlarge"
                    );
                }

                &__value {
                    @include font-style(
                        $size: "small",
                        $color: get-color-neutral("70"),
                        $weight: "base",
                        $line-height: "small"
                    );
                }
            }
        }
    }
}
