.c-select-topics-page {
    @include padding(0, 36px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to("phone") {
        @include padding(0, 24px);
    }
    &__title {
        @include margin(40px, 0, 24px, 0);
        @include font-style($font-primary, "xxxlarge", false, 100, 62px);
        color: get-color-neutral("90");
        text-align: center;

        @include respond-to("phone") {
            @include margin(16px, 32px, 24px, 32px);
            @include font-style($font-primary, "xlarge", false, 100, 36px);
        }
    }
    &__topics {
        max-width: 904px;
        text-align: center;

        @include respond-to("phone") {
            fieldset {
                @include padding(0, 16px);
            }
            .c-checkbox-button {
                @include margin-left(8px);
                @include margin-right(8px);
            }
        }

        p {
            margin: 0 auto 32px;
            max-width: 712px;

            @include respond-to("phone") {
                @include font-style($font-primary, "small", false, 400, 21px);
            }
        }
    }
    &__team {
        max-width: 904px;
    }
    &__divider {
        @include padding-top(25px);
        margin: 0 auto;
        width: 582px;
        border-bottom: 1px solid get-color-neutral("30");

        @include respond-to("sm-tablet") {
            width: 100%;
        }
    }
    a {
        @extend %c-button;
    }
    &__footer {
        @include margin(48px, 0);
        width: 100%;
        max-width: 582px;
        text-align: center;

        @include respond-to("phone") {
            @include margin(32px, 0);
            @include padding-top(32px);
        }

        &__errors {
            @include margin-bottom(16px);
            @include font-style($font-primary, "xsmall", false, 300, 18px);
            font-style: italic;
            color: get-color-primary("error");
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            svg {
                @include margin-right(8px);
                path {
                    fill: get-color-primary("error");
                }
            }

            span {
                display: flex;
                align-items: center;

                &:not(:first-child) {
                    @include margin-top(8px);
                }
            }
        }

        a {
            @extend %c-button;
        }
    }
}
