.c-situational-navigation-results-view {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    z-index: 0;
    @include respond-to-min-width("sm-desktop") {
        @include padding(0, 56px);
    }

    &__parameters {
        @include margin(18px, 0);
        font-style: italic;

        span {
            font-weight: bold;
        }
    }

    &__situations,
    &__solutions {
        @include margin(32px, 0);

        &__header {
            color: get-color-neutral("90");
            @include margin-bottom(13px);
            span {
                font-weight: bold;
            }
        }
    }

    &__situations {
        position: relative;
        &__row {
            position: relative;
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            left: rem(-20px);
            width: calc(100% + 40px);
            transition: all 0.2s;
            user-select: none;
            scrollbar-width: none; /* Firefox */

            @include respond-to-min-width("laptop") {
                overflow: auto;
                white-space: initial;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                left: rem(-16px);
                width: calc(100% + 32px);
            }
        }
    }

    .c-situation-related-situation-card {
        @include margin-right(20px);
        @include margin-bottom(12px);
        cursor: pointer;
        white-space: normal;
        vertical-align: top;

        > p {
            color: get-color-neutral("70");
        }

        @include respond-to-min-width("laptop") {
            @include margin(16px);
            // evenly spaced rows of 2 items each
            width: calc((100% / 2) - #{rem(32px)});
        }

        @include respond-to-min-width("desktop") {
            @include margin(16px);
            // evenly spaced rows of 3 items each
            width: calc((100% / 3) - #{rem(32px)});
        }

        @include respond-to-min-width("lg-desktop") {
            // evenly spaced rows of 4 items each
            width: calc((100% / 4) - #{rem(32px)});
        }

        @include respond-to-min-width("wide-desktop") {
            // evenly spaced rows of 5 items each
            width: calc((100% / 5) - #{rem(32px)});
        }

        @include respond-to-min-width("ultrawide") {
            // evenly spaced rows of 6 items each
            width: calc((100% / 6) - #{rem(32px)});
        }

        @include respond-to-min-width("super-ultrawide") {
            // evenly spaced rows of 7 items each
            width: calc((100% / 7) - #{rem(32px)});
        }

        &:first-of-type {
            @include margin-left(20px);

            @include respond-to-min-width("laptop") {
                @include margin(16px);
            }
        }
    }
}
