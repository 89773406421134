.c-change-indicator-diff-panel {
    @extend %diff-panel;

    &__container {
        @extend %diff-panel-container;
    }

    &__header {
        @extend %diff-panel-header;
    }

    &__heading {
        @extend %diff-panel-heading;
    }

    &__closeIcon {
        @extend %diff-panel-close-icon;
    }

    &__contents {
        @extend %diff-panel-contents;
        @include padding(16px);
    }

    &__subheader {
        @extend %diff-panel-subheading;
    }

    &__body {
        @extend %diff-panel-nested-content;
    }

    &__footer {
        @include padding(16px);
    }
}
