@mixin header($text-transform, $get-color-neutral) {
    color: get-color-neutral($get-color-neutral);
    text-transform: $text-transform;
    @include padding-top(25px);
    @include padding-bottom(10px);
    @include padding-left(16px);
    text-align: left;
}

@mixin item($back-ground-color-hover) {
    @include padding-top(12px);
    @include padding-bottom(12px);
    @include font-style(
        $family: $font-primary,
        $size: "base",
        $color: get-color-neutral("90"),
        $weight: 400,
        $line-height: 24px
    );
    @include rem-fallback(line-height, 24px);
    text-decoration: none;
    display: inline-block;
    @include padding-left(16px);
    @include padding-right(16px);
    text-align: left;

    &:hover {
        @include border-radius("base");
        background-color: get-color-neutral($back-ground-color-hover);
        text-decoration: underline;
    }

    span {
        @include margin-right(16px);
    }
    > .c-icon {
        @include icon-fill(get-color-neutral("90"));
        @include margin-left(6px);
        display: inline-block;
        vertical-align: text-top;
    }

    &.-public {
        text-decoration: none;
    }
}

.c-link-resources {
    &__default {
        @include margin-left(-16px);
        &__header {
            @include header(uppercase, "50");
            @include font-style(
                $family: $font-primary,
                $size: "xsmall",
                $color: false,
                $weight: 700,
                $line-height: 18px,
                $letter-spacing: 0.2em
            );
        }

        &__item {
            @include item("05");
        }
    }

    &__metanav {
        &__header {
            @include header(None, "70");
            @include font-style(
                $family: $font-primary,
                $size: "xsmall",
                $color: false,
                $weight: 400,
                $line-height: 16px,
                $letter-spacing: 0
            );
        }

        &__item {
            @include item("0");
            width: 100%;

            &:hover {
                background-color: get-color-background("primary");
                color: get-color-neutral("90");
                -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 26, 57, 0.1);
                -moz-box-shadow: 0px 3px 10px 0px rgba(0, 26, 57, 0.1);
                box-shadow: 0px 3px 10px 0px rgba(0, 26, 57, 0.1);
                text-decoration: underline;
            }
        }
    }
}
