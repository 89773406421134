.c-share-modal {
    max-width: rem(592px);

    $headerHeight: rem(64px);

    @include respond-to("sm-tablet") {
        @include fill-viewport-height();
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: 0;

        &.c-modal.-bottom {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    &.c-modal {
        &.-bottom {
            padding: 0;
        }
    }

    .c-modal__content + .c-button.-modal-close {
        // hide close button in favor of custom buttons
        display: none;
    }

    &__header {
        @include padding(16px, 16px, 8px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: get-color-neutral("05");
        height: $headerHeight;

        &__left {
            @include icon-fill(get-color-neutral("90"));

            .c-icon {
                @include margin-right(8px);
                vertical-align: text-top;
            }

            > h2 {
                @include font-style(
                    $size: "large",
                    $line-height: get-line-height("large"),
                    $color: get-color-neutral("90")
                );
                display: inline-block;
                justify-self: left;
            }
        }

        &__right {
            .c-button.-tertiary-alt.-modal-close.-icon {
                @include padding(8px);
                background-color: transparent;
            }
        }
    }

    &__body {
        @include padding(32px);
        @include scrollbar();

        @include respond-to("sm-tablet") {
            padding-bottom: 0;
            overflow-y: auto;
        }

        @include respond-to("phone") {
            max-height: calc(#{vh(100)} - #{$headerHeight});
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }

        &__section-preview {
            @include margin-bottom(24px);
            @include padding(16px);
            border: 1px solid get-color-neutral("30");
            border-radius: $border-radius-small;

            @include respond-to("sm-tablet") {
                @include padding(0, 0, 16px, 0);
                border-radius: 0;
                border: none;
                border-bottom: 1px solid get-color-neutral("30");
            }

            &__title {
                @include font-style(
                    $size: "small",
                    $line-height: get-line-height("small"),
                    $color: get-color-neutral("90")
                );
            }

            &__publication-title {
                @include margin-bottom(4px);
                @include font-style(
                    $size: "xsmall",
                    $line-height: rem(20px),
                    $color: get-color-neutral("70"),
                    $style: italic
                );
                letter-spacing: 0.02em;
            }

            &__body {
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall"),
                    $color: get-color-neutral("70")
                );
                @include respond-to("phone") {
                    @include ellipsis();
                }
            }
        }

        .c-form {
            .c-form-field {
                margin-bottom: 0;

                &.-invalid:not(.c-email-multi-input) {
                    @include margin-bottom(16px);
                }

                &.c-email-multi-input {
                    @include margin-bottom(16px);
                }

                &:last-child {
                    @include margin-bottom(32px);
                }

                textarea {
                    resize: vertical;
                    min-height: rem(72px);
                }
            }
        }

        &__footer {
            text-align: right;

            &.-mobile {
                @include margin-bottom(32px);
            }
            .c-button {
                &.-secondary {
                    @include margin-right(8px);
                }
                @include respond-to("phone") {
                    &.-wide {
                        @include margin-bottom(8px);
                        margin-right: 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}
