.c-recent-bookmarks-list {
    &__title {
        @include margin-bottom(16px);
        display: flex;
        align-items: center;

        .c-icon {
            @include margin-right(22px);
            @include icon-fill(get-color-primary("70"));
        }
    }

    .c-recent-bookmark-anchor:last-of-type {
        @include margin-bottom(16px);
    }
}
