.c-bookmark-collections-multi-select {
    .c-multi-select {
        .c-form-field {
            @include margin-bottom(4px);
        }

        &__input__selector__menu-list {
            @include respond-to("phone") {
                max-height: rem(165px);
                overflow-y: auto;
            }
        }
    }

    label.-collections-help {
        @include font-style(
            $size: "xsmall",
            $line-height: get-line-height("xsmall"),
            $color: get-color-neutral("70"),
            $style: italic
        );
    }
}
