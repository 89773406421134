.c-share-link-cta-banner {
    @include padding(16px, 48px);
    @include box-shadow(0px, 7px, 20px, get-color-neutral("30"));
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: get-color-neutral("90");
    max-height: $call-to-action-banner-height-desktop;
    z-index: 10;

    @include respond-to("tablet") {
        flex-direction: column;
        max-height: $call-to-action-banner-height-mobile;
    }

    @include respond-to("phone") {
        @include padding(16px);
        @include box-shadow(0px, 1px, 20px, get-color-neutral("30"));
    }

    &__left-container {
        display: flex;
        flex-grow: 1;

        &__logo {
            @include margin-right(8px);
            width: rem(138px);
            text-align: left;

            img {
                width: 100%;
            }

            @include respond-to("tablet") {
                @include margin-right(40px);
                width: rem(112px);
            }

            @include respond-to("phone") {
                @include margin-right(8px);
                align-self: center;
            }

            .c-icon {
                width: rem(48px);
                height: rem(48px);

                @include respond-to("phone") {
                    width: rem(32px);
                    height: rem(32px);
                }
            }
        }

        &__text {
            @include margin-right(16px);
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: space-evenly;

            @include respond-to("tablet") {
                @include margin-bottom(16px);
            }

            @include respond-to("phone") {
                margin-right: 0;
            }

            label {
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall"),
                    $weight: get-font-weight("bold"),
                    $color: get-color-accents("yellow")
                );
                text-align: center;

                @include respond-to("tablet") {
                    text-align: left;
                }

                @include respond-to("phone") {
                    @include margin-bottom(8px);
                }
            }

            p {
                @include font-style(
                    $size: "small",
                    $line-height: get-line-height("small"),
                    $color: get-color-neutral("white")
                );
                text-align: center;

                @include respond-to("tablet") {
                    text-align: left;
                }
            }
        }
    }

    &__buttons {
        @include padding-top(6px);
        white-space: nowrap;

        @include respond-to("tablet") {
            text-align: center;
            width: 100%;
        }

        .c-button {
            white-space: nowrap;
            text-align: center;

            &.-primary {
                border: 1px solid get-color-neutral("white");
            }

            &:first-child {
                @include margin-right(16px);
            }
        }
    }
}
