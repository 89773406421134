.c-my-link-page {
    display: flex;
    flex-direction: column;
    overflow: auto;

    &__body {
        @include margin-top($breadcrumb-menu-height);
        @include calc-vh(max-height, $breadcrumb-menu-height);
        // overflow: auto;
        // height: 100%;
    }

    &__header {
        @include margin-top(48px);
        @include padding-left(48px);
        @include padding-bottom(16px);

        @include respond-to("tablet") {
            @include padding-left(48px);
            @include padding-top(16px);
        }

        @include respond-to("phone") {
            @include padding-left(24px);
        }

        h4 {
            color: get-color-neutral("90");
        }
    }
}
