.c-enhanced-content-admin-dashboard {
    $padding-width-and-margin: 48px;
    height: 100%;
    @include padding-top(48px);
    display: flex;
    flex-direction: column;

    p,
    label,
    h5 {
        color: get-color-neutral("90");
    }

    &__header {
        > h5 {
            @include padding-left(48px);
            @include padding-bottom(16px);
        }

        .c-route-tabs {
            &__panels {
                display: none;
            }
        }
    }

    &__content {
        @include padding-left($padding-width-and-margin);
        @include padding-right($padding-width-and-margin);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        &__controls__container {
            @include padding-top(24px);
            @include padding-left($padding-width-and-margin);
            @include padding-right($padding-width-and-margin);
            display: flex;
            align-items: center;

            .c-search-form {
                width: 100%;
                max-width: 25rem;
            }
        }

        &__data {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            position: relative;
            width: 100%;

            &__table {
                @include margin-left($padding-width-and-margin);
                @include margin-right($padding-width-and-margin);
                flex: 1;
            }

            .c-page-navigation-menu {
                position: sticky;
                bottom: 0;
                box-shadow: 0px -3px 10px rgba(0, 26, 57, 0.1);
            }
        }

        &.c-enhanced-content-admin-tab-content {
            @include padding-left(0);
            @include padding-right(0);
        }
    }
}
