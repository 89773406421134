.c-category-editor {
    flex-grow: 1;

    .c-admin-editor-layout {
        &__footer {
            &__right {
                display: flex;
                justify-content: space-between;

                &__left {
                    text-align: left;

                    p {
                        @include font-style(
                            $size: "xsmall",
                            $line-height: "xsmall",
                            $color: get-color-neutral("90")
                        );
                    }
                }

                &__right {
                    .c-menu-button {
                        display: inline-block;

                        &__menu {
                            top: unset;
                            right: 0;
                            bottom: rem(50px);
                            min-width: rem(150px);

                            @include if-internet-explorer {
                                top: rem(-122px);
                            }

                            .c-button {
                                color: get-color-neutral("90");
                            }
                        }
                    }
                }
            }
        }
    }
}
