.c-search-modal-results {
    @include padding(0, 16px, 16px, 16px);
    padding-right: 0;
    flex-basis: 70%;
    height: 100%;

    @include respond-to("phone") {
        flex-basis: 100%;
        padding: 0;
    }

    &__header {
        @include padding(8px, 24px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond-to("phone") {
            @include padding(8px, 0);
        }

        &__count {
            @include font-style(
                $size: "small",
                $color: get-color-neutral("70"),
                $line-height: "small"
            );
        }
    }

    &__list {
        height: 100%;

        .c-search-result-card {
            @include padding(16px);
        }

        @include respond-to("phone") {
            .c-search-result-card {
                @include padding(16, 0);
                &:not(:first-of-type) {
                    border-top: rem(1px) solid get-color-neutral("30");
                }
            }
        }
    }

    &__matching-code-and-standards {
        @include font-style(
            $size: "xsmall",
            $color: get-color-neutral("70"),
            $line-height: "small"
        );
        text-transform: uppercase;
        display: inline-flex;
        @include margin-bottom(8px);

        &__label {
            margin: auto;
            @include respond-to("phone") {
                max-width: 40vw;
            }
        }

        &__button {
            @include margin-left(16px);
            @include respond-to("phone") {
                max-width: 60vw;
            }
        }
    }
}
