.c-table-section {
    scroll-margin: rem(28px);

    &.-container {
        position: relative;

        .-change-indicator {
            position: absolute;
            top: 0;
            left: rem(-$change-indicator-icon-spacing);
            background-color: transparent;
            @include respond-to("phone") {
                left: rem(-25px);
            }
        }

        .-change-indicator > .c-icon {
            height: 24px;
            width: 24px;
            padding: 4px;
            border-radius: 24px;
            background-color: get-color-accents("orange-light");
            color: get-color-status("warning-dark");

            @include respond-to("tablet") {
                height: 20px;
                width: 20px;
            }
        }

        // for non-button change indicator in reference panel
        .c-icon.-change-indicator {
            background-color: get-color-accents("orange-light");
        }
    }

    &__notes {
        position: absolute;
        left: 1rem;
        top: 0;
        transform: translateY(-50%);
        background: white;
        height: 32px;
        width: 33px;

        .c-button {
            @include margin-right(8px);
            display: flex;
            border-radius: 50%;
            padding: 0;
            z-index: 6;
            &.multi {
                border-radius: rem(16px);
            }
        }

        .c-icon {
            @include bookmark-icon-colors();
            @include padding(6px);
            position: relative;
            height: 28px;
            width: 28px;
        }
    }

    &__icon-container {
        @include get-color-classes-from-map(
            background,
            "bookmark",
            "background"
        );
        border: 2px solid get-color-neutral("white");
        position: relative;
        display: inline-block;
        border-radius: 50%;
        height: 32px;
        width: 32px;

        &.-secondary-bookmark {
            @include get-color-classes-from-map(background, "bookmark");
        }

        &.-has-count {
            @include padding-right(10px);
            border-radius: 32px;
            width: auto;

            &.-secondary-bookmark {
                padding-right: 0;
                height: 28px;
                width: 28px;
            }

            span {
                @include font-size(10px);
                position: absolute;
                right: rem(6px);
                top: 50%;
                transform: translateY(-50%);
            }
        }

        & ~ .c-table-section__icon-container {
            margin-left: -22px;
            z-index: 0;
        }
        $z: -8;
        @for $i from 1 through 8 {
            &:nth-of-type(#{$i}) {
                z-index: abs($z);
            }
            $z: $z + 1;
        }
    }

    > .c-menu-button {
        position: absolute;
        right: rem(20px);
        top: 0;
        transform: translateY(-50%);
        display: flex;
        // Increasing z-index to force menu button to appear above white border for any sub-sections
        z-index: 6;
        width: rem(28px);
        height: rem(28px);
        visibility: visible;

        @include respond-to("phone") {
            z-index: 8;
        }

        &.-hidden {
            visibility: hidden;
        }

        > .c-menu-button__icon {
            @include border-radius("base");
            @include padding(5px);
            background-color: get-color-neutral("90");
            @include icon-fill(get-color-neutral("white"));
        }

        .c-menu-button__menu {
            min-width: rem(158px);
            top: 32px;
            .c-menu-button__item {
                @include icon-fill(get-color-neutral("50"));
                .c-button {
                    color: get-color-neutral("90");
                }

                .c-icon {
                    @include margin-right(8px);
                    vertical-align: text-top;
                }
            }
        }
    }

    .c-bookmark-settings {
        position: absolute;
        right: rem(16px);
    }

    &__body {
        @include margin(28px, 0);
        border: rem(1px) solid get-color-neutral("50");
        display: block;
        max-height: rem(600px);
        overflow: auto;

        @include respond-to("phone") {
            max-height: unset;
        }

        &.-full {
            // Needs the full page width
            > table {
                min-width: rem(1440px);
            }
        }
        &.-three-quarters {
            // Covering two columns in the print edition
            > table {
                min-width: rem(1024px);
            }
        }
        &.-half {
            // Covering a single column in the print edition
            > table {
                min-width: $section-max-width;
            }
        }

        &.-no-margin {
            @include margin(0);
            border-top: none;
        }

        tr,
        th,
        tbody {
            word-break: initial;
            .c-code-change {
                background: transparent;
            }
        }

        > table.-sticky {
            border-collapse: separate !important;
            border-spacing: 0;
            border-top: none !important;

            thead {
                @include padding-top(20px);
                position: sticky;
                top: 0;
                z-index: 1;
                background-color: white;
                filter: drop-shadow(0px 7px 20px rgba(0, 26, 57, 0.3));
                tr:first-of-type {
                    th {
                        border-top: rem(1px) solid get-color-neutral("50") !important;
                    }
                }
            }
            tbody {
                tr:last-of-type {
                    td {
                        border-bottom: rem(1px) solid get-color-neutral("50") !important;
                    }
                }
            }
        }
        .simple-tableview-icon {
            text-align: right;
            @include rem-fallback("padding-top", 7px);
            @include rem-fallback("padding-right", 7px);
        }

        &.-simple {
            td,
            th {
                border: rem(1px) solid get-color-neutral("50") !important;
            }

            td:first-of-type,
            th:first-of-type {
                border-left: none !important;
            }

            td:last-of-type,
            th:last-of-type {
                border-right: none !important;
            }

            table.-sticky {
                td,
                th {
                    border-top: none !important;
                    border-left: none !important;
                    border-bottom: rem(1px) solid get-color-neutral("50") !important;
                    border-right: rem(1px) solid get-color-neutral("50") !important;
                }
            }
            &.-mobile {
                @include respond-to("tablet") {
                    .c-table-section__body__expander {
                        text-align: right !important;
                    }

                    border: 0 solid get-color-neutral("50") !important;
                    overflow: visible !important;
                    max-height: 100% !important;

                    caption {
                        width: auto !important;
                        background-color: #fff;
                        display: block !important;
                        border: none !important;
                        color: get-color-neutral("90");
                    }

                    .card {
                        background: #fff;
                        border-radius: 0.75rem;
                        display: block;
                        position: relative;
                        border: 0.0625rem solid get-color-neutral("50") !important;
                        @include rem-fallback("padding-bottom", 7px);
                        @include rem-fallback("margin-bottom", 15px);
                        height: auto !important;
                    }

                    table,
                    tbody,
                    tr,
                    td {
                        display: block;
                        border: none !important;
                    }

                    table {
                        background-image: get-color-neutral("50") !important;
                        @include rem-fallback("max-width", 768px);
                        @include rem-fallback("padding-top", 5px);
                        @include rem-fallback("padding-left", 5px);
                        @include rem-fallback("padding-bottom", 5px);
                        @include rem-fallback("padding-right", 5px);
                    }

                    thead {
                        display: none;
                    }

                    tr {
                        column-count: 1;
                    }

                    td {
                        position: relative;
                        text-align: left !important;
                        @include rem-fallback("padding-top", 7px);
                        padding-left: calc(40% + 23px);
                        border-top: none;
                        @include rem-fallback("width", 100%);
                        width: 100% !important;
                        height: 100% !important;
                        word-wrap: break-word !important;
                    }

                    td:before {
                        content: attr(data-label);
                        position: absolute;
                        @include rem-fallback("top", 0);
                        @include rem-fallback("bottom", 0);
                        @include rem-fallback("left", 0);
                        @include rem-fallback("min-width", 90px);
                        @include rem-fallback("width", 150px);
                        text-align: left !important;
                        @include rem-fallback("padding-top", 7px);
                        @include rem-fallback("padding-left", 13px);
                        // padding: 7px 13px;
                        overflow-wrap: break-word !important;
                        @include font-style(
                            $color: get-color-neutral("90"),
                            $weight: 800
                        );
                    }

                    tfoot {
                        tr:last-of-type {
                            td {
                                border-bottom: none !important;
                            }
                        }
                    }
                }
            }
        }

        caption {
            @include padding(12px);
            @include font-style(
                $size: "small",
                $color: get-color-neutral("90"),
                $weight: 800,
                $line-height: rem(16px)
            );
            border-top: 3px solid get-color-neutral("50");
            word-break: break-word;

            span.-description {
                @include font-style(
                    $weight: "base",
                    $size: "small",
                    $line-height: "small",
                    $color: get-color-neutral("90")
                );
            }
        }

        table {
            width: 100%;
            border-width: rem(1px);
            border-collapse: collapse;
            border-color: get-color-neutral("50");

            tbody + caption {
                text-align: left;
                caption-side: bottom;
                color: get-color-neutral("70");
                border: none;
                > p {
                    @include paragraph-styles("small");
                    color: get-color-neutral("70");
                }
            }
            &.-no-title {
                thead {
                    tr:first-of-type {
                        th {
                            @include padding-top(16px);
                            @include padding-bottom(16px);
                        }
                    }
                }
            }
        }

        tr {
            td {
                @include padding(8px, 16px);
                @include font-style(
                    $size: "small",
                    $color: get-color-neutral("70"),
                    $line-height: rem(16px)
                );
                border-width: rem(1px);
                border-color: get-color-neutral("50");
                text-align: center;
                word-break: initial;
            }

            th {
                @include font-style(
                    $size: "small",
                    $color: get-color-neutral("90"),
                    $weight: 800,
                    $line-height: rem(16px)
                );
                @include padding(8px);
                border-width: rem(1px);
                border-color: get-color-neutral("50");
            }
        }

        tfoot {
            border-top: rem(1px) solid get-color-neutral("50");

            tr {
                td {
                    p {
                        @include font-style(
                            $size: "small",
                            $color: get-color-neutral("70"),
                            $line-height: rem(21px)
                        );
                        text-align: left;
                    }
                    .c-code-change.-addition {
                        background-color: get-color-accents("orange-light");
                    }
                }
            }
        }
    }

    &-modal {
        width: fit-content;

        .c-bookmark-slider {
            top: 26rem;
            left: 45rem;
            position: fixed;
        }
        .c-bookmark-settings {
            position: fixed;
            top: 26rem;
            left: 65rem;
        }
        &-header {
            $wrapper-min-width: rem(132px);

            @include padding(8px, 16px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: get-color-background("neutral");
            width: 100%;

            @include respond-to("phone") {
                position: sticky;
                left: 0;
            }

            .title-container {
                display: flex;
                width: fit-content;
                max-width: 80%;
                align-items: center;
                justify-content: space-around;
                .__icon-container {
                    @include padding(6px);
                }
                .c-button {
                    @include padding(0px);
                }
            }
            .-tertiary-alt {
                background-color: get-color-background("neutral");
                border: none;
                padding: none;
            }
            .__icon-container {
                @include get-color-classes-from-map(
                    background,
                    "bookmark",
                    "background"
                );
                border: 2px solid get-color-background("neutral");
                position: relative;
                display: inline-block;
                border-radius: 50%;
                height: 32px;
                width: 32px;
            }
            .c-icon {
                @include bookmark-icon-colors();
            }

            caption {
                @include padding(12px);
                @include font-style(
                    $size: "small",
                    $color: get-color-neutral("90"),
                    $weight: 800,
                    $line-height: rem(16px)
                );
                word-break: break-word;
                width: fit-content;

                span.-description {
                    @include font-style(
                        $weight: "base",
                        $size: "small",
                        $line-height: "small",
                        $color: get-color-neutral("90")
                    );
                }
            }

            &-modal-close {
                display: flex;
                justify-content: flex-end;
                width: fit-content;
                min-width: $wrapper-min-width;
                @include respond-to("phone") {
                    min-width: rem(40px);
                }
                button {
                    @include padding(8px);
                    width: fit-content;
                    height: fit-content;
                    justify-self: end;

                    svg {
                        display: block;
                    }
                }
            }

            .c-button.-medium {
                @include padding(8px);
            }

            &-toggle {
                width: fit-content;
                min-width: $wrapper-min-width;
                button {
                    display: flex;
                }

                .c-icon {
                    @include margin-right(4px);
                }
            }

            .modal-toggle-header-center {
                min-width: 0px;
            }
        }

        .modal-header-center {
            justify-content: center;
        }

        &.-fullscreen {
            @include padding(0);
            max-height: 90vh;

            .c-modal__content {
                max-height: 90vh;
            }
        }

        .c-modal__content {
            @include respond-to("tablet") {
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
            }

            @include respond-to("phone") {
                .c-table-section__body {
                    max-width: rem(400px);
                    overflow: unset;
                    border: none;
                }
            }
        }
    }
}
