.c-drag-and-drop-list-box {
    &.-dragging-container {
        .c-button {
            display: none !important;
        }
    }

    .c-list-box__item {
        &.-dragging {
            @include box-shadow(0px, 7px, 20px, get-color-neutral("30"));
            background-color: get-color-neutral("05");
            border-radius: $border-radius-small;
            border: 1px solid get-color-neutral("30");
            font-size: get-font-size("small");
            line-height: get-line-height("small");

            .c-button {
                display: none;
            }

            .-drag-handle {
                @include icon-fill(get-color-neutral("70"));
            }
        }

        &:hover {
            .-drag-handle {
                @include icon-fill(get-color-neutral("70"));
            }
        }

        .-drag-handle {
            @include icon-fill(get-color-neutral("50"));
            @include margin-left(-12px);
            @include margin-right(8px);
            white-space: nowrap;

            svg {
                margin-top: 50%;
            }
        }
    }
}
