.c-banner-navigation {
    --border-width: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1.2rem;
    position: relative;
    z-index: 1;
    background-color: white;
    grid-area: banner;
    filter: drop-shadow(0px 3px 10px rgba(0, 26, 57, 0.2));

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: var(--border-width);
        width: 100%;
        background-image: url("../../../images/texture_diagonal-lines_gray.png");
        transform: translateY(1rem);
    }

    &__logoWrapper {
        display: flex;
        flex-direction: row;
    }

    &__logo {
        height: 3.5rem; // Fix for Firefox rendering
    }

    &__ctas {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
