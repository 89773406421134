.c-onboarding-layout-banner-modal {
    @include padding(24px);
    width: rem(424px);

    &__header {
        @include margin-bottom(24px);

        > .c-button {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    &__content {
        @include margin-bottom(24px);

        sup {
            position: relative;
            vertical-align: 0;
            bottom: 1ex;
        }
    }
}
