.c-offline-book-list-item {
    @include padding(4);
    display: flex;
    flex-direction: row;

    &:hover {
        background-color: get-color-background("secondary");
    }

    &__title {
        @include font-style($size: "small", $line-height: "small");
        @include margin-right(16);
    }

    &__sync-status {
        @include font-style(
            $size: "xsmall",
            $color: get-color-neutral("70"),
            $style: italic,
            $line-height: "xsmall"
        );
        @include margin-right(16);
        @include padding-top(4);
        text-align: right;
        flex-grow: 1;
    }

    &__download {
        @include margin-right(8px);
        flex-shrink: 0;
    }

    &__delete.-destructive:disabled {
        background-color: get-color-background("neutral");
        color: get-color-neutral("50");

        svg path {
            fill: get-color-neutral("50");
        }
    }
}
