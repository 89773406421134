.c-publication-create {
    height: 100%;

    &_code_edition_container {
        display: flex;
        justify-content: space-between;

        .c-form-field {
            width: 48%;
        }
    }

    &__form {
        @include padding(48px);
        height: calc(100% - #{$admin-footer-height});
        overflow-y: auto;
    }
    &__footer {
        @include padding(16px, 24px);
        height: rem($admin-footer-height);
        border-top: 1px solid get-color-neutral("30");
        > .c-button:first-of-type {
            @include margin-right(16px);
        }
    }
}
