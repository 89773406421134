.c-admin-editor-page-layout-v2 {
    height: 100%;
    @include padding-top(48px);
    display: flex;
    flex-direction: column;

    h5 {
        @include margin-bottom(16px);
        @include padding-left(48px);
    }

    p,
    label,
    h5 {
        color: get-color-neutral("90");
    }

    &__content {
        border-top: 1px solid get-color-neutral("30");
        display: flex;
        flex-grow: 1;

        &__left {
            position: relative;
            border-right: 1px solid get-color-neutral("30");
            width: 25%;
            min-width: 25%;
            max-width: 25%;
            display: flex;
            flex-direction: column;

            .c-text-input-icon {
                @include margin(8px, 8px, 0, 8px);
            }

            &__list {
                @include padding(8px, 8px, 8px, 16px);
                flex-grow: 1;
                overflow-y: auto;
                // because it's in flexbox, this behaves the same as min-height
                // but also vertically resizes it so that all elements fit vertically
                // in the parent. See: https://stackoverflow.com/a/14964944
                height: 1px;
                @include margin-bottom($admin-footer-height);

                &__item {
                    width: 100%;
                    text-align: left;
                    @include border-radius("base");

                    &.-selected,
                    &:hover {
                        background-color: get-color-neutral("05");
                    }

                    &__checkbox {
                        position: relative;
                        top: rem(16px);
                        left: rem(16px);
                        z-index: 1;

                        .e-checkbox__checkmark {
                            background-color: white;
                        }
                    }

                    &.c-button {
                        display: flex;
                        @include padding(11px);
                    }

                    &__cover {
                        width: rem(50px);
                        height: rem(73px);
                        flex-shrink: 0;
                        overflow-y: hidden;
                        @include margin-right(12px);

                        > img {
                            @include border-radius("base");
                            width: 100%;
                        }
                    }

                    &__no-cover {
                        flex-shrink: 0;
                        height: rem(73px);
                        width: rem(50px);
                        @include margin-right(12px);
                        background-color: get-color-background("medium");
                        @include border-radius("base");
                    }

                    &__display {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                    }

                    &__code {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        @include padding-bottom(2px);

                        .c-publish-status-badge-with-icon {
                            height: rem(24px);
                        }

                        .-text {
                            @include font-style(
                                $size: "small",
                                $color: get-color-neutral("90"),
                                $line-height: get-line-height("small")
                            );
                            flex-grow: 1;
                        }
                    }

                    &__title {
                        display: flex;
                        @include font-style(
                            $size: "xsmall",
                            $color: get-color-neutral("90"),
                            $line-height: get-line-height("xsmall")
                        );
                        @include padding(2px, 0);
                    }

                    &__subtitle {
                        @include ellipsis(block);
                        @include font-style(
                            $size: "xxsmall",
                            $color: get-color-neutral("70"),
                            $line-height: get-line-height("xxsmall")
                        );
                        @include padding-top(2px);
                    }
                }

                .-no-records-message {
                    text-align: center;
                    @include margin-top(20px);
                    font-style: italic;
                    color: get-color-neutral("70");
                }
            }

            &__footer {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                @include padding(16px);
                height: rem($admin-footer-height);
                border-top: 1px solid get-color-neutral("30");
                background: get-color-neutral("white");
            }
        }

        &__right {
            flex-grow: 1;
            width: 75%;
            min-width: 75%;
            max-width: 75%;

            &__none-selected {
                text-align: center;

                p {
                    @include margin-top(35%);

                    a {
                        @include font-style(
                            $size: "large",
                            $weight: get-font-weight("bold"),
                            $line-height: get-line-height("large")
                        );
                    }
                }
            }
        }
    }
}
