.c-reference-panel-dropdown-menu {
    $dropdown-height: 320px;
    $dropdown-width: 320px;
    $dropdown-width-tablet: 240px;
    $button-height: 38px;
    position: relative;

    .c-menu-button {
        &__menu {
            width: rem($dropdown-width);
            max-height: $dropdown-height;
            bottom: rem(48px);
            top: initial;
            overflow-y: auto;
            overflow-x: hidden;

            @include respond-to-min-width("ultrawide") {
                right: 0;
            }

            @include respond-to("tablet") {
                right: rem(-32px);
                width: $dropdown-width-tablet;
            }
        }
        &__item {
            width: 100%;

            button {
                white-space: initial;

                &.-active {
                    .c-menu-button__item {
                        &__section-title,
                        &__publication-title {
                            font-weight: get-font-weight("base");
                            color: get-color-accents("blue-dark");
                        }
                    }
                }
            }

            &__section-title {
                @include font-size("xsmall");
                color: get-color-neutral("90");
            }

            &__publication-title {
                @include font-size("xxsmall");
                color: get-color-neutral("70");
            }

            &:last-of-type {
                @include margin-top(40px);
                @include margin-bottom(18px);
                position: relative;
                border-radius: 0;

                &:before {
                    content: "";
                    border-top: 1px solid get-color-neutral("30");
                    position: absolute;
                    top: -16px;
                    left: rem(8px);
                    width: calc(100% - 16px);
                    text-align: center;
                }
            }
        }

        .c-button {
            @include padding(7px);
            display: flex;
            align-items: center;

            @include icon-fill(get-color-neutral("70"));
        }

        svg {
            width: rem(12px);
            height: rem(10px);
        }
    }

    &__modal {
        .c-modal__content {
            @include respond-to("phone") {
                max-height: calc(#{vh()} - 96px);
                overflow-y: auto;
            }

            .c-menu-button__item {
                button {
                    @include respond-to("phone") {
                        @include padding-left(8px);
                        white-space: normal;

                        &.-active {
                            .c-menu-button__item {
                                &__section-title,
                                &__publication-title {
                                    font-weight: get-font-weight("base");
                                    color: get-color-accents("blue-dark");
                                }
                            }
                        }
                    }
                }
                &:last-of-type {
                    @include margin-top(40px);
                    @include padding-bottom(18px);
                    position: relative;
                    border-radius: 0;
                    &:before {
                        content: "";
                        border-top: 1px solid get-color-neutral("30");
                        position: absolute;
                        top: -16px;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
        @include respond-to("phone") {
            top: 0;
        }
    }
}
