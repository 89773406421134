.c-register-account-page {
    @include margin-bottom(80px);
    @include padding(0, 36px);

    @include respond-to("phone") {
        @include padding(0, 24px);
    }

    $baseClass: &;
    &.-signed-in {
        #{$baseClass} {
            &__content {
                flex-direction: column;
                width: 45%;
                @include respond-to("tablet") {
                    width: 100%;
                }

                &__plan {
                    @include margin-bottom(32px);
                    margin-right: 0;
                    width: 100%;
                }

                .c-free-trial-terms {
                    @include margin-bottom(32px);
                    margin-left: auto;
                    margin-right: auto;
                    max-width: rem(376px);
                }
            }
        }

        .c-selected-subscription-card {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__header {
        @include margin-bottom(48px);

        @include respond-to("tablet") {
            @include margin-bottom(32px);
        }

        p {
            text-align: center;
            a {
                font-weight: 700;
            }
            span {
                font-weight: 700;
                text-decoration: underline;

                &:hover {
                    cursor: pointer;
                }
            }
        }
        &__title {
            @include margin(40px, 0, 24px, 0);
            @include font-style($font-primary, "xxxlarge", false, 100, 62px);
            color: get-color-neutral("90");
            text-align: center;

            @include respond-to("phone") {
                @include margin(16px, 0, 24px, 0);
                @include font-style($font-primary, "xlarge", false, 100, 36px);
            }
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        max-width: 900px;
        margin: 0 auto;

        @include respond-to("tablet") {
            @include margin-bottom(32px);
            flex-direction: column;
        }

        .c-userlogin-new-form {
            max-width: none;
        }

        .c-selected-subscription-card {
            @include margin-bottom(16px);

            width: rem(376px);
            @include respond-to("tablet") {
                @include margin-bottom(32px);
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                flex-direction: row;
                max-width: 100%;
            }
        }

        &__form {
            &__submit {
                margin-left: auto;
                margin-right: auto;
                width: 300px;
            }
        }
    }
}
