[data-reach-skip-link] {
    @include padding(13px, 24px, 11px);
    @include border(0, 3px, 3px, 3px, solid, get-color-accents("blue-base"));
    @include font-style(
        $font-primary,
        "xsmall",
        get-color-accents("blue-dark"),
        800,
        18px
    );
    position: absolute;
    left: calc(50% - 67px);
    top: -52px;
    text-decoration: none;
    display: block;
    background: get-color-accents("blue-light");
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

[data-reach-skip-link]:focus {
    box-shadow: 0px 3px 10px rgba(0, 26, 57, 0.2);
    outline: none;
    z-index: 5;
    top: 0;
    transition: top 0.2s;
}
