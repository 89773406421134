.c-enhanced-content-panel {
    @include margin(24px, 0);
    @include padding(16px, 24px);
    background-color: get-color-accents("blue-light");
    border-radius: $border-radius-medium;
    scroll-margin-top: 2rem;

    @include respond-to("phone") {
        @include padding(16px);
    }

    &__content {
        @include margin-top(24px);
        @include padding-bottom(16px);

        h1,
        h2,
        h3 {
            @include margin(16px, 0);
            color: get-color-neutral("90");
        }

        h1,
        p.-header[data-header="1"] {
            @include font-style(
                $size: "xlarge",
                $line-height: 34px,
                $weight: "light"
            );
        }

        h2,
        p.-header[data-header="2"] {
            @include font-style($size: "large", $line-height: 27px);
        }

        h3,
        p.-header[data-header="3"] {
            @include font-style(
                $size: "base",
                $line-height: 24px,
                $weight: "bold"
            );
        }

        .c-rich-text-area {
            ul,
            ol {
                li {
                    @include font-style(
                        $size: "base",
                        $line-height: 24px,
                        $color: get-color-neutral("90")
                    );
                    @include padding-left(4px);
                }
            }

            a {
                font: inherit;
                color: inherit;
            }
        }

        ul,
        ol,
        blockquote {
            @include padding-left(16px);
        }

        blockquote {
            @include margin-bottom(16px);
            color: get-color-accents("blue-dark");
            font-style: italic;
            border-left: 4px solid get-color-accents("blue-base");
        }

        img {
            @include margin(16px, 0);
        }
    }

    &__resources {
        @include padding-top(16px);
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid get-color-neutral("30");

        > p {
            @include padding-bottom(16px);
            width: 100%;
        }
        > .c-resource-tile {
            @include margin-right(16px);
            @include margin-bottom(8px);
            flex: 1;
            min-width: rem(122px);
            max-width: rem(122px);
            vertical-align: top;
        }
    }

    &__footer,
    &__header__title,
    &__header__toggle {
        @include font-style(
            $color: get-color-neutral("90"),
            $size: "xxsmall",
            $line-height: "xxsmall"
        );
    }

    &__footer {
        @include padding-top(16px);
        border-top: 1px solid get-color-neutral("50");
        display: flex;
        align-items: center;

        &__actions {
            margin-left: auto;
            display: flex;
            align-items: center;

            @include respond-to("phone") {
                @include margin-top(8px);
                margin-left: 0;

                .c-menu-button {
                    margin-left: auto;
                }
            }

            .c-menu-button__icon {
                @include padding(1px);
            }
            .c-publish-status-badge {
                margin-top: 0;
            }
        }

        @include respond-to("phone") {
            flex-direction: column;
            align-items: initial;
        }

        &__changes {
            &__status {
                @include padding-right(3px);

                &.-bold {
                    font-weight: get-font-weight("bold");
                }
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        cursor: pointer;

        &__title {
            text-transform: uppercase;
            font-weight: get-font-weight("bold");
        }
        &__toggle {
            @include padding(4px);
            min-width: rem(64px);
            font-weight: get-font-weight("bold");
            margin-left: auto;
            background-color: transparent;
            display: flex;
            align-items: center;
            flex-shrink: 0;

            .c-icon {
                margin-left: auto;
            }
        }
        .c-loader {
            @include margin(0, 8px);
            display: inline;
            height: 24px;
            width: 24px;
        }

        .c-publish-status-badge {
            @include margin-left(8px);
            margin-top: 0;
        }
    }

    &__accordion {
        display: none;
        transition: display 0.25 ease-out;
    }

    &.-expanded {
        .c-enhanced-content-panel {
            @include margin-top(24px);
            max-height: 999999px;
            transition: max-height 0.25s ease-in;
        }
        .c-enhanced-content-panel__accordion {
            display: block;
            transition: display 0.25 ease-in;
        }
    }

    &.-mobile-modal {
        @include padding(60px, 0, 0, 0);
        margin: 0;
        border-radius: 0;
        height: #{vh()};
        max-width: 100%;
        width: 100%;

        .c-enhanced-content-panel__accordion {
            @include padding(16px, 24px);
            height: calc(#{vh()} - #{60px});
            overflow-y: auto;
            display: block;
        }

        .c-enhanced-content-panel__content {
            margin: 0;
        }

        .c-enhanced-content-panel__header {
            @include padding(16px, 24px);
            @include border-bottom(1px, solid, get-color-neutral("30"));
            position: absolute;
            top: 0;
            left: 0;
            height: rem(60px);
            width: 100%;

            > .c-button.-modal-close {
                background: transparent;
                position: absolute;
                right: rem(24px);
            }

            > .c-icon {
                @include margin-right(24px);
            }

            h2 {
                @include font-style(
                    $size: "large",
                    $color: get-color-neutral("90"),
                    $line-height: get-line-height("large")
                );
                display: inline-block;
            }
        }
    }
}
