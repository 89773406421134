.c-no-results-banner {
    @include border-radius("large");
    @include padding(40px);
    background-color: get-color-background("neutral");
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to("sm-tablet") {
        min-width: initial;
    }

    &.-secondary {
        background-color: get-color-neutral("white");
    }

    p:first-of-type {
        @include margin-bottom(8px);
    }

    &__subtitle {
        @include font-style($color: get-color-neutral("70"));
    }
}
