.c-rich-text-editor {
    display: block;
    width: 100%;

    &.-with-footer {
        .c-form-field {
            // move margin to below character counter
            margin-bottom: 0;

            &__bottom {
                &__character-count {
                    @include margin-bottom(20px);
                }
            }
        }
    }

    > div[class^="RichTextEditor"] {
        @include font-style();
        @include margin-top(5px);
        border: 1px solid get-color-neutral("30");
        border-radius: $border-radius-small;

        div[class^="EditorToolbar"] {
            position: relative;
            z-index: 5;
        }

        .Button__root___1gz0c {
            background: none get-color-neutral("white");

            &.IconButton__isActive___2Ey8p {
                background: none get-color-neutral("30");
            }
        }

        .ButtonWrap__root___1EO_R {
            z-index: 1;
        }

        .public-DraftEditor-content {
            min-height: 125px;
        }
    }

    // This error class applies a red border to the RTE input field as well as red text for the
    // character counter.
    &.-error {
        > div[class^="RichTextEditor"] {
            border: 1px solid get-color-primary("error");
            border-radius: $border-radius-small;
        }

        + .c-form-field__bottom__character-count {
            @include font-style(
                $size: "xsmall",
                $color: get-color-primary("error"),
                $line-height: get-line-height("xsmall")
            );
            font-style: normal;
        }
    }

    .c-form-field__bottom__character-count {
        @include font-style(
            $size: "xsmall",
            $color: get-color-neutral("90"),
            $line-height: get-line-height("xsmall")
        );

        margin-left: auto;
    }

    // This error class is to display a red error message to the left of the character counter with
    // an information (!) icon. It also applies the red text styling to the character counter.
    &__error {
        @include icon-fill(get-color-primary("error"));
        @include font-style(
            $size: "xsmall",
            $color: get-color-primary("error"),
            $line-height: get-line-height("xsmall")
        );
        @include margin-top(8px);
        font-style: italic;
        display: flex;
        > svg {
            @include margin-right(8px);
        }

        &-messages {
            display: flex;
            flex-direction: column;
        }

        .c-form-field__bottom__character-count {
            @include font-style(
                $size: "xsmall",
                $color: get-color-primary("error"),
                $line-height: get-line-height("xsmall")
            );
            @include margin-top(0);
            font-style: normal;
        }
    }

    &__v2 {
        display: block;
        width: 100%;
        position: relative;

        .ql-toolbar {
            border: 1px solid get-color-neutral("30");
            border-top-left-radius: $border-radius-small;
            border-top-right-radius: $border-radius-small;

            &.-disabled {
                .ql-formats {
                    visibility: hidden;
                }
            }

            .ql-formats {
                button {
                    .ql-stroke {
                        stroke: get-color-neutral("90");
                    }

                    .ql-fill {
                        fill: get-color-neutral("90");
                    }

                    .-add-reference-icon {
                        @include icon-fill(get-color-neutral("90"));
                        @include margin-top(-2px);
                        width: rem(16px);
                        height: rem(16px);
                    }

                    &.ql-active {
                        .ql-stroke {
                            stroke: get-color-accents("blue-base");
                        }

                        .ql-fill {
                            fill: get-color-accents("blue-base");
                        }

                        .-add-reference-icon {
                            @include icon-fill(get-color-accents("blue-base"));
                        }
                    }

                    @include if-not-touchscreen {
                        &:hover {
                            .ql-stroke {
                                stroke: get-color-accents("blue-base");
                            }

                            .ql-fill {
                                fill: get-color-accents("blue-base");
                            }

                            .-add-reference-icon {
                                @include icon-fill(
                                    get-color-accents("blue-base")
                                );
                            }
                        }
                    }
                }

                .ql-picker {
                    .ql-picker-label {
                        &:before {
                            color: get-color-neutral("90");
                        }

                        .ql-stroke {
                            stroke: get-color-neutral("90");
                        }

                        &.ql-active {
                            &:before {
                                color: get-color-accents("blue-base");
                            }

                            .ql-stroke {
                                stroke: get-color-accents("blue-base");
                            }
                        }

                        @include if-not-touchscreen {
                            &:hover {
                                &:before {
                                    color: get-color-accents("blue-base");
                                }

                                .ql-stroke {
                                    stroke: get-color-accents("blue-base");
                                }
                            }
                        }
                    }

                    .ql-picker-options {
                        .ql-picker-item {
                            &:before {
                                color: get-color-neutral("90");
                            }

                            &.ql-selected {
                                &:before {
                                    color: get-color-accents("blue-base");
                                }
                            }

                            @include if-not-touchscreen {
                                &:hover {
                                    &:before {
                                        color: get-color-accents("blue-base");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__label {
            @include margin-bottom(4px);
            display: block;
        }

        &__editor {
            @include quill-styles();

            .ql-container {
                border: 1px solid get-color-neutral("30");
                border-bottom-left-radius: $border-radius-small;
                border-bottom-right-radius: $border-radius-small;
                overflow: hidden;

                .ql-editor {
                    min-height: rem(150px);
                    max-height: vh(70);
                    overflow-y: auto;
                    position: relative;
                    z-index: 0;

                    &.ql-blank:before {
                        @include margin-top(4px);
                    }

                    li {
                        @include paragraph-styles();
                    }

                    // add some padding at the bottom of the editor
                    *:last-child {
                        @include margin-bottom(16px);
                    }
                }
            }
        }

        &__image-modal,
        &__reference-link-modal {
            .c-tabs {
                &__list-wrapper {
                    @include padding(8px, 24px);
                }
            }

            .c-button.-modal-close {
                position: absolute;
                top: rem(12px);
                right: rem(12px);
                z-index: 2;
                background-color: get-color-neutral("white");
            }

            &__footer {
                @include padding(0, 24px, 24px);
                text-align: right;

                .c-button:first-of-type {
                    @include margin-right(16px);
                }
            }
        }

        &__reference-link-modal {
            max-width: rem(750px);

            .c-form {
                @include padding(40px, 24px, 24px);

                .c-select {
                    @include icon-fill(get-color-neutral("50"));

                    @include if-not-touchscreen {
                        &:hover {
                            @include icon-fill(get-color-neutral("70"));
                        }
                    }

                    select {
                        border-color: get-color-neutral("30");
                    }
                }

                .c-radio-list {
                    @include margin-bottom(16px);
                }

                .c-form-field:last-of-type {
                    margin-bottom: 0;
                }
            }

            &__content-item-search {
                @include margin-top(8px);
                @include padding(16px);
                @include border-radius("large");
                background-color: get-color-neutral("05");

                label {
                    @include font-style($size: "small", $line-height: "small");
                }
            }

            &__footer {
                @include padding-top(24px);
                border-top: 1px solid get-color-neutral("05");
            }
        }

        .c-form-field__bottom__character-count {
            @include font-style(
                $size: "xsmall",
                $color: get-color-neutral("90"),
                $line-height: get-line-height("xsmall")
            );

            margin-left: auto;
        }

        &__error {
            @include icon-fill(get-color-primary("error"));
            @include font-style(
                $size: "xsmall",
                $color: get-color-primary("error"),
                $line-height: get-line-height("xsmall")
            );
            @include margin-top(8px);
            font-style: italic;
            display: flex;
            > svg {
                @include margin-right(8px);
            }

            &-messages {
                display: flex;
                flex-direction: column;
            }

            .c-form-field__bottom__character-count {
                @include font-style(
                    $size: "xsmall",
                    $color: get-color-neutral("90"),
                    $line-height: get-line-height("xsmall")
                );
                margin-left: auto;
                margin-top: 0;
            }
        }

        &.-error {
            .c-form-field__bottom__character-count {
                @include font-style(
                    $size: "xsmall",
                    $color: get-color-primary("error"),
                    $line-height: get-line-height("xsmall")
                );
                font-style: normal;
            }

            .ql-toolbar,
            .ql-container {
                border-color: get-color-status("error");
            }
        }
    }
}
