.c-modal {
    position: relative;
    display: block;
    background-color: #ffffff;
    max-width: 50%;
    border-radius: $border-radius-medium;
    overflow: hidden;
    margin: auto;

    &__body {
        @include padding(32px);
    }

    &__content {
        height: 100%;
        width: 100%;
        @include respond-to("phone") {
            max-height: 100vh;
            display: flex;
            flex-direction: column;
        }
    }

    &__header {
        @include padding(16px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: get-color-background("neutral");
        width: 100%;
        &.-mobile {
            background-color: #ffffff;
            @include respond-to("phone") {
                border-bottom: 1px solid get-color-neutral("30");
            }
        }

        > h2 {
            @include font-style(
                $family: $font-primary,
                $size: "large",
                $color: get-color-neutral("90"),
                $weight: "base",
                $line-height: "large"
            );
            display: inline-block;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }
    &__footer {
        @include padding(20px);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        border-top: 1px solid get-color-neutral("30");
        background: get-color-neutral("white");
        .c-button:first-of-type {
            @include margin-right(8px);
        }

        &.-noBorderTop {
            border-top: none;
        }

        &.-centeredCtas {
            justify-content: center;
        }
    }

    > .c-button.-modal-close {
        position: absolute;
        right: 0;
        top: 0;
    }

    &.-bottom {
        @include padding(32px);
        position: fixed;
        bottom: 0;
        margin: 0;
        width: 100%;
        max-width: initial;
        border-radius: initial;
        border-top-left-radius: $border-radius-medium;
        border-top-right-radius: $border-radius-medium;

        > .c-button.-modal-close {
            position: static;
            width: 100%;
        }
    }
    &.-fullscreen {
        @include padding(8px);
        max-width: 95%;
        max-height: 80vh;

        .-ie-content-container > .c-button.-modal-close {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            border-radius: 0;
        }

        @include respond-to("phone") {
            @include padding-top(16px);
            height: 100%;
            max-height: none;
            max-width: initial;
            border-radius: 0;
        }
        > .c-button.-modal-close {
            height: rem(40px);
            width: rem(40px);
            border-radius: 0;
        }

        > .c-modal__content {
            overflow: auto;
            max-height: 80vh;
            @include respond-to("phone") {
                max-height: 100vh;
                height: 100%;
            }

            // Special circumstances for tables rendered in the fullscreen modal
            // "Limit" the width of the table and center it in the modal
            > .c-table-section__body.-half > table {
                max-width: $section-max-width;
                margin: 0 auto;
            }

            .c-table-section-modal-header {
                caption {
                    max-width: calc(#{$section-max-width} / 2);
                }
            }
        }
    }

    // remove padding and scroll bar from fullscreen modal
    &.-no-padding {
        padding: 0;

        > .c-modal__content {
            overflow: hidden;
        }
    }

    &.-left {
        @include padding(16px, 40px);
        @include fill-viewport-height();
        left: $sidebar-menu-width;
        margin: 0;
        width: calc(75% - #{$sidebar-menu-width});
        border-radius: initial;
        max-width: initial;
        overflow: visible;

        // Left modal will appear in the center of the IE window without these rules.
        @include if-internet-explorer() {
            position: fixed;
            top: 0;
        }

        @include respond-to("sm-desktop") {
            width: calc(100% - #{$sidebar-menu-width});
        }

        @include respond-to("phone") {
            @include padding(16px, 16px, 0, 16px);
            width: 100%;
            top: 0;
            left: 0;
            z-index: 2;
        }

        > .c-button.-modal-close,
        .-ie-content-container > .c-button.-modal-close {
            position: absolute;
            top: 0;
            right: -40px;
            width: 40px;
            height: 40px;
            border-radius: 0;
            @include respond-to("sm-desktop") {
                right: 0;
            }
        }
    }

    &.-search {
        @include fill-viewport-height();
        margin: 0;
        width: calc(75% - #{$sidebar-menu-width});
        border-radius: initial;
        max-width: initial;
        overflow: visible;

        @include if-internet-explorer() {
            position: fixed;
            top: 0;
        }

        @include respond-to("sm-desktop") {
            width: calc(100% - #{$sidebar-menu-width});
        }

        @include respond-to("phone") {
            padding: 0;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 2;
        }

        > .c-button.-modal-close,
        .-ie-content-container > .c-button.-modal-close {
            position: absolute;
            top: 0;
            right: -40px;
            width: 40px;
            height: 40px;
            border-radius: 0;
            @include respond-to("sm-desktop") {
                right: 0;
            }
        }
    }
}
