.c-collapse-panel {
    $b: &;
    @include padding(24px, 32px, 24px, 64px);
    position: relative;
    scroll-margin-top: 100px;

    &__content {
        height: 0;
        max-height: 999999px;
        transition: all 0.15s ease-out;
        overflow: hidden;

        &.-expanded {
            @include margin-top(24px);
            height: fit-content;
        }
    }
    &__accordion {
        visibility: hidden;
        transition: all 0.25s ease-out;

        &.-expanded {
            visibility: visible;
        }
    }

    &__heading {
        cursor: pointer;
    }

    > .c-button {
        position: absolute;
        background: initial;
        top: 24px;
        left: 16px;
    }

    &.-compact {
        @include padding(16px, 24px, 16px, 52px);

        > .c-button:hover {
            background-color: get-color-neutral("30");
        }
    }

    &.-article {
        @include padding(0px, 24px, 0px, 36px);
    }

    &.-active {
        background-color: get-color("neutral", "white");
        border-radius: $border-radius-medium;
    }

    &.-toc {
        padding-left: 3rem;

        .c-button {
            top: 1rem;
            &:disabled {
                background-color: transparent;
                opacity: 0.25;
            }
        }
    }
}
