.c-system-issues-banner {
    @extend .c-privacy-banner;

    background-color: get-color-status("warning-light");
    border: 1px solid get-color-status("warning-dark");
    color: get-color-status("warning-dark");
    place-content: center;
    width: fit-content;

    .c-icon {
        @include margin-right(16px);
        @include icon-fill(get-color-status("warning-dark"));
    }
}
