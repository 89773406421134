.c-publication-batch-editor {
    @include margin-top(5px);
    display: flex;
    flex-direction: column;
    align-items: center;

    &__button-group {
        display: flex;
        justify-content: right;
        @include margin-top(50px);
    }

    &__primary-topic {
        @include margin-top(20px);
        max-width: rem(390px);
        min-width: rem(250px);
    }

    &__status {
        @include margin-top(20px);
        max-width: rem(390px);
        min-width: rem(250px);
    }
}
