.c-bookmark-color-filter-select {
    cursor: pointer;

    &.-unfocused {
        .c-multi-select.-small {
            .c-multi-select__input__selector__control--is-focused {
                box-shadow: none;
                border: 1px solid get-color-neutral("70");
                outline: none;
            }
        }
    }

    &__multi-select {
        &.c-multi-select.-small {
            .c-multi-select {
                &__input {
                    min-width: rem(205px);

                    &__selector {
                        &__control {
                            border-color: get-color-neutral("70");
                        }

                        &__control,
                        &__indicators {
                            height: rem(30px);
                            cursor: pointer;
                        }

                        &__indicator {
                            @include icon-fill(get-color-neutral("70"));
                        }

                        &__value-container {
                            padding-top: 0;
                            padding-bottom: 0;
                            overflow: hidden;
                            height: rem(30px);

                            > div:not(.c-multi-select__input__selector__placeholder) {
                                display: none;
                            }

                            input {
                                display: inline;
                                width: 0;
                                height: 0;
                            }
                        }

                        &__placeholder {
                            @include font-style(
                                $size: "small",
                                $line-height: "small",
                                $color: get-color-neutral("90")
                            );
                        }
                    }
                }
            }
        }
    }

    &__menu-container {
        .e-checkbox {
            pointer-events: none;
        }

        > div {
            position: relative;
            left: 0;
            top: rem(-16px);

            .c-multi-select__input__selector__menu {
                position: relative;
            }
        }

        &__option-label {
            &__color {
                @include margin-right(8px);
                width: rem(16px);
                height: rem(16px);
                border-radius: $border-radius-huge;
                display: inline-block;
                vertical-align: text-bottom;

                @include get-color-classes-from-map(background-color);
            }
        }

        &__value {
            &__multiple,
            &__single {
                @include padding-left(8px);
                @include font-style(
                    $size: "small",
                    $line-height: "small",
                    $color: get-color-neutral("90")
                );
            }

            &__single {
                &__color-circle {
                    @include get-color-classes-from-map(background-color);
                    @include margin-right(8px);
                    width: rem(16px);
                    height: rem(16px);
                    border-radius: $border-radius-huge;
                    display: inline-block;
                    vertical-align: text-top;
                }
            }
        }

        .c-multi-select {
            &__input {
                &__selector {
                    &__menu-list {
                        max-height: rem(350px);
                    }

                    &__option {
                        cursor: pointer;

                        .e-checkbox {
                            color: get-color-neutral("70");

                            &.-checked {
                                color: get-color-neutral("90");
                            }
                        }

                        &--is-selected {
                            background-color: get-color-accents("blue-light");

                            &:hover {
                                background-color: get-color-neutral("05");
                            }

                            .e-checkbox {
                                &__checkmark {
                                    &:after {
                                        border-color: get-color-accents(
                                            "blue-base"
                                        );
                                    }
                                }
                            }
                        }

                        &--is-focused {
                            background-color: get-color-neutral("05");
                        }
                    }
                }
            }
        }
    }
}
