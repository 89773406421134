.c-resource-tile {
    display: inline-block;
    background-color: rgba(get-color-neutral("white"), 50%);
    border-radius: $border-radius-medium;
    text-decoration: none;
    width: rem(122px);
    overflow: hidden;
    &:focus {
        @include box-shadow(0px, 0px, 5px, get-color-accents("blue-dark"), 1px);
        outline: none;
    }

    &:hover {
        @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));
        background-color: get-color-neutral("white");
    }

    > a {
        text-decoration: none;
    }

    &__thumbnail-container {
        position: relative;
        width: 100%;
        height: rem(50px);

        &__thumbnail {
            width: 100%;
            &__image {
                border-top-right-radius: $border-radius-medium; // For iOS Safari/Chrome support
                border-top-left-radius: $border-radius-medium; // For iOS Safari/Chrome support
                height: rem(50px);
                overflow: hidden;

                img {
                    @include object-fit-cover();

                    @include if-internet-explorer {
                        position: relative;
                        transform: none;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &__icon {
                @include icon-fill(get-color-neutral("90"));
                position: absolute;
                top: rem(24px);
                left: rem(10px);
            }
        }

        &__video-play-icon {
            @include icon-fill(get-color-neutral("white"));
            position: absolute;
            bottom: rem(-11px);
            left: rem(50px);
            background-color: get-color-accents("blue-base");
            border-radius: 100%;
            border: rem(2px) solid get-color-neutral("white");
            height: rem(22px);
            max-height: rem(22px);
            max-width: rem(22px);
            width: rem(22px);
            text-align: center;
        }
    }

    &__info {
        @include padding(10px);
        @include icon-fill(get-color-neutral("50"));
        position: relative;

        .c-icon,
        p {
            @include paragraph-styles("xsmall");
            @include margin-bottom(8px);
            @include if-internet-explorer() {
                max-width: 100%;
                word-wrap: break-word;
            }
        }

        .c-icon {
            @include margin-right(12px);
            position: absolute;
            left: rem(16px);
            top: rem(14px);
        }

        label {
            display: block;
        }
    }
}
