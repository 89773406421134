.c-account-dashboard {
    @include padding-left(0);
    height: 100%;
    overflow: auto;

    h4 {
        @include margin(32, 0, 14, 48);
    }

    .c-tabs__list {
        @include padding-left(24);
        @include padding-right(24);
    }

    .c-tabs__panels {
        @include padding-left(48);
        @include padding-right(48);
    }

    &__offline-warning {
        .c-alert-banner {
            @include margin(0, 48px, 24px, 48px);
            max-width: rem(640px);

            @include respond-to("tablet") {
                @include margin(0, 24px, 16px, 24px);
            }
        }
    }

    &__team-name-field {
        @include margin-bottom(32px);
        color: get-color-neutral("90");

        &__name {
            @include margin(8px, 0, 4px);
            @include font-style(
                $size: "small",
                $color: get-color-neutral("70"),
                $line-height: "small"
            );
        }

        .c-button.-anchor {
            @include font-style(
                $size: "xsmall",
                $color: get-color-accents("blue-dark"),
                $line-height: get-line-height("xsmall")
            );
            &:disabled {
                @include respond-to("lg-tablet") {
                    @include font-style(
                        $size: "xsmall",
                        $color: get-color-neutral("50"),
                        $line-height: get-line-height("xsmall")
                    );
                }
            }
        }
    }
    .-pill {
        font-size: 0.75rem;
        font-weight: get-font-weight("bold");
        display: inline-flex;
        padding: 10px;
        margin: 0 rem(5px);
        margin-top: rem(4px);
        border-radius: 500px;
        background-color: get-color-neutral("05");
    }

    &__team-name-modal {
        $modal-vertical-padding: 300px;

        padding-bottom: 0;
        margin-top: rem(40px);
        width: rem(669px);
        min-width: rem(669px);
        overflow: hidden;

        @include respond-to("sm-tablet") {
            @include fill-viewport-height();
            margin: 0 auto;
            max-width: initial;
            min-width: initial;
            width: 100%;
            border-radius: initial;
        }

        &__body {
            overflow-y: auto;
            max-height: calc(#{vh()} - #{$modal-vertical-padding});

            @include respond-to("phone") {
                max-height: calc(#{vh()} - #{$mobile-modal-header-height});
                overflow-y: auto;
            }
        }

        &__header {
            @include padding(24px, 16px, 16px, 32px);
            display: flex;
            justify-content: space-between;
            align-items: center;

            > h2 {
                @include font-style(
                    $size: "large",
                    $color: get-color-neutral("90"),
                    $line-height: "large"
                );
                display: inline-block;
                justify-self: left;
            }

            > .c-button {
                justify-self: right;
            }
            background-color: get-color-background("neutral");
            width: 100%;

            button.-modal-close {
                @include padding(8px);

                svg {
                    display: block;
                }
            }
        }

        &__content {
            @include padding(32px);

            &__title {
                @include margin-bottom(24px);
            }
        }

        &__footer {
            @include padding(16px);
            border-top: 1px solid get-color-neutral("30");
            display: flex;
            justify-content: flex-end;

            .c-button:not(:first-of-type) {
                @include margin-left(8px);
            }
        }
    }

    &__team-management {
        @include padding-bottom(32px);

        .c-group-invite-card {
            @include margin-bottom(48px);
        }
        &__team-members {
            @include padding-bottom(48px);

            min-width: rem(750px);

            @include font-style(
                $size: "small",
                $color: get-color-neutral("90"),
                $line-height: "small"
            );
            letter-spacing: 0.02em;
            max-width: rem(816px);

            .c-loader {
                @include margin-top(16px);
            }

            &__title {
                @include font-style(
                    $color: get-color-neutral("90"),
                    $line-height: "24px"
                );
                display: flex;
                align-items: center;

                &__button-wrapper-for-tooltip {
                    margin-left: auto;
                }
            }

            &__search {
                @include padding-bottom(16px);
                @include box-shadow(0px, 1px, 0px, get-color-neutral("30"));

                .c-search-form {
                    @include margin-top(16px);
                }
            }
        }

        .c-page-navigation-menu {
            @include padding(16px);
            border-top: 1px solid get-color-neutral("30");
        }
    }

    &__workgroups {
        h5 {
            @include paragraph-styles();
            @include margin-bottom(16px);

            @include respond-to("phone") {
                @include font-style(
                    $line-height: get-line-height("base"),
                    $size: "base"
                );
            }
        }
        &__actions {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @include padding-bottom(16px);

            @include respond-to("sm-tablet") {
                flex-direction: column;
                gap: 8px;
            }
        }
        &__search {
            width: rem(400px);

            @include respond-to("phone") {
                max-width: 100%;
            }
        }
        &__table-container {
            width: 100%;

            @include respond-to("phone") {
                max-width: map-get($breakpoints, "phone");
                overflow: auto;
            }
            @include respond-to("tablet") {
                max-width: map-get($breakpoints, "tablet");
                overflow: auto;
            }

            @include respond-to("lg-tablet") {
                max-width: map-get($breakpoints, "lg-tablet");
                overflow: auto;
            }

            &__table {
                width: 100%;
                margin-bottom: rem(100px);

                @include respond-to("lg-tablet") {
                    max-width: map-get($breakpoints, "tablet");
                    min-width: map-get($breakpoints, "tablet");
                    width: map-get($breakpoints, "tablet");
                }

                @include respond-to("tablet") {
                    max-width: map-get($breakpoints, "sm-tablet");
                    min-width: map-get($breakpoints, "sm-tablet");
                    width: map-get($breakpoints, "sm-tablet");
                }

                &__header {
                    &__name {
                        width: 20%;
                        min-width: 140px;
                    }
                    &__members {
                        width: 10%;
                        min-width: 110px;
                        @include respond-to("laptop") {
                            width: 20%;
                        }
                    }
                    &__collections {
                        width: 60%;
                        @include respond-to("laptop") {
                            width: 50%;
                        }
                    }
                    &__actions {
                        width: 10%;
                    }
                }

                tr > td:last-of-type {
                    text-align: end;
                }
                &__pill {
                    font-size: 0.75rem;
                    font-weight: get-font-weight("bold");
                    display: inline-flex;
                    padding: 10px;
                    margin: 0 rem(5px);
                    margin-top: rem(4px);
                    border-radius: 500px;
                    background-color: get-color-neutral("05");
                }
            }
        }
    }

    &__subscription {
        @include padding-bottom(32px);
        display: flex;
        align-items: flex-start;
        flex-direction: row;

        .c-subscription-choice-card {
            @include margin-left(32px);
        }
        .c-button {
            @include margin-top(16px);
        }

        @include respond-to("sm-desktop") {
            flex-direction: column-reverse;
            .c-subscription-choice-card {
                @include margin-bottom(32px);
                margin-left: 0;
            }
        }
        &__cancel-info {
            @include padding(8px);
            @include margin-top(24px);
        }
    }

    &__information {
        max-width: rem(800px);
        position: relative;

        h5 {
            @include paragraph-styles();
            @include margin-bottom(16px);

            @include respond-to("phone") {
                @include font-style(
                    $line-height: get-line-height("base"),
                    $size: "base"
                );
            }
        }

        &-display {
            @include margin-bottom(24px);
            color: get-color-neutral("70");

            h6 {
                @include font-style(
                    $size: "xxsmall",
                    $line-height: get-line-height("xxsmall"),
                    $weight: get-font-weight("bold")
                );
                @include margin-bottom(2px);
                text-transform: uppercase;
                letter-spacing: 0.11em;
            }
            p {
                @include font-style(
                    $size: "small",
                    $line-height: get-line-height("small")
                );
            }
        }

        &__personal {
            @include margin-bottom(64px);

            a {
                color: get-color-accents("blue-dark");
            }

            &__language {
                .c-button.-anchor {
                    @include margin-left(16px);
                    @include font-style(
                        $size: "small",
                        $weight: get-font-weight("base"),
                        $color: get-color-accents("blue-dark")
                    );
                }
            }
        }

        &__workgroups {
            .-interest {
                @include margin-bottom(24px);
                @include margin-top(4px);
                color: get-color-neutral("70");
            }
            @include padding-bottom(32px);
        }

        &__topics {
            p.-interest {
                @include margin-bottom(24px);
                @include margin-top(4px);
                color: get-color-neutral("70");
            }

            .c-checkbox-button {
                @include margin(0, 16px, 16px, 0);
            }

            @include respond-to("phone") {
                .c-user-topics-list fieldset {
                    display: flex;
                    flex-direction: column;

                    .c-checkbox-button {
                        @include max-content-width();
                    }
                }
            }
        }
        &__orgSupport {
            max-width: rem(380px);
        }
        &__orgSupportHelpText {
            @include font-style(
                $size: "small",
                $line-height: "small",
                $weight: "base",
                $color: get-color-neutral("90")
            );
            @include padding-bottom(16px);
        }
    }

    &__offline {
        &__publications,
        &__search-index {
            @include margin-bottom(32px);

            h5 {
                @include paragraph-styles("small");
                @include margin-bottom(4px);
            }
        }

        .c-alert-banner {
            @include margin-bottom(16px);
            max-width: rem(480px);
        }

        .percentage-bar {
            border: rem(1px) solid get-color-neutral("05");
            border-radius: $border-radius-small;
            width: rem(400px);
            height: rem(8px);

            &__status {
                background-color: get-color-neutral("90");
                height: 100%;
            }
        }
    }

    &__modal {
        $modal-vertical-padding: 300px;
        $mobile-modal-footer-height: rem(80px);

        padding-bottom: 0;
        margin-top: rem(40px);
        width: rem(669px);
        min-width: rem(669px);
        overflow: hidden;

        @include respond-to("sm-tablet") {
            @include fill-viewport-height();
            margin: 0 auto;
            max-width: initial;
            min-width: initial;
            width: 100%;
            border-radius: initial;
        }
        table {
            width: 100%;
            border-collapse: collapse;

            thead {
                tr {
                    border-bottom: 1px solid get-color-neutral("05");
                    th {
                        @include font-style(
                            $size: "small",
                            $line-height: "small",
                            $weight: "base",
                            $color: get-color-neutral("90")
                        );
                        @include padding(8px);
                        text-align: left;
                        width: 100%;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        @include font-style(
                            $size: "small",
                            $line-height: "small",
                            $color: get-color-neutral("90")
                        );
                        @include padding(12px, 8px);

                        text-align: left;
                        border-bottom: 2px solid get-color-neutral("05");

                        .c-button.-destructive:hover > .c-icon > path {
                            @include respond-to("phone") {
                                fill: get-color-neutral("error");
                            }
                            fill: get-color-neutral("white");
                        }

                        .c-button.-destructive > .c-icon > path {
                            fill: get-color-status("error");
                        }
                    }

                    td:last-of-type {
                        text-align: end;
                    }
                }
            }
        }

        &__body {
            overflow-y: auto;
            max-height: calc(#{vh()} - #{$modal-vertical-padding});

            @include respond-to("phone") {
                max-height: calc(
                    #{vh()} - #{$mobile-modal-header-height} - #{$mobile-modal-footer-height}
                );
                height: calc(
                    #{vh()} - #{$mobile-modal-header-height} - #{$mobile-modal-footer-height}
                );
                overflow-y: auto;
            }
        }

        &__header {
            @include padding(24px, 16px, 16px, 32px);
            display: flex;
            justify-content: space-between;
            align-items: center;

            > h2 {
                @include font-style(
                    $size: "large",
                    $color: get-color-neutral("90"),
                    $line-height: "large"
                );
                display: inline-block;
                justify-self: left;
            }

            > .c-button {
                justify-self: right;
            }
            background-color: get-color-background("neutral");
            width: 100%;

            button.-modal-close {
                @include padding(8px);

                svg {
                    display: block;
                }
            }
        }

        &__content {
            @include padding(40px);
            height: rem(400px);
            max-height: rem(400px);
            overflow: auto;

            .c-form-field {
                @include padding(0, 8px);
            }

            .c-multi-select > .c-form-field {
                @include margin-bottom(16px);
                @include padding(0);
            }
        }

        &__footer {
            @include padding(16px);
            border-top: 1px solid get-color-neutral("30");
            display: flex;
            justify-content: flex-end;

            @include respond-to("phone") {
                height: $mobile-modal-footer-height;
                align-items: center;
            }

            .c-button:not(:first-of-type) {
                @include margin-left(8px);
            }
        }
    }

    @include respond-to("tablet") {
        h4 {
            @include margin(32, 24, 14, 24);
        }

        .c-tabs__list {
            @include padding-left(0);
        }

        .c-tabs__panels {
            @include padding-left(24);
            @include padding-right(24);
        }
    }
}
