.c-favorite-publications {
    @include margin-bottom(20px);

    > label {
        @include font-style(
            $size: "xxsmall",
            $line-height: "xxsmall",
            $weight: "bold",
            $color: get-color-neutral("70")
        );
        @include margin-bottom(16px);
        letter-spacing: 0.11rem;
        text-transform: uppercase;
        display: block;
    }

    &.-empty {
        margin-right: rem(20px);
    }

    &__favorite-a-publication {
        @include margin-bottom(20px);
        @include border-radius("large");
        @include font-style(
            $size: "small",
            $color: get-color-neutral("70"),
            $line-height: "small"
        );
        padding: rem(95px);
        background-color: get-color-background("neutral");
        border: rem(2px) solid get-color-neutral("30");
        border-radius: $border-radius-small;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: rem(280px);
        height: rem(280px);

        > svg {
            @include margin-bottom(16px);
            @include icon-fill(get-color-neutral("70"));
        }

        @include respond-to("phone") {
            @include padding(24px);
            height: auto;
            width: 100%;
            max-height: rem(156px);
            margin-right: rem(20px);
            flex-direction: row;

            > svg {
                @include margin-right(16px);
                margin-bottom: 0;
            }
        }
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;

        .c-publication-card {
            @include margin(0, 20px, 20px, 0);
            width: 100%;
            max-width: rem(280px);

            @include respond-to("phone") {
                max-width: unset;
                margin-right: 0;
            }
        }
    }
    &__view-all-publications {
        width: 47%;
        @include respond-to("tablet") {
            width: 100%;
        }
        &__button-content {
            &__title {
                letter-spacing: 0.02em;
                @include font-style(
                    $size: get-font-size("large"),
                    $color: get-color-neutral("90"),
                    $line-height: 27px
                );

                @include if-internet-explorer {
                    max-width: rem(300px);
                }
            }
            &__subtitle {
                color: get-color-neutral("70");
                display: flex;
                letter-spacing: 0.02em;

                @include if-internet-explorer {
                    max-width: rem(300px);
                }
            }
        }
    }
    &__select-action {
        @include margin(12px, 0);
    }
}
