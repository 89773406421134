.c-related-solutions-section {
    &__header {
        @include margin-top(40px);
        @extend .c-label, .-small;
        text-transform: uppercase;

        &:first-child {
            margin-top: 0;
        }
    }

    .c-related-solution-card {
        @include margin-top(8px);
        @include padding(16px);
    }
}
