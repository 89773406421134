.c-team-size-select {
    @include margin-bottom(16px);

    select {
        @include paragraph-styles("small");

        @include respond-to("phone") {
            // Keep the font size at 16 on mobile to prevent iPhone from zooming in
            @include paragraph-styles("base");
        }
    }

    &.-error {
        @include font-style($color: get-color-primary("error"));

        label,
        select {
            color: get-color-primary("error");
        }

        select {
            border-color: get-color-primary("error");
            box-shadow: 0 0 0 3px rgba(213, 0, 50, 0.2);
        }
    }

    &__errors {
        @include font-style(
            $line-height: "xsmall",
            $size: "xsmall",
            $style: italic,
            $weight: "light"
        );
        @include icon-fill(get-color-primary("error"));
        color: get-color-primary("error");
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
            @include margin-left(8px);
        }
    }

    label {
        @include margin-bottom(12px);
        display: block;
    }
}
