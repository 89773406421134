.c-publication-sidebar-nav-link {
    @include padding(8, 16);
    @include margin-bottom(8);
    @include border-radius("base");

    $b: &;

    display: block;
    text-decoration: none;

    &.-single-line {
        width: 100%;
        span {
            display: inline;
        }

        &:hover {
            .c-publication-sidebar-nav-link__title {
                text-decoration: underline;
            }
        }
    }

    // Overrides some default styles attached to the <button> element
    &.-button-with-link-style {
        background-color: transparent;
        text-align: left;
    }

    &__label {
        @include font-style($font-primary, "small", false, 700, 22px);
        display: inline-block;
        width: 100%;
    }

    &__title {
        @include font-style($font-primary, "small", false, 400, 22px);
        @include margin-right(8px);
    }

    &:hover {
        background-color: get-color-background("primary");
        color: get-color-neutral("90");
        -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 26, 57, 0.1);
        -moz-box-shadow: 0px 3px 10px 0px rgba(0, 26, 57, 0.1);
        box-shadow: 0px 3px 10px 0px rgba(0, 26, 57, 0.1);

        .c-publication-sidebar-nav-link__label {
            text-decoration: underline;
        }
    }
    > .c-icon {
        @include icon-fill(get-color-neutral("90"));
        vertical-align: text-top;
    }
    &.-active {
        background-color: get-color-buttons("primary-hover");
        color: get-color-neutral("white");
        > .c-icon {
            @include icon-fill(get-color-neutral("white"));
        }
    }

    &.-expandableToC {
        @include padding(6px);
        @include margin(2px, 0);
        font-size: get-font-size("xsmall");

        &.-active,
        &:hover {
            background-color: get-color("neutral", "70");
            color: get-color-neutral("white");
            #{$b}__title {
                text-decoration: none;
            }
        }
    }
}
