.c-tia-indicator {
    $button-border: 2px solid white;
    $b: &;

    @mixin button-flare {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: inherit;
        border-radius: 50%;
        border: $button-border;
        z-index: -1;
    }

    width: 0;

    &__button {
        background-color: #{get-color-accents("orange-light")};
        border-radius: 50%;
        padding: 0.25rem;
        display: flex;
        align-items: center;
        color: #{get-color-status("warning-dark")};
        border: $button-border;
        transform: translateX(calc(-100% - var(--tia-button-position)));

        &:disabled {
            cursor: auto;
        }
    }

    &__icon {
        color: #{get-color-status("warning-dark")};
        display: block;
    }

    &__count {
        @include font-size(10px);
        font-weight: 600;
        padding-right: 0.25rem;

        @include respond-to("phone") {
            position: absolute;
            @include font-size(8px);
            height: 14px;
            font-weight: 700;
            padding-right: 0;
            top: 14px;
            right: 8px;
        }
    }

    &.-multipleTias {
        --tia-indicator-gap-from-content: 1.5rem;
        @include respond-to("phone") {
            --tia-indicator-gap-from-content: 1rem;
        }
        #{$b}__button {
            &::before {
                @include button-flare;
                transform: translateX(30%);
                transition: opacity 0.8s ease;
            }

            &::after {
                @include button-flare;
                transform: translateX(15%);
                transition: opacity 0.3s ease;
            }
            &:hover {
                &::after,
                &::before {
                    opacity: 0.2;
                }
            }

            @include respond-to("phone") {
                &::before,
                &::after {
                    transform: none;
                }
            }
        }
    }
}
