.c-table-of-contents-article {
    a {
        @include padding-bottom(8px);
        @include margin-bottom(8px);
        @include font-size("base");
        @include rem-fallback(line-height, 24px);
        text-decoration: none;
        display: inline-block;
        color: get-color-neutral("90");

        &:hover {
            text-decoration: underline;
        }

        span {
            @include margin-right(16px);
        }
    }
}
