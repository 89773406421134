.c-committee {
    > .c-committee-member__name {
        @include font-style(
            $font-primary,
            "xlarge",
            get-color-neutral("90"),
            300,
            34px
        );
        @include margin(72px, 0, 24px, 0);
        display: block;
    }

    .c-committee-member + .c-committee-scope,
    .c-committee-member-group + .c-committee-member,
    .c-committee-member-group + .c-committee-member-group {
        @include margin-top(40px);
    }
}
