.c-bookmark-read-only-dialog {
    @include border-radius("base");
    @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));
    @include font-style(
        $size: "xsmall",
        $line-height: get-line-height("xsmall")
    );
    background: get-color-neutral("white");
    // z-index must be higher than 8 so there is no possibility of
    // the 8 possible bookmark icons being positioned over top of it.
    z-index: 9;
    width: rem(330px);

    &__header {
        @include padding(16px, 8px, 12px, 16px);
        display: flex;
        align-items: center;

        .c-icon {
            @include bookmark-icon-colors();
        }

        .c-label {
            text-transform: none;
            @include if-internet-explorer {
                max-width: rem(150px);
            }
        }

        &__icon {
            @include font-style(
                $size: "xsmall",
                $color: get-color-neutral("90"),
                $line-height: "base"
            );
            display: flex;
            align-items: center;

            @include if-internet-explorer {
                max-width: 80%;
            }

            svg {
                @include margin-right(8px);
                flex-shrink: 0;
                align-self: flex-start;
                path {
                    fill: get-color-neutral("50");
                }
            }
        }

        &__actions {
            margin-left: auto;
            display: flex;
            align-self: flex-start;

            .c-button.-small {
                @include padding(6px);
                @include if-internet-explorer {
                    @include margin-top(-2px);
                }
                word-break: initial;

                .c-icon {
                    @include icon-fill(get-color-neutral("70"));
                }

                @include respond-to("phone") {
                    @include padding-left(16px);
                    @include padding-right(16px);
                }
            }

            > .c-menu-button {
                @include margin-right(4px);
                z-index: 3;

                .c-menu-button__menu {
                    right: 0;
                    top: rem(32px);
                }
            }
        }
    }

    &__content {
        @include padding(0, 16px, 0, 16px);

        & > .c-bookmark-collections-badge {
            margin-top: 0;

            & .c-icon {
                margin-top: rem(1px);
            }
        }

        p {
            @include font-style(
                $size: "xsmall",
                $color: get-color-neutral("90"),
                $line-height: "small"
            );
            padding: 0;
        }
    }

    &__body {
        &__description {
            @include padding(16px);
            @include border-radius("base");
            @include get-color-classes-from-map(
                background,
                "bookmark",
                "background"
            );
            position: relative;

            & .c-expandable-rich-text-area {
                padding-bottom: 0;
                padding-right: 0;
                position: initial;

                & .c-rich-text-area {
                    line-height: rem(4px);
                    white-space: normal;

                    & ul,
                    ol {
                        & li {
                            line-height: normal;
                        }
                    }
                }

                & .c-button.-expand-collapse {
                    @include font-size("xsmall");
                    bottom: rem(12px);
                    right: rem(16px);
                }
            }

            &.-has-expandable-content {
                @include padding-bottom(28px);
            }
        }
    }

    &__footer {
        @include padding(16px);

        &__actions {
            display: flex;

            &__delete-button {
                @include margin-right(8px);

                &.c-button.-tertiary {
                    @include icon-fill(get-color-neutral("70"));
                }
            }
        }

        &__meta-info {
            display: flex;
            flex-direction: column;

            @include respond-to("phone") {
                @include margin-bottom(8px);
                align-items: center;
                flex-direction: row-reverse;
                justify-content: space-between;
            }
        }

        .c-bookmark-collections-badge {
            @include margin-bottom(16px);
            align-self: flex-start;

            p {
                padding: 0;
            }

            @include respond-to("phone") {
                margin-bottom: 0;
            }
        }

        &__timestamp {
            @include font-style(
                $size: "xxsmall",
                $line-height: "xxsmall",
                $color: get-color-neutral("70"),
                $style: italic
            );
            width: 100%;

            &-author {
                font-weight: get-font-weight("bold");
            }

            @include respond-to("phone") {
                .c-bookmark-collections-badge + & {
                    width: calc(100% - 64px);
                }
            }
        }

        @include respond-to("phone") {
            @include margin-top(8px);
        }
    }
}
