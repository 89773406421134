.c-bookmark-collections-badge {
    @include padding(8px, 16px);
    @include icon-fill(get-color-neutral("70"));
    display: block;
    border-radius: $border-radius-huge;
    background-color: get-color-neutral("05");

    @include respond-to("phone") {
        @include padding(4px, 8px);
    }

    .c-icon {
        @include margin-right(8px);
    }

    &__tooltip {
        padding-bottom: 0.25rem;
        .c-icon {
            @include icon-fill(get-color-neutral("white"));
        }

        a {
            @include font-style(
                $size: "xsmall",
                $line-height: get-line-height("xsmall"),
                $color: get-color-neutral("white")
            );
        }
    }
    &__tooltipList {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    p {
        @include font-style(
            $size: "xsmall",
            $line-height: get-line-height("xsmall"),
            $color: get-color-neutral("70")
        );
        display: inline-block;
        vertical-align: top;
    }
}
