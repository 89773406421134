.c-button-group {
    white-space: nowrap;

    .c-button {
        border-left: 1px solid get-color-neutral("50");
        border-top: 1px solid get-color-neutral("50");
        border-bottom: 1px solid get-color-neutral("50");
        border-radius: 0;

        &:first-child {
            border-top-left-radius: $border-radius-small;
            border-bottom-left-radius: $border-radius-small;
        }

        &:last-child {
            border-right: 1px solid get-color-neutral("50");
            border-top-right-radius: $border-radius-small;
            border-bottom-right-radius: $border-radius-small;
        }
    }
}
