.c-select-billing-cycle-modal {
    max-width: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include respond-to("phone") {
        margin: 10vh 16px;
    }

    &__header {
        @include padding(24px, 24px, 16px, 24px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $color: get-color-neutral("90"),
                $line-height: "large",
                $size: "large",
                $weight: "bold"
            );
            display: inline-block;
            justify-self: left;
        }
        width: 100%;
    }

    &__content {
        @include padding(0, 24px);
        overflow-y: auto;
    }

    &__footer {
        @include padding(4px, 0, 24px, 0);
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @include respond-to("phone") {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
        }

        > .c-button:first-of-type {
            @include margin-right(8px);

            @include respond-to("phone") {
                @include margin-top(16px);
                margin-right: 0;
            }
        }
    }
}
