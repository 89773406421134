.c-admin-hotspot-section {
    position: relative;
    display: block;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    letter-spacing: 0;
    &.c-button {
        width: 100%;

        &:hover,
        &.-hover {
            background-color: transparent;
        }

        &:active {
            opacity: 1;
            box-shadow: none;
        }
    }

    &__map {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &__hotspot {
        @include font-size(12px);
        @include line-height(20px);
        position: absolute;
        z-index: 1;
        width: 24px;
        height: 24px;
        border: 2px solid get-color-neutral("white");
        color: get-color-neutral("white");
        border-radius: 50%;
        display: block;
        font-weight: bold;
        box-shadow: 0px 2px 7px rgba(0, 26, 57, 0.25);
        text-align: center;

        &.-situation {
            background: get-color-neutral("70");
        }

        &.-solution {
            background: get-color-status("success-dark");
        }
    }
}
