.c-invite-content-header {
    &__title {
        @include font-style(
            $size: "xxlarge",
            $weight: "light",
            $line-height: "xlarge"
        );
    }

    &__body {
        @include padding(16px, 0);
    }

    &__team {
        @include padding(16px, 24px);
        background-color: get-color-neutral("white");
        border-radius: $border-radius-small;
        border: rem(1px) solid get-color-neutral("30");
        display: flex;
        flex-direction: column;
        @include margin-top(32px);
        span:first-of-type {
            @include margin-bottom(8px);
            @include font-style(
                $font-primary,
                "xxsmall",
                get-color-neutral("70"),
                700,
                get-line-height("xxsmall")
            );
            letter-spacing: 0.11em;
            text-transform: uppercase;
            display: block;
        }
        span:last-of-type {
            @include font-style(
                $font-primary,
                "base",
                get-color-neutral("90"),
                400,
                get-line-height("base")
            );
            word-wrap: break-word;
        }
    }
}
