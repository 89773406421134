.c-announcement-editor {
    &__content {
        @include padding-left(48px);
        @include padding-right(48px);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-width: rem(1200px);
    }
}
