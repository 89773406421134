$modalContentMaxHeight: rem(671px);
$loginIssuesModalWidth: rem(592px);

.c-account-login-issues-modal {
    padding-bottom: 0;
    margin-top: 4vh;
    width: $loginIssuesModalWidth;
    min-width: $loginIssuesModalWidth;
    overflow: hidden;

    @include respond-to("sm-tablet") {
        @include fill-viewport-height();
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: initial;
    }

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                get-line-height("large")
            );
            display: inline-block;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }
        background-color: get-color-background("neutral");
        width: 100%;

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    .c-modal__content {
        overflow-y: auto;
        max-height: $modalContentMaxHeight;
    }

    &__content {
        @include padding(32px);
        @include respond-to("phone") {
            max-height: calc((var(--vh, 1vh) * 100) - 80px);
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

        a {
            @include font-style($color: get-color-accents("blue-dark"));
        }

        p {
            @include margin-bottom(16px);
        }

        &__paragraph {
            &__intro {
                font-weight: 700;

                &::after {
                    content: " ";
                }
            }
        }
        .-anchor {
            line-height: inherit;
            font-size: inherit;
            font-weight: 400;
            letter-spacing: normal;
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-end;

        @include respond-to("phone") {
            display: none;
        }
    }
}

