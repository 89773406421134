.c-admin-editor-page-layout {
    height: 100%;
    @include padding-top(48px);
    display: flex;
    flex-direction: column;

    h5 {
        @include margin-bottom(16px);
        @include padding-left(48px);
    }

    p,
    label,
    h5 {
        color: get-color-neutral("90");
    }

    &__content {
        border-top: 1px solid get-color-neutral("30");
        display: flex;
        flex-grow: 1;
        overflow: auto;

        &__left {
            border-right: 1px solid get-color-neutral("30");
            width: 25%;
            min-width: 25%;
            max-width: 25%;
            display: flex;
            flex-direction: column;

            .c-text-input-icon {
                @include margin(8px);
                @include margin-bottom(0);
            }

            &__list {
                @include padding(8px, 8px, 8px, 16px);
                flex-grow: 1;
                overflow-y: auto;
                // because it's in flexbox, this behaves the same as min-height
                // but also vertically resizes it so that all elements fit vertically
                // in the parent. See: https://stackoverflow.com/a/14964944
                height: 100%;

                &__item {
                    width: 100%;
                    text-align: left;

                    &.-selected,
                    &:hover {
                        background-color: get-color-neutral("05");
                    }

                    &__title {
                        display: flex;

                        .-text {
                            @include font-style(
                                $size: "xsmall",
                                $color: get-color-neutral("90"),
                                $line-height: get-line-height("xsmall")
                            );
                            flex-grow: 1;
                        }

                        .c-publish-status-badge {
                            @include margin-top(-4px);
                            @include margin-right(-16px);
                            height: rem(24px);
                            vertical-align: middle;
                        }
                    }

                    &__subtitle {
                        @include ellipsis(block);
                        @include font-style(
                            $size: "xxsmall",
                            $color: get-color-neutral("70"),
                            $line-height: get-line-height("xxsmall")
                        );
                    }
                }

                .-no-records-message {
                    text-align: center;
                    @include margin-top(20px);
                    font-style: italic;
                    color: get-color-neutral("70");
                }
            }

            &__footer {
                @include padding(16px);
                height: rem($admin-footer-height);
                border-top: 1px solid get-color-neutral("30");
                background: get-color-neutral("white");
            }
        }

        &__right {
            flex-grow: 1;
            width: 75%;
            min-width: 75%;
            max-width: 75%;

            &__none-selected {
                text-align: center;

                p {
                    margin-top: 35%;

                    a {
                        @include font-style(
                            $size: "large",
                            $weight: get-font-weight("bold"),
                            $line-height: get-line-height("large")
                        );
                    }
                }
            }
        }
    }
}
