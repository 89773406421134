.c-account-expiration-alerts {
    @include margin-bottom(25px);
    @include margin-right(48px);
    display: inline-block;

    &.-cta {
        width: 100%;
        .c-alert-banner,
        .c-account-expiration-alerts__days-remaining {
            @include respond-to("phone") {
                @include font-style($size: "base");
            }
            @include font-style($size: "large");
        }
        .c-alert-banner {
            @include padding(24px);
            background-color: get-color-neutral("white");
            display: flex;
            align-items: center;

            @include respond-to("phone") {
                flex-direction: column;
                align-items: initial;
                text-align: center;
            }
            svg {
                display: none;
            }

            a,
            button {
                @include font-style(
                    $size: "small",
                    $weight: get-font-weight("bold")
                );
                color: get-color-neutral("white");
                margin-left: auto;

                @include respond-to("phone") {
                    @include margin-top(8px);
                    flex-direction: column;
                    margin-left: 0;
                    text-align: center;
                }
            }

            button {
                display: flex;
                div {
                    width: 100%;
                }
            }
        }

        .c-account-expiration-alerts__days-remaining {
            font-weight: get-font-weight("bold");
        }
    }

    &.-cta-alternate {
        .c-alert-banner {
            @include font-style(
                $color: get-color-status("warning-dark"),
                $size: "small"
            );
            background-color: get-color-status("warning-light");
            flex-direction: column;
            align-items: initial;

            a,
            button {
                color: get-color-neutral("90");
                background-color: get-color-neutral("white");
                margin-left: 0;
            }

            .c-account-expiration-alerts__days-remaining {
                @include font-style(
                    $size: "small",
                    $weight: get-font-weight("bold")
                );
            }
        }
    }

    &__days-remaining {
        @include font-style($size: "xsmall", $weight: get-font-weight("bold"));
    }
}
