.c-landing-page-hero {
    $b: &;

    --max-width: clamp(200px, 80vw, 1311px);
    --column: calc(var(--max-width) / 2);
    --side-space: calc((100% - var(--max-width)) / 2);
    background-color: get-color-background("dark");
    color: get-color-neutral("white");
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include respond-to-min-width("laptop") {
        display: flex;
        flex-direction: row;
        height: calc(100vh - 80px);
    }

    // Video Panel
    &__video-panel {
        position: relative;
        width: 100%;
        @include respond-to-min-width("laptop") {
            overflow: hidden;
            height: 100%;
        }
    }

    // Additional container to force sizing
    &__video-container {
        height: 100%;
    }

    &__video {
        width: 100%;
        opacity: 0.2;

        @include respond-to-min-width("laptop") {
            height: 100%;
            overflow: hidden;
            object-fit: cover;
            object-position: center;
            user-select: none;
            pointer-events: none;
        }
    }

    &__icon-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__icon {
        height: rem(80px);
        width: rem(80px);
    }

    // Text Panel
    &__text-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        @include padding(24px);
        align-self: center;
        width: 100%;
        @include font-style(
            $font-primary,
            "small",
            get-color-neutral("white"),
            400
        );

        @include respond-to-min-width("laptop") {
            @include padding(166px, 96px, 166px, 96px);
            @include font-style($font-primary, "base");
        }

        &__content {
            max-width: 750px;
            width: 100%;
        }
    }

    &__heading {
        @include font-style(
            $font-primary,
            "large",
            get-color-neutral("white"),
            300,
            1.4,
            0.2rem
        );
        @include padding-bottom(15px);
        @include respond-to-min-width("lg-tablet") {
            @include font-style(
                $font-primary,
                "xxxlarge",
                get-color-neutral("white")
            );
        }
    }
    &__heading-text {
        font-size: 35px;
    }

    &__eyebrow {
        @include font-style(
            $font-primary,
            "xsmall",
            get-color-neutral("white"),
            700,
            18px
        );
        letter-spacing: 0.15em;
    }

    &__link {
        @include font-style(
            $font-primary,
            "base",
            get-color-neutral("white"),
            700
        );
    }
    &__cta-or,
    &__link-cta {
        @include font-style(
            $font-primary,
            "xsmall",
            get-color-neutral("white"),
            700
        );
        letter-spacing: 0.2em;
        text-transform: uppercase;
    }

    &__link-login {
        letter-spacing: 0.04em;
    }
    &__link-login-text {
        text-decoration: underline;
        background: none;
        color: white;
        letter-spacing: 0.04em;
        font-size: 16px;
        padding: 12px;
    }

    &__cta-or {
        margin: 0 0.75rem;
    }

    &__list {
        line-height: 1.5;

        svg {
            flex: 0 0 rem(20px);
            height: rem(20px);
        }

        svg path {
            fill: get-color-accents("yellow");
        }

        & > * {
            display: flex;
            margin-bottom: clamp(0.75rem, 2vw, 1.75rem);
        }

        @include respond-to-min-width("laptop") {
            column-count: 2;
        }
    }

    &__cta-wrapper {
        padding-top: 20px;
        padding-bottom: 15px;
    }
}
