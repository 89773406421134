.c-subscription-cancellation-modal {
    padding-bottom: 0;
    width: rem(600px);
    min-width: rem(600px);
    overflow: hidden;

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: get-color-background("neutral");
        width: 100%;

        > h2 {
            @include font-style(
                $size: get-font-size("large"),
                $color: get-color-neutral("90"),
                $line-height: get-line-height("base")
            );
            display: inline-block;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__container {
        @include padding(24px);

        &.-condensed-bottom {
            padding-bottom: 0;
        }

        &.-condensed {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &__content {
        // height set to match other modals in app
        max-height: 60vh;
        overflow-y: auto;

        .-hidden {
            display: none;
        }

        ul {
            @include margin-bottom(40px);
            li {
                display: flex;
                @include margin-bottom(16px);
                p {
                    @include padding-left(16px);
                }
                svg {
                    @include margin-top(3px);
                    min-width: 16px;
                }
            }
        }

        .c-form-field {
            &.-on-default {
                select {
                    color: grey;
                }
            }

            &__errors label {
                font-style: italic;
            }
        }
    }
    &__terms {
        @include margin(24px, 0, 24px, 8px);

        span {
            font-weight: bold;
        }
    }
    &__footer {
        @include margin-top(12px);
        display: flex;
        width: 100%;
        border-top: 1px solid get-color-neutral("30");
        justify-content: flex-end;

        .c-button {
            @include margin-left(20px);
        }

        @include respond-to("phone") {
            .c-subscription-cancellation-modal__container {
                text-align: center;
            }

            button:not(:last-child) {
                @include margin-bottom(16px);
            }
        }
    }

    @include respond-to("sm-tablet") {
        @include fill-viewport-height();
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: initial;
    }

    @include respond-to("phone") {
        .c-modal__content {
            display: flex;
            flex-direction: column;
        }

        &__content {
            max-height: calc(#{vh(100)} - #{$mobile-modal-header-height});

            &__name {
                flex-direction: column;

                .c-form-field {
                    flex-basis: 100%;
                }
            }
        }
    }
}
