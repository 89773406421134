.c-support-admin-dashboard-layout {
    height: 100%;
    max-height: calc(100vh - #{$breadcrumb-menu-height});
    overflow-y: auto;

    &__heading {
        max-height: $support-users-heading-height;
        @include padding(24px, 48px);
        @include font-style(
            $size: get-font-size("xxlarge"),
            $color: get-color-neutral("90"),
            $weight: get-font-weight("light"),
            $line-height: get-line-height("xlarge")
        );
    }
}
