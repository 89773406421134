.c-committee-member-group {
    @include margin-bottom(40px);
    display: block;

    &.-officers {
        margin-bottom: 0;
    }

    &__title {
        @include margin-bottom(24px);
        @include paragraph-styles("large");
    }

    &.-officers .c-committee-member__name {
        display: block;
    }
}
