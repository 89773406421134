.c-section-share-link-page {
    @include fill-viewport-height();
    @include padding-left(16px);
    overflow-y: auto;

    .c-shared-section-view {
        margin-bottom: $call-to-action-banner-height-desktop;
    }

    @include respond-to("tablet") {
        .c-shared-section-view {
            margin-bottom: $call-to-action-banner-height-mobile;
        }
    }

    @include respond-to("phone") {
        max-height: calc(#{vh(100)} - #{rem(80px)});
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}
