.c-bookmark-card {
    @include respond-to("phone") {
        @include padding(8px);
    }
    @include padding(16px);
    background-color: get-color-background("primary");
    border: 1px solid get-color-neutral("30");
    border-radius: $border-radius-medium;
    cursor: pointer;
    display: block;
    text-decoration: none;
    overflow: visible;

    $baseClass: &;

    &__top__left__labels {
        text-align: left;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
        @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));

        &:not(.-no-underline) {
            #{$baseClass}__top__left__labels__section {
                text-decoration: underline;
            }
        }
    }

    &.-link-disabled {
        pointer-events: none;
    }

    &__top {
        display: flex;
        justify-content: space-between;

        &__left {
            display: flex;

            &__icon-container {
                @include margin-right(8px);
                max-width: rem(16px);

                .c-icon {
                    @include bookmark-icon-colors();
                }
            }

            &__labels {
                &__section {
                    @include font-style(
                        $size: "xsmall",
                        $line-height: get-line-height("xsmall"),
                        $color: get-color-neutral("90")
                    );
                }

                &__publication {
                    @include font-style(
                        $size: "xxsmall",
                        $line-height: get-line-height("xxsmall"),
                        $color: get-color-neutral("70")
                    );
                }
            }
        }

        &__right {
            display: flex;
            min-width: rem(102px);
            @include margin-left(8px);

            @include respond-to("phone") {
                min-width: unset;
                margin-left: unset;
            }

            &__timestamp {
                @include font-style(
                    $size: "xxsmall",
                    $line-height: get-line-height("xxsmall"),
                    $color: get-color-neutral("70"),
                    $style: italic
                );
                text-align: right;
            }

            &__actions {
                @include margin-left(4px);
                @include margin-top(-8px);
                @include margin-right(-16px);
                min-width: rem(36px);
                cursor: initial;

                .c-menu-button__icon {
                    @include icon-fill(get-color-neutral("70"));
                }
            }

            .c-menu-button__item > .c-button {
                color: get-color-neutral("90");
            }
        }
    }

    &__body {
        width: 100%;
        text-align: left;

        &__description.c-expandable-rich-text-area {
            @include margin-top(16px);
            @include margin-left(10px);
            @include padding-top(12px);
            @include padding-bottom(8px);
            @include padding-left(16px);
            border-radius: $border-radius-small;
            background-color: get-color-neutral("05");
            text-align: left;

            &.-anchor-hovered,
            &.-expanded {
                @include get-color-classes-from-map(
                    $property: background-color,
                    $value: "background"
                );
            }

            .c-button.-anchor {
                @include padding(8px);
                cursor: row-resize;
            }

            p {
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall")
                );
                @include margin-right(8px);
            }

            &:not(.-expanded) {
                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        &__footer {
            display: flex;

            .c-bookmark-collections-badge {
                @include margin-top(16px);
                @include margin-left(10px);
            }

            &__timestamp {
                @include font-style(
                    $size: "xxsmall",
                    $line-height: get-line-height("xxsmall"),
                    $color: get-color-neutral("70"),
                    $style: italic
                );

                @include respond-to("phone") {
                    @include padding-left(24px);
                    @include margin-top(4px);
                }

                &.-with-collections {
                    @include respond-to("phone") {
                        @include margin-top(22px);
                    }
                }
            }

            @include respond-to("phone") {
                display: flex;
                justify-content: space-between;
            }
        }

        table {
            tbody {
                tr:nth-child(n + 2) {
                    display: none;
                }
            }
            tfoot {
                display: none;
            }
        }

        .c-table-section {
            @include margin-left(6px);
            margin-top: 0;
            padding-top: 0;

            &__body {
                margin-bottom: 0;
                overflow: hidden;
            }

            &.-container {
                > .c-icon {
                    &.-change-indicator {
                        display: none;
                    }
                }
            }
        }
    }
}
