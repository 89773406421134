.c-table-of-contents-annex {
    @include margin-bottom(24px);

    h3 {
        line-height: 30px;
    }

    &__chapter-heading {
        text-decoration: none;
    }

    &__chapter-heading:hover {
        text-decoration: underline;
    }
}
