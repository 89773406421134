.c-search-result-card {
    @include padding(24px, 16px);

    &:hover {
        @include border-radius("base");
        background-color: get-color-neutral("05");

        .c-search-result-card {
            &__title {
                text-decoration: underline;
            }

            &__body {
                color: get-color-neutral("90");
            }
        }
    }

    a {
        text-decoration: none;
    }

    &__entity-type {
        @include font-style(
            $font-primary,
            "xxsmall",
            get-color-neutral("70"),
            600,
            14px
        );
        @include margin-bottom(8px);
        letter-spacing: 0.11em;
        text-transform: uppercase;
        line-height: 18px;
        display: flex;
        vertical-align: center;

        .c-icon {
            @include margin-right(8px);
        }

        &.-situation {
            @include icon-fill(get-color-neutral("50"));
        }

        &.-solution {
            color: get-color-status("success-dark");
            @include icon-fill(get-color-status("success"));
        }
    }

    &__title {
        color: get-color-neutral("90");
    }

    &__body {
        @include margin(8px, 0);
        @include respond-to("phone") {
            color: get-color-neutral("90");
        }

        color: get-color-neutral("70");
    }

    &.-direct-result {
        .c-search-result-card__body {
            // By default hide all elements so not to display images, lists, etc...
            * {
                display: none;
            }

            // Only display the first paragraph
            > p {
                @include margin(8px, 0);
                @include respond-to("phone") {
                    color: get-color-neutral("90");
                }

                @include font-size(14px);
                display: block;
                color: get-color-neutral("70");
                word-break: break-word;

                // Highlighted terms come back in <em> tag. Display them.
                // Hide everything else
                em {
                    display: inline;
                }
            }
        }
    }

    &__subtitle {
        @include margin-bottom(8px);
        color: get-color-neutral("70");
        font-style: italic;
    }

    &__breadcrumbs {
        @include margin(8px, 0);
        color: get-color-neutral("70");
        font-style: italic;
    }

    em {
        color: get-color-accents("blue-dark");
        font-weight: bold;
        font-style: normal;
    }
}
