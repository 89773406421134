.c-register-or-login-form {
    width: 100%;

    h2 {
        @include margin-bottom(8px);
        @include font-style(
            $size: "large",
            $color: get-color-neutral("90"),
            $weight: "bold",
            $line-height: "large"
        );
        letter-spacing: 0.02em;

        + div {
            @include font-style(
                $size: "small",
                $color: get-color-neutral("90"),
                $line-height: "small"
            );

            letter-spacing: 0.02em;
            @include margin-bottom(32px);

            .c-register-or-login-form__switch-form-trigger {
                @include font-style(
                    $size: "small",
                    $color: get-color-accents("blue-dark"),
                    $weight: "bold",
                    $line-height: "small"
                );

                text-decoration: underline;
            }
        }
    }

    &__password {
        @include margin-bottom(20px);
        .c-form-field {
            @include margin-bottom(0);

            &.-invalid p {
                display: none;
            }
        }

        .c-form-field.-invalid + p {
            @include margin-top(8px);
            color: get-color-primary("error");
        }

        p {
            font-style: italic;
        }
    }

    &__submit {
        max-width: rem(288px);
        width: rem(288px);
        margin: 0 auto;
    }

    .c-form .c-button {
        width: auto;
    }
}
