.c-recent-bookmark-anchor {
    @include padding(16px);
    @include respond-to("phone") {
        &:not(:last-of-type) {
            @include border-bottom($size: 1px, $color: get-color-neutral("30"));
        }
    }

    a {
        display: flex;
        align-items: center;
        position: relative;
        text-decoration: none; // Remove the underline from the entire anchor so the date doesn't receive it

        .c-icon {
            @include bookmark-icon-colors();
            @include margin-right(16px);
            align-self: flex-start;
        }
    }

    &__content {
        display: flex;
        flex-basis: 100%;

        @include respond-to("phone") {
            flex-direction: column;
        }

        &__title,
        &__date {
            @include respond-to("phone") {
                flex-basis: auto;
            }
        }

        &__title {
            @include font-style($size: "xsmall", $line-height: "xsmall");
            flex-basis: 70%;

            @include respond-to("phone") {
                @include margin-bottom(8px);
                flex-basis: auto;
            }
        }

        &__date {
            @include font-style(
                $color: get-color-neutral("70"),
                $line-height: "xxsmall",
                $size: "xxsmall",
                $style: italic
            );
            margin-left: auto;
            flex-basis: 25%;

            @include respond-to("phone") {
                margin-left: 0;
            }
        }
    }

    &:hover {
        @include border-radius("base");
        background-color: get-color-neutral("05");

        @include respond-to("phone") {
            border-radius: unset;
            background-color: unset;
        }

        .c-recent-bookmark-anchor__content__title {
            text-decoration: underline; // Reapply the underline to 'anchor' portion of this component
        }
    }
}
