.c-free-access-page {
    display: grid;
    grid-template:
        "banner banner" max-content
        "sidebar content" / minmax(auto, 3.5rem) auto;
    height: 100vh;
    position: relative;

    @include respond-to("phone") {
        grid-template:
            "sidebar sidebar" max-content
            "banner banner" max-content
            "content content";
    }

    &.-noSidebar {
        grid-template:
            "banner banner" max-content
            "content content" / minmax(auto, 3.5rem) auto;
    }

    &__banner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 16px;
        width: 100%;
        height: 44px;
        background: get-color-neutral("90");
        transition: width 0.15s ease-out;

        > p.text {
            @include font-style(
                $size: "xsmall",
                $color: get-color-neutral("white"),
                $weight: "bold"
            );
        }

        &.-fullscreen {
            width: 100%;
            left: 0;
        }
    }

    &__modal {
        .c-modal__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__body {
            @include padding(32);
            overflow: auto;

            &__paragraph {
                @include padding(12, 0);
            }
            &__list {
                @include padding(24);
            }
        }

        &__warning {
            color: get-color-status("warning-dark");
            @include padding(0, 16px);
        }

        .-modal-close {
            display: none;
        }
    }
}
