.c-user-management-table {
    th.-roles-col {
        text-align: right;
    }

    td.-roles-col {
        @include padding(20px);
        text-align: right;
    }

    .-collapse {
        padding-left: 0;
        padding-right: 0;
        width: 1px;
        white-space: nowrap;
    }

    .-no-results-row {
        font-style: italic;
        text-align: center;
    }

    &__role-chip {
        @include padding(8px, 12px);
        @include margin(4px);
        @include font-style(
            $size: "xsmall",
            $line-height: "xsmall",
            $weight: "bold",
            $color: get-color-neutral("90")
        );
        display: inline-block;
        border-radius: $border-radius-huge;
        background-color: get-color-neutral("02");
    }

    // make menu-button menu go up
    // instead of down so it's not cut off
    // by the bottom of the page
    tbody tr:last-of-type td {
        .c-menu-button__menu {
            top: rem(-76px);
        }
    }
}
