.c-training-contract {
    &__wrapper {
        display: flex;
        justify-content: center;
        padding: 5rem 1rem;
        flex-wrap: wrap;

        @include respond-to("laptop") {
            flex-direction: column;
        }
    }

    &__header {
        flex: 0 0 100%;
        text-align: center;
    }

    &__content {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: rem(1000px);
        gap: 1rem 5rem;
        padding: 1rem;

        @include respond-to("laptop") {
            flex-direction: column;
            gap: 1rem;
        }
    }

    &__left {
        flex: 0 1 50%;
        &__superscript {
            line-height: 0;
        }
    }

    &__right {
        flex: 0 1 50%;
    }

    &__form {
        display: flex;
        flex-direction: column;
        padding-top: 1rem;
    }

    &__createAccountHeading {
        @include font-style(
            $size: "base",
            $weight: "bold",
            $line-height: "base"
        );
        margin-bottom: 0.5rem;
    }

    &__submitButton {
        margin-top: 1rem;
        align-self: flex-start;
    }
}
