.c-search-modal-filters {
    @include padding-left(32px);
    $reset-button-height: 24px;

    flex-basis: 30%;
    flex-shrink: 0;

    &__mobile {
        &.-bottom {
            padding: 0;
        }
        &__content {
            $header-footer-height: #{$mobile-modal-header-height} - #{$mobile-modal-footer-height};
            padding-left: 0;
            max-height: calc(#{vh()} - #{$header-footer-height});
            overflow-y: auto;
        }
        &__footer {
            @include padding(16px);
            @include box-shadow(0, 8px, 20px, rgba(0, 26, 57, 0.3));
            display: flex;
            justify-content: space-between;
            height: $mobile-modal-footer-height;
        }
    }

    &__header {
        @include padding-bottom(8px);
        display: flex;
        align-items: center;
        box-shadow: inset 0px -1px 0px get-color-neutral("30");
        height: $reset-button-height;

        @include respond-to("phone") {
            @include padding(16px);
            justify-content: space-between;
            border-bottom: rem(1px) solid get-color-neutral("30");

            p {
                color: get-color-neutral("90");
            }
        }

        button {
            @include margin-left(16px);
        }
    }

    &__section {
        @include respond-to("phone") {
            @include padding(16px, 0);
            @include margin(0, 16px);
        }

        &:not(:last-of-type) {
            @include padding-bottom(16px);
            box-shadow: inset 0px -1px 0px get-color-neutral("30");
        }

        .-favorites-label {
            @include font-style(
                $size: get-font-size("xxsmall"),
                $color: get-color-neutral("70"),
                $line-height: get-line-height("xxsmall")
            );
        }

        .c-checkbox-list {
            .e-checkbox {
                @include padding(4px, 4px, 4px, 40px);
                margin: 0;
                border-radius: $border-radius-small;

                &__checkmark {
                    top: 0.25rem;
                }
            }
        }

        .c-checkbox-list {
            &:not(:last-of-type) {
                @include padding-bottom(8px);
            }

            &:not(:first-of-type) {
                @include padding-top(8px);
                box-shadow: inset 0px 1px 0px get-color-neutral("30");
            }
        }

        .c-collapse-panel {
            @include padding(12px, 0, 0, 0);

            &.-expanded {
                .c-collapse-panel__content {
                    @include margin-top(8px);
                }
            }

            &__accordion {
                .c-checkbox-list label,
                .c-radio label {
                    @include font-style(
                        $size: get-font-size("xsmall"),
                        $color: get-color-neutral("90"),
                        $line-height: get-line-height("xsmall")
                    );
                }

                .c-radio {
                    @include padding(8px, 8px, 8px, 4px);
                    border-radius: $border-radius-small;

                    &:hover {
                        background-color: get-color-neutral("white");
                    }
                    &.-selected {
                        background-color: get-color-accents("blue-light");
                        &:hover {
                            background-color: darken(
                                get-color-accents("blue-light"),
                                5%
                            );
                        }
                    }

                    label {
                        @include padding-left(28px);
                        padding-right: 0;
                        &:before {
                            right: unset;
                            left: 0;
                            top: 0;
                        }
                        &:after {
                            left: 5px;
                            right: unset;
                            top: 5px;
                        }
                    }
                }
            }

            &__heading {
                @include font-style(
                    $size: get-font-size("xsmall"),
                    $color: get-color-neutral("90"),
                    $line-height: get-line-height("xsmall")
                );
            }

            & > button {
                left: auto;
                right: rem(16px);
                height: rem(24px);
                width: rem(24px);
                top: rem(8px);

                .c-icon {
                    height: rem(16px);
                    width: rem(16px);
                }
            }
        }
    }

    &__filter-action {
        .c-button {
            @include font-style(
                $size: get-font-size("xsmall"),
                $color: get-color-accents("blue-dark"),
                $line-height: get-line-height("xsmall")
            );
            // @include margin-bottom(2px);
            @include margin-left(8px);
            height: $reset-button-height;
        }
    }
    h3 {
        @include font-style(
            $size: get-font-size("xsmall"),
            $color: get-color-neutral("90"),
            $line-height: get-line-height("xsmall"),
            $weight: "bold"
        );
    }
}
