.c-color-select {
    $baseClass: &;

    @include margin-bottom(20px);
    position: relative;
    z-index: 2;

    @mixin multi-select-focus-styles() {
        @include box-shadow(
            0,
            0,
            0,
            rgba(get-color-accents("blue-base"), 0.5),
            3px
        );
        border: 1px solid get-color-accents("blue-dark");
        outline: none;
    }

    label {
        @include font-style(
            $size: "small",
            $color: get-color-neutral("90"),
            $line-height: "small"
        );
    }

    &__select {
        &__control {
            @include margin-top(5px);
            @include font-style();
            border-color: get-color-neutral("30");
            min-height: rem(40px);

            .c-color-select__placeholder {
                color: get-color-neutral("70");
            }

            &:hover {
                border-color: get-color-neutral("30");
                box-shadow: none;
            }

            &--is-focused {
                @include focus-outline();
                @include hover-outline();

                .c-color-select__placeholder {
                    color: get-color-neutral("50");
                }
            }
        }

        // Using full classname to override 3rd party package
        &__single-value {
            color: get-color-neutral("90") !important;
        }

        &__value-container {
            padding-left: 44px !important; // Override 3rd party package inline style
            position: relative;
            align-items: center;
        }
    }

    &__selected-color {
        @include margin-right(16px);
        border-radius: 50%;
        height: rem(12px);
        width: rem(12px);
        position: absolute;
        left: rem(15px);

        @include if-internet-explorer() {
            top: rem(3px);
        }
    }

    &__option {
        @include padding(8px, 16px);
        background-color: get-color-neutral(
            "white"
        ) !important; // Overriding default style from react-select
        color: get-color-neutral(
            "70"
        ) !important; // Overriding default style from react-select
        position: relative;
        display: flex !important; // Overriding default 'block' display style from react-select
        align-items: center;

        &:hover,
        &:focus,
        &:active,
        &.c-color-select__select__option--is-focused {
            color: get-color-neutral(
                "90"
            ) !important; // Overriding default style from react-select
            outline: none;
            background-color: get-color-neutral(
                "05"
            ) !important; // Overriding default style from react-select
        }

        &__color {
            @include margin-right(16px);
            border-radius: 50%;
            height: rem(11px);
            width: rem(11px);
            left: rem(10px);
            top: rem(6px);

            @include if-internet-explorer() {
                width: rem(12px);
            }
        }
    }

    &.-small {
        .c-color-select__select__control {
            min-height: rem(8px);
        }
        label,
        .c-color-select__select__value-container {
            @include font-style(
                $size: "xsmall",
                $color: get-color-neutral("90"),
                $line-height: "small"
            );
        }
        .c-color-select__select__dropdown-indicator {
            @include margin-right(4px);
            @include padding(2px);
        }
        .c-color-select__select__value-container {
            padding-left: 34px !important; // Override 3rd party package inline style
        }
    }

    // Set colors for the type of the color picker

    &.-bookmark {
        #{$baseClass}__selected-color,
        #{$baseClass}__option__color {
            @include get-color-classes-from-map(background);
        }
    }

    &.-publication {
        #{$baseClass}__selected-color,
        #{$baseClass}__option__color {
            @include get-color-classes-from-map(background, "publication");
        }
    }
}
