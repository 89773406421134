.c-category-selection-modal {
    padding: 0;
    height: 90%;
    min-height: 90%;

    @include respond-to-min-width("laptop") {
        @include box-shadow(12px, 0px, 16px, rgba(0, 26, 57, 0.3), -8px);
        position: fixed;
        bottom: 0;
        margin: 0;
        max-width: initial;
        height: 100%;
        width: 373px;
        border-radius: initial;
        left: calc(#{$sidebar-menu-width} + #{$sitnav-category-selection-menu});
    }

    &__option-description {
        @include padding-right(8px);
        @include font-style(
            $size: "xsmall",
            $line-height: get-font-size("large")
        );
        color: get-color-neutral("70") !important;
    }

    .c-radio.-selected {
        .c-category-selection-modal__option-description {
            color: get-color-neutral("white") !important;
        }
    }

    &.c-modal {
        padding: 0;
        @include respond-to-min-width("laptop") {
            height: 100%;
            width: 373px;
            $category-left-offset: #{$sidebar-menu-width} + #{$sitnav-category-selection-menu};
            left: calc(#{$category-left-offset});
            border-radius: 0;
        }
    }
    .c-modal__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__top {
        @include padding(16px);
        @include padding-bottom(12px);
        position: relative;
        border-bottom: 1px solid get-color-neutral("30");

        &__label {
            @include font-size(18px);
            @include margin-bottom(10px);
            font-weight: get-font-weight("base");
            color: get-color-neutral("90");
        }

        &__help-text {
            @include font-style(
                $size: "xsmall",
                $line-height: get-line-height("xsmall"),
                $color: get-color-neutral("70")
            );
            @include margin-bottom(10px);

            .-bold {
                font-weight: get-font-weight("bold");
            }
        }

        &__close-btn {
            position: absolute;
            top: rem(24px);
            right: rem(24px);
        }
    }

    &__bottom {
        @include padding(16px);
        @include scrollbar();
        flex-grow: 1;
        padding-top: 0;
        overflow-y: auto;

        li:last-child {
            @include margin-bottom(32px);
        }

        &__item {
            margin: 0 !important;
            margin-top: rem(16px) !important;
            width: 100%;
            text-align: left;
            word-break: break-all;
            position: relative;

            span {
                display: inline-block;
                margin-left: 28px;
            }

            .c-icon {
                position: absolute;
            }
        }
    }
}
