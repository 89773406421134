/*------------------------------------*\
  APPLICATION
\*------------------------------------*/

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    word-break: break-word;
}

html {
    height: 100%;
    &:not(.e2e) {
        scroll-behavior: smooth;
    }
}

body {
    @include fill-viewport-height();
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;

    // Hides pixel img's from analytics tools
    > img[height="1"] {
        display: none;
    }

    // Overriding fill-viewport-height mixin to resolve Free Access mobile footer display issue
    @include respond-to("phone") {
        height: 100%;
        max-height: 100%;
    }
}

#root {
    height: 100%;

    &:focus {
        outline: none;
    }
}

#reach-skip-nav {
    height: 100%;

    &[tabindex="-1"] {
        &:focus {
            // tabindex="-1" prevents tabbing to it but not focusing it via a click
            // it looks weird to have an entire page area "focused"
            outline: none;
        }
    }
}
