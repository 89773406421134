.c-publication-editor {
    height: 100%;

    &__form {
        @include padding(48px);
        height: calc(100% - #{$admin-footer-height});
        overflow-y: auto;

        > .c-alert-banner {
            @include margin-bottom(24px);
        }

        &__image-container {
            @include margin-bottom($admin-footer-height);
            overflow: hidden;

            img {
                @include border-radius("large");
                max-width: 300px;
                height: auto;
            }
        }
    }

    &__footer {
        @include padding(16px);
        height: rem($admin-footer-height);
        border-top: 1px solid get-color-neutral("30");
        display: flex;
        justify-content: space-between;

        &__left {
            @include margin-right(16px);
        }

        &__right {
            flex-grow: 1;
        }
    }

    .c-color-select {
        max-width: rem(320px);

        @include respond-to("tablet") {
            max-width: unset;
        }
    }
}
