.c-compact-page-navigation-menu {
    display: flex;
    align-items: center;
    @include rem-fallback(gap, 8);
    input,
    &__first,
    &__next,
    &__prev,
    &__last {
        width: rem(28px);
        height: rem(28px);
        @include margin(0px);
    }

    &__paging {
        display: flex;
        @include font-style($size: "xsmall", $weight: "bold");
    }

    input {
        text-align: center;
        border: 1px solid #d4d6de;
        @include padding(8px, 0px);
        @include rem-fallback(border-radius, 5, 0, 0, 5);
        @include margin-right(1px);
        @include font-style($size: "xsmall", $weight: "bold");
    }

    //Disables scroll wheel for number input type
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        @include margin(0);
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }

    &__total_pages {
        width: rem(48px);
        text-align: center;
        height: rem(28px);
        color: get-color-neutral("white");
        border: 1px solid #d4d6de;
        background: get-color-background("dark");
        @include padding(6px, 0px);
        @include rem-fallback(border-radius, 0, 5, 5, 0);
    }
    &__main {
        .c-button {
            &:first-of-type {
                @include margin-left(8px);
            }

            &:last-of-type {
                @include margin-right(8px);
            }
        }
    }

    @include respond-to("tablet") {
        .c-page-navigation__prev {
            @include margin-right(4px);
        }
    }

    &__max-items {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        span {
            @include font-style(
                $size: "xsmall",
                $color: get-color-neutral("70"),
                $line-height: "xsmall"
            );
        }

        .c-select {
            @include margin-left(16px);
        }

        @include respond-to("phone") {
            flex-direction: column;
            align-items: start;

            .c-select {
                @include margin-top(2px);
                margin-left: 0;

                select {
                    @include font-style($size: "base", $line-height: "base");
                }
            }
        }
    }

    &__page-actions {
        display: flex;
        align-items: center;
        margin-left: auto;

        @include respond-to("phone") {
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &__pages {
        > span {
            @include margin-right(16px);
            @include font-style(
                $size: "xsmall",
                $color: get-color-neutral("70"),
                $line-height: "xsmall"
            );

            @include respond-to("phone") {
                @include margin-right(0);
            }
            width: 100%;
            letter-spacing: 0.02em;
            text-align: right;
        }
    }
}
