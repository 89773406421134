.c-related-solution-card {
    border: 1px solid get-color-neutral("30");
    border-radius: $border-radius-medium;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    position: relative;
    &:focus {
        @include box-shadow(0px, 0px, 5px, get-color-accents("blue-dark"), 1px);
        outline: none;
    }
    &:hover {
        @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));
    }

    &__title {
        @include font-size("small");
        @include line-height("small");
        @include margin-bottom(4px);
    }

    &__subtitle {
        @include font-size("xsmall");
        @include line-height("xsmall");
        color: get-color-neutral("70");
    }
}
