.c-multi-checkbox-select {
    $base-class: &;

    &__menu-container__value__single {
        @include ellipsis(block);
    }

    .c-multi-select {
        &__input__selector__option {
            &:hover,
            &:focus,
            &:active,
            &--is-focused,
            &--is-selected {
                background-color: get-color-background("primary");

                #{$base-class}__menu-container__option-label {
                    color: get-color-accents("blue-dark");
                }

                .e-checkbox {
                    &__checkmark {
                        border-color: get-color-accents("blue-dark");

                        &::after {
                            border-color: get-color-accents("blue-base");
                        }
                    }
                }
            }

            &:hover,
            &:active {
                .e-checkbox {
                    &__checkmark {
                        background-color: get-color-accents("blue-light");
                    }
                }
            }
        }
    }
}
