.c-expandable-meta-nav-collapse-panel {
    $b: &;

    $spacer: 0.5rem;

    @include border-radius("large");
    padding: 1rem;
    display: grid;
    grid-template-columns: rem(20px) auto;
    grid-template-rows: max-content 0;
    grid-auto-flow: row;
    align-items: start;
    gap: $spacer 0;

    .c-collapse-panel {
        &__content {
            grid-row: 2;
            grid-column: 2;

            &.-expanded {
                margin-top: 0;
            }
        }

        &__heading {
            padding-left: $spacer;
            grid-column: 2;
        }

        &__accordion {
            display: none;

            &.-expanded {
                display: block;
                padding: 0 2px;
            }
        }
    }

    .c-publication-sidebar-nav-link__title {
        @include font-style($size: "xsmall", $line-height: "xsmall");
    }

    .c-link-resources__default {
        margin-left: 0;

        &__header {
            padding-left: $spacer;
        }

        &__link {
            text-decoration: none;
        }

        &__item {
            @include font-style($size: "xsmall", $line-height: "xsmall");
            padding: $spacer;
            margin-bottom: 2px;
            display: block;

            &.-active,
            &:hover {
                background-color: get-color("neutral", "70");
                color: get-color-neutral("white");
                text-decoration: none;
            }
        }
    }

    .c-button {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        &:disabled {
            background-color: transparent;
            opacity: 0.25;
        }
    }

    &.-expanded {
        background-color: get-color("neutral", "white");
        margin-bottom: 0.5rem;
        grid-template-rows: max-content max-content;
    }

    &__headingLink {
        display: inline-block;
        text-decoration: none;
    }

    &__heading {
        @include font-style($size: "small", $line-height: "small");
    }

    &__part {
        @include font-style($size: "xsmall", $line-height: "xsmall");
        color: get-color("neutral", "darker");
        padding: 0.375rem;
    }

    &.-child {
        padding: 0.5rem 0;
        #{$b}__heading {
            @include font-style($size: "xsmall", $line-height: "xsmall");
        }

        .c-collapse-panel__content {
            margin-bottom: 0;
        }

        .c-publication-sidebar-nav-link__title {
            @include font-style($size: "xxsmall", $line-height: "xxsmall");
            margin: 0;
        }

        .c-publication-sidebar-nav-link {
            display: inline-block;
        }
    }
}
