.c-publication-card-simple {
    $baseClass: &;
    position: relative;
    cursor: pointer;

    width: rem(150px);
    height: rem(209px);

    &:focus {
        outline: none;
        .c-publication-card-simple__favorite-icon {
            opacity: 1;
        }
    }

    @include respond-to("phone") {
        width: rem(116px);
        height: rem(162px);
    }

    &__image-container {
        height: 100%;
    }

    img {
        @include border-radius("base");
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__favorite-icon {
        @include padding(6px);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: get-color-neutral("white");
        opacity: 0.75;
        cursor: pointer;
        height: rem(28px);
        width: rem(28px);
        z-index: 1;

        @include if-not-touchscreen() {
            &:hover {
                opacity: 1;
            }
        }
    }

    &.-selected {
        &:after {
            @include box-shadow(0, 3px, 10px, rgba(0, 26, 57, 0.2));
            @include border-radius("base");
            @include border(
                $topSize: rem(6px),
                $rightSize: rem(6px),
                $bottomSize: rem(6px),
                $leftSize: rem(6px),
                $color: get-color-neutral("white")
            );
            content: "";
            position: absolute;
            top: rem(-6px);
            left: rem(-6px);
            height: calc(100% + 12px);
            width: calc(100% + 12px);
            z-index: 1;
        }

        .c-publication-card-simple__image-container:after {
            @include border-radius("base");
            content: "";
            background: get-color-accents("blue-dark");
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .c-publication-card-simple__favorite-icon {
            border-top-right-radius: 0;
            opacity: 1;

            svg {
                path {
                    fill: get-color-accents("blue-dark");
                    stroke: get-color-accents("blue-dark");
                }
            }
        }
    }
    &.-no-image {
        .c-publication-card-simple__image-container {
            height: 100%;
            background: get-color-background("primary");

            img {
                top: rem(64px);
                object-fit: none;
                height: unset;
                width: unset;
            }
        }
    }
}
