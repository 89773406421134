.c-redirection-modal {
    max-width: 432px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include respond-to("phone") {
        margin: 10vh 16px;
    }

    &__header {
        @include padding(24px, 24px, 16px, 24px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                700,
                24px
            );
            display: inline-block;
            justify-self: left;
        }
        width: 100%;
    }

    &__content {
        @include padding(0, 24px);
        overflow-y: auto;

        &.-error {
            span,
            label,
            p,
            .c-radio label p {
                color: get-color-primary("error");
            }

            input:not(:checked) + label {
                &:before {
                    border-color: get-color-primary("error");
                    box-shadow: 0 0 0 3px rgba(213, 0, 50, 0.2);
                }
            }
        }
    }

    &__title {
        @include font-style(
            $font-primary,
            "base",
            get-color-neutral("90"),
            400,
            24px
        );
        @include padding-bottom(16);
        display: block;
    }

    &__footer {
        @include padding(16px, 24px, 24px, 24px);
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @include respond-to("phone") {
            flex-wrap: wrap-reverse;
            justify-content: center;

            > .c-button:not(:first-of-type) {
                @include margin(0, 0, 16px, 0);
            }
        }

        > .c-button:first-of-type {
            @include margin-right(8px);
        }
    }
}
