.c-subscription-detail-card-content {
    @include font-style(
        $font-primary,
        "small",
        get-color-neutral("70"),
        get-font-weight("bold"),
        get-line-height("small")
    );

    border: rem(1px) solid get-color-neutral("30");
    border-radius: $border-radius-small;
    border-spacing: 0;
    margin-bottom: rem(24px);
    width: 100%;

    tr {
        td {
            border-bottom: rem(1px) solid get-color-neutral("30");
        }

        &:last-child {
            td {
                border-bottom: none;
            }
        }
    }

    td {
        @include padding(8px, 16px);

        &:first-child {
            color: get-color-neutral("90");
            text-align: right;
            width: rem(1px);
            white-space: nowrap;
        }

        &.-off {
            color: get-color-primary("error");
        }
    }

    &__days-remaining {
        font-weight: get-font-weight("bold");
    }
}
