.c-email-multi-input {
    &__select-container {
        @include respond-to("tablet") {
            position: relative;

            $mobile-dropdown-button-width: 40px;

            .c-multi-select.-has-options {
                .c-multi-select__input {
                    &__selector {
                        &__value-container,
                        &__value-container--is-selected,
                        &__value-container--is-multi {
                            padding-right: rem($mobile-dropdown-button-width);

                            > div {
                                @include padding-right(24px);
                                width: 100%;
                                max-width: 100%;

                                > div {
                                    width: 100%;

                                    input {
                                        max-width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .c-button.-tertiary-alt {
                @include icon-fill(get-color-neutral("50"));
                padding: rem(2px) 0 0;
                width: rem($mobile-dropdown-button-width);
                flex-grow: 0;
                border: none;
                position: absolute;
                right: 2px;
                top: 1px;
                bottom: 1px;

                &:focus {
                    outline: none;
                }

                .-separator {
                    position: absolute;
                    left: 0;
                    top: 8px;
                    bottom: 8px;
                    width: 1px;
                    background-color: get-color-neutral("30");
                }
            }
        }
    }

    .c-multi-select {
        &__input {
            &__selector {
                &__indicators {
                    display: none;
                }

                &__value-container {
                    @include padding(6px, 18px);
                }
            }
        }
    }

    &.c-form-field {
        &.-invalid {
            input:not([type="checkbox"]) {
                box-shadow: none;
            }

            .c-multi-select {
                &__input {
                    &__selector {
                        &__control {
                            @include form-field-error-styles();
                        }
                    }
                }
            }
        }
    }
}
