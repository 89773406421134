.c-update-bookmark-modal {
    @include padding($mobile-modal-header-height, 0px, 16px, 0px);
    max-width: rem(600px);

    @include respond-to("phone") {
        height: vh();
        width: 100%;
        border-radius: 0;
    }

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        background-color: get-color-background("neutral");
        position: absolute;
        top: 0;
        left: 0;
        height: rem($mobile-modal-header-height);
        width: 100%;

        > .c-button.-modal-close {
            position: absolute;
            right: rem(24px);
        }

        > .c-icon {
            @include margin-right(24px);
        }

        h2 {
            @include font-style(
                $size: "large",
                $color: get-color-neutral("90"),
                $line-height: get-line-height("large")
            );
            display: inline-block;
        }
    }

    &__body {
        max-height: rem(640px);
        overflow-y: auto;

        @include respond-to("phone") {
            max-height: calc(#{vh()} - #{rem($mobile-modal-header-height)});
        }

        &__form-area {
            @include padding(16px, 32px);

            &__section-info {
                p {
                    @include font-style();
                }

                label {
                    @include font-style(
                        $size: "xsmall",
                        $line-height: "xsmall",
                        $color: get-color-neutral("70")
                    );
                }
            }

            .c-form {
                @include margin-top(16px);

                .c-rich-text-editor {
                    > div[class^="RichTextEditor"] {
                        div[class^="EditorToolbar"] {
                            z-index: 1;
                        }
                    }

                    .public-DraftEditor-content {
                        max-height: rem(155px);
                        overflow-y: auto;

                        @include respond-to("phone") {
                            max-height: rem(245px);
                        }
                    }
                }
            }

            &__description-optional-label {
                color: get-color-neutral("70");
            }
        }

        &__footer {
            @include padding(24px);
            border-top: 1px solid get-color-neutral("30");
            text-align: right;

            .c-button {
                &:first-child {
                    @include margin-right(8px);
                }
            }
        }
    }

    &.c-modal {
        @include if-internet-explorer {
            overflow: visible;
        }
    }
}
