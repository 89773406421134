.c-resources-section {
    &__type-header {
        @include margin-top(40px);
        @extend .c-label, .-small;
        text-transform: uppercase;

        &:first-child {
            margin-top: 0;
        }
    }
}
