.c-situational-navigation-category-link {
    position: relative;
    @include margin-bottom(16px);

    @include respond-to-min-width("laptop") {
        min-width: rem(250px);
    }

    &__main-button {
        @extend %c-button, .-large;
        @include padding-left(100px);
        @include padding-bottom(25px);
        @include padding-top(25px);
        @include font-style(
            $size: 14px,
            $color: get-color-accents("blue-dark")
        );
        background: get-color-neutral("white");
        font-weight: 400;
        border-radius: 5px;
        text-align: left;
        overflow: hidden;
        min-height: rem(68px);

        @include respond-to-min-width("laptop") {
            width: 100%;
            white-space: nowrap;
        }

        &.-disabled {
            background-color: get-color-neutral("05");
            border: 1px solid get-color-neutral("50");
            box-shadow: none;

            img {
                opacity: 0.5;
            }

            &.c-button.-tertiary-alt {
                @include font-size("xsmall");
                @include padding(17px, 24px, 17px, 100px);
                color: get-color-neutral("70");

                .-no-selections {
                    @include font-style(
                        $size: get-font-size("xsmall"),
                        $color: get-color-neutral("70")
                    );
                }
            }

            .-no-selections {
                @include font-size("xsmall");
                font-style: italic;
            }

            &:hover {
                opacity: 1;
            }
        }

        &.c-button.-tertiary-alt {
            @include font-style(
                $size: get-font-size("small"),
                $color: get-color-accents("blue-dark")
            );
        }

        .c-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        &__selection {
            p {
                @include font-size(14px);
                font-weight: get-font-weight("bold");
                color: get-color-accents("blue-dark");
                line-height: rem(21px);
            }
        }

        &.c-button.-tertiary-alt.-selected {
            @include padding(11px, 24px, 11px, 100px);
            @include font-size("xsmall");
            border: 1px solid get-color-accents("blue-dark");
            background: get-color-accents("blue-light");
            line-height: rem(18px);
            box-shadow: none;

            .c-situational-navigation-category-link {
                &__selection {
                    @include font-size(14px);
                    display: block;
                }
            }
        }

        img {
            @include padding(4px);
            width: 80px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__close {
        position: absolute;
        right: 0;
        top: 0;
        width: rem(28px);
        height: rem(28px);
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        background: get-color-accents("blue-dark");

        svg {
            @include margin-top(3px);
            path {
                fill: get-color-neutral("white");
            }
        }
    }
}
