.c-admin-page-with-table {
    $spacer-x: 48px;
    $spacer-y: 16px;
    height: 100%;
    @include padding-top($spacer-x);
    display: flex;
    flex-direction: column;
    overflow: auto;

    &__heading {
        @include padding-left($spacer-x);
        @include padding-right($spacer-x);
        @include padding-bottom($spacer-y);
    }

    &__tableWrapper {
        @include padding-left($spacer-x);
        @include padding-right($spacer-x);
    }
}
