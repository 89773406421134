.c-publication-content {
    // The start of a generic styling for displaying basic content (frontmatter, etc)
    @include if-firefox {
        @include padding-bottom(32px);
    }

    @include if-internet-explorer {
        &:after {
            content: "";
            height: rem(32px);
            display: block;
        }
    }

    h2 {
        @extend .c-section-detail__title;
        @include margin-top(72px);
    }

    p:not(:last-of-type) {
        @include margin-bottom(16px);
    }

    > .c-origins-body {
        @include margin-bottom(32px);
    }

    &__block {
        &:not(:last-of-type) {
            @include padding-bottom(72px);
            border-bottom: 2px solid get-color-neutral("30");
        }
    }
}
