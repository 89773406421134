.c-organization-support-modal {
    &__modal {
        max-width: rem(592px);
    }
    &__header,
    &__content {
        @include padding(16px);
    }
    &__header {
        background-color: get-color-background("neutral");
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__heading {
        @include font-style(
            $font-primary,
            "large",
            get-color-neutral("90"),
            400,
            get-line-height("large")
        );
        display: inline-block;
        justify-self: left;
    }

    &__closeButton {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__itemLabel {
        @include font-style(
            $font-primary,
            "base",
            get-color-neutral("70"),
            400,
            get-line-height("base")
        );
        @include margin-bottom(8px);
    }

    &__item {
        @include font-style(
            $font-primary,
            "base",
            get-color-neutral("90"),
            400,
            get-line-height("base")
        );
        @include margin-bottom(32px);
        width: fit-content;
    }

    &__entry {
        @include padding-bottom(8px);
        display: flex;
        width: 100%;
    }

    &__entryIcon {
        width: rem(18px);
        height: rem(18px);
    }

    &__entryText {
        @include padding-right(24px);
        flex: 1 1 100%;
        text-align: left;
    }

    &__websiteWrapper {
        display: flex;
        align-items: baseline;
        gap: 1rem;
    }

    &__anchor {
        color: get-color-accents("blue-dark");
    }
}
