.c-user-management-dashboard {
    @include padding(48px);
    @include padding-top(56px);
    padding-bottom: 0;
    height: 100%;
    overflow-y: auto;

    > h5 {
        @include margin-bottom(16px);
        color: get-color-neutral("90");
        line-height: get-line-height("xlarge");
    }

    &__content {
        &__controls {
            display: flex;
            justify-content: space-between;

            @include if-internet-explorer {
                display: block;
            }

            .c-search-form {
                flex-grow: 0.25;

                @include if-internet-explorer {
                    display: inline-block;
                    width: 25%;
                    max-width: 25%;
                }
            }

            &__right {
                display: flex;

                @include if-internet-explorer {
                    display: inline-block;
                    width: 75%;
                    max-width: 75%;
                    text-align: right;

                    .c-button {
                        vertical-align: text-top;
                        height: rem(30px);
                    }
                }

                .c-select {
                    @include margin-right(8px);

                    @include if-internet-explorer {
                        vertical-align: bottom;
                    }

                    select {
                        height: 100%;
                    }
                }
            }
        }
    }
}
