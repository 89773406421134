.c-text-input-icon {
    @include icon-fill(get-color-neutral("50"));
    position: relative;

    &.-clearable {
        input {
            @include padding-right(30px);
        }

        .c-button {
            position: absolute;
            right: 0;
            margin: 0;
            top: calc(50% - #{rem(14px)});
            background-color: transparent;
            display: none;

            &.-has-value {
                display: initial;
            }

            &.-icon {
                @include icon-fill(get-color-neutral("50"));
            }
        }
    }

    .c-icon:not(.-close-icon) {
        position: absolute;
        left: 0;
        top: calc(50% - #{rem(14px)});
    }
    input {
        @include padding-left(30px);
        border: none;
        width: 100%;
    }
}
