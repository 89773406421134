.c-full-screen-transition {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: get-color-background("neutral");
    z-index: 99;

    &__content {
        @include font-style(
            $font-primary,
            "xsmall",
            get-color-neutral("70"),
            400,
            18px
        );
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        letter-spacing: 0.02em;
        text-align: center;

        img {
            @include margin-bottom(80px);
            width: rem(160px);
            height: auto;
        }

        &__transition-text {
            @include margin-top(16px);
        }
    }
}
