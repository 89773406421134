.c-bookmark-settings-mobile-modal {
    @include padding-top($mobile-modal-header-height);
    border-radius: 0;
    height: #{vh()};
    max-width: 100%;
    width: 100%;

    &__content {
        height: calc(#{vh()} - #{$mobile-modal-header-height});
        overflow-y: auto;
        .c-bookmark-settings {
            &__content {
                @include padding(16px, 32px);
            }
        }
    }

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        background-color: get-color-background("neutral");
        position: absolute;
        top: 0;
        left: 0;
        height: $mobile-modal-header-height;
        width: 100%;

        > .c-button.-modal-close {
            position: absolute;
            right: rem(24px);
        }

        > .c-icon {
            @include margin-right(24px);
        }

        h2 {
            @include font-style(
                $size: "large",
                $color: get-color-neutral("90"),
                $line-height: get-line-height("large")
            );
            display: inline-block;
        }
    }
}
