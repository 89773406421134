.c-user-collection-modal {
    padding-bottom: 0;

    width: rem(600px);
    min-width: rem(600px);
    overflow: hidden;

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $size: "large",
                $line-height: get-line-height("large"),
                $color: get-color-neutral("90")
            );
            display: inline-block;
            justify-self: left;
        }
        background-color: get-color-background("neutral");
        width: 100%;

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__content {
        height: rem(400px);
        min-height: rem(400px);
        max-height: 60vh;
        overflow-y: auto;

        &__team-message {
            @include font-style($size: "small");
            display: flex;
            align-items: center;

            .c-icon {
                @include margin-right(4px);
            }
        }
    }

    &__footer {
        @include margin-top(12px);
        border-top: 1px solid get-color-neutral("30");
        text-align: right;

        .c-button {
            @include margin-left(20px);
        }
    }

    &__container {
        @include padding(24px);
    }

    @include respond-to("sm-tablet") {
        @include fill-viewport-height();
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: initial;
    }

    @include respond-to("phone") {
        .c-modal__content {
            display: flex;
            flex-direction: column;
        }

        &__content {
            max-height: calc(#{vh(100)} - #{$mobile-modal-header-height});

            &__name {
                flex-direction: column;

                .c-form-field {
                    flex-basis: 100%;
                }
            }
        }
    }

    &__button-group {
        width: 100%;
        display: flex;
        @include margin-top(5px);

        &__button-group-item {
            width: 100%;
            @include font-style($size: "xsmall");
            font-weight: get-font-weight("bold");
        }
    }
}
