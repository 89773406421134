/*------------------------------------*\
  PARAGRAPHS
\*------------------------------------*/

p,
%p {
    @include font-style($font-primary, "base", false, 400, 26px);
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    color: get-color-neutral("90");

    &.-xxxxlarge {
        @include font-style($font-primary, "xxxxlarge", false, 400, 78px);
    }

    &.-xxxlarge {
        @include font-style($font-primary, "xxxlarge", false, 400, 62px);
    }

    &.-xxlarge {
        @include font-style($font-primary, "xxlarge", false, 400, 56px);
    }

    &.-xlarge {
        @include font-style($font-primary, "xlarge", false, 400, 44px);
    }

    &.-large {
        @include font-style($font-primary, "large", false, 400, 27px);
    }

    &.-base {
        @include font-style($font-primary, "base", false, 400, 24px);
    }

    &.-small {
        @include font-style($font-primary, "small", false, 400, 21px);
    }

    &.-xsmall {
        @include font-style($font-primary, "xsmall", false, 400, 18px);
    }

    &.-xxsmall {
        @include font-style($font-primary, "xxsmall", false, 400, 16px);
    }
}
