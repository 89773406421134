.c-page-navigation {
    display: flex;

    &__prev {
        @include margin-right(8px);
        > .c-icon {
            position: relative;
            top: 1px;
            right: 1px;
        }
    }
    &__next {
        @include margin-left(8px);
        > .c-icon {
            position: relative;
            top: 1px;
            left: 1px;
        }
    }
    &__main {
        > .c-button {
            @include margin(0, 1px);
            border-radius: 0;
            &:first-of-type {
                margin: 0;
                border-top-left-radius: $border-radius-small;
                border-bottom-left-radius: $border-radius-small;
            }
            &:last-of-type {
                margin: 0;
                border-top-right-radius: $border-radius-small;
                border-bottom-right-radius: $border-radius-small;
            }
        }
    }
    .c-button {
        height: 28px;
        width: 28px;
        padding: 0;
    }
}
