.c-support-admin-dashboard-table {
    &__row {
        &:hover {
            background-color: get-color-background("secondary");
            cursor: pointer;
        }
    }

    .-no-results-row {
        font-style: italic;
        text-align: center;
    }
}
