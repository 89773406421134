.c-landing-page-spotlights-section {
    display: flex;

    @include respond-to("phone") {
        display: flex;
        flex-direction: column;
    }
    &__card {
        width: 100%;
        padding: 20px;
        position: relative;

        &__image {
            height: 50%;
            width: 100%;
        }

        &__text-panel {
            &__subtext {
                color: get-color-neutral("70");
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
        &__container {
            position: relative;

            &__box {
                background-color: get-color-accents("blue-base");
                position: absolute;
                height: 100%;
                width: 100%;
                opacity: 0.7;
                top: 0;
                left: 0;
                border-radius: 10px;
            }

            &__icon-wrapper {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &__icon {
                height: rem(80px);
                width: rem(80px);
            }
        }
    }
}
