.c-unauthenticated-footer {
    @include padding(16px, 80px);
    display: flex;
    align-items: center;
    height: $unauthenticated-footer-height;
    background-color: get-color-neutral("05");
    border-top: rem(1px) solid get-color-neutral("30");

    @include respond-to("tablet") {
        @include padding(16px, 24px);
        flex-direction: column;
        height: initial;

        > * {
            @include margin-bottom(16px);
        }
    }

    &__links {
        display: flex;
        margin-left: auto;

        @include respond-to("tablet") {
            margin-left: 0;
        }
    }

    .c-dropdown-button {
        @include margin-left(32px);

        @include respond-to("tablet") {
            margin-left: 0;
        }
    }

    a {
        @include margin-right(24px);
        @include font-style(
            $color: get-color-accents("blue-dark"),
            $line-height: "xsmall",
            $size: "xsmall"
        );

        @include respond-to("phone") {
            @include margin-right(16px);
        }
    }

    img {
        height: rem(30px);
    }
}
