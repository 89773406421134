.c-select-favorites {
    @include margin-top(32px);
    @include margin-bottom(40px);
    @include padding(0, 36px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to("tablet") {
        overflow: hidden;
    }

    @include respond-to("phone") {
        padding: 0;
    }

    &__description {
        margin: 0 auto 32px;
        max-width: rem(824px);
        text-align: center;

        @include respond-to("phone") {
            @include font-style($size: "small", $line-height: "xxxxlarge");
            @include padding(0, 36px);
        }
    }

    &__divider {
        @include padding-top(25px);
        margin: 0 auto;
        width: rem(998px);
        border-bottom: rem(1px) solid get-color-neutral("30");

        @include respond-to("phone") {
            width: calc(100% - 72px);
        }
    }

    &__footer {
        @include margin(48px, 0);

        a {
            @extend %c-button;
        }
    }

    &__publications {
        @include margin-bottom(40px);
        text-align: center;
        @include respond-to("phone") {
            @include padding-left(36px);
            display: block;
            margin: 0;
            width: calc(100% + 64px);
        }

        &__list {
            @include margin-bottom(24px);
            display: flex;
            flex-wrap: wrap;
            max-width: rem(970px);
            flex-direction: row;
            justify-content: center;

            @include respond-to("phone") {
                display: block;
                position: relative;
                height: rem(190px);
                left: rem(-32px);
                max-width: none;
                overflow-x: scroll;
                overflow-y: hidden;
                scrollbar-width: none; /* Firefox */
                transition: all 0.2s;
                user-select: none;
                white-space: nowrap;
            }

            .c-publication-card-simple {
                @include margin(0, 22px, 44px, 22px);
                @include respond-to("phone") {
                    @include margin(8px);
                    display: inline-block;
                    white-space: normal;
                    vertical-align: middle;

                    &:first-of-type {
                        @include margin-left(64px);
                    }
                }
            }
        }

        .c-button.c-select-favorites__publications__show-more-button {
            @include font-style(
                $color: get-color-accents("blue-dark"),
                $weight: "base"
            );
            @include respond-to("phone") {
                display: none;
            }
        }
    }

    .c-alert-banner {
        @include border(
            $topSize: rem(1px),
            $rightSize: rem(1px),
            $bottomSize: rem(1px),
            $leftSize: rem(1px),
            $color: get-color-accents("blue-base")
        );
        @include border-radius("base");

        @include respond-to("phone") {
            @include margin(0, 36px);
        }
    }

    &__title {
        @include font-style(
            $size: "xxxlarge",
            $weight: 100,
            $line-height: rem(62px)
        );
        @include margin(40px, 0, 24px);
        color: get-color-neutral("90");
        text-align: center;

        @include respond-to("phone") {
            @include font-style(
                $size: "xlarge",
                $weight: 100,
                $line-height: rem(36px)
            );
            @include margin(16px, 32px, 24px);
        }
    }
}
