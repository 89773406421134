.c-getting-started-link {
    @include margin-bottom(16px);
    @include padding(16px);
    border: 1px solid get-color-neutral("30");
    background: get-color-neutral("white");
    border-radius: 9px;
    text-decoration: none;
    word-break: break-word;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;

    &:focus {
        @include box-shadow(0px, 0px, 5px, get-color-accents("blue-dark"), 1px);
        outline: none;
    }
    &:hover {
        @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));
        background: get-color-neutral("white");
    }

    &__icon {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: get-color-accents("blue-light");
        position: relative;
        align-self: flex-start;
        flex-shrink: 0;

        .c-icon {
            height: auto;
            width: auto;
        }

        svg {
            @include icon-fill(get-color-accents("blue-dark"));
            position: absolute;
            right: 50%;
            top: 50%;
            transform: translateX(50%) translateY(-50%);
        }
    }

    &__content {
        @include padding-left(12px);
        @include padding-right(12px);
        display: flex;
        flex-direction: column;
    }

    &__label {
        p {
            @include margin-top(8px);
            color: get-color-neutral("70");
            display: block;
            letter-spacing: 0.02em;

            @include if-internet-explorer {
                max-width: rem(300px);
            }
        }
    }
}
