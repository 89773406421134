.c-annex-page {
    display: flex;
    min-height: 100vh;
    width: 100%;

    &__panel {
        @include padding-top(197px);
        @include padding-bottom(20px);
        flex-basis: auto;

        &-metadata {
            @include padding-top(36px);
            @include padding-left(32px);
            position: relative;
            background-color: get-color-background("neutral");
            width: 15%;
            height: 100vh;
            overflow-y: scroll;

            a:first {
                @include font-style($font-primary, "small", #000000, 300, 24px);
            }

            h4 {
                @include margin-top(16px);
            }
        }

        &-content {
            @include padding-top(96px);
            @include padding-left(75px);
            @include padding-right(148px);
            width: 85%;
        }
    }

    &__errors {
        color: get-color-neutral("darker");
    }

    &__loading {
        color: get-color-neutral("darker");
    }
}
