.c-exception-section {
    @include margin-top(24px);
    @include font-style(
        $font-primary,
        "small",
        get-color-neutral("90"),
        400,
        22px
    );
    font-style: italic;

    &.-no-label {
        font-style: normal;
        text-align: center;
        text-transform: uppercase;

        p {
            display: block;
        }
    }

    + p {
        @include margin-top(24px);
    }

    .c-informational-note-section {
        margin-bottom: 0;
    }

    .c-publication-list {
        @include margin-top(16px);
    }

    p {
        @extend .-small;
        color: get-color-neutral("90");
        display: inline;
    }

    a {
        @include font-style(
            $font-primary,
            "small",
            get-color-neutral("90"),
            700,
            22px
        );
        font-style: inherit;
        color: inherit;
    }
}
