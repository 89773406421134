.c-solution-code-sections {
    @include margin-top(72px);

    .c-list-box {
        &__item {
            &__label {
                min-width: rem(160px);
            }

            &__action {
                white-space: nowrap;
            }
        }
    }

    &__select-container {
        .c-form-field {
            display: flex;
            width: 100%;
        }

        &__controls {
            display: flex;
            flex-direction: row;

            .c-select {
                @include margin-right(16px);
                @include margin-top(6px);
                max-width: 25%;

                select {
                    border-color: get-color-neutral("30");
                }
            }

            .c-typeahead {
                flex-grow: 1;

                .c-multi-select__input {
                    width: 100%;
                }
            }

            .c-button {
                @include margin-top(6px);
                margin-bottom: auto;
            }
        }
    }
}
