%diff-panel {
    @include margin(0, 16px);
    @include box-shadow(0px, 3px, 10px, get-color-neutral("30"));
    border-radius: $border-radius-medium;
    position: absolute;
    left: 100%;
    width: 100%;
    top: -4.25rem;

    &.-is-table {
        top: 0;
    }

    &.-part {
        top: -3.25rem;
    }
}

%diff-panel-container {
    $change-summary-dropdown-menu: 9.625rem;
    display: block;
    max-height: calc(85vh - #{$change-summary-dropdown-menu});
    display: flex;
    flex-direction: column;
}

%diff-panel-contents {
    overflow-y: auto;
}

%diff-panel-title {
    font-size: rem(40px);
}

%diff-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include padding(10px);
    background-color: get-color-status("warning-light");
    color: get-color-status("warning-dark");
    border-top-left-radius: $border-radius-medium;
    border-top-right-radius: $border-radius-medium;
}

%diff-panel-heading {
    @include font-size(14px);
    @include padding(4px);
    font-weight: map-get($font-weights, "bold");
    line-height: 1;
}

%diff-panel-subheading {
    font-style: italic;

    @include font-style(
        $family: $font-primary,
        $size: "small",
        $color: get-color-status("warning-dark"),
        $weight: "base"
    );
}

%diff-panel-close-icon {
    background-color: transparent;
    color: get-color-status("warning-dark");
    transition: transform 0.2s ease;

    &:hover {
        transform: scale(1.1);
    }
}

%diff-panel-nested-content {
    .c-sub-section-detail__title {
        @include padding-top(8px);
    }
    .c-sub-sub-section-detail__title {
        display: inline-flex;
        align-items: center;
    }

    .c-table-section__notes {
        display: none;
    }

    .c-bookmark-slider {
        display: none;
    }

    .-deletion {
        display: revert;

        span.c-xml-label {
            width: revert;
            white-space: nowrap;
            @include margin-right(4px);
        }
    }

    .c-code-change.-deletion,
    .c-publication-anchor.c-code-change.-deletion {
        display: revert;
    }

    .-addition {
        display: none;
    }

    .-change-indicator {
        display: none;
    }
    @include respond-to("phone") {
        @include padding-bottom(48px);
    }
}
