.c-copy-text-button {
    &__button {
        @include font-style(
            $font-primary,
            "base",
            get-color-neutral("90"),
            400,
            get-line-height("base")
        );
        display: flex;
        align-items: center;
        gap: 0.5rem;
        background-color: transparent;
    }

    &__icon {
        color: get-color-neutral("70");
    }
}
