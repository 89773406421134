.c-data-table {
    width: 100%;
    border-collapse: collapse;

    thead {
        tr {
            th {
                @include font-style(
                    $size: "xsmall",
                    $line-height: "xsmall",
                    $weight: "bold",
                    $color: get-color-neutral("90")
                );
                @include padding(24px);
                @include padding-bottom(4px);
                text-align: left;
            }
            border-bottom: 1px solid get-color-neutral("50");
        }
    }

    tbody {
        tr {
            td {
                @include font-style(
                    $size: "xsmall",
                    $line-height: "xsmall",
                    $color: get-color-neutral("90")
                );
                @include padding(24px);
                text-align: left;

                &.c-cell-alt {
                    color: get-color-neutral("70");
                }
            }
            border-bottom: 1px solid get-color-neutral("50");
        }
    }
}
