.c-not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    .c-alert-notice {
        @include margin-top(48px);

        @include respond-to("phone") {
            @include margin-top(16px);
        }
    }
}
