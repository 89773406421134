.c-publication-page-layout {
    @extend .o-grid__row;
    height: 100%;
    width: 100%;
    flex-wrap: nowrap;
    --tia-button-position: 1rem;
    $padding-left: rem(48px);

    @include respond-to("tablet") {
        display: block;
    }

    &__metadata {
        $m: &;
        height: 100%;
        overflow-y: auto;
        position: relative;
        width: $metanav-width;
        min-width: $metanav-width;
        z-index: 1;
        transition: all 200ms ease-in-out;

        &.-collapse {
            width: 10px;
            min-width: 10px;
            overflow: hidden;

            & + .c-publication-page-layout__content {
                width: 100%;
                transition: all 200ms ease-in-out;
            }
        }

        &__left {
            @include padding(16);
            @include padding-bottom(48px);
            width: 100%;
            background-color: get-color-background("neutral");
            min-height: 100vh;

            @include respond-to("tablet") {
                @include fixedViewHeightScrollY();
                width: $metanav-width;
            }

            @include respond-to("phone") {
                width: 100%;
                overflow-y: initial;
            }
        }

        &__toggle {
            position: absolute;
            top: 0;
            left: $sidebar-plus-metanav-width;
            transform: translate(-4px, 1.75rem);
            padding: 0.5rem;
            z-index: $publication-metanav-toggle-z-index;
            transition: all 200ms ease-in-out;

            &.-collapse {
                left: $sidebar-menu-width;
                transform: translate(4px, 1.75rem);
            }
        }

        &__right {
            display: none;

            &__close-button {
                position: fixed;
                padding: 8px;
                background-color: get-color-background("secondary");

                &:hover {
                    cursor: pointer;
                }

                @include respond-to("tablet") {
                    max-height: 40px;
                    background-color: get-color-neutral("white");
                }

                > button {
                    border: none;

                    &:hover {
                        cursor: pointer;
                    }

                    @include respond-to("tablet") {
                        max-height: 24px;
                        background-color: get-color-neutral("white");
                    }

                    @include respond-to("phone") {
                        background-color: get-color-background("primary");
                        max-height: 40px;
                    }
                }
            }

            @include respond-to("tablet") {
                width: calc(100% - #{$metanav-width});
                background-color: rgba(get-color-background("dark"), 0.75);
                display: block;
            }

            @include respond-to("phone") {
                background-color: get-color-background("neutral");
                width: 40px;
            }
        }

        @include respond-to("tablet") {
            display: none;

            &.-open {
                width: calc(100% - #{$sidebar-menu-width});
                display: flex;
                position: absolute;
                z-index: $publication-metanav-z-index;
            }
        }

        @include respond-to("phone") {
            &.-open {
                position: fixed;
                height: calc(100% - #{$sidebar-menu-width});
                width: 100%;
                padding: 0;
                background-color: get-color-background("neutral");
                z-index: $publication-metanav-z-index;
                top: $sidebar-menu-width;
            }
        }

        > .c-button {
            @include margin-bottom(64px);
        }
        > a:not(.c-button) {
            font-size: rem(18px);
            font-weight: 100;
        }
    }

    &__content {
        height: 100%;
        width: calc(100% - #{$metanav-width});
        overflow: hidden;

        @include respond-to("tablet") {
            width: 100%;
            max-width: initial;
        }

        &__body {
            @include paragraph-styles();
        }

        &__header {
            position: relative;
            display: flex;
            align-items: center;

            @include respond-to("tablet") {
                margin-top: 0;
            }

            .-highlight-section {
                border-color: get-color-status("warning");
                border-style: solid;
                border-radius: 10px;
                border-width: thin;
                scroll-margin-top: 76px;
            }
            .c-publication-component-actions {
                visibility: hidden;
            }

            &.-article,
            &.-chapter,
            &.-annex {
                --tia-indicator-position-top: 2rem;
            }

            &__main {
                width: 100%;
                border: 1px solid transparent;
                padding: 0.5rem;
                position: relative;
                &.-hover:hover {
                    .c-publication-component-actions {
                        visibility: visible;
                    }

                    @include border-radius("base");
                    border: 1px dashed get-color-accents("blue-base");
                }
                &__title {
                    display: flex;
                    align-items: center;
                }
            }
        }

        &__table-of-contents {
            width: 100%;
            max-width: 730px;
            padding: 0.5rem;
        }
        &__main {
            @include padding(32, 0);
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            overflow-y: auto;

            &.-show-changes {
                align-items: flex-start;
                @include padding-left(64);
            }

            @include respond-to("desktop") {
                @include padding(32, 48);
            }
            @include respond-to("phone") {
                @include padding(16, 16, 16, 42);
                margin-top: 0;
            }
            .content-panel-container {
                width: 100%;
                max-width: $section-max-width;
                &.-expanded {
                    --bookmark-card-position-right: 0;
                    max-width: $section-max-width-expanded;
                }
                display: flex;
                justify-content: center;

                .changes-panel-wrapper {
                    height: 100%;
                    width: 50%;
                    max-width: calc(#{$section-max-width} + #{$padding-left});
                }
            }
            &__content-wrapper {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;
                @include padding-left(16);

                &.-show-changes {
                    align-items: end;
                    width: 50%;
                    padding-left: $padding-left;
                    @include padding-top(80);
                    max-width: calc(
                        #{$section-max-width-expanded} + #{$padding-left}
                    );
                }

                &.-highlight-content {
                    border: 1px solid get-color-status("warning");
                    border-radius: 10px;
                    padding-top: 0;
                }

                > section,
                .c-origins-section {
                    width: 100%;
                    flex-shrink: 0;
                }

                > section > .c-table-of-contents-section {
                    @include margin-top(26px);
                }

                > hr {
                    width: 100%;
                }
            }

            > section,
            .c-origins-section {
                max-width: $section-max-width;
                &.-expanded {
                    max-width: $section-max-width-expanded;
                }
                padding-left: 70px;
                width: 100%;
                flex-shrink: 0;
            }

            .c-translations-section {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p {
                    margin-bottom: 0;
                    text-align: center;
                    max-width: 1000px;
                }

                // to add spacing between the sections
                b {
                    display: inline-block;
                    margin-top: 2rem;
                }

                &__logo {
                    margin-top: 1rem;
                    height: 10rem;
                }
            }

            &.-virtualized {
                padding: 0;
                .c-publication-page-layout__content__header {
                    @include padding(32, 0);
                    margin: 0 auto;

                    @include respond-to("desktop") {
                        @include padding(32, 16);
                    }
                    @include respond-to("phone") {
                        @include padding(32);
                        margin-top: 0;
                    }

                    &.c-section-wrapper.-virtualized {
                        > div {
                            position: relative;
                        }
                    }
                }
            }
        }

        &__title {
            @extend %h3;
            @include padding(0, 16px);
            padding-left: 0;
            color: get-color-neutral("90");
            border: 1px solid transparent;
        }

        &__title-header {
            font-size: 3rem;
        }

        article {
            @include margin-bottom(16px);
            position: relative;

            + .c-exception-section {
                @include margin-top(24px);
            }

            sup,
            sub {
                vertical-align: baseline;
                position: relative;
                top: -0.4em;
            }
            sub {
                top: 0.4em;
            }
        }

        article:last-of-type {
            @include margin-bottom(0);
        }
    }

    &__errors {
        color: get-color-neutral("darker");
    }

    &__loading {
        color: get-color-neutral("darker");
    }
    &__more-menu {
        display: none;
        @include respond-to("tablet") {
            display: block;
        }
    }

    &.-reference-panel-open {
        .c-publication-page-layout__content {
            width: calc(100% - #{$reference-panel-width});

            &__main {
                @include padding-left(48px);
                @include padding-right(4px);

                @include respond-to-min-width("desktop") {
                    @include padding-right(32px);
                }

                @include respond-to-min-width("ultrawide") {
                    @include padding-left(32px);
                    @include padding-right(0);
                }
            }

            @include respond-to("tablet") {
                width: 50%;
            }

            @include respond-to("phone") {
                width: 100%;
            }
        }
    }

    &.-section-page {
        .c-section-wrapper {
            padding-top: 0;
        }
    }
}
