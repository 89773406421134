.c-video-card {
    &__button {
        @include margin-bottom(16px);
        border: 1px solid get-color-neutral("30");
        background: get-color-neutral("white");
        border-radius: $border-radius-medium;
        text-decoration: none;
        word-break: break-word;
        width: 100%;
        display: flex;
        flex-direction: column;

        &:focus {
            @include box-shadow(
                0px,
                0px,
                5px,
                get-color-accents("blue-dark"),
                1px
            );
            outline: none;
        }
        &:hover {
            @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));
            background: get-color-neutral("white");
        }

        &__thumbnail {
            width: 100%;
            height: rem(160px);
            overflow: hidden;
            border-radius: $border-radius-medium $border-radius-medium 0 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        &__icon {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            background-color: get-color-accents("blue-light");
            position: relative;
            align-self: flex-start;
            flex-shrink: 0;

            .c-icon {
                @include padding-left(2px);
                height: auto;
                width: auto;
            }

            svg {
                @include icon-fill(get-color-accents("blue-dark"));
                position: absolute;
                right: 50%;
                top: 50%;
                transform: translateX(50%) translateY(-50%);
            }
        }

        &__footer {
            @include padding(16px);
            text-align: left;
            display: flex;
            align-items: center;
            &__content {
                @include padding-left(12px);
                @include padding-right(12px);
                display: flex;
                flex-direction: column;
            }

            &__label {
                p {
                    @include margin-top(8px);
                    color: get-color-neutral("70");
                    display: block;
                    letter-spacing: 0.02em;

                    @include if-internet-explorer {
                        max-width: rem(300px);
                    }
                }
            }
        }
    }
}
