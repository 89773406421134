.c-subscription-detail-card {
    @include padding(24px, 32px, 40px);
    border: rem(1px) solid get-color-neutral("30");
    border-radius: $border-radius-small;
    max-width: rem(480px);
    width: 50%;

    @include respond-to("laptop") {
        @include padding-bottom(32px);
        width: 100%;
    }

    &__admins,
    &__type {
        @include margin(16px, 0, 24px, 0);
        @include padding(16px);
        display: inline-block;
        min-width: 100%;
        border: rem(1px) solid get-color-accents("blue-base");
        border-radius: $border-radius-small;

        @include respond-to("sm-tablet") {
            display: block;
            min-width: 0;
        }

        &__icon {
            display: flex;
            align-items: center;

            .c-icon {
                @include margin-right(8px);
            }

            span {
                @include font-style(
                    $font-primary,
                    "small",
                    get-color-accents("blue-dark"),
                    get-font-weight("bold"),
                    get-line-height("small")
                );
            }
        }
    }

    &__admins {
        @include margin-top(0);

        &__svg {
            color: get-color-accents("blue-dark");
        }

        &__title {
            align-self: flex-start;
        }

        &__entry {
            @include margin-top(10px);
        }

        &__name {
            @include font-style(
                $size: "small",
                $weight: "bold",
                $color: get-color-neutral("70")
            );
        }
        &__email {
            @include font-style($size: "small", $line-height: "base");
        }
    }

    &__content {
        @include padding(24px);
        background-color: get-color-status("warning-light");
        border-radius: $border-radius-small;
        p {
            color: get-color-status("warning-dark");
            &.-small {
                @include margin-top(16px);
                a {
                    text-decoration: none;
                    color: inherit;
                    font-weight: get-font-weight("bold");
                }
            }
        }

        a.-upgrade {
            color: get-color-accents("blue-dark");
            font-size: inherit;
            font-weight: get-font-weight("bold");
        }
    }

    &__modify-subscription {
        .c-button {
            @include margin-right(8px);
        }
    }

    &.-free-trial {
        .c-alert-banner.c-alert-banner__warning {
            @include font-style(
                $font-primary,
                "small",
                get-color-status("warning-dark"),
                get-font-weight("base"),
                get-line-height("large")
            );
            @include padding(24px);

            svg {
                display: none;
            }
        }
    }
}
