.c-privacy-banner {
    @include font-style($size: "small", $line-height: "small");
    background-color: get-color-accents("blue-dark");
    color: get-color-neutral("white");

    @include respond-to("phone") {
        flex-direction: column;
        align-items: flex-start;
    }

    a {
        color: inherit;
        font-weight: get-font-weight("bold");
    }

    .c-button {
        @include margin-left(24px);
        flex-shrink: 0;

        @include respond-to("phone") {
            @include margin-top(16px);
            margin-left: 0;
        }
    }
}
