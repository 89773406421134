.c-expandable-rich-text-area {
    @include padding-bottom(16px);
    @include padding-right(52px);
    overflow-y: hidden;
    position: relative;

    &.-expanded {
        max-height: rem(99999px);
    }

    &:not(.-expanded) {
        > * {
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .c-button.-expand-collapse {
        position: absolute;
        bottom: 4px;
        right: 4px;
    }
}
