.c-new-user-form {
    &__password {
        @include margin-bottom(20px);
        .c-form-field {
            @include margin-bottom(0);

            &.-invalid p {
                display: none;
            }
        }

        .c-form-field.-invalid + p {
            @include margin-top(8px);
            color: get-color-primary("error");
        }

        p {
            @include margin-top(4px);
            font-style: italic;
            color: get-color-neutral("70");
        }
    }
    &__captcha-errors {
        @include margin-top(8px);
        @include margin-right(8px);
        @include margin-bottom(20px);
        > p {
            color: get-color-status("error");
            font-style: italic;
        }
    }

    .c-free-trial-terms {
        @include margin-bottom(32px);
    }
}
