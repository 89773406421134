.c-dashboard {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background-color: get-color-background("neutral");

    &.-breadcrumb {
        height: calc(100% - #{$breadcrumb-menu-height});
    }

    &__content {
        display: flex;

        @include respond-to("lg-laptop") {
            flex-direction: column;
        }
    }

    &__publications {
        &__additional-publications {
            @include margin-bottom(20px);

            > label {
                @include font-style(
                    $size: "xxsmall",
                    $line-height: "xxsmall",
                    $weight: "bold",
                    $color: get-color-neutral("70")
                );
                @include margin-bottom(16px);
                letter-spacing: 0.11rem;
                text-transform: uppercase;
                display: block;
            }

            &__cards {
                display: flex;
                flex-wrap: wrap;

                .c-publication-card {
                    @include margin(0, 20px, 20px, 0);
                    width: 100%;
                    max-width: rem(280px);

                    @include respond-to("phone") {
                        max-width: unset;
                    }
                }
            }
        }
    }

    &__panel {
        flex-basis: auto;
        flex-wrap: nowrap;
        outline: none;

        @include respond-to("lg-laptop") {
            flex-basis: unset;
            flex-wrap: wrap;
        }

        &.-left {
            @include padding(32px, 48px, 0, 48px);
            flex-basis: 100%;

            @include respond-to("sm-desktop") {
                flex-basis: auto;
            }
            @include respond-to("phone") {
                @include padding(32px, 24px, 0, 24px);
            }

            &__title {
                @include margin-bottom(24px);
                display: flex;
                align-items: center;

                .c-icon {
                    @include icon-fill(get-color-neutral("90"));
                    @include margin-right(16px);
                }
            }
        }

        &.-right {
            @include padding(32px, 48px, 48px);
            background: get-color-neutral("white");
            min-width: rem(490px);
            min-height: 100vh;

            @include respond-to("lg-laptop") {
                margin-left: unset;
                min-width: auto;
                flex-basis: 100%;
            }

            @include respond-to("phone") {
                @include padding(32px, 24px, 48px);
            }

            .c-recent-bookmarks-list {
                @include margin-bottom(56px);
            }

            &__getting-started {
                @include margin-top(48px);
                p.-title {
                    @include margin-bottom(24px);
                }
            }
        }
    }
}
