.c-codes-modal {
    min-width: rem(480px);
    width: rem(600px);
    display: flex;
    flex-direction: column;

    &__header,
    &__footer {
        @include padding(20px, 20px);
    }

    &__body {
        @include padding(20px, 20px);

        @include respond-to("phone") {
            // Reduced width padding in relation to header/footer to account for change indicators
            @include padding(20px, 8px);
        }

        .c-section-content-preview__body {
            @include padding(20px, 32px);
        }
    }

    &__header {
        @include padding(16px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: get-color-background("neutral");
        width: 100%;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                get-line-height("large")
            );
            display: inline-block;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__body {
        overflow-y: auto;
        max-height: calc(100vh - 300px);
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        border-top: 1px solid get-color-neutral("30");
        z-index: 1;
        background: get-color-neutral("white");

        &.-with-reference-button {
            justify-content: space-between;

            > .c-button {
                @include icon-fill(get-color-neutral("70"));
                @include padding-left(12px);
                @include padding-right(12px);

                &.-icon-left {
                    @include padding-top(7px);
                    @include padding-bottom(7px);
                }

                svg {
                    @include margin-right(8px);
                }
            }
        }
    }

    .c-button.-medium.-tertiary.-modal-close.-icon {
        background: transparent;
        top: rem(20px);
        right: rem(20px);
    }
}

.c-modal {
    &.c-codes-modal {
        &.-fullscreen {
            min-width: unset;
            width: 100%;
            max-width: 100%;
            margin: 0;
            border-radius: 0;
            padding: 0;

            & > .c-button {
                &.-modal-close {
                    display: none;
                }
            }

            & > .c-modal__content {
                max-height: #{vh()};
            }

            .c-codes-modal__body {
                max-height: calc(#{vh(100)} - 153px);
            }
        }
    }
}
