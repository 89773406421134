.c-search-modal-header {
    @include padding(16px, 40px, 0, 40px);
    border-bottom: 1px solid get-color-neutral("30");

    @include respond-to("phone") {
        @include padding(16px, 16px, 0, 16px);

        .c-search-form {
            @include margin-right(40px);
        }
    }
    &__tabs-container {
        @include margin-top(24px);
        display: flex;
    }
    &__heading {
        @include font-style(
            $size: get-font-size("large"),
            $color: get-color-neutral("90"),
            $line-height: 27px
        );
        @include margin-bottom(16px);

        .c-select {
            @include margin-left(16px);
        }

        @include respond-to("phone") {
            display: none;
        }
    }
    &__tab {
        @include font-style(
            $size: get-font-size("xsmall"),
            $color: get-color-neutral("70"),
            $weight: "bold",
            $line-height: get-line-height("xsmall")
        );
        @include padding(8px);
        @include padding-bottom(12px);
        @include margin-right(8px);
        background: none;
        position: relative;
        box-shadow: none;

        &:hover {
            color: get-color-accents("blue-dark");
            background: none;
            box-shadow: none;
        }

        .-active-border {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 4px solid get-color-accents("blue-base");
            display: none;
        }

        &.-active {
            color: get-color-accents("blue-dark");
            outline: none;
            box-shadow: none;
            opacity: 1;

            .-active-border {
                display: block;
            }
        }

        &:focus {
            background-color: get-color-background("neutral");
        }

        &:disabled {
            background: none;
        }
    }

    .c-search-form__clear:after {
        border-right: none;
    }
}
