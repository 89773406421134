.c-announcement-editor-footer {
    padding: rem(16px);
    height: rem($admin-footer-height);
    border-top: 1px solid get-color-neutral("30");
    background: get-color-neutral("white");
    display: flex;
    align-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &__left {
        display: flex;
        align-items: center;
        width: 100%;
        > .c-button {
            margin-right: rem(16px);
        }

        &__status-text {
            p {
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall")
                );
                text-align: left;

                &.-bold {
                    font-weight: get-font-weight("bold");
                }
            }
        }
    }

    &__right {
        @include margin-left(16px);
        text-align: right;
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-shrink: 0;

        .c-announcement-publish-status-menu {
            margin-left: auto;
        }
    }
}
