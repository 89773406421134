.c-admin-related-solutions-section {
    .c-list-box {
        &__item {
            &__text {
                @include padding-right(16px);

                a {
                    &:hover {
                        text-decoration: underline;
                    }

                    p {
                        @include ellipsis(block);
                    }
                }

                label {
                    @include ellipsis(block);
                    @include font-size("xsmall");
                    @include line-height("xsmall");
                    color: get-color-neutral("70");
                }
            }

            &__action {
                white-space: nowrap;

                @include if-internet-explorer {
                    @include margin-top(8px);
                    @include margin-bottom(8px);
                    min-width: rem(68px);
                }
            }
        }
    }

    &__select-container {
        &__option {
            &__title {
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall"),
                    $color: get-color-neutral("90")
                );
                @include ellipsis(block);
            }

            &__subtitle {
                @include font-style(
                    $size: "xxsmall",
                    $line-height: get-line-height("xxsmall"),
                    $color: get-color-neutral("70")
                );
                @include ellipsis(block);
            }
        }

        .c-form-field {
            display: flex;
            width: 100%;
        }

        .c-multi-select {
            &__input {
                flex-grow: 1;
            }

            .c-button {
                @include margin-left(16px);
                margin-top: auto;

                @include if-internet-explorer {
                    margin-top: rem(6px);
                }
            }
        }
    }
}
