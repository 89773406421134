.c-admin-editor-layout {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    .c-form {
        @include padding-bottom(48px);
    }

    &__content {
        padding: rem(48px);
        margin-bottom: $admin-footer-height;
        flex-grow: 1;
        overflow-y: auto;
        // in flexbox, this is essentially a min-height;
        // it will grow as much as possible to fill the area available
        height: 1px;

        &__heading {
            margin-bottom: rem(24px);
        }

        &__tags {
            @include margin-top(60px);

            p.-no-help-text {
                @include margin-top(40px);
                @include margin-bottom(16px);
            }

            &__help-text {
                @include margin-top(8px);
                @include margin-bottom(16px);
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall")
                );
                color: get-color-status("info-dark") !important;

                @include icon-fill(get-color-status("info"));
                svg {
                    @include margin-right(8px);
                    transform: translateY(#{rem(2px)});
                }
            }
        }

        .c-admin-situation-solutions-section,
        .c-admin-related-solutions-section,
        .c-admin-resources-section {
            @include margin-top(72px);
        }

        .c-solution-code-sections,
        .c-admin-related-solutions-section,
        .c-admin-situation-solutions-section {
            &__select-container {
                > p {
                    @include margin-bottom(18px);
                }
            }
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: rem(16px);
        height: rem($admin-footer-height);
        border-top: 1px solid get-color-neutral("30");
        background: get-color-neutral("white");
        display: flex;
        align-content: space-between;

        &__left {
            a.c-button {
                margin-right: rem(16px);
            }
        }

        &__right {
            @include margin-left(16px);
            text-align: right;
            flex-grow: 1;
        }
    }
}
