.c-admin-resources-section {
    &__heading {
        @include margin-bottom(18px);
    }

    &__resources {
        @include margin-top(16px);
        @include margin-bottom(16px);
        min-height: 75px;

        .c-list-box {
            &__item {
                &__label {
                    @include padding-right(16px);
                    white-space: normal;
                    word-break: keep-all;

                    @include respond-to("tablet") {
                        width: 16%;
                        min-width: 16%;
                    }
                }

                &__text {
                    padding-top: 0;
                    padding-bottom: 0;

                    &__container {
                        @include padding-right(16px);

                        a {
                            @include ellipsis(inline-block);
                            @include font-style(
                                $size: "small",
                                $line-height: rem(21px)
                            );
                            margin-bottom: 0;

                            &:hover,
                            &:focus {
                                text-decoration: underline;
                            }
                        }

                        label {
                            @include ellipsis(inline-block);
                            font-size: get-font-size("xsmall");
                            color: get-color-neutral("70");
                        }
                    }
                }

                &__custom-actions {
                    white-space: nowrap;

                    .c-button {
                        @include margin-top(8px);

                        &:first-child {
                            @include margin-right(8px);
                        }
                    }
                }
            }
        }
    }

    &__dropdown-btn {
        .c-icon {
            @include margin-left(8px);
            vertical-align: middle;
        }
    }
}
