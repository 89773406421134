.c-message-modal {
    padding-bottom: 0;
    width: rem(600px);
    min-width: rem(600px);
    overflow: hidden;

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: get-color-background("neutral");
        width: 100%;

        > h2 {
            @include font-style(
                $size: get-font-size("large"),
                $color: get-color-neutral("90"),
                $line-height: get-line-height("base")
            );
            display: inline-block;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__container {
        @include padding(24px);

        &.-condensed-bottom {
            padding-bottom: 0;
        }

        &.-condensed {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &__content {
        // height set to match other modals in app
        max-height: 60vh;
        overflow-y: auto;

        .-hidden {
            display: none;
        }

        &__name {
            display: flex;
            justify-content: space-between;

            .c-form-field {
                flex-basis: 49%;
            }
        }

        textarea {
            resize: vertical;
        }

        p {
            @include margin-bottom(24px);
        }
    }
    &__footer {
        @include margin-top(12px);
        display: flex;
        width: 100%;
        border-top: 1px solid get-color-neutral("30");
        justify-content: flex-end;

        .c-button {
            @include margin-left(20px);
        }
    }
}

.c-modal.c-message-modal {
    @include respond-to("sm-tablet") {
        @include fill-viewport-height();
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: initial;
    }

    @include respond-to("phone") {
        .c-modal__content {
            display: flex;
            flex-direction: column;
        }

        &__content {
            max-height: calc(#{vh(100)} - #{$mobile-modal-header-height});

            &__name {
                flex-direction: column;

                .c-form-field {
                    flex-basis: 100%;
                }
            }
        }
    }
}
