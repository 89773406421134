.c-equation {
    @include margin-top(8px);
    @include margin-bottom(24px);
    background-color: transparent;
    cursor: pointer;
    width: 100%;

    &,
    &__modal {
        .c-equation-number {
            display: flex;
            justify-content: flex-end;
        }

        img {
            display: flex;
            margin: 0 auto;
        }
    }

    @include respond-to("phone") {
        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__modal {
        @include padding(16px);

        .c-modal {
            &__content {
                @include respond-to("phone") {
                    @include margin-top(40px);
                }
            }
        }
    }
}
