.c-alert-modal {
    @include padding(24px);
    width: rem(282px);
    &.c-modal {
        max-width: none;
    }
    &__header {
        @include margin-bottom(16px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                24px
            );
            display: inline-block;
            font-weight: 700;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }

        width: 100%;

        button.-modal-close {
            padding: 8px;

            svg {
                display: block;
            }
        }
    }
    &__content {
        @include margin-bottom(24px);
    }
    &__footer {
        text-align: center;
    }
}
