.c-menu-button {
    position: relative;

    $backdrop-z-index: 9999999;

    &__tooltip {
        &__title {
            @include font-style(
                $size: "small",
                $color: get-color-neutral("white"),
                $weight: "bold",
                $line-height: get-line-height("small")
            );
        }

        &__description {
            @include font-style(
                $size: "xsmall",
                $color: get-color-neutral("white"),
                $line-height: get-line-height("xsmall")
            );
        }
    }

    &__outside-click-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        z-index: $backdrop-z-index;
    }

    &.c-modal {
        @include respond-to("phone") {
            padding: 24px 32px;
            margin: 0;
            max-width: initial;
            width: 100%;
            max-width: 100%;
            border-radius: $border-radius-medium $border-radius-medium 0 0;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;

            .c-menu-button__item {
                @include margin-bottom(24px);

                &:focus-within {
                    background-color: transparent;

                    button {
                        outline: none;
                    }
                }
                button {
                    @include font-style(
                        $font-primary,
                        "base",
                        get-color-neutral("90"),
                        400,
                        21px
                    );
                    background-color: transparent;
                    padding-left: 0;

                    &.-active {
                        background-color: get-color-accents("blue-light");
                    }
                }
            }
        }
    }

    &__icon {
        padding: 8px;

        svg {
            display: block;
        }

        &.-offline {
            cursor: not-allowed;
        }

        &:disabled {
            cursor: not-allowed;
        }

        &.-tertiary {
            background-color: get-color-buttons("tertiary");
            border: 1px solid get-color-buttons("tertiary");
            color: get-color-neutral("90");
        }
    }

    &__label-or-text {
        @include font-style(
            $color: get-color-neutral("90"),
            $size: "xxsmall",
            $weight: "bold"
        );
        @include margin-right(6px);
    }

    &__menu {
        @include box-shadow(0, 3px, 10px, rgba(0, 26, 57, 0.2));
        position: absolute;
        background: #ffffff;
        border-radius: $border-radius-medium;
        border: 1px solid get-color-neutral("30");
        right: 0;
        top: 40px;
        z-index: #{$backdrop-z-index + 1};
        cursor: pointer;

        // @include respond-to-min-width("ultrawide") {
        //     right: -24px;
        // }

        @include respond-to(phone) {
            right: 5px;
        }
    }
    &__item {
        display: block;
        cursor: pointer;

        &:first-child {
            border-top-left-radius: $border-radius-medium;
            border-top-right-radius: $border-radius-medium;

            > button {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:last-child {
            border-bottom-left-radius: $border-radius-medium;
            border-bottom-right-radius: $border-radius-medium;

            > button {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &:hover {
            background-color: get-color-neutral("05");

            a,
            button {
                text-decoration: none;
                color: get-color-neutral("90");
            }
        }

        &:focus-within:not(:hover) {
            background-color: get-color-neutral("05");

            a,
            button {
                text-decoration: none;
                color: get-color-neutral("90");
            }
        }

        a,
        button {
            @include padding(8, 16);
            @include font-style(
                $font-primary,
                "xsmall",
                get-color-neutral("70"),
                400,
                22px
            );
            text-decoration: none;
            width: 100%;
            text-align: left;
            white-space: nowrap;
            background: none;

            &:hover {
                background: none;
            }
        }

        button {
            @extend %a;
            background-color: none;

            &.-active {
                border-left: 4px solid get-color-accents("blue-base");
                background-color: get-color-accents("blue-light");
                font-weight: 700;
            }

            &.c-button:disabled,
            &:disabled {
                background-color: inherit;
                color: get-color-neutral("50");
            }

            &:hover {
                background-color: none;
            }
        }
    }

    button {
        background: none;
    }
}
