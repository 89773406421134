.c-bookmark-settings {
    @include border-radius("base");
    @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));
    @include font-style(
        $size: "xsmall",
        $line-height: get-line-height("xsmall")
    );
    background: get-color-neutral("white");
    // z-index must be higher than 8 so there is no possibility of
    // the 8 possible bookmark icons being positioned over top of it.
    z-index: 10;
    width: rem(330px);

    &__header {
        @include padding(16px, 8px, 12px, 16px);
        display: flex;
        align-items: center;

        .c-label {
            text-transform: none;
            @include if-internet-explorer {
                max-width: rem(150px);
            }
        }

        &__actions {
            margin-left: auto;
            display: flex;
            align-self: flex-start;

            .c-button {
                &.-small {
                    @include padding(6px);
                    @include if-internet-explorer {
                        @include margin-top(-2px);
                    }
                }

                .c-icon {
                    @include icon-fill(get-color-neutral("70"));
                }
            }

            > .c-menu-button {
                z-index: 3;
            }
        }

        &__title {
            @include font-style(
                $font-primary,
                "xsmall",
                get-color-neutral("90"),
                400,
                18px
            );
            display: flex;
            align-items: center;

            @include if-internet-explorer {
                max-width: 80%;
            }

            svg {
                @include margin-right(8px);
                flex-shrink: 0;
                align-self: flex-start;
                path {
                    fill: get-color-neutral("50");
                }
            }

            @include respond-to("phone") {
                @include font-style($line-height: get-line-height("large"));
            }
        }

        @include respond-to("phone") {
            @include padding(16px, 32px);
        }
    }

    &__content {
        @include padding(0, 16px, 16px, 16px);
        padding-top: 0;

        .c-rich-text-editor {
            @include margin-bottom(16px);

            > div[class^="RichTextEditor"] {
                div[class^="EditorToolbar"] {
                    z-index: 1;
                }
            }

            label {
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall"),
                    $color: get-color-neutral("90")
                );

                @include respond-to("phone") {
                    font-size: get-font-size("small");
                }

                .-help-text {
                    color: get-color-neutral("70");
                }
            }

            .public-DraftEditorPlaceholder-inner {
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall")
                );
            }

            .public-DraftEditor-content {
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall")
                );
                min-height: unset;
                // Set the max-height of the 'content' field for the RichTextEditor so it does not expand
                // the height of this settings component as additional content is added
                max-height: 250px;

                @include respond-to("phone") {
                    font-size: get-font-size("base");
                }
            }
        }

        .c-bookmark-color-select {
            label {
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall")
                );
            }
        }
    }

    &__footer {
        @include padding(0, 16px, 16px, 16px);
        display: flex;

        &__actions {
            margin-left: auto;

            &__delete-button.c-button.-tertiary {
                @include icon-fill(get-color-neutral("70"));
                @include padding-left(6px);
                @include padding-right(6px);
            }

            .c-button:not(:first-of-type) {
                @include margin-left(8px);
            }

            @include respond-to("phone") {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
        }

        @include respond-to("phone") {
            @include padding(24px);
            border-top: 1px solid get-color-neutral("30");
        }
    }

    &__error {
        @include margin-top(10px);
        font-style: italic;

        p {
            color: get-color-status("error");

            svg {
                @include margin-right(4px);
                vertical-align: sub;

                path {
                    fill: get-color-status("error");
                }
            }
        }
    }
}
