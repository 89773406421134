.c-page-navigation-menu {
    @include respond-to("phone") {
        @include padding(4.8px, 40px);
    }
    @include padding(12px, 40px);
    display: flex;
    width: 100%;
    background-color: #ffffff;

    .c-page-navigation {
        &__prev,
        &__next {
            margin: 0;
        }

        &__main {
            .c-button {
                &:first-of-type {
                    @include margin-left(8px);
                }

                &:last-of-type {
                    @include margin-right(8px);
                }
            }
        }

        @include respond-to("tablet") {
            .c-page-navigation__prev {
                @include margin-right(4px);
            }
        }
    }

    &__max-items {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        span {
            @include font-style(
                $size: "xsmall",
                $color: get-color-neutral("70"),
                $line-height: "xsmall"
            );
        }

        .c-select {
            @include margin-left(16px);
        }

        @include respond-to("phone") {
            flex-direction: column;
            align-items: start;

            .c-select {
                @include margin-top(2px);
                margin-left: 0;

                select {
                    @include font-style($size: "base", $line-height: "base");
                }
            }
        }
    }

    &__page-actions {
        display: flex;
        align-items: center;
        margin-left: auto;

        @include respond-to("phone") {
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &__pages {
        > span {
            @include margin-right(16px);
            @include font-style(
                $size: "xsmall",
                $color: get-color-neutral("70"),
                $line-height: "xsmall"
            );

            @include respond-to("phone") {
                margin-right: 0;
            }
            width: 100%;
            letter-spacing: 0.02em;
            text-align: right;
        }
    }
}
