.c-landing-page-faq {
    &__heading {
        width: fit-content;
        margin: auto;
        padding-bottom: rem(36px);
        text-align: center;
    }

    &__additional {
        margin-top: rem(46px);
        @include font-style($color: get-color-neutral("70"));
    }
}
