.c-featured-publications-admin-page {
    height: calc(100% - #{$breadcrumb-menu-height});
    @include padding-top($breadcrumb-menu-height);
    display: flex;
    flex-direction: column;

    p,
    label,
    h5 {
        color: get-color-neutral("90");
    }

    &__header {
        @include padding-bottom(4px);
        border-bottom: 1px solid get-color-neutral("30");

        > h5 {
            @include padding-top(6px);
            @include padding-left(48px);
            @include padding-bottom(16px);
        }
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        $gutter-size: 32px;

        &__controls {
            @include margin-left($gutter-size);
            @include margin-right($gutter-size);
            @include padding-bottom($gutter-size);
            @include margin-top(16px);
            text-align: left;
        }

        &__items {
            @include padding-top($gutter-size);
            @include padding-left($gutter-size);
            @include padding-right($gutter-size);

            &__item {
                &__label {
                    @include border-radius();
                    width: rem(50px);
                    height: rem(65px);

                    img {
                        height: 100%;
                        width: auto;
                    }
                }

                &__text {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    max-width: 400px;

                    &__top {
                        flex-grow: 1;
                        p {
                            font-weight: get-font-weight("bold");
                        }
                    }

                    &__status-badge {
                        @include border-radius("huge");
                        @include padding(2px, 6px);
                        @include font-style($size: "xsmall", $weight: "bold");
                        align-self: flex-start;

                        display: inline-block;
                        flex-grow: 0;
                        border: 1px solid get-color-neutral("30");

                        &.-available-now {
                            background-color: get-color-status("success-light");
                            color: get-color-status("success-dark");
                        }

                        &.-coming-soon {
                            background-color: get-color-neutral("white");
                        }

                        &.-hidden {
                            background-color: get-color-status("error-light");
                            color: get-color-status("error");
                        }
                    }
                }

                &__actions {
                    button:first-child {
                        @include margin-right(8px);
                    }
                }
            }
        }
    }
}
