.c-login-layout {
    display: flex;
    min-height: 100%;
    overflow-y: auto;
    flex-direction: column;

    @include if-internet-explorer {
        height: 100%;
    }

    @include respond-to("sm-desktop") {
        height: auto;
    }

    &__panels {
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        flex: 1 1;
        flex-basis: auto;

        @include respond-to("sm-desktop") {
            flex-direction: column;
        }
    }

    &__panel {
        @include padding-top(104px);
        @include padding-bottom(104px);
        flex: 1;
        flex-basis: auto;

        @include respond-to("sm-desktop") {
            height: initial;
        }
        @include respond-to("phone") {
            @include padding-top(64px);
            @include padding-bottom(48px);
        }

        &__features {
            .c-subscription-card-feature {
                @include margin-bottom(16px);
                .c-icon {
                    @include margin-right(16px);
                }

                &:last-of-type {
                    @include margin-bottom(48px);
                }
            }
        }

        &.-left {
            flex-grow: 1;
            display: flex;
            padding-top: 0;
            flex-direction: column;
            justify-content: flex-start;
            background-color: get-color("background", "neutral");
        }

        &__form {
            position: relative;
            @include padding-top(104px);
            @include padding-left(80px);
            width: 100%;

            @include respond-to("phone") {
                @include padding-top(64px);
                @include padding-left(24px);
            }
        }

        &.-right {
            @include padding(104px, 105px, 104px, 80px);
            background-color: get-color("background", "dark");
            max-width: 564px;

            @include respond-to("sm-desktop") {
                @include padding-top(96px);
                @include padding-right(148px);
                max-width: 100%;
            }

            @include respond-to("tablet") {
                @include padding-right(80px);
            }

            @include respond-to("phone") {
                @include padding(48px, 24px, 48px, 24px);
            }

            h2 {
                @include font-style(
                    $font-primary,
                    "xsmall",
                    get-color-neutral("white"),
                    700,
                    18px
                );
                letter-spacing: 0.15em;

                & + p {
                    @include margin-top(24px);
                    @include margin-bottom(40px);
                    @include font-style(
                        $font-primary,
                        "xxlarge",
                        false,
                        300,
                        45px
                    );

                    @include respond-to("phone") {
                        @include font-size("xlarge");
                        @include margin-bottom(24px);
                        @include margin-top(16px);
                        @include padding-left(0);
                        @include rem-fallback(line-height, 34px);
                    }
                }
            }

            p {
                color: get-color-neutral("white");
                a {
                    color: get-color-neutral("white");
                }
            }

            svg path {
                fill: get-color-accents("yellow");
            }
        }
    }
}
