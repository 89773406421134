.c-filter-button {
    &__badge {
        @include margin-right(8px);
        vertical-align: middle;
        display: inline-block;
        width: rem(16px);
        height: rem(16px);
        border-radius: $border-radius-huge;
        background-color: get-color-accents("blue-dark");
        color: get-color-accents("blue-light");

        span {
            display: inline-block;
            margin-top: 50%;
            transform: translateY(-50%);
        }
    }

    &.-active {
        border: 1px solid get-color-accents("blue-dark");
        color: get-color-accents("blue-dark");
        background-color: get-color-accents("blue-light");
    }
}
