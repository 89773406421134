.c-show-more {
    max-height: 180px;
    padding-bottom: 32px;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        bottom: 0;
        width: 100%;
        height: 100px;
        position: absolute;
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 60%,
            get-color-neutral("white") 100%
        );
        pointer-events: none;
    }

    &.-expanded {
        max-height: none;
        overflow-y: auto;

        &:after {
            display: none;
        }
    }

    &__button-container {
        display: flex;
        justify-content: center;
    }
}
