.c-announcements-admin-page {
    padding-top: $breadcrumb-menu-height;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &__content {
        margin-bottom: rem($admin-footer-height);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        &__header {
            > h5 {
                @include margin-top(24px);
                @include padding-left(48px);
                @include padding-bottom(16px);
            }
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: rem(16px);
        height: rem($admin-footer-height);
        border-top: 1px solid get-color-neutral("30");
        background: get-color-neutral("white");
        display: flex;
        align-content: space-between;

        &__left {
            a.c-button {
                margin-right: rem(16px);
            }
        }

        &__right {
            @include margin-left(16px);
            text-align: right;
            flex-grow: 1;
        }
    }
}
