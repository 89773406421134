.c-featured-publications-grid {
    $grid-spacing: 16px;

    /**
     * local mixin since otherwise
     * we'd need to duplicate some margin
     * properties for specificity reasons.
     */
    @mixin configure-columns($num-cols) {
        @include grid(
            $cols: $num-cols,
            $gutter: $grid-spacing,
            $breakpoints: false
        );
        margin: 0;
    }

    @include respond-to-min-width("phone") {
        @include configure-columns(2);

        .c-featured-publication-card {
            &__image,
            &__text {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    @include respond-to-min-width("sm-tablet") {
        @include configure-columns(3);
    }

    @include respond-to-min-width("sm-desktop") {
        @include configure-columns(4);
    }

    @include respond-to-min-width("desktop") {
        @include configure-columns(6);
    }

    @include respond-to("phone") {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0;

        .c-featured-publication-card {
            display: flex;
            width: 100%;
        }
    }

    .c-featured-publication-card {
        @include margin-bottom(16px);
    }
}
