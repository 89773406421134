.c-sidebar-navigation {
    @include padding-top(8px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: get-color-background("dark");
    z-index: 2;
    grid-area: sidebar;

    .c-profile-menu {
        @include margin-bottom(16px);
        margin-top: auto;
    }

    @include respond-to("phone") {
        height: $sidebar-menu-width; // Switches to a top menu on mobile
        width: 100%;
        flex-direction: initial;

        @include if-internet-explorer {
            flex-direction: row;
        }

        .c-profile-menu {
            @include margin(0, 16px, 0, 0);
            margin-left: auto;
        }
    }

    &.-free-access {
        @include respond-to("phone") {
            position: static;
        }
    }

    &__logo {
        @include padding(8px);
        display: flex;
        justify-content: center;

        img {
            width: rem(40px);
            height: auto;

            @include respond-to("phone") {
                @include margin-left(8px);
            }
        }
    }

    > .c-button.-icon {
        width: $sidebar-menu-width;
        height: $sidebar-menu-width;
        border-radius: 0;

        @include respond-to("phone") {
            height: unset;
        }

        > .c-icon.-large {
            @include respond-to("phone") {
                margin-top: unset;
            }
        }
    }

    > .c-tooltip-nav-item {
        @include if-not-touchscreen {
            &:hover {
                background-color: get-color-buttons("primary-hover");
            }
        }
        width: $sidebar-menu-width;
        height: $sidebar-menu-width;
        border-radius: 0;
        text-align: center;
        .c-icon {
            margin-top: calc(50% - 6px);
            @include icon-fill(get-color-neutral("white"));

            &.-large {
                margin-top: calc(50% - 12px);
            }
        }
    }

    &__tooltip {
        &__title {
            @include font-style(
                $family: $font-primary,
                $size: "small",
                $color: get-color-neutral("white"),
                $weight: "bold",
                $line-height: get-line-height("small")
            );
        }

        &__description {
            @include font-style(
                $family: $font-primary,
                $size: "xsmall",
                $color: get-color-neutral("white"),
                $weight: "base",
                $line-height: get-line-height("xsmall")
            );
        }

        &__button {
            @include padding-top(8px);
            text-align: right;
        }
    }
}
