.c-notification-dot-icon {
    background: get-color-status("warning");
    border-radius: 100%;
    box-shadow: 0 0 0 0 rgba(255, 160, 0, 0.7);
    width: rem(6px);
    height: rem(6px);

    &.-pulse {
        animation: pulse 1.4s infinite cubic-bezier(0.17, 0.67, 0.92, 0.62);
        -webkit-animation: pulse 1.4s infinite
            cubic-bezier(0.17, 0.67, 0.92, 0.62);
        -moz-animation: pulse 1.4s infinite cubic-bezier(0.17, 0.67, 0.92, 0.62);
    }
}

@keyframes pulse {
    100% {
        box-shadow: 0 0 0 8px rgba(255, 160, 0, 0);
    }
}
