.c-radio {
    @include padding(16px);

    &:hover {
        cursor: pointer;
    }

    input {
        &:checked,
        &:not(:checked) {
            position: absolute;
            left: -9999px;
        }
        &:checked + label,
        &:not(:checked) + label {
            @include paragraph-styles();
            @include padding-right(40px);
            position: relative;
            display: inline-block;
            color: get-color-neutral("90");
            cursor: pointer;
            &:before {
                content: "";
                position: absolute;
                right: 0;
                top: 3px;
                width: 20px;
                height: 20px;
                background: #fff;
                border: 1px solid get-color-accents("blue-base");
                border-radius: 100%;
            }
            &:after {
                position: absolute;
                top: 8px;
                right: 5px;
                content: "";
                width: 10px;
                height: 10px;
                background: get-color-accents("blue-base");
                border-radius: 100%;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
        }
        &:focus + label {
            &:before {
                border: 1px solid get-color-accents("blue-dark");
            }
            &::after {
                background: get-color-accents("blue-dark");
            }
        }
        &:not(:checked) + label:before {
            border-color: get-color-neutral("30");
        }
        &:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        &:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
    label {
        @include paragraph-styles();
        @extend .-small;
        letter-spacing: 0.02em;
        word-break: break-word;

        p {
            color: get-color-neutral("70");
        }
    }
}
