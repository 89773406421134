.c-publication-component-actions {
    display: flex;
    position: absolute;
    top: 0;
    right: 4px;
    z-index: 2;

    .c-menu-button__item {
        line-height: 0;
    }

    > .c-menu-button {
        @include margin-top(4px);
        display: flex;
        width: rem(28px);
        height: rem(28px);

        &.-hidden {
            visibility: hidden;
        }

        > .c-menu-button__icon {
            @include border-radius("base");
            @include padding(5px);
        }

        .c-menu-button__menu {
            min-width: rem(158px);
            top: 32px;
            .c-menu-button__item {
                @include icon-fill(get-color-neutral("50"));
                .c-button {
                    color: get-color-neutral("90");
                }

                .c-icon {
                    @include margin-right(8px);
                    vertical-align: text-top;
                }
            }
        }
    }
}
