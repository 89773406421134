.c-invalid-share-link-view {
    @include fill-viewport-height();

    &__container {
        @include padding-top(164px);

        &__icon,
        &__header,
        &__body,
        h6 {
            text-align: center;
        }

        &__icon {
            @include icon-fill(get-color-status("warning"));
            @include margin-bottom(24px);

            .c-icon {
                width: rem(60px);
                height: rem(60px);
            }
        }

        &__header {
            @include margin-bottom(16px);

            h6 {
                color: get-color-neutral("90");
            }
        }

        &__body {
            padding-left: 15%;
            padding-right: 15%;

            @include respond-to("sm-tablet") {
                @include padding-left(16px);
                @include padding-right(16px);
            }

            p {
                color: get-color-neutral("70");
            }
        }
    }
}
