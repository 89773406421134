.c-video-modal {
    background-color: transparent;

    &.-fullscreen {
        height: calc(100vw * 0.75 * 9 / 16);
        max-width: rem(1305px);
        padding: 0;
        width: 75%;

        .-ie-content-container {
            height: 100%;
        }

        &.-mobile {
            @include border-radius("base");

            height: calc((100vw - 16px) * 9 / 16);
            margin: rem(8px);
            max-width: calc(100vw - 16px);
            width: 100%;

            .c-modal__content {
                height: 100%;
            }
        }

        .c-modal__content {
            overflow: hidden;

            iframe {
                height: 100%;
                width: 100%;
            }
        }
    }

    &.-mobile {
        max-width: 94%;
    }

    &__video {
        height: 100%;
        width: 100%;
    }
}
