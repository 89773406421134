.c-bookmark-slider {
    @include border-radius("base");
    @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));
    @include font-style(
        $size: "xsmall",
        $line-height: get-line-height("xsmall")
    );
    background: get-color-neutral("white");
    // z-index must be higher than 8 so there is no possibility of
    // the 8 possible bookmark icons being positioned over top of it.
    z-index: 9;
    position: absolute;
    top: 2.5rem;
    left: 2rem;
    width: rem(330px);

    .c-bookmark-read-only-dialog {
        border-radius: 0;
    }

    &__header {
        @include padding(8px, 8px, 4px, 16px);
        display: flex;
        align-items: center;
        border-bottom: 1px solid get-color-neutral("30");

        .c-label {
            text-transform: none;
            @include if-internet-explorer {
                max-width: rem(150px);
            }
        }

        span {
            @include font-style(
                $font-primary,
                "xsmall",
                get-color-neutral("90"),
                400,
                18px
            );
            display: flex;
            align-items: center;

            svg {
                @include margin-right(8px);
                flex-shrink: 0;
                align-self: flex-start;
                path {
                    fill: get-color-neutral("50");
                }
            }
        }

        &__actions {
            display: flex;
            align-self: flex-start;
            justify-content: space-between;
            width: 100%;

            .c-button {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__left {
                display: flex;
                align-items: center;
                p {
                    @include margin-top(8px);
                    @include font-style(
                        $size: "xxsmall",
                        $line-height: "xxsmall",
                        $color: get-color-neutral("90"),
                        $weight: "bold"
                    );
                    @include padding-bottom(10px);
                    display: inline-block;
                    vertical-align: top;
                }

                .c-button.-tertiary-alt {
                    @include padding(4px);
                    @include icon-fill(get-color-neutral("70"));

                    &.-disabled {
                        @include icon-fill(get-color-neutral("30"));
                    }
                }
                .c-button.-medium {
                    border: 1px solid rgba(0, 26, 57, 0.2);
                }

                .c-button.-view-bookmarks {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include padding-y(5px);
                    .c-icon {
                        @include margin-right(2px);
                    }
                }

                &__paging {
                    display: flex;
                    @include margin(0, 4px);
                }
            }

            &__right {
                .c-button {
                    &.-small {
                        @include padding(6px);
                        @include if-internet-explorer {
                            @include margin-top(-2px);
                        }

                        @include respond-to("phone") {
                            @include padding-left(16px);
                            @include padding-right(16px);
                        }
                    }

                    .c-icon {
                        @include icon-fill(get-color-neutral("70"));
                    }
                }
            }
        }
    }
    &__content {
        padding-bottom: 0;
        padding-top: 0;

        .c-bookmark-read-only-dialog {
            box-shadow: none;
        }
    }

    &__footer {
        @include box-shadow(0px, 7px, 20px, get-color-neutral("30"));
        @include padding(16px);
        &__actions {
            display: flex;
            align-self: flex-start;
            justify-content: space-between;
            width: 100%;

            p {
                @include margin-top(8px);
                @include font-style(
                    $size: "xsmall",
                    $line-height: "xsmall",
                    $color: get-color-neutral("90"),
                    $weight: "bold"
                );
                display: inline-block;
                vertical-align: top;
            }

            .c-button.-tertiary-alt {
                @include padding(4px);
                @include icon-fill(get-color-neutral("70"));

                &.-disabled {
                    @include icon-fill(get-color-neutral("30"));
                }

                @include respond-to("phone") {
                    @include padding-left(6px);
                    @include padding-right(6px);
                }
            }
        }
    }
}
