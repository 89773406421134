.c-user-collections {
    $spacer-x: 48px;
    $spacer-y: 16px;
    @include padding-top(48px);
    @include margin-bottom(60px);
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include respond-to("phone") {
        @include margin-bottom(80px);
    }
    &__header {
        @include padding-left($spacer-x);
        @include padding-right($spacer-x);
        @include padding-bottom(22px);

        position: sticky;
        bottom: 0;
        z-index: 1;
        box-shadow: 0px 3px 3px rgba(0, 26, 57, 0.1);

        display: flex;
        justify-content: space-between;
        > .c-search-form {
            align-self: flex-start;
            width: rem(280px);
            @include respond-to("sm-tablet") {
                width: rem(200px);
            }
        }
    }

    &__heading {
        @include padding-left($spacer-x);
        @include padding-right($spacer-x);
        @include padding-bottom($spacer-y);
    }
    &__body {
        @include padding-left($spacer-x);
        @include padding-right($spacer-x);
        display: flex;
        flex-direction: column;
        overflow: auto;

        > .c-filter-label {
            @include margin-bottom(16px);
        }
        > .c-no-results-banner {
            @include margin-top(128px);
            align-self: center;
            justify-self: center;
        }
    }
}
