.c-landing-page-section-wrapper {
    $mobile-side-padding: 1rem;
    $top-bottom-padding: rem(140px);
    $b: &;

    background-color: get-color-neutral("white");

    &__content {
        max-width: rem(1311px);
        margin: 0 auto;
        padding: $mobile-side-padding;

        @include respond-to-min-width("laptop") {
            padding-top: $top-bottom-padding;
        }
    }

    &:last-of-type {
        #{$b}__content {
            padding-bottom: $top-bottom-padding;
        }
    }
}
