.c-change-summary {
    position: relative;
    scroll-margin-top: 100px;
    background-color: get-color-status("warning-light");
    border-radius: $border-radius-medium;
    @include padding(16px);

    &__content {
        height: 0;
        max-height: 999999px;
        transition: all 0.15s ease-out;
        overflow: hidden;
        color: get-color-status("warning-dark");

        &.-expanded {
            @include padding-top(16px);
            height: fit-content;
        }
    }
    &__accordion {
        visibility: hidden;
        transition: all 0.25s ease-out;

        &.-expanded {
            visibility: visible;
        }
    }

    &__heading {
        @include font-style(
            $family: $font-primary,
            $size: "xxsmall",
            $color: get-color-status("warning-dark"),
            $weight: "bold"
        );
        background-color: initial;
        width: 100%;
        color: get-color-status("warning-dark");
        @include icon-fill(get-color-status("warning-dark"));
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        &.-compact {
            @include padding(16px, 24px, 16px, 52px);
            > .c-button:hover {
                background-color: get-color-neutral("30");
            }
        }
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
        }
    }

    &__admin {
        @include padding-top(16px);
        border-top: 1px solid get-color-neutral("50");
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__actions {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 9;

            @include respond-to("phone") {
                @include margin-top(8px);
                margin-left: 0;

                .c-menu-button {
                    margin-left: auto;
                    button {
                        background: get-color-neutral("white");
                    }
                }

                .c-button {
                    &.-xsmall {
                        padding: 0.25rem;
                    }
                }
            }

            .c-menu-button__icon {
                @include padding(1px);
            }
            .c-publish-status-badge {
                margin-top: 0;
            }
        }

        @include respond-to("phone") {
            flex-direction: column;
            align-items: initial;
        }

        &__changes {
            &__status {
                @include padding-right(3px);

                &.-bold {
                    font-weight: get-font-weight("bold");
                }
            }
        }
    }

    &.-active {
        background-color: get-color("neutral", "white");
        border-radius: $border-radius-medium;
    }
}
