.c-disclaimer-text {
    @include margin-bottom(32px);

    #additional-info & {
        @include margin-top(32px);
    }

    p {
        @include font-style(
            $color: get-color-neutral("70"),
            $line-height: get-line-height("large"),
            $size: "xxsmall"
        );

        &:first-of-type {
            @include margin-bottom(8px);
        }
    }

    &__disclaimer-label {
        font-weight: get-font-weight("bold");

        &::after {
            content: " ";
        }
    }
}
