.c-admin-situation-hotspot-image {
    position: relative;

    &__image-container {
        @include margin-bottom(24px);

        &__top {
            @include margin-bottom(16px);
            display: flex;
            justify-content: space-between;

            label {
                @include font-style(
                    $size: "small",
                    $line-height: get-line-height("small")
                );
            }
        }

        &__hotspot {
            position: relative;

            &__map {
                width: 100%;
                border: 1px solid get-color-neutral("30");
                border-radius: $border-radius-small;
                overflow: hidden;
            }
        }
    }

    &__placeholder-container {
        label {
            @include font-style(
                $size: "small",
                $line-height: get-line-height("small")
            );
            @include margin-bottom(16px);
            display: block;
        }

        &__placeholder {
            @include margin-bottom(24px);
            height: rem(350px);
            background-color: get-color-neutral("05");
            border-radius: $border-radius-small;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;

            &__contents {
                text-align: center;
            }
        }
    }

    &__upload-modal {
        .c-modal {
            &__content {
                @include padding(24px);
            }
        }

        &__footer {
            text-align: right;

            .c-button {
                height: rem(40px);

                &:first-child {
                    @include margin-right(8px);
                }
            }
        }
    }
}
