.c-multi-select {
    $base-class: &;

    // the reason we're duplicating .c-multi-select is
    // to increase CSS specificity so that we don't need
    // to use !important on the CSS properties. This is
    // needed due to the way that react-select applies
    // the CSS classes to child elements.
    .c-multi-select__input__selector__control {
        @include margin-top(5px);
        @include font-style();
        border-color: get-color-neutral("30");
        min-height: rem(40px);

        .c-multi-select__input__selector__placeholder {
            color: get-color-neutral("70");
        }

        &:hover {
            border-color: get-color-neutral("30");
            box-shadow: none;
        }

        &--is-focused {
            @include focus-outline();
            @include hover-outline();
            @include active-outline-styles();

            .c-multi-select__input__selector__placeholder {
                color: get-color-neutral("50");
            }
        }
    }

    @include if-firefox {
        // since placeholder is not a true placeholder in the react-select,
        // since it's not a true <input> element,
        // we need to account for the browser rendering
        // the placeholders differently
        .c-multi-select__input__selector__control {
            .c-multi-select__input__selector__placeholder {
                color: get-color-neutral("50");
            }

            &--is-focused {
                .c-multi-select__input__selector__placeholder {
                    color: get-color-neutral("30");
                }
            }
        }
    }

    .c-multi-select__input__selector__multi-value {
        background-color: get-color-neutral("05");
        @include font-style();

        &__label {
            color: get-color-neutral("90");
        }

        &__remove {
            color: get-color-neutral("90");

            &:hover {
                background-color: get-color-status("error-light");
                color: get-color-status("error");
            }
        }
    }

    .c-multi-select__input__selector__menu {
        .c-multi-select__input__selector__option {
            color: get-color-neutral("90");

            &--is-focused {
                background-color: get-color-neutral("05");
            }
        }
    }

    &__input {
        label.-help-text {
            color: get-color-neutral("70");
        }
    }

    &.-small {
        label {
            @include font-style(
                $size: "xsmall",
                $line-height: get-line-height("xsmall")
            );
        }

        #{$base-class}__input {
            &__selector {
                &__placeholder {
                    @include font-style(
                        $size: "xsmall",
                        $line-height: get-line-height("xsmall")
                    );
                }

                &__dropdown-indicator {
                    @include margin-right(4px);
                    @include padding(2px);
                }

                &__input {
                    @include margin-top(-4px);
                }

                &__multi-value {
                    &__label {
                        @include font-style(
                            $size: "xxsmall",
                            $line-height: get-line-height("xxsmall")
                        );
                    }
                }

                &__value-container {
                    @include padding(4px);
                    line-height: rem(1px);
                }

                &__control {
                    @include font-style(
                        $size: "xxsmall",
                        $line-height: get-line-height("xxsmall")
                    );
                    min-height: rem(8px);
                }
            }
        }
    }

    // invalid state styles
    .c-form-field.-invalid {
        input:not([type="checkbox"]) {
            box-shadow: none;
        }

        #{$base-class}__input__selector {
            &__control {
                @include form-field-error-styles();
            }

            &__placeholder {
                color: get-color-status("error");
            }
        }
    }
}
