.c-tooltip {
    @include box-shadow(0, 7px, 20px, rgba(0, 26, 57, 0.3));
    @include font-style($size: "small");
    @include padding(12px);
    background-color: get-color-neutral("90");
    color: get-color-neutral("white");
    border-radius: $border-radius-small;

    .tippy-content {
        div {
            word-break: break-word;
        }
    }

    &.with-outline-border {
        border: 1px solid white;
    }

    &[data-placement="bottom"] {
        // if trigger is scrolled to top of screen, tooltip drops below trigger
        .c-tooltip__arrow {
            bottom: unset;
            bottom: initial;
            top: rem(-8px);
        }
    }
}
