.c-publication-selection-list-group-item {
    $actions-menu-width: 64px;

    position: relative;
    border-bottom: rem(1px) solid get-color-neutral("30");

    &:not(:first-child) {
        @include margin-top(1px);
    }

    &:hover,
    &:focus-within,
    &.-focused {
        background-color: get-color-neutral("05");

        .c-publication-selection-list-group-item__item {
            &__title,
            &__edition {
                text-decoration: underline;
            }
        }

        .c-button {
            text-decoration: underline transparent;
        }
    }

    &.-favorite {
        background-color: get-color-accents("blue-light");

        .c-checkbox__icon-checkmark {
            @include icon-fill(
                get-color-accents("blue-dark"),
                get-color-accents("blue-dark")
            );
        }

        &:hover,
        &:focus-within,
        &.-focused {
            background-color: get-color-neutral("05");

            .c-checkbox__icon-checkmark {
                @include icon-fill(
                    get-color-neutral("70"),
                    get-color-neutral("70")
                );
            }
        }

        .c-publication-selection-list-group-item__actions {
            &:hover,
            &:focus-within,
            &.-focused {
                .c-checkbox__icon-checkmark {
                    @include icon-fill(
                        get-color-neutral("90"),
                        get-color-neutral("90")
                    );
                }

                .c-menu-button__icon {
                    @include icon-fill(get-color-neutral("90"));
                }
            }
        }
    }

    &:not(.-favorite) {
        .c-publication-selection-list-group-item__actions {
            &:hover,
            &:focus-within,
            &.-focused {
                .c-checkbox__icon-checkmark {
                    @include icon-fill(get-color-neutral("70"));
                }

                .c-menu-button__icon {
                    @include icon-fill(get-color-neutral("90"));
                }
            }
        }
    }

    &__item_parent {
        display: flex;
        justify-content: space-between;
        @include padding(0px, 12px, 0px, 0px);
    }
    &__item {
        @include padding(16px);
        @include font-style(
            $size: "xsmall",
            $line-height: "xsmall",
            $color: get-color-neutral("90")
        );
        display: flex;
        text-decoration: none;
        width: 90%;
        overflow: hidden;

        .-title-first &__edition,
        .-edition-first &__title {
            @include ellipsis(block);
            color: get-color-neutral("70");
            letter-spacing: 0.02em;
        }
        &__title {
            &.-bold {
                font-weight: get-font-weight("bold");
            }
        }
    }

    &__cover {
        @include margin-right(16px);
        background: no-repeat center/16px
            url("../../../images/nfpa-link-glyph-grayscale.png");
        height: rem(44px);
        min-width: rem(32px);
        width: rem(32px);
        border-radius: $border-radius-small;
        border: rem(1px) solid get-color-neutral("30");

        .c-icon {
            @include icon-fill(get-color-neutral("30"));

            width: 100%;
            height: 100%;
        }

        + div {
            @include margin-top(2px);
            overflow: hidden;
        }
    }

    // Unable to remove this without breaking the background image rule above
    &:first-of-type {
        border-top: rem(1px) solid get-color-neutral("30");
    }

    &.-nested {
        @include padding-left(32px);
        border-top: none;
    }

    &__toggle {
        display: flex;
        gap: 16px;
        @include padding-top(3px);
        @include padding-bottom(3px);

        .c-button {
            @include font-style(
                $size: "xsmall",
                $color: get-color-accents("blue-dark"),
                $weight: "base"
            );
            text-decoration: none;
            @include margin-right(4px);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__actions {
        display: flex;
        width: fit-content;
        align-items: center;

        .c-action-button {
            border: none;
            @include padding(5px);
            background-color: transparent;
            &:hover {
                background-color: get-color-neutral("30");
            }
        }

        .c-legacy-icon {
            width: 22px;
            height: 40px;
            path {
                fill: get-color-neutral("70");
            }
        }

        .c-checkbox {
            $icon-size: 16px;
            $item-height: 72px;

            height: 100%;
            width: 100%;
            margin: 0;

            @include padding(
                ($item-height - $icon-size) / 2,
                ($actions-menu-width - $icon-size) / 2
            );

            &__icon-checkmark {
                @include icon-fill(get-color-neutral("50"));
                svg {
                    display: block;
                }
            }

            &:focus-visible {
                outline: -webkit-focus-ring-color auto 1px;
            }
        }
    }
}
