.c-reference-panel {
    background: get-color-neutral("white");
    border-left: 1px solid get-color-neutral("30");
    height: 100vh;
    margin-top: 0;
    overflow-y: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    width: $reference-panel-width;
    z-index: 1;

    @include if-internet-explorer() {
        height: calc(100vh - #{$breadcrumb-menu-height});
    }

    @include respond-to("tablet") {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: calc(50% - #{$sidebar-menu-width});
    }

    @include respond-to("phone") {
        position: absolute;
        height: initial;
        left: 0;
        max-width: 100%;
        top: rem(56px);
        z-index: 8;
    }

    &__body {
        @include padding(24px);
    }

    &__container {
        height: 100%;
        overflow-y: auto;
        &.-empty {
            @include margin-top(-80px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include if-internet-explorer() {
                margin-top: 50%;
            }

            .c-icon {
                @include icon-fill(get-color-neutral("50"));
                @include margin-bottom(20px);
            }

            p {
                @include font-style(
                    $color: get-color-neutral("70"),
                    $size: 14px
                );

                &:first-of-type {
                    @include font-style(
                        $color: get-color-neutral("90"),
                        $size: 18px
                    );
                    @include margin-bottom(16px);
                }
            }

            @include respond-to("phone") {
                @include margin-top(-96px);
            }
        }

        .c-equation {
            img {
                max-width: 100%;
            }
        }

        .c-section-content-preview__body {
            @include padding-left(40px);
            @include padding-right(40px);
            max-height: calc(100% - 80px);

            & > h6 {
                @include font-style($letter-spacing: 0.02em, $size: "xsmall");
            }
        }

        @include respond-to("sm-desktop") {
            height: calc(#{vh()} - #{$breadcrumb-menu-height});
        }
    }

    &__footer {
        @include box-shadow(0px, 1px, 10px, rgba(0, 26, 57, 0.2));
        @include padding(24px);
        background: get-color-neutral("white");
        bottom: 0;
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        z-index: 1;

        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            width: 100%;

            > .c-button:first-child {
                @include margin-right(8px);
                @include padding(7px);

                svg {
                    width: rem(12px);
                    height: rem(12px);
                }
            }

            .c-page-navigation {
                .c-button {
                    background: transparent;
                    border: none;
                    margin: 0;

                    &:disabled {
                        @include icon-fill(get-color-neutral("50"));
                    }
                }
            }

            &__left,
            &__right {
                align-items: center;
                display: inline-flex;
                width: 50%;

                > *:first-child {
                    @include margin-right(12px);
                }
            }

            &__left {
                justify-content: flex-start;
            }

            &__right {
                justify-content: flex-end;
            }
        }
    }

    &__header {
        @include font-style(
            $color: get-color-neutral("70"),
            $letter-spacing: 0.11em,
            $size: "xxsmall",
            $weight: "bold"
        );
        @include padding(4px, 4px, 4px, 12px);
        background: get-color-neutral("05");
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: uppercase;

        .c-button {
            height: rem(30px);
            z-index: 1;

            > .c-icon {
                @include icon-fill(get-color-neutral("70"));
            }
        }
    }
}
