.c-offline-settings-modal {
    max-width: rem(592px);
    &.c-modal.-bottom {
        padding: 0;
    }
    .c-alert-banner {
        @include margin-top(16px);
    }
    .c-publication-selection-list-group-item {
        @include padding(16px, 0, 24px, 0);
        border-bottom: 1px solid get-color-neutral("30");

        &:first-of-type {
            // Override base style
            border-top: none;
        }

        &:hover {
            text-decoration: none;
            background-color: initial;
        }

        &__item {
            padding-bottom: 0;
            padding-left: 0;
        }
    }

    .c-modal__body {
        padding-top: 0;
        @include respond-to("phone") {
            @include padding(16px);
        }
    }

    .c-modal__content {
        @include respond-to("phone") {
            max-height: 80vh;
            overflow: auto;
            position: relative;
        }
    }

    .c-cta-toggle {
        @include margin-top(32px);
        @include margin-bottom(24px);
        width: 100%;
        max-width: none;
    }
    .e-checkbox {
        @include margin-top(16px);
        @include icon-fill(get-color-neutral("30"));

        &:first-of-type {
            margin-top: 0;
        }

        .c-icon {
            @include margin-left(8px);
            vertical-align: text-top;
        }

        &.-checked {
            color: get-color-accents("blue-dark");
            .e-checkbox__checkmark {
                border-color: get-color-accents("blue-dark");
                &:after {
                    border-color: get-color-accents("blue-base");
                }
            }
        }
        &.-disabled {
            color: get-color-neutral("70");
        }
    }
}
