.c-enhanced-content-migration-field {
    div {
        @include font-style($font-primary, "xsmall");
        color: get-color-neutral("90");
    }

    > label + .c-list-box {
        @include margin-top(8px);
    }

    .c-button {
        @include margin-top(8px);
        display: block;
    }

    &__container {
        @include padding(14px, 16px);
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-flow: row nowrap;
    }

    &__info {
        @include rem-fallback("max-height", 36px);
        flex-grow: 1;

        .c-loader {
            display: inline-block;
        }
    }

    &__migrating {
        @include padding-left(6px);
        color: get-color-neutral("darker");
        vertical-align: super;
    }

    &__migration {
        .c-button {
            @include margin(0);
            @include margin-left(6px);
            @include font-style($font-primary, "xsmall");
            display: inline;
        }
    }

    &__migrated-text,
    &__source-text {
        @include padding-left(6px);
    }

    &__action {
        button.c-button {
            @include font-style($font-primary, "xsmall");
            @include margin(0, 12px, 0, 0);
            font-weight: get-font-weight("bold");
            height: 100%;

            &:disabled {
                color: get-color-neutral("70");
            }
        }
    }
}
