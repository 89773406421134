.c-table-of-contents {
    @include padding(24px, 0px);

    &__collapseButtons {
        @include margin-bottom(24px);
        text-align: right;

        @include respond-to("phone") {
            text-align: initial;
        }
    }
}
