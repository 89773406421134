.c-billing-cycle-radio-buttons {
    @include padding-bottom(12px);

    &.-error {
        @include font-style($color: get-color-primary("error"));

        span,
        label,
        p,
        legend,
        .c-radio label p {
            color: get-color-primary("error");
        }

        input:not(:checked) + label {
            &:before {
                border-color: get-color-primary("error");
                box-shadow: 0 0 0 3px rgba(213, 0, 50, 0.2);
            }
        }
    }

    &.-inline {
        display: flex;
        .c-radio {
            flex-grow: 1;
            flex-basis: 0;
            padding: 0;
            text-align: center;
            input:not(:checked) + label,
            input:checked + label {
                @include padding-top(40px);
                padding-left: 0;
                &:before {
                    left: calc(50% - 10px);
                }
                p {
                    text-align: center;
                }
                &:after {
                    left: calc(50% - 5px);
                }
            }
        }
    }

    &__errors {
        @include margin-top(8px);
        @include font-style(
            $line-height: "xsmall",
            $size: "xsmall",
            $style: italic,
            $weight: "light"
        );
        @include icon-fill(get-color-primary("error"));
        color: get-color-primary("error");
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
            @include margin-left(8px);
        }
    }

    legend {
        @include margin-bottom(12px);
    }

    .c-radio {
        @include padding(8px, 8px, 8px, 0px);

        input:not(:checked) + label,
        input:checked + label {
            @include padding-left(40px);
            padding-right: 0;
            display: inline-flex;
            align-items: center;
        }

        input:checked + label {
            color: get-color-accents("blue-base");
            p {
                color: get-color-accents("blue-dark");
            }
        }

        input:checked:focus + label {
            &:before {
                box-shadow: 0 0 0 3px rgba(0, 176, 202, 0.4);
            }
        }
        label {
            @include paragraph-styles("small");
            color: get-color-neutral("90");

            &:before {
                left: 0;
                right: unset;
            }

            &:after {
                left: 5px;
                right: unset;
            }

            p {
                @include paragraph-styles("small");
                color: get-color-neutral("90");
                span {
                    font-weight: 700;
                }
            }
        }
    }
}
