.c-section-body {
    @include paragraph-styles();
    display: inline;
    position: relative;
    > p {
        @include margin-bottom($space-xs);
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &.c-code-change.-addition {
        background-color: get-color-accents("orange-light");
    }

    &.c-code-change.-deletion {
        display: none;
    }
}
