.c-cta-toggle {
    @include padding(16px);
    @include padding-right(74px);
    background-color: get-color-neutral("05");
    border-radius: $border-radius-small;
    color: get-color-neutral("90");
    position: relative;
    transition: 0.4s;
    text-align: left;

    > label {
        @include font-style($size: "small", $line-height: 21px);
    }

    > p {
        @include font-style($size: "xxsmall", $line-height: 14px);
        color: get-color-neutral("70");
    }

    > .c-toggle-button {
        position: absolute;
        top: rem(24px);
        right: 16px;
    }

    &.-checked {
        background-color: get-color-accents("blue-light");
        color: get-color-accents("blue-dark");
        transition: 0.4s;
        > p {
            color: inherit;
        }
    }

    &:disabled {
        cursor: not-allowed;
    }
}
