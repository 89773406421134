.c-publication-page {
    height: 100%;
    @extend .o-grid__row;

    @include respond-to("tablet") {
        display: block;
        overflow: auto;
        height: 100%;
    }

    &__metadata {
        @include padding(36px, 48px);
        @include span-col(4, $no-gutter-grid);
        position: relative;
        background-color: get-color-background("neutral");
        max-height: vh();

        @include respond-to("tablet") {
            max-width: initial;
            flex: auto;
        }
        @include respond-to("phone") {
            @include padding(16px);
            width: 100%;
        }

        &__top {
            display: flex;
            justify-content: space-between;

            > .c-button,
            &__right__favorite {
                height: rem(28px);
                @include margin-bottom(64px);
                @include respond-to("tablet") {
                    @include margin-bottom(42px);
                }
                @include respond-to("phone") {
                    @include margin-bottom(32px);
                }
            }

            &__right {
                display: flex;
                align-content: flex-end;

                &__favorite {
                    @include font-style(
                        $size: "xxsmall",
                        $line-height: "xxsmall",
                        $weight: "bold"
                    );
                    @include padding(8px);
                    display: flex;

                    @include if-not-touchscreen {
                        @include border-radius("base");

                        &:hover {
                            background-color: get-color-neutral("30");
                        }
                    }

                    .c-icon {
                        @include margin-right(4px);
                        vertical-align: text-top;
                        width: rem(14px);
                        height: rem(14px);

                        @include respond-to("phone") {
                            @include margin-top(-3px);
                            margin-right: 0;
                            width: rem(18px);
                            height: rem(18px);
                        }
                    }

                    @include respond-to("phone") {
                        > span {
                            display: none;
                        }
                    }
                }

                > .c-loader {
                    margin-right: 0;
                }

                &__more-menu {
                    display: none;
                    float: right;
                    background-color: transparent;

                    @include respond-to("tablet") {
                        @include margin-top(-6px);
                        @include margin-right(-24px);
                        display: block;
                    }

                    @include respond-to("phone") {
                        @include margin-right(-12px);
                    }

                    .c-menu-button__menu {
                        @include margin-right(16px);
                    }
                }
            }
        }

        > a:not(.c-button) {
            font-size: rem(18px);
            font-weight: 100;
        }
    }

    &__toc {
        @include span-col(8, $no-gutter-grid);
        @include padding(0, 72px);
        overflow-y: scroll;
        height: 100%;

        @include respond-to("tablet") {
            @include padding(0, 16px);
            width: 100%;
            max-width: initial;
            max-height: calc(100vh - #{$metanav-width});
            flex: auto;
            overflow: initial;
        }
    }
    &__footer {
        @include padding(16px, 48px);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        box-shadow: 0px -1px 0px get-color-neutral("30");
        > a {
            @include margin-right(24px);
        }
        @include respond-to("tablet") {
            display: none;
        }
    }
    &__errors {
        color: get-color("neutral", "white");
    }
    &__menu {
        display: flex;
        flex-direction: column;
        @include respond-to("tablet") {
            flex-direction: row;
        }
    }
    &__cover {
        @include margin-bottom(48px);
        width: rem(80px);
        flex-shrink: 0;
        @include respond-to("tablet") {
            @include margin-right(36px);
            margin-bottom: 0;
        }
        @include respond-to("phone") {
            @include margin-right(24px);
        }

        > img {
            @include border-radius("base");
            @include box-shadow(
                $top: 0,
                $left: 3px,
                $blur: 10px,
                $color: rgba(0, 26, 57, 0.2),
                $spread: false,
                $inset: false
            );
            max-width: 100%;
            height: auto;
        }
    }

    &__code {
        @include margin-bottom(4px);
        color: get-color-neutral("90");
    }
    &__title {
        color: get-color-neutral("70");

        @include respond-to("tablet") {
            // p.-large
            @include font-style($font-primary, "large", false, 400, 27px);
        }
        @include respond-to("phone") {
            // p.-small
            @include font-style($font-primary, "small", false, 400, 21px);
        }
    }
    &__edition {
        @include margin-bottom(8px);
        > p {
            letter-spacing: 0.02em;
            @include respond-to("phone") {
                // p.-small
                @include font-style($font-primary, "small", false, 400, 21px);
            }
        }
    }
    &__subtitle {
        color: get-color("neutral", "white");
    }
}
