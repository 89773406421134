.c-no-categories-available-modal {
    @include padding(32px);
    min-width: rem(275px);

    h5 {
        @include margin-bottom(16px);
        font-weight: get-font-weight("bold");
    }

    p {
        @include margin-bottom(32px);
    }

    &__action-container {
        text-align: center;
    }
}
