.c-select-plan-page {
    $gutter-width: 36px;
    @include padding(0, $gutter-width);
    overflow-x: hidden;

    @include respond-to("phone") {
        @include padding(0, 24px);
    }

    &__title {
        @include margin(40px, 0, 0, 0);
        @include font-style(
            $line-height: get-line-height("xxxlarge"),
            $size: "xxxlarge",
            $weight: "lightest"
        );
        color: get-color-neutral("90");
        text-align: center;

        @include respond-to("phone") {
            @include margin(16px, 0, 24px, 0);
            @include font-style(
                $line-height: get-line-height("xlarge"),
                $size: "xlarge",
                $weight: "lightest"
            );
        }

        &.-no-cards {
            @include margin-bottom(24px);
        }
    }
    &__subtitle {
        @include font-style($line-height: "large", $size: "large");
        @include margin(16px, 0, 48px, 0);
        @include rem-fallback(max-width, 800px);
        margin-right: auto;
        margin-left: auto;
        text-align: center;

        a {
            @include font-style($line-height: "large", $size: "large");
        }

        @include respond-to("phone") {
            @include font-style($line-height: "small", $size: "small");
            max-width: unset;

            a {
                @include font-style($line-height: "small", $size: "small");
            }
        }
    }
    &__expiration-title {
        @include margin(40px, 0, 24px, 0);
        @include font-style(
            $line-height: get-line-height("xxxlarge"),
            $size: "xxxlarge",
            $weight: "lightest"
        );
        color: get-color-neutral("90");
        text-align: center;

        @include respond-to("phone") {
            @include margin(16px, 0, 24px, 0);
            @include font-style(
                $line-height: get-line-height("xlarge"),
                $size: "xlarge",
                $weight: "lightest"
            );
        }
    }
    &__expiration-subtitle {
        @include margin(24px, 0, 48px, 0);
        @include font-style(
            $line-height: get-line-height("large"),
            $size: "large"
        );
        color: get-color-neutral("90");
        text-align: center;

        @include respond-to("phone") {
            @include margin(16px, 0, 24px, 0);
            @include font-style(
                $line-height: get-line-height("small"),
                $size: "small"
            );
        }
    }
    &__cards {
        @include margin-bottom(48px);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__footer {
        @include margin-bottom(80px);
        text-align: center;

        a {
            @include paragraph-styles("large");
            font-weight: 700;
        }
    }

    .c-featured-publications-area {
        @include margin(0, (-$gutter-width));
        @include padding(0, $gutter-width);
    }
}
