.c-teams-admin-detail-page {
    &__section {
        $x-padding: 48px;
        @include padding(0px, $x-padding);
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-height: calc(100vh - #{$breadcrumb-menu-height});
        overflow-y: auto;
    }

    &__sectionHeading {
        @include margin-bottom(14px);
        @include font-style(
            $size: "xlarge",
            $color: get-color-neutral("90"),
            $line-height: "xlarge"
        );
    }

    &__entry {
        @include margin-bottom(18px);
    }

    &__heading {
        text-transform: uppercase;
        @include font-style(
            $size: "xsmall",
            $color: get-color-neutral("70"),
            $weight: "bold",
            $letter-spacing: 0.11em,
            $line-height: "xlarge"
        );
    }

    &__value {
        @include font-style(
            $size: "small",
            $color: get-color-neutral("70"),
            $weight: "base",
            $line-height: "xlarge"
        );
    }
}
