.c-terms-and-conditions-page {
    background-color: get-color-neutral("05");

    &__container {
        @extend .o-grid__container;

        @include respond-to("tablet") {
            @include padding(0, 80px);
        }

        @include respond-to("phone") {
            padding: inherit;
        }
    }

    &__inner-container {
        @extend .o-grid__row;
        @include span-col(10);
        @include push-col(1);
    }

    &__help-info {
        margin: 0 auto;
        text-align: Center;
        max-width: rem(800px);
    }

    &__content {
        margin: 0 auto;

        &__heading {
            @include padding(36px, 0, 24px, 0);
            color: get-color-neutral("90");
            text-align: center;
        }

        &__terms {
            @include border-top(1px, solid, get-color-neutral("50"));
            @include border-bottom(1px, solid, get-color-neutral("50"));
            @include padding(28px, 24px);
            @include scrollbar();
            height: 440px;
            overflow-y: scroll;

            li > p > strong,
            ol > li > strong {
                text-decoration: underline;
            }

            ol > li ol > li {
                @include margin(16px, 0px);
            }

            ol ol {
                list-style-type: lower-alpha;
                margin-left: rem(16px);
            }

            ol ol ol {
                list-style-type: lower-roman;
                margin-left: rem(32px);
            }

            p {
                @include margin(16px, 0px);
            }
        }

        &__action {
            @include padding(40px, 0, 36px, 0);
            background: get-color-neutral("05");
            color: get-color-neutral("90");
            margin: inherit;
            max-width: 420px;

            a {
                @extend %c-button;
                @include margin-top(16px);
                text-align: center;
                width: 100%;

                @include respond-to("phone") {
                    display: block;
                }
            }

            &.errors {
                .c-form-field {
                    label {
                        .e-checkbox__checkmark {
                            border-color: get-color-primary("error");
                            color: get-color-primary("error");
                            box-shadow: 0 0 0 3px rgba(213, 0, 50, 0.2);
                        }

                        color: get-color-primary("error");
                    }

                    &__errors {
                        @include margin-top(12px);
                        @include margin-bottom(12px);
                        @include margin-left(36px);

                        label {
                            @include font-style($size: "small", $style: italic);
                        }
                    }
                }
            }
        }
    }
}
