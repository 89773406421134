.c-publication-list {
    @include margin(24, 0);

    &__item {
        display: flex;

        &__label,
        .c-xml-label {
            width: 40px;
        }

        &__content {
            width: calc(100% - 40px);
        }
    }
}
