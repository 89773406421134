.c-breadcrumb-modal {
    > ol {
        @include margin-top(8px);
        padding-left: 0;
        list-style-type: none;

        > li {
            @include margin-bottom(16px);

            &:first-of-type {
                .c-breadcrumb {
                    color: get-color-neutral("70");
                }
            }

            > .c-breadcrumb {
                display: block;
                position: relative;
                font-size: get-font-size("small");
                > .c-icon {
                    position: absolute;
                    top: 1px;
                    left: 0;
                }
                > .c-breadcrumb__label {
                    @include margin-left(24px);
                    text-overflow: initial;
                    white-space: normal;
                    box-decoration-break: clone;
                    -webkit-box-decoration-break: clone;
                }
            }
        }
    }
}
