.c-topics-filter-modal {
    &.-bottom {
        padding: 0;
    }

    &__header,
    &__body,
    &__footer {
        @include padding(16px);
    }

    &__header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid get-color-neutral("30");

        p {
            color: get-color-neutral("90");
        }
    }

    &__body {
        $header-footer-height: #{$mobile-modal-header-height} - #{$mobile-modal-footer-height};
        max-height: calc(#{vh()} - #{$header-footer-height});
        overflow-y: auto;

        &__filter-group {
            @include margin-top(24px);

            &:first-of-type {
                margin-top: 0;
            }

            label:not(.e-checkbox) {
                @include font-style(
                    $size: "xsmall",
                    $line-height: get-line-height("xsmall"),
                    $color: get-color-neutral("90")
                );
            }

            label.e-checkbox {
                color: get-color-neutral("70");

                &.-checked {
                    color: get-color-neutral("90");
                }
            }

            .c-checkbox-list {
                @include margin-top(8px);
            }

            &__topics {
                display: flex;

                &__topic {
                    @include margin-right(16px);
                    width: rem(16px);
                    height: rem(16px);
                    border-radius: $border-radius-huge;

                    @include get-color-classes-from-map(background-color);
                }
            }
        }
    }

    &__footer {
        @include box-shadow(0, 8px, 20px, rgba(0, 26, 57, 0.3));
        display: flex;
        justify-content: space-between;
        height: $mobile-modal-footer-height;
    }
}
