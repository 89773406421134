.c-userlogin-new-form {
    max-width: 360px;
    @include respond-to("phone") {
        @include margin-left(0);
    }

    > h1 {
        @include margin-bottom(36px);
        font-size: rem(48px);
    }

    > img {
        @include margin-bottom(48px);
        width: rem(160px);
        height: auto;
    }

    > .c-form {
        @include margin-top(13px);
        @include margin-right(56px);

        > .c-alert-banner {
            @include margin-bottom(16px);
        }

        .c-button {
            width: initial;
        }
    }

    .c-button {
        @include margin-bottom(25px);
        display: inline-block;
        + .c-form-field {
            @include margin-left(24px);
            display: inline-block;

            @include respond-to("phone") {
                display: block;
                margin-left: 0;
            }
        }
    }

    .c-free-trial-terms {
        @include margin-bottom(32px);
    }

    &__help-links {
        a {
            @include margin-right(42px);

            @include respond-to("phone") {
                @include margin-bottom(16px);
                display: block;
            }
        }
    }

    &__errors {
        @include margin-bottom(25px);
        color: get-color-primary("error");
    }
}
