.c-committee-member {
    @include paragraph-styles();
    display: block;
    color: get-color-neutral("90");
    &__name {
        display: inline;
        font-weight: 700;
        > em {
            font-weight: 400;
        }
    }
    &__additionalInfo {
        display: block;
    }
}
