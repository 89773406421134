.c-route-tabs {
    overflow: hidden;
    position: relative;
    &__list-wrapper {
        @include padding(0, 24px);
        position: relative;
        overflow: hidden;
        // Floating border effect

        &:after {
            bottom: 4px;
            width: calc(100% + 48px);
            left: -24px;
            display: block;
            position: relative;
            border-bottom: 1px solid get-color-neutral("30");
            content: "";
        }
        &__scroll {
            position: absolute;
            background: #ffffff;
            top: 0;
            left: 0;
            line-height: 0;
            height: 100%;
            vertical-align: middle;

            > svg > path {
                fill: get-color-neutral("70");
                &:hover {
                    fill: get-color-neutral("50");
                }
            }
            &.-right {
                right: 0;
                left: initial;
            }
        }
    }

    &__list {
        @include margin-left(16px);
        position: relative;
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        @include respond-to("phone") {
            @include margin-left(4px);
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__tab {
        @include font-style(
            $font-primary,
            "xsmall",
            get-color-neutral("70"),
            700,
            18px
        );
        @include padding(8px);
        @include padding-bottom(12px);
        @include margin-bottom(4px);
        @include margin-right(8px);
        display: inline-block;
        text-decoration: none;
        outline: none;
        background: none;
        position: relative;

        &:hover {
            color: get-color-accents("blue-dark");
        }

        .-active-border {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 4px solid get-color-accents("blue-base");
            display: none;
        }

        &.-active {
            color: get-color-accents("blue-dark");
            outline: none;

            .-active-border {
                display: block;
            }
        }
        &:focus {
            background-color: get-color-background("neutral");
        }
    }
    &__panels {
        @include margin-top(-4px);
        @include padding(32px, 48px, 0);

        @include respond-to("phone") {
            @include padding(32px, 24px, 0);
        }
    }
}
