.c-modal-overlay {
    @include fill-viewport-height();
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(get-color-neutral("90"), 0.7);
    z-index: $modal-overlay-z-index;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;

    @include respond-to("phone") {
        height: 100%;
        max-height: none;
    }

    @include if-internet-explorer {
        -ms-flex-pack: center;
    }

    > div {
        width: 100%;

        @include respond-to("phone") {
            height: 100%;
            display: flex;
        }
    }

    > .c-button.-modal-close {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 1;
    }

    &.-show-sidebar {
        @include respond-to-min-width("phone") {
            left: $sidebar-menu-width;

            .c-modal.-left {
                left: unset;
            }
        }
    }
}
