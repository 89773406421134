.c-sub-section-detail {
    @include padding($section-padding);
    @include focus-outline();
    width: 100%;
    position: relative;
    // Adding a 1px white border so there's no size difference on hover when dashed border is applied
    border: 1px solid get-color-neutral("white");

    &.-deleted {
        padding: 8px;

        + .c-sub-section-detail.-deleted {
            display: none;
        }

        .c-icon.-change-indicator {
            position: absolute;
            top: 0;
            left: rem(-26px);

            @include respond-to("desktop") {
                left: rem(-18px);
            }
        }

        &:hover {
            border: 1px solid get-color-neutral("white");
        }

        &.-highlight-section {
            border-color: get-color-status("warning");
            border-radius: 10px;

            .c-tia-indicator {
                height: 0;
            }
        }
    }

    .c-menu-button {
        visibility: hidden;
    }

    &:hover {
        @include border-radius("base");
        border: 1px dashed get-color-accents("blue-base");
        box-sizing: border-box;
        .c-menu-button {
            visibility: visible;
        }
    }

    .c-table-section,
    &__title {
        @include change-indicator-styles();
    }

    &__title {
        @include padding-right(16px);
        // @include padding-bottom(16px);
        @include font-style($color: get-color-neutral("90"), $weight: "bold");
        display: flex;
        position: relative;
        white-space: pre-wrap;
        align-items: center;

        .c-publication-anchor {
            &:not(.-not-found) {
                color: get-color-accents("blue-dark");
            }
        }

        > span {
            @include margin-right(8px);
        }

        .c-section-detail-actions {
            display: inline-flex;
            min-height: rem(28px);
            // height: rem(28px);
            text-indent: initial;

            // &__notes {
            //     position: relative;
            //     top: rem(8px);
            // }

            .c-bookmark-settings,
            .c-bookmark-slider {
                position: absolute;
                right: var(--bookmark-card-position-right, -25%);
                top: -25%;

                @include respond-to("lg-desktop") {
                    right: var(--bookmark-card-position-right, -10%);
                    top: -25%;
                }
            }
        }
    }
}
