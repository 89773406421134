.c-bookmark-read-only-mobile-modal {
    &.c-modal.-bottom {
        padding: 0;
    }

    .c-slider {
        @include padding(16px);
    }

    .c-bookmark-read-only-dialog {
        width: 100%;

        &__content,
        &__header,
        &__footer {
            padding: 0;
        }

        &__content {
            max-height: calc(#{vh()} - 148px);
            overflow-y: auto;
        }

        &__header {
            @include margin-bottom(16px);
        }

        &.-team-bookmark {
            .c-bookmark-read-only-dialog__content {
                max-height: calc(#{vh()} - 210px);
            }
        }
    }
}
