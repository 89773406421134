.c-user-collection-list {
    h6 {
        @include font-style(
            $color: get-color-neutral("70"),
            $letter-spacing: 0.11em,
            $line-height: 14px,
            $size: "xxsmall",
            $weight: "bold"
        );

        @include padding-bottom(24);
        text-transform: uppercase;

        @include padding-top(24);
    }

    &__rows {
        @extend .o-grid__row;

        &:after {
            content: "";
            flex: auto;
        }
    }
}
