.c-situational-navigation-page {
    $toolbar-height: rem(45px);
    height: 100%;
    display: flex;
    padding-bottom: $toolbar-height;
    background-color: get-color-background("neutral");
    flex-direction: column;
    overflow-y: auto;
    @include respond-to-min-width("laptop") {
        flex-direction: row;
        padding-bottom: 0;
    }

    &.-results {
        position: relative;
        top: $toolbar-height;
        background-color: get-color-background("primary");
    }

    h3 {
        @include font-style(
            $size: "large",
            $line-height: get-line-height("large"),
            $weight: 300,
            $color: get-color-neutral("90")
        );

        @include respond-to-min-width("laptop") {
            @include font-style(
                $size: "xxlarge",
                $line-height: get-line-height("xxlarge"),
                $weight: 300,
                $color: get-color-neutral("90")
            );
        }
        @include margin(48px, 0, 16px, 0);
    }

    & > p {
        @include margin-bottom(16px);
    }
    &__button-list {
        .c-button {
            @include margin-bottom(8px);
        }
    }

    &__container {
        @extend .o-grid__container;
        position: relative;
        @include respond-to-min-width("laptop") {
            @include padding(0, $space-xl);
            // override rhythm styles
            width: rem(440px);
            margin-left: 0;
            margin-right: 0;
        }
    }
    &__about {
        position: absolute;
        top: rem(16px);
        right: rem(24px);
    }
    &__results {
        display: flex;
        height: 100%;
        background-color: get-color-background("primary");
        justify-content: center;

        @include respond-to-min-width("laptop") {
            flex-grow: 1;
            // line break causes compiler issue here, so prettier-ignore
            // prettier-ignore
            $sitnav-results-width: $sitnav-category-selection-menu + $sidebar-menu-width;
            width: calc(100% - #{$sitnav-results-width});

            &__placeholder {
                @include icon-fill(get-color-accents("blue-dark"));
                width: rem(290px);
                text-align: center;
                align-self: center;

                > .c-icon {
                    width: rem(35px);
                    height: rem(35px);
                }

                > p {
                    color: get-color-accents("blue-dark");
                }
            }
        }

        &__situations {
            position: relative;
            &__row {
                position: relative;
                width: 100%;
                overflow-x: scroll;
                overflow-y: hidden;
                left: rem(-20px);
                width: calc(100% + 40px);
                transition: all 0.2s;
                user-select: none;
                cursor: pointer;
                white-space: nowrap;
                scrollbar-width: none; /* Firefox */
            }
        }

        .c-situation-related-situation-card {
            vertical-align: top;
        }
    }

    &__categories-container {
        flex-grow: 1;
    }

    &__help-text {
        @include margin-bottom(24px);
        @include font-style(
            $size: "xsmall",
            $line-height: get-line-height("xsmall"),
            $color: get-color-neutral("70")
        );

        @include respond-to-min-width("laptop") {
            @include font-style(
                $size: "base",
                $line-height: get-line-height("base"),
                $color: get-color-neutral("70")
            );
        }
    }

    &__toolbar {
        @extend .o-grid__row;
        @include padding(14px, 20px);
        @include font-size(14px);
        color: get-color-neutral("white");
        background: get-color-neutral("70");
        position: fixed;
        bottom: 0;
        left: $sidebar-menu-width;
        width: calc(100% - #{$sidebar-menu-width});
        font-weight: 300;
        min-height: rem(45px);
        text-decoration: none;

        &.-filled {
            background-color: get-color-accents("blue-dark");
        }

        &.-top {
            top: 0;
            bottom: auto;

            @include respond-to("phone") {
                top: 56px;
            }
        }

        @include respond-to("phone") {
            left: 0;
            width: 100%;
        }

        &__left,
        &__right {
            width: 50%;
            display: inline-block;
        }

        &__right {
            text-align: right;

            a {
                font-weight: get-font-weight("bold");
                color: get-color-neutral("white");
                text-decoration: none;
            }
        }
    }
}
