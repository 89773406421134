.c-accept-group-invitation-form {
    $baseClass: &;
    @include padding(80px, 32px);
    display: flex;
    margin: 0 auto;
    max-width: rem(800px);

    &.-logged-in {
        justify-content: center;
        align-items: center;
        #{$baseClass}__invited {
            flex-basis: 100%;
            max-width: rem(400px);

            .c-alert-banner {
                @include margin-top(24px);
            }

            button {
                @include margin-top(24px);
            }
        }
    }

    &__invited {
        flex-basis: 40%;

        div:first-of-type {
            @include margin-bottom(24px);
            @include font-style(
                $font-primary,
                "xxlarge",
                get-color-neutral("90"),
                300,
                get-line-height("xxlarge")
            );
        }

        &__team {
            @include padding(16px, 24px);
            background-color: get-color-neutral("white");
            border-radius: $border-radius-small;
            border: rem(1px) solid get-color-neutral("30");
            display: flex;
            flex-direction: column;
            span:first-of-type {
                @include margin-bottom(8px);
                @include font-style(
                    $font-primary,
                    "xxsmall",
                    get-color-neutral("70"),
                    700,
                    get-line-height("xxsmall")
                );
                letter-spacing: 0.11em;
                text-transform: uppercase;
                display: block;
            }
            span:last-of-type {
                @include font-style(
                    $font-primary,
                    "base",
                    get-color-neutral("90"),
                    400,
                    get-line-height("base")
                );
                word-wrap: break-word;
            }
        }
    }

    &__form {
        margin-left: auto;
        flex-basis: 45%;

        .c-form,
        .c-userlogin-new-form__help-links a:last-of-type {
            margin-right: 0;
        }

        .c-new-user-form,
        .c-userlogin-new-form {
            @include margin-top(24px);
        }

        h2 {
            @include margin-bottom(8px);
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                700,
                get-line-height("large")
            );
            letter-spacing: 0.02em;

            + span {
                @include font-style(
                    $font-primary,
                    "small",
                    get-color-neutral("70"),
                    400,
                    get-line-height("small")
                );

                letter-spacing: 0.02em;

                button {
                    @extend %a;
                    font-weight: 700;
                    text-decoration: underline;
                    color: get-color-neutral("70");
                }
            }
        }
        .c-button {
            @include padding(13px, 16px);
        }

        &__email {
            @include font-style(
                $size: "small",
                $color: get-color-neutral("90"),
                $line-height: "small"
            );

            &__controls {
                display: flex;
                align-items: center;
                gap: 16px;

                &__button {
                    word-break: keep-all;
                }
            }
        }
    }

    @include respond-to("tablet") {
        @include padding(80px, 56px);
        flex-direction: column;
        flex-wrap: wrap;

        &__invited {
            @include margin-bottom(32px);
        }

        &__form {
            margin-left: 0;
        }
    }

    @include respond-to("phone") {
        @include padding(40px, 24px);

        &__invited {
            div:first-of-type {
                @include font-style(
                    $font-primary,
                    "xlarge",
                    get-color-neutral("90"),
                    300,
                    get-line-height("xlarge")
                );
            }
        }
    }
}
