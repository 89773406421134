.c-subscription-card {
    $b: &;

    @include padding(24px, 32px, 32px);
    @include margin-bottom(36px);
    min-height: 566px;
    min-width: 376px;
    background: get-color-neutral("white");
    box-shadow: 0px 3px 10px rgba(0, 26, 57, 0.2);
    border-radius: 9px;
    display: flex;
    flex-direction: column;

    + .c-subscription-card {
        @include margin-left(36px);

        @include respond-to("laptop") {
            margin-left: 0;
        }
    }

    @include respond-to("super-ultrawide") {
        max-width: 376px;
    }

    @include respond-to("laptop") {
        height: auto;
        min-height: unset;
        min-width: unset;
        max-width: unset;
        width: 100%;
        flex-direction: row;
    }

    @include respond-to("phone") {
        @include padding(24px);
        flex-direction: column;
    }

    &__header {
        @include margin-bottom(16px);
        @include respond-to("laptop") {
            flex-grow: 0.5;
        }
        @include respond-to("phone") {
            flex-grow: unset;
        }
        &__title {
            display: inline-flex;
            align-items: center;
            .c-icon {
                @include margin-right(24px);
                color: get-color-accents("blue-base");
                height: 40px;
                width: 40px;
            }
        }
        &__cost {
            div {
                @include margin-top(8px);
                &:first-of-type {
                    @include font-style(
                        $font-primary,
                        "xxlarge",
                        get-color-buttons("primary"),
                        300,
                        45px
                    );
                }
                &:last-of-type {
                    @include paragraph-styles("small");
                    color: get-color-neutral("70");
                }
            }
        }
    }
    &__button-wrapper {
        @include margin-bottom(32px);
    }
    h2 {
        @include font-style(
            $font-primary,
            "large",
            get-color-accents("blue-dark"),
            700,
            27px
        );
    }

    // Used for landing page variant
    &.-landingPageSectionCard {
        max-width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 0;

        @include respond-to-min-width("laptop") {
            flex-direction: column;
            flex-wrap: nowrap;
        }

        #{$b}__header {
            flex: 1 1 auto;
        }
        #{$b}__content {
            flex: 1 1 100%;
            max-width: 100%;
        }
        #{$b}__button-wrapper {
            @include margin-top(16px);
            @include margin-bottom(0);
            display: flex;
            flex-direction: column;
            order: 1;
            justify-content: stretch;
            flex: 1 0 100%;

            @include respond-to-min-width("laptop") {
                flex: 1 1 auto;
            }
        }
    }
}
