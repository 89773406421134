.c-selected-subscription-card {
    @include padding(32px, 32px, 48px, 32px);
    @include margin-right(48px);
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    flex-shrink: 0;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 26, 57, 0.2);
    border-radius: 9px;
    max-width: rem(376px);

    @include respond-to("tablet") {
        @include margin-bottom(24px);
        margin-right: 0;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-basis: 100%;
    }

    @include respond-to("phone") {
        @include padding(24px, 24px, 32px, 24px);
    }

    &.-free-trial {
        @include padding-bottom(32px);
        @include respond-to("tablet") {
            min-width: rem(376px);
        }

        @include respond-to("phone") {
            @include padding-bottom(24px);
            min-width: 100%;
        }
        a {
            margin-bottom: 0;
        }

        &.-signed-in {
            width: 100%;
        }
    }

    &__subscription {
        .c-button {
            @include margin-top(16px);
            @include margin-bottom(32px);
        }

        @include respond-to("tablet") {
            flex-basis: 40%;

            a {
                @include padding(7px, 16px);
                @include font-style(
                    $line-height: "xxsmall",
                    $size: "xxsmall",
                    $weight: "bold"
                );

                &.-icon-left {
                    @include padding(5px, 12px, 5px, 7px);

                    .c-icon {
                        @include margin-right(4px);
                        vertical-align: middle;
                    }
                }
            }
        }

        @include respond-to("phone") {
            flex-basis: 100%;
        }

        > span {
            @include margin-bottom(8px);
            @include font-style(
                $color: get-color-neutral("90"),
                $line-height: "xxsmall",
                $size: "xxsmall",
                $weight: "bold"
            );
            display: block;
            letter-spacing: 0.11em;
        }

        div {
            @include padding(8px, 16px);
            @include border-radius();
            display: flex;
            align-items: center;
            border: 1px solid get-color-accents("blue-base");

            .c-icon {
                @include margin-right(24px);
                color: get-color-accents("blue-base");
                height: 40px;
                width: 40px;
            }

            span {
                @include font-style(
                    $color: get-color-accents("blue-dark"),
                    $line-height: "base",
                    $size: "base",
                    $weight: "bold"
                );
            }
        }
    }

    &__radios {
        @include respond-to("tablet") {
            flex-basis: 50%;
        }

        @include respond-to("phone") {
            flex-basis: 100%;
        }

        .c-billing-cycle-radio-buttons {
            margin-top: auto;
        }
    }

    a {
        @include margin(16px, 0, 40px, 0);
        align-self: flex-start;
    }
}
