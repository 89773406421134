.c-publication-sidebar-nav {
    &__chapter-title {
        @include paragraph-styles();
        @include padding(8, 16);
        @include margin-bottom(16);
        @include margin-top(16);
    }

    & .c-publication-meta-nav-parts-list {
        & .c-part-title {
            @include padding(8, 16);
            @include font-style(
                $font-primary,
                "xsmall",
                get-color-neutral("70"),
                400,
                18px
            );
            @include margin-top(40);

            .c-code-change.-addition {
                background-color: transparent;
            }

            &.-link {
                padding-left: 0;
                a {
                    @include padding(8px, 16px);
                    @include font-style(
                        $font-primary,
                        "xsmall",
                        get-color-neutral("70"),
                        400,
                        18px
                    );
                    display: flex;
                    text-decoration: none;

                    &:hover,
                    &:active {
                        text-decoration: underline;
                    }
                    &:hover {
                        @include box-shadow(0, 3px, 10px, rgba(0, 26, 57, 0.1));
                        background-color: get-color-background("primary");

                        .c-publication-sidebar-nav-link__label {
                            text-decoration: underline;
                        }
                    }
                    &.-active {
                        background-color: get-color-buttons("primary-hover");
                        color: get-color-neutral("white");
                    }
                }
            }
        }

        &:first-of-type {
            & .c-part-title {
                @include margin-top(16);
            }
        }
    }
}
