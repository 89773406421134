.c-breadcrumb-navigation {
    @include padding-left(16px);
    min-width: 0;
    flex: 0 2 auto;

    @include respond-to("phone") {
        @include padding-left(8px);
    }

    > ol {
        list-style-type: none;
        padding-left: 0;

        > li {
            display: inline-block;
            margin-bottom: 0;

            &:first-of-type {
                .c-icon > path {
                    fill: get-color-neutral("70");
                }
            }
            &:last-of-type {
                .c-breadcrumb {
                    color: get-color-neutral("70");

                    > span {
                        max-width: initial;
                    }
                }
            }
        }
    }
}
