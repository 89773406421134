.c-user-collection-list-item {
    @include padding(16px);
    @include margin-right(30px);
    @include margin-bottom(30px);
    display: flex;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: rem(180px);
    border: 1px solid get-color-neutral("30");
    border-radius: $border-radius-medium;
    background: white;
    text-decoration: none;
    width: rem(240px);

    @include respond-to("tablet") {
        @include padding(22px);
        height: auto;
        width: 100%;
        margin-right: 0;
    }

    @include respond-to("phone") {
        @include padding(16px, 20px);
    }

    &:hover {
        &:after {
            @include box-shadow(0, 3px, 10px, rgba(0, 26, 57, 0.2), 0);
        }
    }

    &:focus {
        @include box-shadow(
            0,
            0,
            0,
            rgba(get-color-accents("blue-base"), 0.5),
            3px
        );
        border: 1px solid get-color-accents("blue-dark");
        outline: none;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        border: 1px solid get-color-neutral("30");
        width: 100%;
        height: 100%;
        border-radius: rem(9px);
        z-index: -1;
    }

    &:before {
        top: rem(4px);
        left: rem(4px);
    }

    &:after {
        top: rem(8px);
        left: rem(8px);
    }

    &__left {
        span {
            @include margin-top(8px);
        }
    }
    &__meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            @include font-style(
                $size: get-font-size("xxsmall"),
                $line-height: get-line-height("base"),
                $weight: get-font-weight("bold")
            );
            letter-spacing: 0.11em;
            color: get-color-neutral("70");
            text-transform: uppercase;

            @include respond-to("phone") {
                position: static;
            }
        }

        .c-icon {
            @include icon-fill(get-color-neutral("70"));

            @include respond-to("phone") {
                position: static;
            }
        }

        .-team {
            @include icon-fill(get-color-accents("blue-base"));
        }

        &__left,
        &__right {
            display: flex;
            align-items: center;
        }

        &__left {
            gap: rem(6px);
            button {
                background-color: transparent;
            }
        }

        &__right {
            .c-menu-button__icon {
                @include padding(2px);
            }
        }

        .c-menu-button__menu {
            width: rem(200px);

            &__item button {
                color: get-color-neutral("90");
            }
        }
    }

    p {
        @include font-style(
            $size: get-font-size("base"),
            $line-height: get-line-height("base")
        );
        @include margin-bottom(6px);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @include respond-to("tablet") {
            margin-bottom: 0;
        }
        @include respond-to("phone") {
            @include margin-bottom(6px);
        }
    }

    label {
        @include font-style(
            $size: "xsmall",
            $line-height: "xsmall",
            $color: get-color-neutral("70"),
            $style: italic
        );
    }
}
