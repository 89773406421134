.c-table-of-contents-chapter {
    $b: &;
    @include margin-bottom(8px);
    @include border-radius("large");

    &__heading,
    h2 {
        @include font-style(
            $font-primary,
            "base",
            get-color-links("default"),
            400,
            24px
        );
        @include rem-fallback("font-size", 18px);
        display: block;
        text-decoration: none;

        h3 {
            @include font-style($font-primary, 18px, false, 700, 56px);
        }

        &.-small {
            font-size: get-font-size("small");
            @include rem-fallback("font-size", 14px);
            letter-spacing: 0.02em;
        }

        &.-article {
            font-size: get-font-size("xsmall");
        }

        &.-active {
            background-color: get-color("neutral", "white");
        }
    }

    &__heading:hover {
        text-decoration: underline;
    }

    &:hover {
        background-color: get-color-background("neutral");
        cursor: pointer;
    }

    .c-collapse-panel {
        @include border-radius("large");
        &.-expanded {
            background-color: get-color-background("neutral");
        }
        > button:hover {
            background-color: get-color-buttons("tertiary-hover");
        }
    }
}
