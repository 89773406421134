.c-publication-admin-bulk-actions-modal {
    @include margin-top(4vh);
    width: rem(592px);
    min-width: rem(592px);
    overflow: hidden;

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                get-line-height("large")
            );
            display: inline-block;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }
        background-color: get-color-background("neutral");
        width: 100%;

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__content {
        @include padding(32px);

        > fieldset {
            @include margin(16px, 0);
        }

        .c-radio-list > ul > li {
            border: none;
        }

        .c-radio {
            @include padding(8px);
        }
    }

    &__buttons {
        @include padding(24px);
        display: flex;
        justify-content: flex-end;

        > button {
            @include margin-left(16px);
        }
    }
}
