.c-enhanced-content-preview-modal {
    min-width: rem(480px);
    width: rem(600px);

    &__header,
    &__body,
    &__footer {
        @include padding(20px, 20px);
    }

    &__header {
        @include padding(16px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: get-color-background("neutral");
        width: 100%;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                get-line-height("large")
            );
            @include padding-right(16px);
            display: inline-block;
            justify-self: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        > .c-button {
            justify-self: right;
        }

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__content {
        overflow-y: auto;
        max-height: calc(100vh - 300px);

        .c-enhanced-content-panel {
            margin: 0;
            border-radius: 0;
        }
    }

    &__footer {
        @include padding(24px);
        width: 100%;
        border-top: 1px solid get-color-neutral("30");
        background: get-color-neutral("white");
        display: flex;

        a {
            margin-left: auto;
        }
    }

    .c-button.-medium.-tertiary.-modal-close.-icon {
        background: transparent;
        top: rem(20px);
        right: rem(20px);
    }
}
