.c-emulation-mode-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 8px 0;
    border: 1px solid red;
    background-color: get-color-status("warning-light");
    max-height: $emulation-banner-height;

    @include respond-to("phone") {
        justify-content: space-around;
        gap: 10px;
        font-size: x-small;
    }

    &__user {
        display: flex;
        flex-direction: column;

        &__email {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include respond-to("phone") {
                max-width: 140px;
            }
        }
    }

    &__end {
        min-width: fit-content;

        @include respond-to("phone") {
            font-size: x-small;
        }
    }

    &__expires {
        display: flex;
        flex-direction: column;
        @include respond-to("phone") {
            max-width: 100px;
        }
    }
}
