.c-rich-text-area {
    ul {
        @include padding-left(40px);
        list-style: initial;
    }

    ol {
        @include padding-left(40px);
    }

    ul,
    ol {
        @include margin-bottom(16px);

        li {
            @include font-style(
                $line-height: "xlarge",
                $color: get-color-neutral("90")
            );

            del {
                @include font-style($size: "xsmall", $line-height: "xsmall");
            }
        }
    }

    p {
        @include margin-bottom(8px);
    }

    img {
        max-width: 100%;
        height: auto;
    }

    &.-paragraph-small {
        p {
            @include font-size("small");
        }
    }

    @include quill-styles();
}
