.c-support-page {
    @include padding(24px, 0);
    color: get-color-neutral("90");
    width: 100%;

    &__faqs {
        display: flex;
        flex-direction: column;
        gap: rem(10px);
    }

    &__container {
        @extend .o-grid__container;
        flex-direction: row;
    }
    &__left {
        width: 62%;
        float: left;

        @include respond-to("sm-desktop") {
            width: 100%;
            float: none;
        }
    }
    &__emulation-mode-right {
        width: rem(250px);
        position: fixed;
        top: rem(182px);
        left: 75%;

        @include respond-to("sm-desktop") {
            @include padding-bottom(80px);
            width: 100%;
            position: static;
        }
    }
    &__right {
        width: rem(350px);
        position: fixed;
        top: rem(122px);
        left: 65%;

        @include respond-to("sm-desktop") {
            @include padding-bottom(80px);
            width: 100%;
            position: static;
        }
    }
    &__contact-container {
        p:first-of-type {
            @include margin-bottom(8px);
        }

        p:last-of-type {
            @include font-style(
                $color: get-color-neutral("70"),
                $size: "small"
            );
        }

        @include respond-to("laptop") {
            padding: 0;
        }
    }
    &__contact {
        @include margin(16px, 0);

        a {
            @include font-style($size: "base");
        }

        .c-button {
            @include margin-top(10px);
        }
    }

    h4 {
        @include margin-bottom(64px);
    }

    &__change-indicators {
        .c-icon.-change-indicator {
            vertical-align: middle;
            position: static;
        }

        ul {
            li:last-of-type {
                span {
                    white-space: nowrap;
                }
            }
        }
    }

    .c-collapse-panel {
        border-bottom: 1px solid get-color-neutral("30");

        &__accordion {
            p {
                @include margin-bottom(16px);
            }
        }

        &:first-of-type {
            @include margin-top(9px);
            border-top: 1px solid get-color-neutral("30");
        }

        &:last-of-type {
            @include margin-bottom(24px);
        }

        &.-expanded {
            background: get-color-neutral("05");

            .c-collapse-panel__heading p {
                font-weight: bold;
            }
        }

        ul,
        ol {
            @include padding(20px);
        }

        ul {
            list-style-type: disc;
        }

        li > ol {
            @include padding(10px, 20px, 0);
        }

        a {
            line-height: inherit;
            font-size: inherit;
        }
    }
}

.c-application-layout__panel {
    .c-support-page {
        $viewHeightValue: vh(100);
        @include fixedViewHeightScrollY();
        @include respond-to("phone") {
            height: 100vh;
            height: calc(#{$viewHeightValue} - #{$sidebar-menu-width});
            overflow-y: auto;
        }
    }
}
