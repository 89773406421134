.c-publication-batch-reprocess-statuses {
    @include padding(32px);

    &__header {
        display: flex;
        justify-content: space-between;

        > h2 {
            @include font-style(
                $font-primary,
                "base",
                get-color-neutral("90"),
                400,
                18px
            );
        }
    }

    &__table {
        > tbody > tr {
            border-bottom: 1px solid get-color-neutral("50");
        }
        > tbody > td {
            border: none;
        }
    }
}
