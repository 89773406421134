.c-toggle-button {
    &__switch {
        position: relative;
        display: inline-block;
        width: rem(34px);
        height: rem(20px);

        input {
            opacity: 0;
            width: 0;
            height: 0;
            &:checked + .c-toggle-button__slider {
                border: 1px solid get-color-accents("blue-dark");
                &:before {
                    background-color: get-color-accents("blue-base");
                    border: 1px solid get-color-accents("blue-base");
                }
            }
        }
        &.-disabled * {
            cursor: not-allowed;
        }
    }
    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid get-color-neutral("50");
        border-radius: $border-radius-small;
        transition: 0.4s;
        &:before {
            position: absolute;
            content: "";
            height: rem(12px);
            width: rem(12px);
            left: rem(4px);
            bottom: rem(3px);
            background-color: get-color-neutral("70");
            border: 1px solid get-color-neutral("70");
            transition: 0.4s;
            border-radius: $border-radius-xsmall;
        }
    }
    input {
        &:focus + .c-toggle-button__slider {
            outline: 1px solid get-color-accents("blue-dark");
        }
        &:checked + .c-toggle-button__slider:before {
            transform: translateX(12px);
        }
    }
}
