.c-text-input {
    &__hint-text {
        @include font-style(
            $size: "xsmall",
            $color: get-color-neutral("70"),
            $style: italic,
            $line-height: rem(18px)
        );
        margin-top: rem(8px);
        position: absolute;
    }
}
