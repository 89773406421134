.c-user-collection-visibility-modal {
    width: rem(600px);

    @include respond-to("phone") {
        @include fill-viewport-height();
        max-width: initial;
        min-width: initial;
        width: 100%;
    }
    .c-modal {
        &__body {
            height: rem(400px);
            @include font-style($color: get-color-neutral("90"));
            overflow: auto;

            @include respond-to("phone") {
                max-height: calc(
                    #{vh()} - #{$mobile-modal-header-height} - #{$mobile-modal-footer-height}
                );
                height: calc(
                    #{vh()} - #{$mobile-modal-header-height} - #{$mobile-modal-footer-height}
                );
                overflow-y: auto;
            }

            h6 {
                @include font-style($line-height: "base", $style: italic);
            }

            li {
                margin-bottom: 0;
            }

            h6,
            li {
                @include padding(16px, 8px);
                width: 100%;
                border-bottom: 1px solid get-color-neutral("30");
            }
        }
    }
}
