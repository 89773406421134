.c-publication-selection-list {
    &__search-results {
        @include padding-top(4px);

        > span {
            @include margin-bottom(16px);
            @include font-style(
                $size: "xsmall",
                $line-height: "xsmall",
                $color: get-color-neutral("70")
            );
            font-style: italic;
            display: block;
        }

        .c-publication-selection-list-group-item:first-of-type {
            border-top: none;
        }
    }
    &__group {
        @include padding(16px, 0, 0);

        &:last-child {
            @include margin-bottom(16px);
        }

        > span {
            @include margin-bottom(8px);
            @include font-style(
                $size: "xsmall",
                $line-height: "xsmall",
                $color: get-color-neutral("90"),
                $weight: "bold"
            );
            display: block;
            width: 100%;
        }

        &__editions {
            @include font-style(
                $size: "xsmall",
                $line-height: "xsmall",
                $color: get-color-neutral("90")
            );
            &__edition {
                @include padding(16px);
                display: flex;
                align-items: center;

                .c-checkbox {
                    @include icon-fill(get-color-accents("blue-dark"));
                    margin-left: auto;
                }

                &__cover {
                    @include margin-right(16px);
                    height: 44px;
                    width: 32px;
                    border: rem(1px) solid get-color-neutral("30");
                    border-radius: $border-radius-small;
                }

                &:first-of-type {
                    border-top: rem(2px) solid get-color-neutral("30");
                }
                &__title {
                    color: get-color-neutral("70");
                    letter-spacing: 0.02em;
                }
            }
        }

        &__action {
            @include padding-left(16px);
            .c-button.-anchor {
                color: get-color-accents("blue-dark");
            }
        }
    }
}
