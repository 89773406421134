.c-table-of-contents-section {
    @include margin-left(-16px);

    .c-table-of-contents-section__item {
        @include padding(12px, 16px);
        @include font-style(
            $font-primary,
            "base",
            get-color-neutral("90"),
            400,
            24px
        );
        @include rem-fallback(line-height, 24px);
        text-decoration: none;
        display: inline-block;

        &:hover {
            @include border-radius("base");
            background-color: get-color-neutral("05");
            text-decoration: underline;
        }

        span {
            @include margin-right(16px);
        }
        > .c-icon {
            @include icon-fill(get-color-neutral("90"));
            @include margin-left(6px);
            display: inline-block;
            vertical-align: text-top;
        }

        &.-public {
            text-decoration: none;
        }
    }

    &__part {
        .c-code-change.-addition {
            background-color: transparent;
        }
        &:not(:first-of-type) {
            @include margin-top(48px);
        }

        p {
            @include margin-right(16px);
            @include margin-left(16px);
            @include margin-bottom(16px);
            color: get-color-neutral("70");

            &.-link {
                margin-left: 0;
            }

            a {
                @include paragraph-styles();
                @include paragraph-styles("small");
                margin-left: 0;
            }
        }
    }

    ul > li {
        margin-bottom: 0;
    }
}
