.c-publication-batch {
    @include margin-top(100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: rem(400px);

    &__batch-heading {
        @include font-style(
            $size: "xlarge",
            $line-height: "xxlarge",
            $weight: "base"
        );
        @include padding-bottom(5px);
    }
}
