.c-topics-selects {
    position: relative;
    z-index: $topics-selects-z-index;

    &__primary {
        max-width: rem(390px);
    }

    &__secondary {
        max-width: rem(600px);
    }
}
