.c-part-separator {
    scroll-margin: 4rem;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 730px;

    &__part-title {
        width: 100%;
        padding: 1.5rem 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
        border: 1px solid transparent;
        position: relative;
        &.-highlight-section {
            border-color: get-color-status("warning");
            border-style: solid;
            border-radius: 10px;
            border-width: thin;
            scroll-margin-top: 76px;
        }

        &__part-heading {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .c-publication-component-actions {
        visibility: hidden;
    }
    .-hover:hover {
        .c-publication-component-actions {
            visibility: visible;
        }
        @include border-radius("base");
        border: 1px dashed get-color-accents("blue-base");
    }

    .c-icon.-change-indicator {
        position: absolute;
        top: 22px;
        left: rem(-40px);

        @include respond-to("tablet") {
            top: rem(64px);
        }

        @include respond-to("phone") {
            left: rem(-16px);
        }
    }

    &__hr {
        @include font-style(
            $font-primary,
            "base",
            get-color-neutral("90"),
            700,
            24px
        );
        background: get-color-neutral("white");
        flex-shrink: 0;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 100%;
        z-index: 1;
        display: flex;
        align-items: center;

        @include respond-to("phone") {
            width: calc(100% - 16px);
        }
        &__line {
            @include border-bottom(1px, solid, get-color-neutral("30"));
            height: 1px;
            flex-grow: 1;
            @include if-internet-explorer() {
                border: none;
            }
        }
    }
    &__title {
        @include padding(0, 16px);
        display: inline-block;
        max-width: 470px;
    }
}
