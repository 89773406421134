.c-user-role-group-row {
    $baseClass: &;

    @include padding(16px);
    display: flex;
    text-align: left;
    align-items: center;

    @include respond-to("phone") {
        align-items: flex-start;
        flex-direction: column;

        &__name {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }

        &__actions {
            margin-left: auto;
            padding: 1.5rem;
        }

        &__last_active {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }

        &__email {
            @include margin-bottom(8px);
        }
    }

    &:not(:last-of-type) {
        @include box-shadow(0px, 1px, 0px, get-color-neutral("30"));
    }

    & &__name {
        flex-basis: 25%;
    }

    &__email {
        color: get-color-neutral("70");
    }

    &__last_active {
        color: get-color-neutral("70");
        text-align: center;
        margin-left: auto;
    }

    &__type {
        flex-shrink: 0;
        color: get-color-neutral("70");
        font-style: italic;
        text-align: center;
        margin-left: auto;

        @include respond-to("phone") {
            text-align: left;
            margin-left: 0;
        }

        + #{$baseClass}__actions {
            @include margin-left(24px);
            @include respond-to("phone") {
                margin-left: 0;
            }
        }
    }

    &__actions {
        flex-shrink: 0;
    }
}
