.c-select-billing-cycle-modal-v2 {
    @include respond-to("phone") {
        margin: 10vh 16px;
        max-width: none;
        flex: 1 1 auto;
    }

    &__header {
        background-color: get-color-neutral("05");
        padding: rem(30px);
        display: flex;
        justify-content: space-between;
    }

    &__heading {
        display: flex;
        gap: rem(9px);
    }

    &__heading-text {
        @include font-style(
            $color: get-color-neutral("90"),
            $line-height: "large",
            $size: "large",
            $weight: "bold"
        );
    }

    &__team-select-label {
        @include font-style($line-height: "xlarge", $size: "xlarge");
    }

    &__content {
        overflow: auto;
        @include padding(30px);
    }

    &__footer {
        @include margin-top(62px);
        display: flex;
        justify-content: center;
    }

    .c-team-size-select {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
