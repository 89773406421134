.c-onboarding-layout-v2 {
    $banner-height: 80px;
    $banner-height-mobile: 56px;
    width: 100%;

    // for demo
    // min-height: calc(#{vh()} + 1000px);

    &__banner {
        height: $banner-height;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: get-color-neutral("white");
        padding: 0 rem(40px);
        position: sticky;
        top: 0;
        z-index: 1;
        filter: drop-shadow(0px 3px 10px rgba(get-color-neutral("90"), 0.2));

        @include respond-to("phone") {
            height: $banner-height-mobile;
            padding: 0 rem(16px);
        }

        &__home {
            width: fit-content;

            &__logo {
                height: 48px;
                width: auto;

                @include respond-to("phone") {
                    height: 32px;
                }
            }
        }
    }
    &__headerRightSide {
        display: flex;
    }
}
