.c-publish-status-filters-bar {
    @include padding-bottom(8px);
    text-align: center;

    &__button-group {
        display: flex;
        justify-content: center;

        &__item {
            &.c-button.-xsmall {
                padding: 0 rem(8px);
            }

            &.-all {
                &.c-button.-xsmall {
                    padding: rem(5px) rem(16px);
                }
                vertical-align: bottom;
                height: rem(31px);

                &.-selected {
                    p {
                        color: get-color-accents("blue-dark");
                        font-weight: get-font-weight("bold");
                    }
                }
            }

            &.-selected {
                border-color: get-color-accents("blue-dark");
                background-color: get-color-accents("blue-light");

                + .c-publish-status-filters-bar__item {
                    border-left: none;
                }
            }

            .c-publish-status-badge.-small {
                @include font-style(
                    $size: "xxxsmall",
                    $line-height: get-line-height("xxxsmall"),
                    $weight: get-font-weight("bold")
                );
                @include margin(2px);
            }
        }
    }
}

// Media Queries
@include respond-to("tablet") {
    .c-publish-status-filters-bar {
        &__button-group {
            &__item {
                flex-grow: 1;

                &.-all {
                    &.c-button.-xsmall {
                        padding: rem(5px) rem(8px);
                    }
                }
            }
        }
    }
}
