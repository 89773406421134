.c-loader {
    // Default
    margin: 0 auto;
    background-image: url(#{$loader-dual-ball-neutral});
    background-size: contain;
    background-repeat: no-repeat;
    height: 54px;
    width: 54px;

    &.-dual-ball-neutral {
        background-image: url(#{$loader-dual-ball-neutral});
    }
    &.-dual-ball-colored {
        background-image: url(#{$loader-dual-ball-colored});
    }
    &.-link-full {
        background-image: url(#{$loader-link-full});
        height: rem(68.5px);
        width: rem(200px);
    }
    &.-link-glyph {
        background-image: url(#{$loader-link-glyph});
    }
    &.-link-glyph-blue {
        background-image: url(#{$loader-link-glyph-blue});
    }
    &.-link-glyph-gray {
        background-image: url(#{$loader-link-glyph-gray});
    }
    &.-ripple {
        background-image: url(#{$loader-ripple});
        height: 277px;
        width: 277px;
    }
    &.-small {
        height: rem(24px);
        width: rem(26px);
    }
    &.-xsmall {
        height: rem(18px);
        width: rem(20px);
    }
}
