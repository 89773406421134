.c-where-list {
    span:nth-of-type(even) {
        display: inline-block;
    }
    &__content {
        @include padding-left(8px);
    }

    .c-definition {
        display: inline;
        &:after {
            content: "\a";
            white-space: pre;
            display: block;
        }
    }

    .c-symbol {
        display: inline-flex;
        flex-shrink: 0;
        &__equals-sign {
            @include padding(0, 4px);
        }
    }
}
