.c-switch-profile-modal {
    padding-bottom: 0;
    margin-top: 4vh;
    width: rem(600px);
    min-width: rem(600px);
    overflow: hidden;

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                get-line-height("large")
            );
            display: inline-block;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }
        background-color: get-color-background("neutral");
        width: 100%;

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }
    &__card {
        $card: &;
        @include padding(16px, 24px);
        @include font-style(
            $font-primary,
            "small",
            false,
            700,
            get-line-height("small")
        );
        width: 100%;
        background-color: transparent;
        border: 1px solid get-color-neutral("30");
        border-radius: 5px;

        &:last-child {
            @include margin-bottom(24px);
        }

        & + & {
            @include margin-top(16px);
        }

        &.-selected {
            border-color: get-color-accents("blue-base");
            background-color: get-color-accents("blue-light");

            #{$card}__status {
                @include font-style(
                    $font-primary,
                    "xsmall",
                    get-color-accents("blue-dark"),
                    700,
                    get-line-height("xsmall")
                );
                padding: 0;
                font-style: italic;
                border: none;
                opacity: 0.8;
            }
        }

        &:hover {
            @include box-shadow(0, 3px, 10px, rgba(0, 26, 57, 0.2));

            @include respond-to("sm-tablet") {
                box-shadow: none;
            }
        }
        &:focus {
            @include box-shadow(0, 0, 0, rgba(0, 176, 202, 0.4), 3px);
            outline: none;

            @include respond-to("sm-tablet") {
                box-shadow: none;
            }
        }
        &__header {
            display: flex;

            span {
                @include margin-right(8px);
                color: get-color-accents("blue-dark");
                display: flex;
                align-items: center;
                text-align: left;
                word-break: break-all;
                word-break: break-word;

                svg {
                    min-width: rem(24px);
                }
            }
        }
        &__status {
            @include padding(8px, 16px);
            @include font-style(
                $font-primary,
                "xxsmall",
                get-color-neutral("90"),
                700,
                get-line-height("xxsmall")
            );
            margin-left: auto;
            letter-spacing: 0.04em;
            border: 1px solid get-color-neutral("70");
            border-radius: 5px;
        }
        &__team-info {
            @include margin-top(16px);
            display: flex;
            align-items: center;
            color: get-color-neutral("90");
            font-weight: 400;

            span {
                @include font-style($font-primary, "base", false, 400, 24px);
                text-align: left;
                color: get-color-accents("blue-dark");
                display: flex;
                align-items: center;
                word-break: break-all;
                word-break: break-word;

                svg {
                    min-width: rem(24px);
                }
            }
        }

        .c-icon {
            @include margin-right(16px);
        }
    }
    &__content {
        @include padding(32px);
    }

    .c-modal__content {
        overflow-y: auto;
        max-height: 640px;

        @include respond-to("sm-tablet") {
            max-height: initial;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }

    @include respond-to("sm-tablet") {
        @include fill-viewport-height();
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: initial;
    }

    @include respond-to("phone") {
        &__content {
            max-height: calc((var(--vh, 1vh) * 100) - 80px);
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }

        &__card {
            text-align: left;
            &__status {
                @include margin-top(16px);
                display: inline-block;
            }
        }
    }
}
