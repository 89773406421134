.c-sticky-notification {
    @include padding(4px, 8px);
    @include font-style(
        $size: "xxsmall",
        $weight: get-font-weight("bold"),
        $color: get-color-status(error-dark)
    );
    background-color: get-color-status("error-light");
    position: absolute;
    z-index: $sticky-notification-z-index;
    bottom: 0;
    right: 5%;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 5px 5px 0 0;
}
