.c-cover-image {
    &__placeholder {
        background-color: get-color-background("medium");
        height: 100%;
    }
    &.-small {
        width: 40px;
        height: 54px;
    }
}
