.c-publication-filter-modal {
    min-width: rem(600px);
    width: rem(600px);

    .c-modal__content {
        @include respond-to("phone") {
            display: flex;
            flex-direction: column;
        }
    }

    &__header,
    &__body,
    &__footer {
        @include padding(16px);
    }

    &__header {
        @include padding-left(24px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: rem(1px) solid get-color-neutral("30");
        width: 100%;

        @include respond-to("phone") {
            span {
                display: flex;
                align-items: center;
                .c-icon {
                    @include margin-right(8px);
                }
            }
        }

        h2 {
            @include font-style(
                $family: $font-primary,
                $size: "large",
                $color: get-color-neutral("90"),
                $weight: "base",
                $line-height: "large"
            );
            display: inline-block;
            justify-self: left;
        }

        .c-button {
            justify-self: right;
        }

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__search {
        @include padding(16px, 24px);
    }

    &__body {
        @include padding(12px, 24px);
        overflow-y: auto;
        height: rem(600px);
        max-height: calc(100vh - 300px);

        @include respond-to("phone") {
            @include padding(12px, 16px);
            height: auto;
            max-height: 100%;
        }

        .c-checkbox-list label {
            @include margin-bottom(2px);
            color: get-color-neutral("70");
        }
    }

    &__footer {
        width: 100%;
        border-top: 1px solid get-color-neutral("30");
        background: get-color-neutral("white");
        display: flex;
        justify-content: space-between;
    }

    .-modal-close-tertiary {
        background: transparent;
        top: rem(20px);
        right: rem(20px);
    }
}

.c-modal {
    &.c-publication-filter-modal {
        &.-fullscreen {
            min-width: unset;
            width: 100%;
            max-width: 100%;
            max-height: #{vh()};
            margin: 0;
            border-radius: 0;
            padding: 0;
            height: #{vh()};

            & > .c-button {
                &.-modal-close {
                    display: none;
                }
            }

            & > .c-modal__content {
                max-height: #{vh()};
            }

            .c-publication-selection-modal__body {
                @include padding-bottom(84px);
                max-height: calc(#{vh(100)} - 153px);
            }

            .c-publication-selection-modal__footer {
                position: fixed;
                bottom: 0;
                padding-bottom: 1.25rem;
            }
        }
    }
}
