.c-offline-alert-modal {
    @extend %c-confirmation-modal;
    @include respond-to-min-width("tablet") {
        max-width: rem(480px);
    }

    .c-alert-notice {
        @include padding(120px, 90px);
        max-width: 100%;
    }
}
