.c-call-to-action {
    @include padding(24px, 64px);
    @include border-radius("large");
    align-items: center;
    background: get-color-status("info-light");
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 100%;
    width: rem(600px);

    @include respond-to("phone") {
        @include padding(24px);
        margin: 0 auto;
        text-align: center;
        width: 100%;
    }

    &__message {
        @include font-style(
            $size: "xlarge",
            $line-height: "xlarge",
            $weight: "light"
        );
        @include margin-right(24px);
        float: left;

        @include respond-to("phone") {
            float: none;
            @include margin-bottom(16px);
            @include margin-right(0);
        }
    }
}
