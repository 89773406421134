.c-committee-list {
    text-align: center;
    max-width: $section-max-width;

    li:not(:last-of-type) {
        @include padding-bottom(72px);
        border-bottom: 2px solid get-color-neutral("30");
    }

    &:first-of-type li {
        &:first-of-type {
            @include padding-bottom(24px);
            border-bottom: none;

            .c-committee-list__name {
                @include margin-top(16px);
                @include paragraph-styles();
            }
        }

        .c-committee-list__name {
            @include margin-top(40px);
            display: inline-block;
        }
    }

    &__articles {
        @include margin-bottom(40px);
    }

    &__name {
        @extend .c-section-detail__title;
        @include margin-top(72px);
    }

    .c-committee-member {
        @include margin-bottom(24px);
    }

    li:last-of-type {
        @include margin-bottom(0);
        .c-committee-member-group {
            @include margin-bottom(0);

            .c-committee-member:last-of-type:not(:first-of-type) {
                @include margin-bottom(0);
            }
        }
    }
}
