.c-breadcrumb {
    @include font-style(
        $font-primary,
        "xsmall",
        get-color-neutral("90"),
        400,
        22px
    );
    display: flex;
    text-decoration: none;

    @include respond-to("phone") {
        display: none;
    }
    &.-home {
        > .c-breadcrumb__label {
            display: none;
        }
        @include respond-to("phone") {
            display: flex;
        }
    }

    &.-second,
    &.-last {
        @include respond-to("phone") {
            display: flex;
        }
    }

    &.-mobile-separator {
        display: none;
        > .c-icon > path {
            fill: get-color-netural("30");
        }
        @include respond-to("phone") {
            display: flex;
        }
    }

    &:hover {
        text-decoration: underline;
    }

    > span {
        @include respond-to("tablet") {
            max-width: 60px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    > .c-icon {
        @include respond-to("phone") {
            margin: 0.15rem 0.15rem 0 0.15rem;
        }

        margin: 0.15rem 0.25rem 0 0.25rem;
        vertical-align: text-bottom;

        > path {
            fill: get-color-neutral("30");
        }
    }
}
