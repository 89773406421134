.c-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    overflow-y: auto;

    .c-alert-notice {
        @include margin-top(48px);
    }

    &.-authenticated {
        position: absolute;
        top: 0;
        left: $sidebar-menu-width;
        height: 100%;
        width: 100%;
        @include respond-to("phone") {
            left: 0;
            height: calc(100% - #{$sidebar-menu-width});
            top: $sidebar-menu-width;
        }

        .c-alert-notice {
            margin: 0;
            @include respond-to("phone") {
                height: calc(100% - 16px);
            }
        }
    }
}
