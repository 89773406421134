@mixin indicator(
    $background-color: #{get-color-accents("orange-light")},
    $color: #{get-color-status("warning-dark")}
) {
    height: 24px;
    width: 24px;
    padding: 4px;
    border-radius: 24px;
    background-color: $background-color;
    color: $color;

    @include respond-to("tablet") {
        height: 20px;
        width: 20px;
    }
}

.c-icon {
    display: inline-block;
    height: 16px;
    width: 16px;

    &.-medium {
        height: 20px;
        width: 20px;
    }

    &.-large {
        height: 24px;
        width: 24px;
    }

    &.-xlarge {
        height: 44px;
        width: 44px;
    }
    &.-change-indicator {
        @include indicator();
        left: -33px;
        position: absolute;
        top: 3.25rem;
    }

    &__count {
        @include font-size(10px);
        position: absolute;
        right: rem(6px);
        top: 50%;
        transform: translateY(-50%);
        font-weight: 600;
    }

    
}
