.c-subscription-card-feature {
    @include margin-bottom(16px);
    display: flex;
    flex-basis: auto;
    align-items: center;

    .c-icon {
        @include margin-right(24px);
        @include icon-fill("blue-base");
        flex-shrink: 0;
        align-self: flex-start;
    }

    &__content {
        span.-bold {
            font-weight: get-font-weight("bold");
        }

        @include respond-to("tablet") {
            max-width: 260px;
        }
    }
}
