.c-featured-publications-area {
    background-color: get-color-neutral("white");

    &__heading {
        @include padding-top(90px);
        @include margin-bottom(64px);
        text-align: center;
        color: get-color-neutral("90");

        > h4 {
            @include margin-bottom(16px);
        }

        p {
            color: get-color-neutral("70");
        }
    }

    &__section {
        @include padding-bottom(64px);
        max-width: rem(1200px);
        margin-left: auto;
        margin-right: auto;

        &:last-of-type {
            @include padding-bottom(90px);
        }

        h5 {
            @include margin-bottom(32px);
            text-align: center;
            color: get-color-neutral("90");
        }
    }

    &__browse-all,
    &__browse-all a {
        @include font-style(
            $size: "large",
            $line-height: "large",
            $color: get-color-neutral("90")
        );

        text-align: center;
    }

    .c-call-to-action {
        @include margin-top(72px);
    }
}
