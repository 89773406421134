.c-tia-changes-panel {
    $b: &;
    @extend %diff-panel;
    top: -3.5rem;

    &.-title {
        top: -3.25rem;
    }

    &.-part {
        top: -2.5rem;
    }

    &__container {
        @extend %diff-panel-container;
    }

    &__header {
        @extend %diff-panel-header;
    }

    &__heading {
        @extend %diff-panel-heading;
    }

    &__title {
        @extend %diff-panel-title;
    }

    &__closeButton {
        @extend %diff-panel-close-icon;
    }

    &__contents {
        @extend %diff-panel-contents;
        @include padding(16px);
        display: grid;
        grid-auto-flow: row;
        gap: 0.5rem;
        animation: 500ms ease-in tiaEnter;
    }

    &__subheader {
        @extend %diff-panel-subheading;
        line-height: normal;
        //Probably should move line height to change indicator subheading
    }

    &__body {
        @extend %diff-panel-nested-content;
        max-width: 90vw;
    }

    &__controls {
        @include padding(16px);
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid get-color-neutral("30");
    }

    &__control {
        @include font-style(
            $font-primary,
            "xxsmall",
            false,
            700,
            get-line-height("xxsmall")
        );
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: 0;

        &:disabled {
            color: inherit;
            opacity: 0.3;
        }

        &:hover:not(:disabled) {
            #{$b}__controlIcon {
                &.-next {
                    transform: translateX(0.25rem);
                }
                &.-back {
                    transform: translateX(-0.25rem);
                }
            }
        }
    }

    &__controlIcon {
        transition: transform 0.3s ease;
    }

    &__footer {
        @include padding(16px);
    }

    @keyframes tiaEnter {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }

    &__bookviewHeading {
        @include font-style(
            $font-primary,
            "small",
            false,
            700,
            get-line-height("small")
        );
        color: get-color-status("warning-dark");
        text-transform: uppercase;
    }

    &.-list {
        position: sticky;
        top: 0;
        width: unset;

        #{$b}__contents {
            display: flex;
            flex-direction: column;
        }

        #{$b}__container {
            // 64px total y padding + breadcrumb height = 105
            max-height: calc(100vh - 105px);
        }
    }
}
