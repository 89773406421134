.c-chapter-tables-content {
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        @include padding(0, 12px);
    }

    &.-show-changes {
        max-width: MIN(50%, $section-max-width);
    }
}
