.c-publications-admin-dashboard {
    height: 100%;
    @include padding-top(48px);
    display: flex;
    flex-direction: column;

    p,
    label,
    h5 {
        color: get-color-neutral("90");
    }

    .c-form-field__bottom__errors label {
        color: get-color-primary("error");
    }

    &__header {
        > h5 {
            @include padding-left(48px);
            @include padding-bottom(16px);
        }

        &.-border-bottom {
            margin-bottom: 4px;
            border-bottom: 1px solid get-color-neutral("30");
        }

        .c-route-tabs {
            &__panels {
                display: none;
            }
        }
    }

    &__content {
        margin-top: -4px;
        flex-grow: 1;
        display: flex;
        overflow-y: auto;

        &__sidebar {
            display: flex;
            flex-direction: column;
            height: 100%;
            max-width: rem(600px);
            min-width: rem(450px);
            border-right: 1px solid get-color-neutral("30");

            &__filters {
                display: flex;
                justify-content: space-between;
                @include padding(0px, 16px);
            }

            &__select {
                @include padding-left(16px);
                @include margin-bottom(16px);
            }

            &__items {
                @include scrollbar();
                @include padding(8px, 8px, 8px, 16px);
                flex-grow: 1;
                // flexbox-y min-height rule  See: https://stackoverflow.com/a/14964944
                // Setting to 1 works for Chrome and FF, but is off in Safari - doesn't work at all for IE
                // Using calc() solves for both Chrome and Safari.
                height: calc(#{vh()} - 266px);
                overflow-y: auto;
                overflow-x: hidden;

                @include if-internet-explorer {
                    // IE is breaking when using vh() here.
                    // There is a 4px difference between IE and the other browsers.
                    height: calc(100vh - 270px);
                }

                > .c-loader {
                    @include margin-top(48px);
                }
            }

            &__item {
                @include padding(16px, 8px);
                @include margin-bottom(8px);
                border: 1px solid get-color-neutral("30");
                border-radius: $border-radius-small;
                display: block;
                text-decoration: none;

                &:hover,
                &.-selected {
                    background-color: get-color-neutral("05");
                }
            }

            &__footer {
                @include padding(16);
                min-height: rem($admin-footer-height);
                border-top: 1px solid get-color-neutral("30");
            }
        }

        &__editor {
            height: 100%;
            flex-basis: 75%;
            flex-grow: 1;
            overflow-y: auto;

            > .c-loader {
                position: relative;
                top: 25%;
            }

            div.-no-publication {
                p {
                    @include margin-top(320px);
                    @include font-style(
                        $size: "large",
                        $line-height: get-line-height("large")
                    );
                    text-align: center;
                }
            }
        }
    }
}
