.c-sticky-banner {
    @include padding(16px, 32px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    border-radius: $border-radius-small;
    position: fixed;
    z-index: $modal-overlay-z-index;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - #{rem(48px)});
    max-width: rem(944px);

    &.-bottom {
        bottom: rem(36px);
    }

    &.-top {
        top: rem(24px);
    }
}
