.c-add-admin-role-modal {
    max-width: rem(750px);

    &__header {
        @include padding(24px);
        background-color: get-color-neutral("05");
        display: flex;
        justify-content: space-between;

        p {
            @include font-style($size: "large", $line-height: "large");
        }
    }

    &__body {
        @include padding(24px);

        @include if-internet-explorer {
            .c-typeahead {
                .c-multi-select__input__selector__menu-list {
                    max-height: 200px;
                }
            }
        }

        &__user-details {
            &__item {
                @include margin-bottom(24px);

                label {
                    @include font-style(
                        $size: "xxsmall",
                        $line-height: "xxsmall",
                        $weight: "bold",
                        $color: get-color-neutral("70")
                    );
                    text-transform: capitalize;
                }

                p {
                    @include font-style(
                        $size: "small",
                        $line-height: "small",
                        $color: get-color-neutral("90")
                    );

                    &.-empty {
                        @include font-style(
                            $size: "small",
                            $line-height: "small",
                            $color: get-color-neutral("70"),
                            $style: italic
                        );
                    }
                }
            }
        }
    }

    &__footer {
        @include padding(24px);
        text-align: right;
        border-top: 1px solid get-color-neutral("05");

        .c-button {
            @include margin-left(8px);
        }
    }
}
