.c-publication-options-menu {
    .c-menu-button {
        button {
            @include icon-fill(get-color-neutral("70"));

            &:focus,
            &:hover {
                @include icon-fill(get-color-neutral("90"));
            }
        }

        > .c-menu-button__icon {
            @include border-radius("base");
            @include padding(2);
            height: 28px;
            width: 28px;
        }

        .c-menu-button__menu {
            min-width: rem(158px);
            top: 32px;

            .c-menu-button__item {
                button {
                    @include icon-fill(get-color-neutral("50"));
                    color: get-color-neutral("90");

                    &.-highlighted {
                        @include icon-fill(
                            get-color-accents("blue-base"),
                            get-color-accents("blue-base")
                        );
                    }

                    &:disabled {
                        @include icon-fill(get-color-neutral("50"));
                        color: get-color-neutral("50");
                    }
                }

                .c-icon {
                    @include margin-right(8px);
                    vertical-align: text-top;
                }
            }
        }
    }

    &__mobile-modal.c-menu-button {
        .c-menu-button__item {
            button {
                @include font-style($size: "small");
                @include icon-fill(get-color-neutral("50"));
                color: get-color-neutral("90");

                &.-highlighted {
                    @include icon-fill(
                        get-color-accents("blue-base"),
                        get-color-accents("blue-base")
                    );
                }

                &:disabled {
                    @include icon-fill(get-color-neutral("50"));
                    color: get-color-neutral("50");
                }
            }

            .c-icon {
                @include margin-right(8px);
                vertical-align: text-top;
            }
        }
    }
}
