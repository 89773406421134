.c-billing-cycle-cards {
    $b: &;

    $best-value-color: get-color-accents("blue-base");

    &__card-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: rem(30px);

        @include respond-to-min-width("laptop") {
            flex-direction: row;
        }
    }

    &__group-heading {
        text-align: center;
        @include margin-bottom(21px);
        @include font-style($line-height: "xlarge", $size: "xlarge");
    }

    &__card {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        border: 2px solid get-color-neutral("05");
        border-radius: 5px;

        &.-bestValue {
            border-color: $best-value-color;
        }
    }

    &__heading {
        background-color: $best-value-color;
        text-transform: uppercase;
        @include font-style(
            $color: get-color-neutral("white"),
            $line-height: "large",
            $size: "large",
            $weight: 700
        );
        text-align: center;
    }

    &__content {
        @include padding-y(26px);
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    &__price {
        @include font-style(
            $line-height: "xxxlarge",
            $size: "xxxlarge",
            $weight: 300
        );
    }

    &__cycle {
        @include font-style(
            $line-height: "large",
            $size: "large",
            $weight: 700
        );
    }

    &__autorenew {
        @include font-style(
            $color: get-color-neutral("70"),
            $line-height: "base",
            $size: "base",
            $weight: 400
        );
    }

    &__button {
        @include margin(12px);
    }

    &__errors {
        @include margin-top(21px);
        @include font-style(
            $line-height: "small",
            $size: "small",
            $style: italic,
            $weight: "light"
        );
        @include icon-fill(get-color-primary("error"));
        color: get-color-primary("error");
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
}
