.c-faq-panel {
    border: 1px solid get-color-neutral("30");
    @include border-radius("base");
    display: flex;
    flex-direction: column;
    background-color: get-color-neutral("white");

    &__panel-top {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include font-style($size: "large", $color: get-color-neutral("90"));
        @include padding(20px);
        gap: rem(16px);

        &__question {
            text-align: left;
        }

        &__icon {
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include icon-fill(get-color-neutral("70"));
            transition: all 0.25s ease-out;
            flex-shrink: 0;
        }

        &[aria-expanded="true"] &__icon {
            transform: rotateZ(180deg);
        }
    }

    &__content {
        @include font-style($color: get-color-neutral("70"));
        overflow: hidden;
        max-height: 0;
        transition: all 0.5s cubic-bezier(0, 1, 0, 1);
        visibility: hidden;

        &[aria-hidden="false"] {
            transition: max-height 1s ease-in-out;
            max-height: 1500px;
            visibility: visible;
        }

        &__container {
            @include padding(0, 20px, 20px);
        }

        .-answer {
            @include padding-top(12px);
            color: get-color-neutral("70");
        }

        .-u-list,
        .-o-list {
            @include padding(20px, 20px, 0);
            list-style-type: lower-alpha;
        }

        .-u-list {
            list-style-type: disc;
        }

        li > .-o-list {
            @include padding(10px, 20px, 0);
        }

        .-anchor {
            line-height: inherit;
            font-size: inherit;
            font-weight: 400;
            letter-spacing: normal;
        }

        .c-icon.-change-indicator {
            vertical-align: middle;
            position: static;
        }
    }
}

