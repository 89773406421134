.c-enhanced-content-admin-table {
    &__header {
        &-content {
            min-width: rem(148px);
        }

        &-publication {
            min-width: rem(112px);
        }

        &-note {
            min-width: rem(148px);
        }

        &-last-change {
            min-width: rem(276px);
        }
    }

    .-status {
        .c-publish-status-badge {
            margin-right: 0;
        }
    }

    .-collapse {
        padding-left: 0;
        padding-right: 0;
        width: 1px;
        white-space: nowrap;
    }

    .-no-results-row {
        font-style: italic;
        text-align: center;
    }

    thead tr th,
    tbody tr td {
        &.-right-col {
            text-align: right;
        }
    }

    tbody tr td {
        vertical-align: top;

        *:not(.c-publish-status-badge) {
            @include font-style($size: "xsmall");
        }

        p:last-child {
            margin-bottom: 0;
        }

        .c-rich-text-area {
            word-break: break-all;
        }
    }

    &.-middle {
        tbody tr td {
            vertical-align: middle;
        }
    }

    // make menu-button menu go up
    // instead of down so it's not cut off
    // by the bottom of the page
    tbody tr:last-of-type td {
        border-bottom: none;

        .c-menu-button__menu {
            top: rem(-76px);
        }
    }

    tbody tr td {
        .c-menu-button__item:last-of-type {
            button {
                border-top: 1px solid get-color-neutral("light");
            }
        }
    }

    &.c-data-table {
        thead tr th.c-enhanced-content-admin-table__header-status {
            @include padding-right(44px);
        }
    }
}
