.c-publication-preview-page {
    display: flex;
    flex-direction: column;

    &__header {
        background-color: get-color-neutral("05");
        display: flex;
        justify-content: center;
        width: 100%;

        &-content {
            @include rem-fallback("max-width", 840px);
            @include rem-fallback("margin-left", 24px);
            @include rem-fallback("margin-right", 24px);
            @include rem-fallback("padding-top", 48px);
            @include rem-fallback("padding-bottom", 48px);
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            width: 100%;

            @include respond-to("tablet") {
                @include padding(32px, 16px, 16px, 16px);
                align-items: stretch;
                flex-direction: column;
                width: 100%;
            }
        }
    }

    &__title-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .c-button {
            @include rem-fallback("width", 160px);
        }
    }

    &__identification {
        @include rem-fallback("padding-top", 24px);
        @include rem-fallback("padding-bottom", 24px);
        display: flex;
    }

    &__metadata {
        @include rem-fallback("margin-right", 24px);
        width: 100%;

        @include respond-to("phone") {
            width: unset;
        }
    }

    &__cover {
        @include margin-right(40px);

        > img {
            @include border-radius("base");
            @include box-shadow(
                $top: 0,
                $left: 3px,
                $blur: 10px,
                $color: rgba(0, 26, 57, 0.2),
                $spread: false,
                $inset: false
            );
            @include rem-fallback("max-width", 84px);
        }
    }

    &__call-to-action {
        @include padding(24px, 16px);
        background-color: get-color-background("primary");
        border-radius: $border-radius-medium;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 256px;

        &__heading {
            @include font-style(
                $line-height: "large",
                $size: "large",
                $weight: "light"
            );
            text-align: center;
        }

        &__message {
            @include font-style($line-height: "small", $size: "small");
            @include rem-fallback("margin", 16px);
            text-align: center;
        }

        .c-button {
            @include padding(12px, 24px);
            background-color: get-color-neutral("90");
            margin: 0 auto;
            text-align: center;
            width: 70%;
        }

        @include respond-to("tablet") {
            max-width: unset;
        }
    }

    &__edition {
        @include font-style($line-height: "large", $size: "large");
    }

    &__code {
        @include font-style(
            $line-height: "xxxlarge",
            $size: "xxxlarge",
            $weight: "light"
        );
    }

    &__title {
        @include font-style(
            $line-height: "xlarge",
            $size: "xlarge",
            $weight: "light"
        );
    }

    &__content {
        background-color: get-color-background("primary");
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        .c-table-of-contents {
            margin: 0 auto;
            @include margin-bottom(48px);
            @include rem-fallback("width", 840px);
            background-color: get-color-background("primary");
            max-width: 100%;

            @include respond-to("tablet") {
                @include rem-fallback("padding-left", 12px);
                @include rem-fallback("padding-right", 12px);
            }
        }

        .c-call-to-action {
            @include margin-bottom(48px);
            @include rem-fallback("width", 820px);
            align-items: center;
            display: flex;
            justify-content: space-between;

            .c-button {
                @include font-style(
                    $line-height: "small",
                    $size: "small",
                    $weight: "bold"
                );
            }

            @include respond-to("tablet") {
                @include rem-fallback("margin-left", 24px);
                @include rem-fallback("margin-right", 24px);
                width: unset;

                .c-button {
                    @include rem-fallback("width", 150px);
                }
            }

            @include respond-to("phone") {
                align-items: center;
                flex-direction: column;
            }
        }
    }

    .c-table-of-contents-chapter {
        &.-public {
            &.-empty {
                cursor: default;

                &:hover {
                    cursor: default;
                    background-color: get-color-background("primary");
                }

                .c-collapse-panel__heading {
                    cursor: default;
                }

                .c-table-of-contents-chapter__heading:hover {
                    cursor: default;
                }
            }
        }
    }

    .c-table-of-contents-chapter {
        &__heading:hover {
            &.-public {
                text-decoration: none;
            }
        }
    }

    .c-table-of-contents-section__item {
        &.-public {
            &:hover {
                cursor: default;
            }
        }
    }
}
