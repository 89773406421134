.c-change-summaries-admin-controls {
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;

    &__left,
    &__right {
        display: flex;
        flex: 1 0 50%;
        gap: 1rem;
    }
}
