.c-checkbox-list {
    .e-checkbox {
        @include padding(8px);
        @include padding-left(40px);
        @include margin-bottom(8px);

        &__checkmark {
            top: rem(8px);
            left: rem(8px);
        }

        &.-checked {
            background-color: get-color-accents("blue-light");
            color: get-color-accents("blue-base");

            .e-checkbox__checkmark:after {
                border-color: get-color-accents("blue-base");
            }
        }

        &.-disabled {
            .e-checkbox__checkmark {
                background-color: get-color-neutral("30");
            }
        }
    }
}
