.c-situation-related-situation-card {
    border: 1px solid get-color-neutral("30");
    border-radius: $border-radius-medium;
    text-decoration: none;
    display: inline-block;
    width: rem(163px);
    overflow: hidden;
    &:focus {
        @include box-shadow(0px, 0px, 5px, get-color-accents("blue-dark"), 1px);
        outline: none;
    }
    &:hover {
        @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 106px;
        background: get-color-neutral("05");
        overflow: hidden;
        border-bottom: 1px solid get-color-neutral("30");

        img {
            @include object-fit-cover();

            @include if-internet-explorer {
                position: relative;
                transform: none;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
            }
        }

        svg {
            background: get-color-neutral("70");
            padding: 8px;
            border-radius: 50%;
            width: 32px;
            height: 32px;

            path {
                fill: get-color-neutral("white");
            }
        }
    }

    &__content {
        @include padding(10px, 16px, 10px, 48px);
        position: relative;

        .c-icon,
        p {
            display: inline-block;

            @include if-internet-explorer() {
                max-width: 100%;
                word-wrap: break-word;
            }
        }
        .c-icon {
            @include margin-right(12px);
            position: absolute;
            left: rem(16px);
            top: rem(14px);

            path {
                fill: get-color-neutral("50");
            }
        }
    }

    p {
    }
}
