.c-about-modal {
    padding-bottom: 0;
    margin-top: 4vh;
    width: rem(600px);
    min-width: rem(600px);
    overflow: hidden;

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                get-line-height("large")
            );
            display: inline-block;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }
        background-color: get-color-background("neutral");
        width: 100%;

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__content {
        @include padding(32px);

        &__title {
            @include margin-bottom(32px);
        }

        img {
            @include margin-bottom(16px);
            width: rem(40px);
        }

        a {
            @include margin-bottom(8px);
            display: block;

            &:last-of-type {
                @include margin-bottom(16px);
            }
        }
    }

    .c-modal__content {
        overflow-y: auto;
        max-height: 640px;

        @include respond-to("sm-tablet") {
            max-height: initial;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }

    @include respond-to("sm-tablet") {
        @include fill-viewport-height();
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: initial;
    }

    @include respond-to("phone") {
        &__content {
            max-height: calc((var(--vh, 1vh) * 100) - 80px);
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }

        &__card {
            text-align: left;
            &__status {
                @include margin-top(16px);
                display: inline-block;
            }
        }
    }
}
