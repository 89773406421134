.c-publication-card {
    position: relative;
    height: rem(280px);

    @include respond-to("phone") {
        height: rem(156px);
    }

    > a {
        @include box-shadow(0, 3px, 10px, rgba(0, 26, 57, 0.2));
        @include border-radius("base");
        height: 100%;
        text-decoration: none;
        overflow: hidden;
        width: 100%;
        cursor: pointer;
        display: block;
        position: relative;
        z-index: 0;
    }

    $color-bar-height: rem(6px);
    $baseClass: &;

    &.-no-image {
        #{$baseClass}__color-bar,
        #{$baseClass}__detail {
            width: 100%;
        }

        #{$baseClass}__favorite-button {
            background-color: get-color-neutral("05");

            @include if-not-touchscreen {
                &:hover {
                    background-color: get-color-neutral("30");
                }
            }
        }
    }

    @include if-not-touchscreen {
        &:hover {
            @include box-shadow(0, 8px, 15px, rgba(0, 26, 57, 0.2));
            #{$baseClass}__image-container {
                background-color: transparent;
                img {
                    mix-blend-mode: normal;
                }
            }
        }
    }

    @include if-not-touchscreen {
        &:hover:not(.-no-image),
        &:focus:not(.-no-image) {
            #{$baseClass}__color-bar,
            #{$baseClass}__detail {
                @include respond-to-min-width("phone") {
                    left: -25%;
                }
            }
            label {
                &.-edition {
                    position: relative;
                    left: 47%;
                }
            }
        }
    }

    &__color-bar {
        @include get-color-classes-from-map(background, "publication");
        transition: 0.5s; // slide animation duration
        height: $color-bar-height;
        width: 70%;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__detail {
        @include padding(24px);
        transition: 0.5s; // slide animation duration
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - #{$color-bar-height});
        position: absolute;
        left: 0;
        width: 70%;
        top: $color-bar-height;
        bottom: 0;
        background-color: get-color-neutral("white");
        z-index: 1;

        &__top {
            label {
                cursor: pointer;

                &.-edition {
                    transition: 0.5s;
                    left: 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    @include font-style(
                        $size: "small",
                        $line-height: get-line-height("small"),
                        $color: get-color-neutral("90")
                    );
                }

                &.-title {
                    @include font-style(
                        $size: "xsmall",
                        $line-height: get-line-height("xsmall"),
                        $color: get-color-neutral("70")
                    );
                }
            }

            &__legacy-icon-container {
                display: flex;
            }
            &__legacy-icon {
                @include margin-left(5px);
                path {
                    fill: get-color-neutral("50");
                }
            }

            p {
                @include margin-bottom(8px);
                @include font-style($color: get-color-neutral("90"));
                cursor: pointer;
            }

            label,
            p {
                display: block;
                text-decoration: none;
            }
        }
    }

    &__image-container {
        @include get-color-classes-from-map(background-color, "publication");
        display: flex;
        position: relative;
        top: 0;
        height: 100%;
        transition: background-color 1.5s;
        mix-blend-mode: multiply;

        img {
            flex-shrink: 0;
            margin-left: 45%;
            height: 100%;
            max-width: 100%;
            mix-blend-mode: multiply;

            @include respond-to("phone") {
                margin-left: auto;
            }
        }
    }

    &__favorite-button {
        @include padding(6px);
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        border-top-left-radius: $border-radius-small;
        border-bottom-right-radius: $border-radius-small;
        background-color: get-color-neutral("white");
        opacity: 0.75;
        cursor: pointer;
        height: rem(28px);
        width: rem(28px);

        @include if-not-touchscreen() {
            &:hover {
                opacity: 1;
            }
        }
    }

    .c-publication-options-menu {
        position: absolute;
        top: rem(8px);
        right: rem(8px);
        z-index: 1;

        .-has-cover-image {
            .c-menu-button__icon {
                @include icon-fill(get-color-neutral("90"));
                background-color: get-color-neutral("white");
                opacity: 0.75;

                svg {
                    opacity: 1;
                }

                &:focus,
                &:hover {
                    opacity: 1;
                }
            }
        }

        .c-menu-button__icon {
            @include icon-fill(get-color-neutral("70"));

            &:focus,
            &:hover {
                @include icon-fill(get-color-neutral("90"));
            }
        }
    }
}
