.c-featured-publication-card {
    $img-base-width: 150px;
    $img-base-height: 209px;
    $img-mobile-width: 64px;
    $img-mobile-height: 86px;

    width: rem($img-base-width);

    @include respond-to("phone") {
        display: flex;
        width: rem($img-mobile-width);
    }

    &__image {
        @include margin-bottom(16px);
        width: rem($img-base-width);
        height: rem($img-base-height);
        border: 1px solid get-color-neutral("30");
        border-radius: $border-radius-small;
        overflow: hidden;

        @include respond-to("phone") {
            @include margin-right(16px);
            margin-bottom: 0;
            flex-shrink: 0;
            width: rem($img-mobile-width);
            height: rem($img-mobile-height);
        }

        img {
            @include object-fit-cover();

            @include if-internet-explorer {
                width: auto;
            }
        }
    }

    &__text {
        width: rem($img-base-width);
        @include respond-to("phone") {
            width: auto;
        }
        p {
            @include font-style(
                $size: "small",
                $line-height: "small",
                $color: get-color-neutral("90")
            );

            &.-release-text {
                font-weight: get-font-weight("bold");
            }
        }
    }
}
