.c-contact-modal {
    padding-bottom: 0;
    width: rem(600px);
    min-width: rem(600px);
    overflow: hidden;

    &.-fullscreen {
        padding: 0;
    }

    &__header {
        @include padding(24px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h2 {
            @include font-style(
                $font-primary,
                "large",
                get-color-neutral("90"),
                400,
                get-line-height("large")
            );
            display: inline-block;
            justify-self: left;
        }
        background-color: get-color-background("neutral");
        width: 100%;

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__captcha-errors {
        @include margin-top(8px);
        @include margin-right(8px);
        flex-grow: 1;
        font-style: italic;

        label {
            display: block;
            color: get-color-status("error");
        }

        svg {
            position: relative;
            top: rem(2px);
            path {
                fill: get-color-status("error");
            }
        }
    }

    &__content {
        max-height: 60vh;
        overflow-y: auto;

        .-hidden {
            display: none;
        }
    }

    &__container {
        @include padding(24px);

        &.-condensed-bottom {
            padding-bottom: 0;
        }

        &.-condensed {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &__footer {
        @include margin-top(12px);
        border-top: 1px solid get-color-neutral("30");
        text-align: right;

        .c-button {
            @include margin-left(20px);
        }
    }

    @include respond-to("sm-tablet") {
        @include fill-viewport-height();
        margin: 0 auto;
        max-width: initial;
        min-width: initial;
        width: 100%;
        border-radius: initial;
    }

    @include respond-to("phone") {
        .c-modal__content {
            display: flex;
            flex-direction: column;
        }

        &__content {
            max-height: calc(#{vh(100)} - #{$mobile-modal-header-height});

            &__name {
                flex-direction: column;

                .c-form-field {
                    flex-basis: 100%;
                }
            }
        }
    }
}
