.c-publication-selection-modal {
    min-width: rem(600px);
    width: rem(600px);

    &__header,
    &__body,
    &__footer {
        @include padding(20px, 20px);
    }

    &__header {
        @include padding(16px, 16px, 16px, 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: get-color-background("neutral");
        width: 100%;

        > h2 {
            @include font-style(
                $family: $font-primary,
                $size: "large",
                $color: get-color-neutral("90"),
                $weight: "base",
                $line-height: "large"
            );
            display: inline-block;
            justify-self: left;
        }

        > .c-button {
            justify-self: right;
        }

        button.-modal-close {
            @include padding(8px);

            svg {
                display: block;
            }
        }
    }

    &__search {
        @include padding(16px, 24px);
        .c-search-form__submit {
            border-bottom: rem(1px) solid get-color-neutral("30");
        }
    }

    &__body {
        @include padding(12px, 24px);
        overflow-y: auto;
        height: rem(600px);
        max-height: calc(100vh - 300px);

        &__section-title {
            @include font-style($size: "large", $line-height: "large");
            @include margin-bottom(20px);
        }

        &__sub-section-title {
            @include font-style(
                $size: "base",
                $weight: "bold",
                $line-height: "base"
            );
            @include margin-bottom(20px);
        }

        h6 {
            @include margin-bottom(20px);
            @include font-style(
                $family: $font-primary,
                $size: "xxsmall",
                $color: get-color-neutral("70"),
                $weight: 800,
                $line-height: rem(14px)
            );
            text-transform: uppercase;
            letter-spacing: rem(2px);
        }

        div + h5 {
            @include margin-top(40px);
        }
    }

    &__footer {
        width: 100%;
        border-top: 1px solid get-color-neutral("30");
        background: get-color-neutral("white");
        padding-bottom: 3.5rem;

        button {
            float: right;
        }
    }

    .-modal-close-tertiary {
        background: transparent;
        top: rem(20px);
        right: rem(20px);
    }
}

.c-modal {
    &.c-publication-selection-modal {
        &.-fullscreen {
            min-width: unset;
            width: 100%;
            max-width: 100%;
            max-height: #{vh()};
            margin: 0;
            border-radius: 0;
            padding: 0;
            height: #{vh()};

            & > .c-button {
                &.-modal-close {
                    display: none;
                }
            }

            & > .c-modal__content {
                max-height: #{vh()};
            }

            .c-publication-selection-modal__body {
                @include padding-bottom(84px);
                max-height: calc(#{vh(100)} - 153px);
            }

            .c-publication-selection-modal__footer {
                position: fixed;
                bottom: 0;
                padding-bottom: 1.25rem;
            }
        }
    }
}
