.c-tag {
    @include padding(5px);
    @include margin-right(5px);
    @include margin-bottom(5px);
    font-style: normal;
    font-weight: get-font-weight("bold");
    border-radius: $border-radius-small;

    &.-enhancedContent {
        background-color: get-color-accents("blue-light");
        color: get-color-accents("blue-dark");
    }
}
