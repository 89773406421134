.c-section-detail-actions {
    display: flex;
    align-items: center;
    min-height: rem(28px);

    > .c-menu-button {
        @include margin-top(4px);
        // Increasing z-index to force menu button to appear above white border for any sub-sections
        display: flex;
        z-index: 9;
        width: rem(28px);
        height: rem(28px);

        @include respond-to("phone") {
            z-index: 8;
        }

        &.-hidden {
            visibility: hidden;
        }

        > .c-menu-button__icon {
            @include border-radius("base");
            @include padding(5px);
        }

        .c-menu-button__menu {
            min-width: rem(158px);
            top: 32px;
            .c-menu-button__item {
                @include icon-fill(get-color-neutral("50"));
                .c-button {
                    color: get-color-neutral("90");
                }

                .c-icon {
                    @include margin-right(8px);
                    vertical-align: text-top;
                    background: transparent;
                }
            }
        }
    }

    // the menu modal on mobile layouts
    &__modal {
        .c-menu-button {
            &__item {
                @include icon-fill(get-color-neutral("50"));
                .c-button {
                    color: get-color-neutral("90");
                }

                .c-icon {
                    @include margin-right(8px);
                    vertical-align: text-top;
                }
            }
        }
    }

    &__icon-container {
        @include get-color-classes-from-map(
            background,
            "bookmark",
            "background"
        );
        border: 2px solid get-color-neutral("white");
        position: relative;
        display: inline-block;
        border-radius: 50%;
        height: 32px;
        width: 32px;

        &.-secondary-bookmark {
            @include get-color-classes-from-map(background, "bookmark");
        }

        &.-has-count {
            @include padding-right(10px);
            border-radius: 32px;
            width: auto;

            &.-secondary-bookmark {
                padding-right: 0;
                height: 28px;
                width: 28px;
            }

            span {
                @include font-size(10px);
                position: absolute;
                right: rem(6px);
                top: 50%;
                transform: translateY(-50%);
            }
        }

        & ~ .c-section-detail-actions__icon-container {
            margin-left: -22px;
            z-index: 0;
        }
        $z: -8;
        @for $i from 1 through 8 {
            &:nth-of-type(#{$i}) {
                z-index: abs($z);
            }
            $z: $z + 1;
        }
    }

    &__notes {
        .c-button {
            @include margin-right(8px);
            display: flex;
            padding: 0;
            border-radius: 50%;

            &.multi {
                border-radius: rem(16px);
            }
        }

        .c-icon {
            @include bookmark-icon-colors();
            @include padding(6px);
            position: relative;
            height: 28px;
            width: 28px;
        }
    }
}
