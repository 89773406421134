.c-situation-page {
    background: get-color-neutral("05");
    height: 100%;
    overflow-y: auto;
    word-break: break-word;

    &__additional-info-button {
        @include margin-bottom(18px);
    }

    &__additional-info-container {
        @include padding(24);
        background: get-color-neutral("05");

        &.-has-info {
            // add bottom margin so that the "Additional Info" button can properly scroll to it
            // when there is only a small amount of content.
            margin-bottom: #{vh(60)};
        }

        &__header {
            @include margin-bottom(16px);
        }
    }

    &__body {
        @include paragraph-styles("small");
    }

    &__container {
        @include padding(24);
        @include padding-bottom(56);
        background: get-color-neutral("white");
    }

    &__content {
        @include padding-top(8);
        max-width: 730px;
        margin: 0 auto;

        > p {
            @include margin-bottom(18px);
            color: get-color-neutral("70");
        }

        &__map {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            &__hotspot {
                @extend .c-admin-hotspot-section__hotspot;
            }
        }

        &__image {
            &-container {
                position: relative;
            }

            @extend .c-admin-situation-hotspot-image__image-container__hotspot__map;
            @include margin-bottom(24px);
        }
    }

    &__header {
        @include margin-bottom(16px);
    }

    &__situation-icon {
        @include margin-right(8px);
        vertical-align: middle;

        path {
            fill: get-color-neutral("70");
        }
    }

    &__top {
        @include padding-left(24);
        @include padding-right(24);
        @include padding-top(24);
        background: get-color-neutral("white");
        display: flex;
        justify-content: space-between;
    }

    .c-situation-related-situation-card {
        position: relative;
    }
}
