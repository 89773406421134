.c-publication-anchor,
%c-publication-anchor {
    @include paragraph-styles();
    font-weight: 700;

    &.-deletion {
        display: none;
    }

    &.c-code-change.-deletion {
        display: none;
    }
}
