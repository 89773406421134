.c-hidden-content-alert-banner {
    $mobile-top-padding: 1rem;
    $phone-total-x-padding: 3.625rem;
    $phone-padding-left: 2.625rem;
    $phone-padding-right: 1rem;
    $tablet-x-padding: 3rem;
    $top-padding: 2rem;

    background-color: get-color-status("info-light");
    display: flex;
    place-content: center;
    position: sticky;
    top: 0;
    transform: translateY(-$top-padding);
    width: 100%;
    z-index: $hidden-content-banner-z-index;

    &__button {
        color: get-color-accents("blue-dark");
        text-decoration: underline;
        @include font-style(
            $size: "xsmall",
            $letter-spacing: 0.02em,
            $line-height: 1
        );
        vertical-align: middle;
    }

    @include respond-to("desktop") {
        margin: 0 -#{$tablet-x-padding};
        min-width: calc(100% + 2 * #{$tablet-x-padding});
    }

    @include respond-to("phone") {
        margin-left: -$phone-padding-left;
        margin-right: -$phone-padding-right;
        min-width: 100vw;
        transform: translateY(-$mobile-top-padding);
    }

    &__text {
        @include font-style(
            $size: "xsmall",
            $letter-spacing: 0.02em,
            $line-height: 1
        );
        vertical-align: middle;
    }
    .c-alert-banner {
        &__info {
            border-radius: unset;
        }
        padding-right: 1rem;
    }
}
