.c-offline-book-list {
    @include margin-top(16px);
    @include rem-fallback(width, 480px);
    display: flex;
    flex-direction: column;

    @include respond-to("phone") {
        width: 100%;
    }
}
