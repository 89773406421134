.c-support-user-detail-page {
    $x-padding: 48px;
    @include padding(0px, $x-padding);
    display: flex;
    flex-direction: column;
    gap: 30px;

    > .c-button {
        display: flex;
        align-items: center;
        gap: rem(4px);
        @include padding(8px, 16px);
        width: fit-content;
    }

    &__user-details {
        display: flex;
        flex-direction: column;
        gap: rem(30px);

        &__user {
            display: flex;
            flex-direction: row;
            color: get-color-neutral("90");
        }

        &__emulate {
            margin: 10px 10px 10px 50px;
        }

        &__data {
            display: flex;
            gap: rem(60px);

            &__item {
                display: flex;
                flex-direction: column;

                &__heading {
                    text-transform: uppercase;
                    @include font-style(
                        $size: "xxsmall",
                        $color: get-color-neutral("70"),
                        $weight: "bold",
                        $letter-spacing: 0.11em,
                        $line-height: "xlarge"
                    );
                }

                &__value {
                    @include font-style(
                        $size: "small",
                        $color: get-color-neutral("70"),
                        $weight: "base",
                        $line-height: "small"
                    );
                    cursor: pointer;

                    > .c-button {
                        color: inherit;
                        font-weight: inherit;
                    }
                }
            }

            &__extend {
                display: flex;
                gap: 2px;

                &__date-picker {
                    padding-top: 4px;
                    padding-bottom: 4px;
                }
            }
        }
    }

    &__subscriptions {
        padding-bottom: rem(48px);
        width: 100%;

        &__heading {
            @include font-style(
                $size: "base",
                $weight: "base",
                $line-height: "small",
                $color: get-color-neutral("90")
            );

            @include padding-bottom(16px);
        }

        &__tabs-row {
            @include margin-left(-$x-padding);
            padding-left: calc(#{$x-padding} - 8px);
            width: calc(100% + (2 * #{$x-padding}));
            box-shadow: inset 0px -1px 0px get-color-neutral("30");
            display: flex;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;

            &__tab {
                display: flex;
                @include padding(8px);
                align-items: center;
                gap: 4px;
                @include font-style(
                    $size: "xsmall",
                    $weight: "bold",
                    $line-height: "small",
                    $color: get-color-neutral("70"),
                    $letter-spacing: 0.02em
                );
                margin-bottom: 3px;

                &.-active {
                    box-shadow: 0px 3px 0px get-color-accents("blue-base");
                    color: get-color-accents("blue-dark");
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        &__details {
            display: flex;
            flex-direction: column;
            gap: rem(16px);
            @include padding-top(32px);
        }
    }
}
