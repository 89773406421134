.c-offline-tab-header {
    @include margin-bottom(24px);

    .c-alert-banner {
        @include margin-bottom(24px);
        max-width: rem(480px);
    }

    &__usage,
    &__status {
        @include font-style(
            $color: get-color-neutral("70"),
            $line-height: "small",
            $size: "small"
        );
    }

    &__status {
        span {
            &.-running {
                color: get-color-status("success");
            }
            &.-waiting {
                color: get-color-status("warning");
            }
            &.-disabled {
                color: get-color-status("error");
            }
        }
    }
}
