.c-resource-card {
    @include margin-top(8px);
    @include padding(16px);
    border-radius: $border-radius-medium;
    border: 1px solid get-color-neutral("30");
    display: flex;
    text-decoration: none;
    position: relative;

    &:focus {
        @include box-shadow(0px, 0px, 5px, get-color-accents("blue-dark"), 1px);
        outline: none;
    }
    &:hover {
        @include box-shadow(0px, 3px, 10px, rgba(0, 26, 57, 0.2));
    }

    &:last-child {
        @include margin-bottom(40px);
    }

    &__thumbnail-container {
        position: relative;

        &__video-play-icon {
            border-radius: 100%;
            background-color: get-color-accents("blue-base");
            position: absolute;
            top: rem(50px);
            left: rem(30px);
            transform: translateX(-50%);
            z-index: 1;
            border: rem(2px) solid get-color-neutral("white");
            width: rem(22px);
            max-width: rem(22px);
            height: rem(22px);
            max-height: rem(22px);
            text-align: center;

            svg {
                vertical-align: middle;

                path {
                    fill: get-color-neutral("white");
                }
            }
        }

        &__thumbnail {
            @include margin-right(16px);
            border-radius: $border-radius-medium;
            overflow: hidden;
            width: rem(60px);
            max-width: rem(60px);
            min-width: rem(60px);
            height: rem(60px);
            max-height: rem(60px);
            min-height: rem(60px);
            position: relative;

            &__video {
                height: 100%;
                width: 100%;
                position: relative;
                background: get-color-neutral("05") url($file-video-icon)
                    no-repeat center;

                video {
                    @include object-fit-cover();
                    width: 100%;
                    height: 100%;
                }
            }

            &__image {
                height: 100%;
                width: 100%;
                position: relative;

                img {
                    @include object-fit-cover();
                    width: 100%;
                    height: 100%;
                }
            }

            &__icon {
                @include padding(16px);
                background-color: get-color-neutral("05");
                text-align: center;

                .c-icon {
                    path {
                        fill: get-color-neutral("70");
                    }
                }
            }
        }
    }

    &__info {
        flex-grow: 1;
        overflow: hidden;

        &__metadata {
            @include margin-top(4px);

            p {
                @include font-size("xsmall");
                @include line-height("xsmall");
                color: get-color-neutral("70");
                font-style: italic;
            }
        }

        &__title {
            @include font-size("small");
            @include line-height("small");
            @include ellipsis();
            font-weight: get-font-weight();
        }

        &__subtitle {
            p {
                @include font-size("xsmall");
                @include line-height("xsmall");
                color: get-color-neutral("70");
            }
        }
    }
}
