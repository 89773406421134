.c-user-collection-page {
    @include padding(40px, 0, 0);
    height: 100%;
    display: flex;
    flex-direction: column;

    @include respond-to("phone") {
        @include padding(8px, 0, 0);
    }

    &__page-label {
        @include respond-to("phone") {
            @include margin(8px);
        }
        @include margin-bottom(16px);
        display: flex;

        &.-team {
            @include icon-fill(get-color-accents("blue-base"));
        }
    }

    &__header {
        @include respond-to("phone") {
            @include margin(8px);
            @include padding(8px);
        }
        @include padding(0, 48px);
        @include margin-bottom(24px);
        background: get-color-neutral("white");

        &__top {
            @include respond-to("phone") {
                @include margin(0);
            }
            @include clearfix();
            @include margin(24px, 0);

            svg.c-icon {
                margin-right: 0;
            }

            .c-menu-button {
                float: right;
            }
        }

        &__meta {
            h4 {
                @include font-style(
                    $size: "large",
                    $line-height: "large",
                    $weight: "base",
                    $color: get-color-neutral("90")
                );
            }

            label {
                @include font-style(
                    $size: "xsmall",
                    $line-height: "xsmall",
                    $color: get-color-neutral("70"),
                    $style: italic
                );
            }
        }

        &__controls {
            @include margin-top(23px);
            display: flex;
            justify-content: space-between;

            .c-text-input-icon {
                width: 60%;
            }

            .c-dropdown-button {
                &:last-of-type {
                    @include margin-left(16px);
                }
            }
        }

        .c-icon {
            @include margin-right(8px);
            vertical-align: middle;

            path {
                fill: get-color-neutral("70");
            }
        }
    }

    &__bookmarks {
        @include margin-left(-48px);
        @include margin-right(-48px);

        @include respond-to("phone") {
            @include margin-left(-16px);
            @include margin-right(-16px);
        }

        height: 100%;
    }
}
