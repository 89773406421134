.c-file-upload-progress-bar {
    &.-success {
        .c-file-upload-progress-bar__top {
            &__status {
                &__text {
                    p {
                        color: get-color-status("success-dark");
                    }
                }
            }
        }
    }

    &__top {
        &__status {
            display: flex;

            &__percent {
                color: get-color-neutral("70");
                font-size: get-font-size("xsmall");
            }

            &__text {
                flex-grow: 1;

                svg {
                    vertical-align: top;

                    path {
                        fill: get-color-status("success");
                    }
                }

                p {
                    display: inline-block;
                    font-size: get-font-size("xsmall");
                    font-weight: get-font-weight("bold");
                }

                &.-error {
                    p {
                        font-weight: get-font-weight("base");
                        color: get-color-status("error-dark");
                        @include margin-right(8px);
                    }

                    .c-button.-anchor {
                        font-size: get-font-size("xsmall");
                        font-weight: get-font-weight("bold");
                        color: get-color-status("error-dark");
                    }
                }
            }
        }
    }

    &__bar {
        @include margin-bottom(8px);
    }
}
