.c-landing-page-plans {
    &__cards {
        display: grid;
        gap: rem(40px);

        @include respond-to-min-width("laptop") {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__header {
        @include margin-bottom(44px);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__title {
        @include font-style(
            $font-primary,
            "xxxlarge",
            inherit,
            300,
            "xlarge",
            0.2em
        );
        @include margin-bottom(9px);
    }

    &__intro {
        max-width: rem(730px);
        @include font-style($font-primary, "large");
    }

    &__footer {
        text-align: center;
        padding-top: rem(42px);
    }

    &__free-trial-link {
        @include font-style($font-primary, "large");
    }
}
